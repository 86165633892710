import * as React from 'react';
import {
  ICheckboxProps,
} from '@amount/frontend-components';
import { useLazyQuery } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';

import GetLabelSuggestionsQuery, {
  GetLabelSuggestions,
  GetLabelSuggestionsInput,
  SelectionOnSuggestions as ILabel,
} from '../../queries/getLabelSuggestions.graphql';
import GetUserSuggestionsQuery, {
  GetUserFields,
  GetUserFieldsInput,
  SelectionOnRequestUserSuggestions as IUser,
} from '../../queries/getUserSuggestions.graphql';

import TagInput, { ITagInputProps, TagMap } from './TagInput';

export interface IMultiStateEntry extends ICheckboxProps {
  id: string;
}

type SearchWithTermType = (query: string) => void;

export const TagInputSearch = <T extends GetLabelSuggestions | GetUserFields>(searchQuery: DocumentNode, serviceDeskId: string): {
  data: T | undefined; loading: boolean; searchWithTerm: SearchWithTermType;
} => {
  const [getSuggestions, { data, loading }] = useLazyQuery<T, GetLabelSuggestionsInput | GetUserFieldsInput>(searchQuery);
  const searchWithTerm: SearchWithTermType = q => getSuggestions({ variables: { query: q, id: serviceDeskId } });

  return { data, loading, searchWithTerm };
};

export const LabelInput: React.FC<ITagInputProps> = props => {
  const { data, loading, searchWithTerm } = TagInputSearch<GetLabelSuggestions>(GetLabelSuggestionsQuery, props.serviceDeskId);
  const suggestions: ILabel[] = data && data.requestLabelSuggestions.suggestions || [];
  const options: Map<string, string> = suggestions.reduce((acc: TagMap, l: ILabel) => acc.set(l.label, l.label), new Map());

  return <TagInput {...props} options={options} onSearch={searchWithTerm} loading={loading} tagAddable={true} />;
};

export const UserInput: React.FC<ITagInputProps> = props => {
  const { data, loading, searchWithTerm } = TagInputSearch<GetUserFields>(GetUserSuggestionsQuery, props.serviceDeskId);
  const suggestions: IUser[] = data && data.requestUserSuggestions || [];
  const options: Map<string, string> = suggestions.reduce((acc: TagMap, u: IUser) => acc.set(u.accountId, u.displayName), new Map());

  return <TagInput {...props} options={options} onSearch={searchWithTerm} loading={loading} />;
};
