import * as React from 'react';
import { GlobalStyleWrap, IThemeInterface, VALID_THEMES } from '@amount/frontend-components';
import { ThemeProvider as ThemeProviderComponent } from 'styled-components';
import Events from '@avant/crm-frontend-utils/events';
// tslint:disable-next-line: no-submodule-imports
import amountTheme from '@amount/frontend-components/es/lib/StyledComponents/Theme/index.amount';
// tslint:disable-next-line: no-submodule-imports
import darkTheme from '@amount/frontend-components/es/lib/StyledComponents/Theme/index.amountDark';

import { getDarkModeEnabled, partnersThemeKey, THEME_CHANGED_EVENT } from '../services/theme';

type PARTNERS_VALID_THEMES = typeof VALID_THEMES.amount | typeof VALID_THEMES.amountDark;

const THEME_MAP: { [theme in PARTNERS_VALID_THEMES]: IThemeInterface } = {
  [VALID_THEMES.amount]: amountTheme,
  [VALID_THEMES.amountDark]: darkTheme
};

interface IThemeProviderState {
  theme: PARTNERS_VALID_THEMES;
}

export class ThemeProvider extends React.Component<{}, IThemeProviderState> {
  public state: IThemeProviderState = {
    theme: getDarkModeEnabled() ? VALID_THEMES.amountDark : VALID_THEMES.amount
  };

  public componentDidMount (): void {
    Events.subscribe(THEME_CHANGED_EVENT, this.changeTheme);
  }

  public componentWillUnmount (): void {
    Events.unsubscribe(THEME_CHANGED_EVENT, this.changeTheme);
  }

  public render (): JSX.Element {
    const theme: IThemeInterface = THEME_MAP[this.state.theme];

    return (
      <ThemeProviderComponent theme={theme}>
        <>
          {/* tslint:disable-next-line: no-any */}
          <GlobalStyleWrap {...theme as any} />
          {this.props.children}
        </>
      </ThemeProviderComponent>
    );
  }

  private readonly changeTheme: (e: CustomEvent<{ detail: boolean }>) => void = e => {
    if (!!e.detail) {
      partnersThemeKey.setTrue();
      this.setState({ theme: VALID_THEMES.amountDark });
    } else {
      partnersThemeKey.setFalse();
      this.setState({ theme: VALID_THEMES.amount });
    }
  }
}
