import * as React from 'react';
import styled from 'styled-components';

import { SelectionOnCreateAttachment } from '../../queries/createRequestAttachment.graphql';
import RemoveableFile from '../../RequestDetail/RemoveableFile';

import { AttachmentUploaderWithRouter } from './UploadAttachment';

interface IAttachmentSection {
  id: string;
  value: string[];
  formControlId: string;
  setAttachments (name: string, attachment: string[]): void;
}

interface IState {
  attachments: Map<string, string>;
}

const PaddedContainer = styled.div`
  padding-top: 1em;
`;

export class FormAttachment extends React.Component<IAttachmentSection, IState> {
  public state: IState = {
    attachments: new Map()
  };

  public render (): JSX.Element {
    return (
      <PaddedContainer>
        {this.props.value.map(v => (
          <RemoveableFile
            key={name}
            id={v}
            fileName={this.state.attachments.get(v) || 'Attachment Not Found'}
            onRemove={this.removeAttachment}
          />
        ))}
        <AttachmentUploaderWithRouter
          setAttachments={this.handleNewAttachments}
          formControlId={this.props.formControlId}
        />
      </PaddedContainer>
    );
  }

  private readonly handleNewAttachments: (data: SelectionOnCreateAttachment) => void = ({ temporaryAttachments }) => {
    const newAttachments: Array<[string, string]> = temporaryAttachments.map(a => [a.temporaryAttachmentId, a.fileName]);
    this.setState({ attachments: new Map<string, string>([...this.state.attachments, ...newAttachments]) });

    const attachmentIds = temporaryAttachments.map(a => a.temporaryAttachmentId);
    this.props.setAttachments(this.props.id, [...this.props.value, ...attachmentIds]);
  }

  private readonly removeAttachment = (fileid: string): void => {
    const filteredAttachments = this.props.value.filter(v => v !== fileid);
    this.props.setAttachments(this.props.id, filteredAttachments);
  }
}
