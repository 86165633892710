import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import {
  Banner,
  BannerLink,
  ConditionalRenderWrapper,
  Headline,
  media,
} from '@amount/frontend-components';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { InnerContainer } from '../InnerContainer';
import { PartnersContainer } from '../PartnersContainer';
import {
  AlertIcon,
  AlertLabel,
  StyledAlert,
} from '../CommonComponents/Alert';
import {
  AVANT_HELPDESK,
  HELPDESK_PERMISSIONS,
  VIEW_HELPDESK,
} from '../../../shared/config/permissions';
import { PermissionedComponent } from '../PermissionedComponent';
import HomeRedirectComponent from '../HomeRedirectComponent';
import { MaxWidthContainer } from '../MaxWidthContainer';

import HelpdeskSubmit from './Submit';
import HelpdeskCard from './Common/HelpdeskCard';
import { HelpdeskSubtitle } from './Common';
import { Config } from './Config';

const CardContain = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  grid-gap: 10px;
  width: 100%;
  padding-top: 1em;

  ${media.small`
    grid-gap: 16px;
  `}
`;

const ErrorBanner = styled(Banner)`
  padding: 1em 0.75em 0.75em;
  text-align: center;
  font-size: 1.5rem;
`;

const HelpdeskCategory = styled.div`
  padding-top: 2.5em;

  &:last-child {
    padding-bottom: 2em;
  }
`;

const HelpdeskAlert = styled(StyledAlert)`
  margin-top: 1em;
`;

const TECHNICAL_ALLOWED_PERMISSIONS: HELPDESK_PERMISSIONS[] = [
  AVANT_HELPDESK
];
const BUSINESS_ALLOWED_PERMISSIONS: HELPDESK_PERMISSIONS[] = [
  AVANT_HELPDESK
];

const helpdeskSubtitles: Map<HelpdeskSubtitle, HELPDESK_PERMISSIONS[]> = new Map<HelpdeskSubtitle, HELPDESK_PERMISSIONS[]>([
  ['Technical Requests', TECHNICAL_ALLOWED_PERMISSIONS],
  ['Business Requests and Inquiries', BUSINESS_ALLOWED_PERMISSIONS],
]);

const allHelpdeskPermissions: HELPDESK_PERMISSIONS[] = [
  ...TECHNICAL_ALLOWED_PERMISSIONS,
  ...BUSINESS_ALLOWED_PERMISSIONS,
];

const NoSections: React.FC = () => (
  <HelpdeskAlert alertStyle='inactive'>
    <ConditionalRenderWrapper hiddenOnMobile={true} breakpoint='small'>
      <AlertIcon icon='circle-alert-large' />
    </ConditionalRenderWrapper>
    <AlertLabel>
      You do not have any sections.
      Please contact <BannerLink bannerStyle='inactive'>
        <a href='mailto:partnerportalsupport@amount.com'>partnerportalsupport@amount.com</a>
      </BannerLink>.
    </AlertLabel>
  </HelpdeskAlert>
);

const Helpdesk: React.FC = () => (
  <PermissionedComponent allowedPermissions={[VIEW_HELPDESK]} NoAccessComponent={HomeRedirectComponent}>
    <Helmet>
      <title>Helpdesk · Partner Portal</title>
    </Helmet>
    <ErrorBanner bannerStyle='warning'>
      <span>Please use the </span>
      <BannerLink>
        <a href='https://avantinc.atlassian.net/servicedesk/customer/portals'>Avant Service Desk</a>
      </BannerLink>
      <span> to submit a request.</span>
    </ErrorBanner>
    <PartnersContainer>
      <MaxWidthContainer>
        <InnerContainer>
          <Headline scale='large'>Helpdesk</Headline>
          <PermissionedComponent allowedPermissions={allHelpdeskPermissions} NoAccessComponent={NoSections} />
          {Array.from(helpdeskSubtitles, ([subtitle, subtitlePermissions]) => (
            <PermissionedComponent allowedPermissions={subtitlePermissions} key={subtitle}>
              <HelpdeskCategory key={`${subtitle}-container`}>
                <Headline scale='small'>{subtitle}</Headline>
                <CardContain>
                  {Object.keys(Config)
                    .filter(helpdeskSection => Config[helpdeskSection].helpdeskSubtitle === subtitle)
                    .map(helpdeskSection => (
                      <HelpdeskCard
                        section={helpdeskSection}
                        iconType={Config[helpdeskSection].iconType}
                        title={Config[helpdeskSection].title}
                        subtitle={Config[helpdeskSection].subtitle}
                        key={helpdeskSection}
                      />
                    ))
                  }
                </CardContain>
              </HelpdeskCategory>
            </PermissionedComponent>
          ))}
        </InnerContainer>
      </MaxWidthContainer>
    </PartnersContainer>
  </PermissionedComponent>
);

const HelpdeskRoutes: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/:section`} >
      <HelpdeskSubmit />
    </Route>
    <Route path='' >
      <Helpdesk />
    </Route>
    <Redirect
      exact={true}
      from='**'
      to={''}
    />
  </Switch>
);

const RoutedRoutes = withRouter(HelpdeskRoutes);

export default RoutedRoutes;
