import * as React from 'react';
import { MutationFn } from 'react-apollo';
import { DrawerClose, DrawerItem, DrawerTitle, DrawerTitleSpan, SVGIcon } from '@amount/frontend-components';

import Modal from '../../Modal';
import { DeleteFolder, DeleteFolderInput } from '../queries/deleteFolder.graphql';
import { MoveFile, MoveFileInput } from '../queries/moveFile.graphql';

import {
  drawerMobilePadding,
  FileIcon,
  IFolderBaseProps,
} from './common';

export interface ILiftedFolderDrawer extends Pick<IFolderBaseProps, 'Prefix' | 'root'> {
  show: boolean;
  folderName: string | undefined;
  close: React.MouseEventHandler;
  showEdit: React.MouseEventHandler;
  showDelete: React.MouseEventHandler;
}

interface IFolderDrawer extends ILiftedFolderDrawer {
  renameFolder: MutationFn<MoveFile, MoveFileInput>;
  deleteFolder: MutationFn<DeleteFolder, DeleteFolderInput>;
}

export const FolderDrawer: React.FC<IFolderDrawer> = props => (
  <Modal
    show={props.show}
    close={props.close}
    drawer={true}
    padding={drawerMobilePadding}
  >
    <DrawerClose onClick={props.close}>
      <SVGIcon icon='close' />
    </DrawerClose>
    <DrawerTitle>
      <FileIcon icon='folder' />
      <DrawerTitleSpan>{props.folderName}</DrawerTitleSpan>
    </DrawerTitle>
    <DrawerItem onClick={props.showEdit}>Edit Folder</DrawerItem>
    <DrawerItem onClick={props.showDelete}>Delete Folder</DrawerItem>
  </Modal>
);
