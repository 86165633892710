
import * as React from 'react';
import { Link } from 'react-router-dom';
import camelCase from 'lodash-es/camelCase';
import { ConditionalRenderWrapper, media } from '@amount/frontend-components';
import styled from 'styled-components';

import {
  FileActionContain,
  FileActionIcon,
  FileIcon,
  FileRestrictedIcon,
  FileText,
  FileTextContain,
  IFolderBaseProps,
  ListEntry,
  MobileActionButton,
} from './common';

const FolderListEntry = styled(ListEntry)`
  ${media.small`
    ${FileActionIcon} {
      transition: opacity 100ms ease;
      opacity: 0;
    }

    &:hover {
      ${FileActionIcon} {
        opacity: 1;
      }
    }
  `}
`;

export const removeTrailingSlash: (path: string) => string =
  path => path.endsWith('/') ? path.substring(0, path.length - 1) : path;
export const replaceSlashes: (path?: string) => string = path => (path || '').replace(/\//g, '%2f');

class FolderBase extends React.PureComponent<IFolderBaseProps> {
  public render (): JSX.Element | null {
    const { canEdit, Prefix, prefixNoParents, restricted, root, showDrawer } = this.props;
    if (!Prefix) { return null; }

    return (
      <Link
        to={`/${root}/${replaceSlashes(removeTrailingSlash(Prefix))}`}
        key={Prefix}
        title={prefixNoParents}
        data-event={camelCase(`${prefixNoParents} folder`)}
      >
        <FolderListEntry>
          <FileIcon icon='folder' />
          <FileTextContain>
            <FileText>
              {prefixNoParents}
              {restricted && (
                <FileRestrictedIcon icon='lock' />
              )}
            </FileText>
          </FileTextContain>
          <FileActionContain>
            <ConditionalRenderWrapper breakpoint='small' hiddenOnMobile={true}>
              <FileActionIcon icon='arrow-forward' />
            </ConditionalRenderWrapper>
            {canEdit && (
              <ConditionalRenderWrapper breakpoint='small' hiddenOnMobile={false}>
                <MobileActionButton onClick={showDrawer}>
                  <FileActionIcon icon='ellipses' />
                </MobileActionButton>
              </ConditionalRenderWrapper>
            )}
          </FileActionContain>
        </FolderListEntry>
      </Link>
    );
  }
}

export default FolderBase;
