import * as React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';
import { Query } from 'react-apollo';

import { AppRoutes, ModuleRoutes } from '../shared/routes';

import Me, { Me as MeResult } from './components/Context/me.graphql';
import { ProviderComponent } from './components/Context';
import Header from './components/Header';
import EmbeddedLookerIframe from './components/Reports/EmbeddedLookerIframe';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Spinner } from './components/Spinner';

const Home = React.lazy(() => import('./components/Home'));
const Helpdesk = React.lazy(() => import('./components/Helpdesk'));
const JitbitHelpdesk = React.lazy(() => import('./components/JitbitHelpdesk'));
const MyAccount = React.lazy(() => import('./components/MyAccount'));
const PSAEs = React.lazy(() => import('./components/SAR'));
const Reports = React.lazy(() => import('./components/Reports'));
const UserManagement = React.lazy(() => import('./components/UserManagement'));

const LiftedProviderComponent: React.FC<RouteComponentProps> = props => (
  <Query<MeResult> query={Me}>
    {({ data, loading }) => !loading && (
      <ProviderComponent {...props} data={data} loading={loading} />
    )}
  </Query>
);

// JitbitHelpdesk

const Modules: React.FC<RouteComponentProps> = props => (
  <LiftedProviderComponent {...props}>
    <Header {...props}>
      <ErrorBoundary>
        <React.Suspense fallback={<Spinner />}>
          <Switch>
            <Route exact={true} path='/'  >
              <Home />
            </Route>
            <Route path={ModuleRoutes.helpdesk} >
              <Helpdesk />
            </Route>
            <Route path={ModuleRoutes.psaes} >
              <PSAEs />
            </Route>
            <Route path={ModuleRoutes.jitbitHelpdesk} >
              <JitbitHelpdesk />
            </Route>
            <Route path={ModuleRoutes.reports} component={Reports} />
            <Route path={ModuleRoutes.policies} component={Reports} />
            <Route path={ModuleRoutes.userManagement} >
              <UserManagement />
            </Route>
            <Route path={`${AppRoutes.lookerDash}/:file`} component={EmbeddedLookerIframe} exact={true} />
            <Route path={AppRoutes.myAccount} component={MyAccount} />
            <Redirect
              exact={true}
              from='**'
              to={'/'}
            />
          </Switch>
        </React.Suspense>
      </ErrorBoundary>
    </Header>
  </LiftedProviderComponent>
);

export default Modules;
