/**
 * This _should_ be something built into FEC, but for now I've just
 * hacked up the original ActionButton code here to make a (technically)
 * WCAG-compliant link-styled-as-a-button.
 *
 * see also: https://github.com/amount/frontend-components/blob/master/src/components/ActionButton/ActionButton.tsx
 */

import styled, { css } from 'styled-components';

export interface IActionLinkButtonOptions {
  active?: boolean;
}

const ActiveStyles = css<IActionLinkButtonOptions>`
   border-color: ${({ theme: { actionButton } }) => actionButton.colorBorderHover};
   background: ${({ theme: { actionButton } }) => actionButton.colorBackgroundActive};
   color: ${({ theme: { actionButton } }) => actionButton.colorTextActive};
 
   svg {
     fill: ${({ theme: { actionButton } }) => actionButton.colorTextActive};
   }
 `;

export const ActionLinkButton = styled.a<IActionLinkButtonOptions>`
   display: flex;
   align-items: center;
   justify-content: center;
 
   line-height: 1.2;
   font-size: ${({ theme: { button } }) => button.fontSize};
   padding: 0.75em 1.125em;
   color: ${({ theme: { actionButton } }) => actionButton.colorText};
   min-height: 4.2rem;
 
   border-radius: 30px;
   border: ${({ theme: { button } }) => button.borderWidth} solid
     ${({ theme: { actionButton } }) => actionButton.colorBorder};
   transition: color 100ms ease-out, border 100ms ease-out,
     background 100ms ease-out;
 
   * {
     display: flex;
     align-items: center;
     justify-content: center;
   }
 
   svg {
     margin-right: 0.625em;
     fill: ${({ theme: { actionButton } }) => actionButton.colorText};
     width: 1.4rem;
   }
 
   ${({ active, theme: { actionButton } }) =>
    active
      ? ActiveStyles
      : css`
           &:focus,
           &:hover {
             color: ${actionButton.colorTextHover};
             border-color: ${actionButton.colorBorderHover};
 
             svg {
               fill: ${actionButton.colorTextHover};
             }
           }
         `}
 
   &:active {
     ${ActiveStyles}
   }
 
   &:disabled {
     color: ${({ theme: { actionButton } }) => actionButton.colorBorder};
 
     svg {
       fill: ${({ theme: { actionButton } }) => actionButton.colorBorder};
     }
 
     &:hover {
       color: ${({ theme: { actionButton } }) => actionButton.colorBorder};
       border-color: ${({ theme: { actionButton } }) =>
    actionButton.colorBorder};
 
       svg {
         fill: ${({ theme: { actionButton } }) => actionButton.colorBorder};
       }
     }
   }
 `;
