import * as React from 'react';
import { SelectInput, SelectOption, Spinner } from '@amount/frontend-components';
import { useMutation } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import ErrorHandler from '@avant/crm-frontend-utils/error';
import gql from 'graphql-tag';
import { DataProxy } from 'apollo-cache';

import { GetOwnedRequests } from '../queries/getOwnedRequests.graphql';
import AddIssuePriority, {
  AddIssuePriority as IAddIssuePriority,
  AddIssuePriorityInput,
} from '../queries/addIssuePriority.graphql';
import { client } from '../../../client';
import { ErrorLogger } from '../../../services/error';
import { MeContext } from '../../Context';
import GetRequestDetailQuery, {
  GetRequestDetail,
  GetRequestDetailInput,
  SelectionOnRequestFieldValues
} from '../queries/getRequestDetail.graphql';
import { JIRA_PRIORITY_FIELD_ID } from '../../../../shared/config/helpdesk';
import { canChangePriority } from '../__helpers__/permissioning';

const PRIORITY_MAP: { [key: string]: string } = {
  Highest: '1',
  High: '2',
  Medium: '3',
  Low: '4',
  Lowest: '5',
  None: '6'
};

const PRIORITY_MAP_KEYS: string[] = Object.keys(PRIORITY_MAP);

interface IProps {
  issueId: string;
  requestKey: string;
  requestType: string;
  priority: string | undefined;
  update: React.Dispatch<React.SetStateAction<string | undefined>>;
}

type AddPriorityType = (value: string) => Promise<void>;

const insertPriorityOnFields: (requestFields: SelectionOnRequestFieldValues[], priority: string) => (
  SelectionOnRequestFieldValues[]) = (requestFields, priority) =>
  requestFields.map(field => {
    if (field.fieldId === JIRA_PRIORITY_FIELD_ID) { field.value.textData = priority; }

    return field;
  });

const updatePriorityFragment = gql`
fragment ownedRequests on IssueType {
  __typename
  fields {
    __typename
    priority {
      __typename
      id
      name
    }
  }
}
`;

const addPriorityHook = (requestKey: string, issueId: string): { addPriority: AddPriorityType; loading: boolean } => {
  const context = React.useContext(MeContext);
  const [updatePriority, { loading }] = useMutation<IAddIssuePriority, AddIssuePriorityInput>(AddIssuePriority);
  const addPriority: AddPriorityType = async (value: string) => {
    await updatePriority({ variables: { id: requestKey, priority: value }});

    // Update the ownedRequests cache
    try {
      const ownedRequestsQuery: DataProxy.Fragment<{}> = {
        fragment: updatePriorityFragment,
        id: `IssueType:${issueId}`
      };

      let requestData: GetOwnedRequests | null;

      try {
        requestData = client.readFragment(ownedRequestsQuery);

        if (!requestData) {
          throw Error('ownedRequest data not read from cache');
        }

        client.writeFragment({
          fragment: updatePriorityFragment,
          data: {
            __typename: 'IssueType',
            fields: {
              __typename: 'RequestFields',
              priority: {
                __typename: 'RequestPriority',
                id: PRIORITY_MAP[value],
                name: value
              }
            }
          },
          id: `IssueType:${issueId}`
        });
      } catch {
        requestData = null;
      }

    } catch (e) {
      ErrorLogger.captureException(e);
    }

    // Update the requestDetail cache
    try {
      const queryDetails: { query: DocumentNode; variables: GetRequestDetailInput } = {
        query: GetRequestDetailQuery,
        variables: { key: requestKey, partner: context.currentPartner || '' }
      };
      const requestData: GetRequestDetail | null = client.readQuery<GetRequestDetail, GetRequestDetailInput>(queryDetails);
      if (!requestData || !requestData.requestDetail || !requestData.requestDetail.requestFieldValues) {
        ErrorHandler.notify('Cannot display new Priority. Please refresh the page.', 'Helpdesk', 'error');
        throw Error('requestDetail data not read from cache');
      }

      const newFields: SelectionOnRequestFieldValues[] = insertPriorityOnFields(
        JSON.parse(JSON.stringify(requestData.requestDetail.requestFieldValues)), value);

      client.writeQuery<GetRequestDetail>({
        ...queryDetails,
        data: {
          requestComments: {...requestData.requestComments},
          requestDetail: {
            ...requestData.requestDetail,
            requestFieldValues: newFields
          }
        },
      });
    } catch (e) {
      ErrorLogger.captureException(e);
    }
  };

  return { addPriority, loading };
};

const RequestPriority: React.FC<IProps> = props => {
  const { addPriority, loading } = addPriorityHook(props.requestKey, props.issueId);
  const context = React.useContext(MeContext);

  const _handleChange: React.FormEventHandler<HTMLSelectElement> = event => {
    const priority: string = event.currentTarget.value;

    addPriority(priority)
      .then(_value => props.update(priority))
      .catch(error => console.error(error));
  };

  if (loading) { return <Spinner small={true} />; }

  return (
    !canChangePriority(props.requestType, context) ? <>{props.priority}</> :
      (
        <SelectInput id='comment-priority' style={{ padding: '0.1em 0em 0.5em 0em'}} value={props.priority} onChange={_handleChange}>
          {PRIORITY_MAP_KEYS.map(key =>
            <SelectOption key={key} value={key} >{key}</SelectOption>
          )}
        </SelectInput>
      )
  );
};

export default RequestPriority;
