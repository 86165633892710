import { VALID_COLOR } from '@amount/frontend-components';

import {
  AMOUNT_PERMISSION_KEY,
  AVANT_PERMISSION_KEY,
  BARCLAYS_PERMISSION_KEY,
  BBVA_PERMISSION_KEY,
  BPO_PERMISSION_KEY,
  CAPITAL_ONE_PERMISSION_KEY,
  DEMO_BANK_PERMISSION_KEY,
  ELOAN_PERMISSION_KEY,
  GUARANTEED_RATE_PERMISSION_KEY,
  HSBC_PERMISSION_KEY,
  PNC_PERMISSION_KEY,
  POLLEN_STREET_PERMISSION_KEY,
  REGIONS_PERMISSION_KEY,
  TD_PERMISSION_KEY,
  WEBBANK_PERMISSION_KEY,
  JENIUS_PERMISSION_KEY,
} from './permissions';
import { Organizations, SwitchablePartner } from './partners';

// tslint:disable-next-line: tsr-detect-non-literal-buffer
export const JIRA_BEARER_TOKEN: string = `Basic ${new Buffer(`${process.env.JIRA_USER}:${process.env.JIRA_API_TOKEN}`).toString('base64')}`;
export const JIRA_API_URL: string = 'https://linearft.atlassian.net/rest';
export const SERVICE_ACCOUNT_DISPLAY_NAME: string = process.env.JIRA_SERVICE_ACCOUNT_DISPLAY_NAME || 'Amount Helpdesk';
export const APS_SERVICEDESK_ID: string = '22';
export const SI_SERVICEDESK_ID: string = '20';
export const TEST_SERVICEDESK_ID: string = '17';
export const SHOW_TEST_SERVICEDESK: boolean = process.env.SHOW_TEST_SERVICEDESK === 'true';
export const HELPDESK_DATE_FORMAT: string = 'MMM Do, YYYY';
export const HELPDESK_TIME_FORMAT: string = 'h:mm aa';
export const HELPDESK_REQUEST_EXPORT_DATE: string = 'MM/DD/YYYY';
export const BEGIN_AUTHOR_STRING: string = '*Author: [mailto:';
export const END_AUTHOR_STRING: string = ']*\n\n';

// Jira customfield constants
export enum JIRA_CF {
  partner = 'cf[10198]',
  participants = 'cf[10026]',
  requestTypes = 'cf[10010]',
  product = 'cf[10168]',
}

export const AMOUNT_SERVICE_INCIDENT: string = 'Amount Service Incident';
export const SERVICE_INCIDENT: string = 'Service Incident';
export const EMAILED_REQUEST: string = 'Emailed Request';
export const OPERATIONAL_SUPPORT: string = 'Operational Support';
export const BPO_SUPPORT: string = 'BPO Support';
export const AVANT_DATA_SET_SPLIT: string = 'Avant Data Set Split';


export const PARTNER_EMAIL_DOMAINS: {[key in Organizations]?: string[]} = {
  [AMOUNT_PERMISSION_KEY]: ['@amount.com'],
  [AVANT_PERMISSION_KEY]: ['@avant.com', '@alltran.com'],
  [BARCLAYS_PERMISSION_KEY]: ['@barclays.com', '@barclaycardus.com', '@barclaycard.co.uk'],
  [BBVA_PERMISSION_KEY]: ['@bbva.com', '@bbvausa.com'],
  [BPO_PERMISSION_KEY]: ['@avant.com', '@alltran.com'],
  [CAPITAL_ONE_PERMISSION_KEY]: ['@capitalone.com'],
  [DEMO_BANK_PERMISSION_KEY]: ['@demo.com'],
  [ELOAN_PERMISSION_KEY]: ['@eloan.com', '@my.eloan.com', '@popular.com'],
  [GUARANTEED_RATE_PERMISSION_KEY]: ['@rate.com'],
  [HSBC_PERMISSION_KEY]: ['@hsbc.co.in', '@us.hsbc.com'],
  [PNC_PERMISSION_KEY]: ['@pnc.com'],
  [POLLEN_STREET_PERMISSION_KEY]: ['@psc.com'],
  [REGIONS_PERMISSION_KEY]: ['@regions.com'],
  [TD_PERMISSION_KEY]: ['@td.com'],
  [WEBBANK_PERMISSION_KEY]: ['@webbank.com'],
  [JENIUS_PERMISSION_KEY]: ['@jeniusbank.com', '@fisglobal.com']
};

export const POLLEN_STREET_EMAIL_WHITELIST: string[] = [
  'adrian.king@pollencap.com',
  'andy.allan@pollencap.com',
  'ben.scowen@pollencap.com',
  'caroline.barr@avant.com',
  'chris.till@avant.com',
  'claire.prescott@avant.com',
  'dan.alsop@avant.com',
  'daniel.whiteside@avant.com',
  'daniela.francisco@avant.com',
  'eddie.jackson@avant.com',
  'hannah.newsham@avant.com',
  'hawa.batha@avant.com',
  'jane.rostamian@avant.com',
  'jason.singh@avant.com',
  'john.berry@avant.com',
  'jonathan.karmanski@avant.com',
  'katie.stead@avant.com',
  'kevin.chauhan@avant.com',
  'kirsty.shuttleworth@avant.com',
  'laechelle.charles@avant.com',
  'laura.pilkington@avant.com',
  'louise.hammond@avant.com',
  'lucy.dewhurst@avant.com',
  'michael.freer@avant.com',
  'mohammed.darez@avant.com',
  'noreply@pollencap.com',
  'oana.popescu@avant.com',
  'paul.smith@avant.com',
  'peter.mcateer@avant.com',
  'rachel.mcghee@avant.com',
  'richard.nixon@avant.com',
  'samantha.mcclelland@avant.com',
  'shannon.horsfield@avant.com',
  'stacey.wilkinson@avant.com',
  'sue.leach@avant.com',
  'tia.moriarty@avant.com',
  'umar.nallamill@avant.com',
];

export const JIRA_ORGANIZATION_ID_MAP: {[key in SwitchablePartner]?: number} = {
  [POLLEN_STREET_PERMISSION_KEY]: 12,
  [TD_PERMISSION_KEY]: 23,
  [REGIONS_PERMISSION_KEY]: 24,
  [HSBC_PERMISSION_KEY]: 24,
  [AMOUNT_PERMISSION_KEY]: 10,
  [PNC_PERMISSION_KEY]: 14,
  [BBVA_PERMISSION_KEY]: 15,
  [ELOAN_PERMISSION_KEY]: 16,
  [AVANT_PERMISSION_KEY]: 17,
  [BARCLAYS_PERMISSION_KEY]: 18,
  [CAPITAL_ONE_PERMISSION_KEY]: 19,
  [GUARANTEED_RATE_PERMISSION_KEY]: 20,
  [JENIUS_PERMISSION_KEY]: 21,
};

export const JIRA_PARTNER_FIELD_MAP: {[key in SwitchablePartner]?: string} = {
  [POLLEN_STREET_PERMISSION_KEY]: '10583',
  [TD_PERMISSION_KEY]: '10556',
  [REGIONS_PERMISSION_KEY]: '10578',
  [HSBC_PERMISSION_KEY]: '10573',
  [AMOUNT_PERMISSION_KEY]: '10582',
  [PNC_PERMISSION_KEY]: '10558',
  [ELOAN_PERMISSION_KEY]: '10570',
  [AVANT_PERMISSION_KEY]: '10560',
  [BBVA_PERMISSION_KEY]: '10564',
  [BARCLAYS_PERMISSION_KEY]: '10562',
  [CAPITAL_ONE_PERMISSION_KEY]: '10566',
  [GUARANTEED_RATE_PERMISSION_KEY]: '10572',
  [JENIUS_PERMISSION_KEY]: '10576',
};

export const JIRA_ORGANIZATION_FIELD_ID: string = 'customfield_10002';
export const JIRA_PARTNER_FIELD_ID: string = 'customfield_10198';
export const JIRA_PRIORITY_FIELD_ID: string = 'priority';
export const JIRA_SUMMARY_FIELD_ID: string = 'summary';

export const JIRA_ORG_VALUES_MAP: {[key in SwitchablePartner]?: string} = {
  [POLLEN_STREET_PERMISSION_KEY]: 'Pollen Street',
  [TD_PERMISSION_KEY]: 'TD',
  [REGIONS_PERMISSION_KEY]: 'Regions',
  [HSBC_PERMISSION_KEY]: 'HSBC',
  [AMOUNT_PERMISSION_KEY]: 'Platform',
  [PNC_PERMISSION_KEY]: 'PNC',
  [ELOAN_PERMISSION_KEY]: 'Eloan',
  [AVANT_PERMISSION_KEY]: 'Avant',
  [BBVA_PERMISSION_KEY]: 'BBVA',
  [BARCLAYS_PERMISSION_KEY]: 'Barclays',
  [CAPITAL_ONE_PERMISSION_KEY]: 'Capital One',
  [GUARANTEED_RATE_PERMISSION_KEY]: 'Guaranteed Rate',
  [JENIUS_PERMISSION_KEY]: 'Jenius Bank',
};

export const SERVICE_DESK_ENABLED_PARTNERS: SwitchablePartner[] = [
  AMOUNT_PERMISSION_KEY,
  ELOAN_PERMISSION_KEY,
  POLLEN_STREET_PERMISSION_KEY,
  REGIONS_PERMISSION_KEY,
  TD_PERMISSION_KEY,
  HSBC_PERMISSION_KEY,
  PNC_PERMISSION_KEY,
  BBVA_PERMISSION_KEY,
  BARCLAYS_PERMISSION_KEY,
  JENIUS_PERMISSION_KEY
];

export const JITBIT_ENABLED_PARTNERS: SwitchablePartner[] = [
  AVANT_PERMISSION_KEY,
  DEMO_BANK_PERMISSION_KEY,
];

interface IPartnerCategoryData {
  category: string;
  color: VALID_COLOR;
}

export const PARTNER_FACING_CATEGORIES: { [key in 'amount' | 'partner' | 'closed' | 'onHold']: IPartnerCategoryData } = {
  amount: {
    category: 'Waiting on Amount',
    color: 'primary'
  },
  partner: {
    category: 'Waiting on Partner',
    color: 'warning'
  },
  closed: {
    category: 'Closed',
    color: 'inactive'
  },
  onHold: {
    category: 'On Hold',
    color: 'inactive'
  }
};

export const DEFAULT_STATUSES: string[] =  [
  PARTNER_FACING_CATEGORIES.amount.category,
  PARTNER_FACING_CATEGORIES.partner.category
];

const VALID_STATUS_LIST = [
  'Created',
  'Consultation',
  'In Discovery',
  'Scheduled',
  'In Progress',
  'Processing',
  'Requirements',
  'Refinement',
  'Prioritized',
  'Pending Release',
  'BLOCKED',
  'In Review',
  'Pending Initial Report',
  'Pending Resolution',
  'Pending Post Mortem',
  'Pending Remediation',
  'Validating',
  'Awaiting Partner Approval',
  'Information Required',
  'Delivered',
  'Done',
  'Will Not Do',
  'Complete',
  'Closed',
  'Canceled',
  'Resolved',
  'To Do',
  'Waiting for Approval',
  'On Hold'
] as const;

type VALID_STATUS = (typeof VALID_STATUS_LIST)[number];

export const JIRA_STATUSES: { [key in VALID_STATUS]: IPartnerCategoryData } = {
  Created: PARTNER_FACING_CATEGORIES.amount,
  Consultation: PARTNER_FACING_CATEGORIES.amount,
  'In Discovery': PARTNER_FACING_CATEGORIES.amount,
  Scheduled: PARTNER_FACING_CATEGORIES.amount,
  'In Progress': PARTNER_FACING_CATEGORIES.amount,
  Processing: PARTNER_FACING_CATEGORIES.amount,
  'Pending Initial Report': PARTNER_FACING_CATEGORIES.amount,
  'Pending Resolution': PARTNER_FACING_CATEGORIES.amount,
  'Pending Post Mortem': PARTNER_FACING_CATEGORIES.amount,
  'Pending Remediation': PARTNER_FACING_CATEGORIES.amount,
  Validating: PARTNER_FACING_CATEGORIES.amount,
  Requirements: PARTNER_FACING_CATEGORIES.amount,
  Refinement: PARTNER_FACING_CATEGORIES.amount,
  Prioritized: PARTNER_FACING_CATEGORIES.amount,
  'Pending Release': PARTNER_FACING_CATEGORIES.amount,
  BLOCKED: PARTNER_FACING_CATEGORIES.amount,
  'Awaiting Partner Approval': PARTNER_FACING_CATEGORIES.partner,
  'Information Required': PARTNER_FACING_CATEGORIES.partner,
  Delivered: PARTNER_FACING_CATEGORIES.partner,
  'In Review': PARTNER_FACING_CATEGORIES.partner,
  Done: PARTNER_FACING_CATEGORIES.closed,
  'Will Not Do': PARTNER_FACING_CATEGORIES.closed,
  Complete: PARTNER_FACING_CATEGORIES.closed,
  Closed: PARTNER_FACING_CATEGORIES.closed,
  Canceled: PARTNER_FACING_CATEGORIES.closed,
  Resolved: PARTNER_FACING_CATEGORIES.closed,
  'To Do': PARTNER_FACING_CATEGORIES.amount,
  'Waiting for Approval': PARTNER_FACING_CATEGORIES.partner,
  'On Hold': PARTNER_FACING_CATEGORIES.onHold
};

export const JIRA_SD_EXPAND = 'expand=participant,requestType';
