import * as React from 'react';
import { media, Pill } from '@amount/frontend-components';
import styled from 'styled-components';

import { JIRA_STATUSES } from '../../../../shared/config/helpdesk';
import { ErrorLogger, Severity } from '../../../services/error';

const ResponsivePill = styled(Pill)`
  margin: 1em 0;

  ${media.small`
    margin: 0 0 1em 0;
  `}
`;

// tslint:disable-next-line
const UserStatusPill: React.FC<{ status: string; as?: keyof JSX.IntrinsicElements | React.ComponentType}> = ({ status, as }) => {
  const { color, category } = JIRA_STATUSES[status] || {};
  if (!color || !category) {
    ErrorLogger.addBreadcrumb({ data: { category, status }, level: Severity.Warning });
    ErrorLogger.captureMessage('A Helpdesk request has an unknown Status');
  }

  return <ResponsivePill pillColor={color || 'inactive'} as={as}>{status}</ResponsivePill>;
};

export default UserStatusPill;
