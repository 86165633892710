import * as React from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import {
  ActionButton,
  ConditionalRenderWrapper,
  media,
  MobileCTAButton,
  Spinner,
  SVGIcon
} from '@amount/frontend-components';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Events from '@avant/crm-frontend-utils/events';
import { Mutation, MutationFn } from 'react-apollo';
import ErrorHandler from '@avant/crm-frontend-utils/error';

import { VIEW_HELPDESK } from '../../../shared/config/permissions';
import { PermissionedComponent } from '../PermissionedComponent';
import HomeRedirectComponent from '../HomeRedirectComponent';
import { ModuleRoutes } from '../../../shared/routes';
import { ButtonLabel } from '../CommonComponents/ButtonLabel';
import { PartnersContainer } from '../PartnersContainer';
import { PARTNER_CHANGED_EVENT } from '../../../shared/config/constants';
import { client } from '../../client';
import { ErrorLogger } from '../../services/error';
import { stringToFileDownload } from '../../services/downloadFile';
import { DEFAULT_STATUSES } from '../../../shared/config/helpdesk';
import { MarginlessH1Headline } from '../../components/CommonComponents';

import getHelpdeskCSV, { GetHelpdeskCSV, GetHelpdeskCSVInput } from './queries/getCSV.graphql';
import RequestList from './RequestList';
import HelpdeskRequestDetail from './RequestDetail';
import Submit from './Submit';
import HelpdeskCategoryList from './RequestCategoryList';
import TempAmountBanner from './TempAmountBanner';
import { HELPDESK_ROUTES } from './helpdesk-routes';
import { FILTER_NAMES, IActionBar, IActionState } from './constants';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 1em 0.75em;

  ${media.small`
    padding: 1em 0;
  `}
`;

const HeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const RequestTableContainer = styled(PartnersContainer)`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;

  ${media.small`
    padding: 0 1.75em 3em;
  `}
`;

const DownloadButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5em 0 0;
`;

const HelpdeskSpinner = styled(Spinner)`
  margin-right: 0.5em;
`;

const MobileNewRequestButton = styled(MobileCTAButton)`
  z-index:333;
`;

const CreateRequestLink = styled(Link)`
  &:focus > ${ConditionalRenderWrapper} > ${ActionButton} {
    color: ${({ theme: { actionButton } }) => actionButton.colorTextHover};
    border-color: ${({ theme: { actionButton } }) => actionButton.colorBorderHover};
  }
`;

const createUpdatedSet = (s: Set<string>, value: string): Set<string> => {
  const copy: Set<string> = new Set([...s]);
  if (copy.has(value)) {
    copy.delete(value);
  } else {
    copy.add(value);
  }

  return copy;
};

export interface IHelpdeskProps extends IActionBar {
  createCSV: MutationFn<GetHelpdeskCSV, GetHelpdeskCSVInput>;
}

const Helpdesk: React.FC<IHelpdeskProps> = ({ createCSV, ...props }) => {
  const [downloadingCSV, setDownloadingCSV] = React.useState(false);
  const handleDownloadCSV = async () => {
    setDownloadingCSV(true);
    try {
      const { Search, Status, Product, Priority, Category } = props.actionBarState;
      const res = await createCSV({
        variables: {
          partner: 'amount',
          Search,
          Status: [...Status],
          Product: [...Product],
          Priority: [...Priority],
          Category: [...Category]
        }
      });

      if (!res || !res.data || !res.data.createHelpdeskCSV) { throw new Error('invalid response'); }
      const data: string | null = res.data.createHelpdeskCSV.data;
      if (!data) { throw new Error('response data is undefined'); }

      stringToFileDownload(data, 'Helpdesk_Request_Export.csv');
    } catch (e) {
      ErrorHandler.notify('CSV download failed', 'Download Error', 'error');
    }

    setDownloadingCSV(false);
  };

  return (
    <>
      <Helmet>
        <title>Helpdesk · Partner Portal</title>
      </Helmet>
      <TempAmountBanner />
      <RequestTableContainer>
        <HeaderContainer>
          <MarginlessH1Headline scale='large' as='h1'>Helpdesk</MarginlessH1Headline>
          <HeaderActions>
            <DownloadButtonWrap>
              {downloadingCSV && (
                <HelpdeskSpinner small={true} />
              )}
              <ActionButton disabled={downloadingCSV} data-event='downloadCSV' onClick={handleDownloadCSV}>
                <SVGIcon icon='download' />
                <ButtonLabel>Download CSV</ButtonLabel>
              </ActionButton>
            </DownloadButtonWrap>
            <CreateRequestLink to={`${ModuleRoutes.helpdesk}${HELPDESK_ROUTES.createRequestCategories}`}>
              <ConditionalRenderWrapper hiddenOnMobile={false} breakpoint='small'>
                <MobileNewRequestButton as='div'>
                  <SVGIcon icon='plus' />
                </MobileNewRequestButton>
              </ConditionalRenderWrapper>
              <ConditionalRenderWrapper hiddenOnMobile={true} breakpoint='small'>
                <ActionButton data-event='createRequest' as='div'>
                  <SVGIcon icon='plus' />
                  <ButtonLabel>Create New Request</ButtonLabel>
                </ActionButton>
              </ConditionalRenderWrapper>
            </CreateRequestLink>
          </HeaderActions>
        </HeaderContainer>
        <RequestList {...props} />
      </RequestTableContainer>
    </>
  );
};

const LiftedHelpdesk: React.FC<IActionBar> = props => (
  <Mutation<GetHelpdeskCSV, GetHelpdeskCSVInput> mutation={getHelpdeskCSV}>
    {createCSV => (
      <Helpdesk {...props} createCSV={createCSV} />
    )}
  </Mutation>
);

const RoutedHelpdesk: React.FC<IActionBar> = (props: IActionBar) => {
  const { path } = useRouteMatch();

  React.useEffect(() => {
    const onChange = () => client.resetStore().catch(ErrorLogger.captureException); // Clears cache, and refetch's all active queries
    Events.subscribe(PARTNER_CHANGED_EVENT, onChange);

    return () => Events.unsubscribe(PARTNER_CHANGED_EVENT, onChange);
  });

  return (
    <Switch>
      <Route path={`${path}${HELPDESK_ROUTES.createRequestCategories}`} >
        <HelpdeskCategoryList />
      </Route>
      <Route path={`${path}${HELPDESK_ROUTES.createRequestTypes}/:serviceDeskId/:requestTypeId`} >
        <Submit />
      </Route>
      <Route path={`${path}${HELPDESK_ROUTES.viewRequest}/:requestId`} >
        <HelpdeskRequestDetail />
      </Route>
      <Route
        path={`${path}`}
        // tslint:disable-next-line: jsx-no-lambda
        render={() => <LiftedHelpdesk {...props} />}
      />
      <Redirect from='**' to={ModuleRoutes.helpdesk} />
    </Switch>
  );
};

class StatefulRoutedHelpdesk extends React.Component<{}, IActionState> {
  public state: IActionState = {
    [FILTER_NAMES.search]: '',
    [FILTER_NAMES.sort]: '',
    [FILTER_NAMES.reporter]: new Set<string>(),
    [FILTER_NAMES.status]: new Set(DEFAULT_STATUSES),
    [FILTER_NAMES.priority]: new Set<string>(),
    [FILTER_NAMES.product]: new Set<string>(),
    [FILTER_NAMES.requestTypes]: new Set<string>()
  };

  public render (): JSX.Element {
    return (
      <PermissionedComponent allowedPermissions={[VIEW_HELPDESK]} NoAccessComponent={HomeRedirectComponent}>
        <RoutedHelpdesk
          actionBarState={this.state}
          handleChange={this.handleChange}
          handleClear={this.handleClear}
          handleSearchChange={this.handleSearchChange}
          handleSelectChange={this.handleSelectChange}
        />
      </PermissionedComponent>
    );
  }

  private readonly handleChange: React.FormEventHandler<HTMLInputElement> = ({ currentTarget: { value, name } }) => {
    // tslint:disable-next-line: no-any
    this.setState({ [name]: createUpdatedSet(this.state[name], value) } as any);
  };

  private readonly handleClear: React.FormEventHandler<HTMLButtonElement> = ({ currentTarget: { name } }) => {
    // tslint:disable-next-line: no-any
    this.setState({ [name]: new Set<string>() } as any);
  };

  private readonly handleSearchChange: React.FormEventHandler<HTMLInputElement> = ({ currentTarget: { value } }) => {
    this.setState({ [FILTER_NAMES.search]: value });
  };

  private readonly handleSelectChange: React.FormEventHandler<HTMLSelectElement> = ({ currentTarget: { value } }) => {
    this.setState({ [FILTER_NAMES.sort]: value });
  };
}

export default StatefulRoutedHelpdesk;
