import { ISessionUser, UserPermissions } from '../../shared/config/user';
import { ISuspiciousActivityReport } from '../models/suspiciousActivityReport';
import {
  AML_PSAE,
  AMOUNT_REJECTED_STEP_PSAE,
  DOCUMENTS_PERMISSIONS,
  DRAFT_STEP_PSAE,
  EDIT_USERS,
  FINAL_REVIEW_SEND_BACK_PSAE,
  FINAL_REVIEW_STEP_PSAE,
  FRAUD_PSAE,
  HELPDESK_PERMISSIONS,
  OPEN_STEP_PSAE,
  PARTNER_APPROVED_GENERATE_JOB,
  PARTNER_APPROVED_STEP_PSAE,
  PARTNER_REJECTED_STEP_PSAE,
  PEER_REVIEW_STEP_PSAE,
  REPORTS_PERMISSIONS,
  VIEW_CIP,
  VIEW_COMPLAINTS,
  VIEW_CSP,
  VIEW_DISPUTES,
  VIEW_PAYMENT_GATEWAY,
  VIEW_PSAE,
  VIEW_USERS,
  VIEW_KNOWLEDGE_BASE,
  VIEW_MERCHANTS,
  VIEW_CONFIGURATIONS,
} from '../../shared/config/permissions';
import { ORGANIZATION_LIST, Organizations } from '../../shared/config/partners';
import { INVALID_ORGANIZATION, LoggedOutError } from '../../shared/config/constants';

// tslint:disable-next-line: no-any
export interface IAvailablePermission<Name extends string = string, Obj = any> {
  name: Name;
  when? (context: { user: ISessionUser; obj: Obj }): boolean;
}

// tslint:disable-next-line: no-any
export interface IPermissionCheck<Name extends string = string, Obj = any> {
  permission: Name;
  user: ISessionUser;
  obj: Obj;
}

export type ReportPermission = IAvailablePermission<REPORTS_PERMISSIONS, string>;
export type ReportPermissionCheck = IPermissionCheck<REPORTS_PERMISSIONS, string>;
export type PolicyPermission = IAvailablePermission<DOCUMENTS_PERMISSIONS, string>;
export type PolicyPermissionCheck = IPermissionCheck<DOCUMENTS_PERMISSIONS, string>;

export type HelpdeskPermission = IAvailablePermission<
HELPDESK_PERMISSIONS,
never | void | undefined | null
>;
export type HelpdeskPermissionCheck = IPermissionCheck<
HELPDESK_PERMISSIONS,
never | void | undefined | null
>;

export type SARTeamPermissions =
  | typeof AML_PSAE
  | typeof FRAUD_PSAE;

export type SARPermissions =
  | typeof FINAL_REVIEW_STEP_PSAE
  | typeof OPEN_STEP_PSAE
  | typeof DRAFT_STEP_PSAE
  | typeof PEER_REVIEW_STEP_PSAE
  | typeof PARTNER_REJECTED_STEP_PSAE
  | typeof PARTNER_APPROVED_STEP_PSAE
  | typeof AMOUNT_REJECTED_STEP_PSAE
  | SARTeamPermissions;

export type SARPermissionCheckObject = Pick<
ISuspiciousActivityReport,
'status' | 'partner' | 'metadata'
>;

export type SARPermission = IAvailablePermission<
SARPermissions,
SARPermissionCheckObject
>;
export type SARPermissionCheck = IPermissionCheck<
SARPermissions,
SARPermissionCheckObject
>;

export type AccessPermissions =
  | typeof FINAL_REVIEW_SEND_BACK_PSAE
  | typeof VIEW_PSAE
  | typeof VIEW_USERS
  | typeof VIEW_COMPLAINTS
  | typeof VIEW_KNOWLEDGE_BASE
  | typeof VIEW_MERCHANTS
  | typeof VIEW_CONFIGURATIONS;

export type AccessPermission = IAvailablePermission<
AccessPermissions,
never | void | undefined | null
>;

export type AccessPermissionCheck = IPermissionCheck<
AccessPermissions,
never | void | undefined | null
>;

export type GardenPermissions = typeof PARTNER_APPROVED_GENERATE_JOB;

export type GardenPermission = IAvailablePermission<
GardenPermissions,
never | void | undefined | null
>;
export type GardenPermissionCheck = IPermissionCheck<
GardenPermissions,
never | void | undefined | null
>;

export type UserAdminPermission = IAvailablePermission<
  typeof EDIT_USERS,
never | void | undefined | null
>;

export type UserAdminCheck = IPermissionCheck<
  typeof EDIT_USERS,
never | void | undefined | null
>;

export type CrmPermission = IAvailablePermission<typeof VIEW_CSP>;
export type CrmPermissionCheck = IPermissionCheck<typeof VIEW_CSP>;

export type CipPermission = IAvailablePermission<typeof VIEW_CIP>;
export type CipPermissionCheck = IPermissionCheck<typeof VIEW_CIP>;

export type DisputesPermission = IAvailablePermission<typeof VIEW_DISPUTES>;
export type DisputesPermissionCheck = IPermissionCheck<typeof VIEW_DISPUTES>;

export type PaymentGatewayPermission = IAvailablePermission<typeof VIEW_PAYMENT_GATEWAY>;
export type PaymentGatewayPermissionCheck = IPermissionCheck<typeof VIEW_PAYMENT_GATEWAY>;

/**
 * Possible Permission Check contexts that can be passed to the Warden's check
 * method
 */
export type PermissionChecks =
  | CrmPermissionCheck
  | CipPermissionCheck
  | SARPermissionCheck
  | AccessPermissionCheck
  | DisputesPermissionCheck
  | PaymentGatewayPermissionCheck
  | HelpdeskPermissionCheck
  | PolicyPermissionCheck
  | ReportPermissionCheck
  | GardenPermissionCheck
  | UserAdminCheck;

/**
 * Possible Permissions
 */
export type Permission =
  | SARPermission
  | AccessPermission
  | DisputesPermission
  | PaymentGatewayPermission
  | HelpdeskPermission
  | PolicyPermission
  | ReportPermission
  | CrmPermission
  | CipPermission
  | GardenPermission
  | UserAdminPermission;

export interface IOrganizationConfig {
  availablePermissions: Permission[];
}

export type IWardenConfig = { [organization in Organizations]: IOrganizationConfig };

export type AvailableOrganizations = Organizations;
export type AvailablePermissions = string;

const convertToObjectToMap: (perms: IAvailablePermission[]) => PermissionMap = perms => perms.reduce<PermissionMap>(
  (acc, perm) => acc.set(perm.name, perm),
  new Map()
);

export type PermissionMap = Map<AvailablePermissions, IAvailablePermission>;

// tslint:disable-next-line interface-over-type-literal
export interface PermissionConfig {
  available: PermissionMap;
}
type InternalConfig = Map<AvailableOrganizations, PermissionConfig>;

/**
 * The Warden provides an easy way to obtain:
 *  - a user's permissions
 *  - permissions available for a user
 *  - if a user has access to an object
 *
 */
export class Warden {
  private readonly config: InternalConfig = new Map();

  /**
   * @param wardenConfig The configuration object for the Warden
   * @returns A new Warden class
   */
  constructor (wardenConfig: IWardenConfig) {
    Object.entries(wardenConfig).forEach(([org, config]) =>
      this.config.set(org as Organizations, {
        available: convertToObjectToMap(config.availablePermissions)
      })
    );
  }

  /**
   * Gets the permissions available for a provided user.
   * An available permission is one that they are able to hold,
   * not necessarily one that they have.
   *
   * @param user The provided user
   * @returns The available permissions for the user
   */
  public availablePermissions (user: ISessionUser): string[] {
    return [...this.getOrganizationPermissions(user.organization).available.values()].map(
      ({ name }) => name
    );
  }

  /**
   * Gets the filtered permissions for a provided user.
   * Filtered Permissions are the list of permissions a user has
   * with the permissions not in warden config's available
   * permissions removed.
   *
   * @param allPermissions The provided permissions list
   * @param organization The organization of the user
   * @returns The available permissions for the user
   */
  public userAvailablePermissions (allPermissions: UserPermissions, organization: Organizations): UserPermissions {
    return Object.keys(allPermissions).reduce<UserPermissions>(
      (acc: UserPermissions, org: string) => {
        const availablePermissions: PermissionConfig = this.getOrganizationPermissions(organization);

        const permissions: string[] = (allPermissions[org] || []).filter(
          (permission: string) => availablePermissions.available.has(permission)
        );
        acc[org] = permissions;

        return acc;
      },
      {}
    );
  }

  /**
   * Given a context object, check if the provided user has permission
   *
   * @param context The provided context, see {@link PermissionChecks} for
   * available contexts
   * @returns Whether or not permission should be granted
   *
   * @throws {Invalid Organization} If user has an unknown organization
   */
  public check (context: PermissionChecks): boolean {
    const { permission, user } = context;

    const currentPartnerPermissions: PermissionConfig = this.getUserCurrentPartnerPermissions(user);

    if (!user.permissions.includes(permission)) {
      return false;
    }

    const availablePermission: IAvailablePermission | undefined = currentPartnerPermissions.available.get(permission);

    if (!availablePermission) {
      return false;
    }

    if (!availablePermission.when) {
      return true;
    }

    return !!availablePermission.when(context);
  }

  /**
   * Gets the available permissions for a given organization
   *
   * @param organization The provided organization
   * @returns A {@link PermissionConfig} object containing available
   * permissions for the provided organization
   */
  public getOrganizationPermissions (organization: Organizations): PermissionConfig {
    const config: PermissionConfig | undefined = this.config.get(organization);
    if (!config) { throw new Error(`Missing permission config for organization ${organization}!`); }

    return config;
  }

  /**
   * Gets {@link PermissionConfig} for a privated user's current partner
   *
   * @param user The provided user
   * @returns A {@link PermissionConfig} for the provided user
   */
  private getUserCurrentPartnerPermissions (user: ISessionUser): PermissionConfig {
    if (!user) {
      throw LoggedOutError;
    }
    if (
      user.organization === INVALID_ORGANIZATION ||
      !ORGANIZATION_LIST.includes(user.organization)
    ) {
      throw new Error(`Organization ${user.organization} is invalid!`);
    }
    if (
      !user.currentPartner ||
      !user.switchablePartners.includes(user.currentPartner)
    ) {
      throw new Error(`CurrentPartner ${user.currentPartner} is invalid!`);
    }

    return this.getOrganizationPermissions(user.organization);
  }
}
