import * as React from 'react';
import camelCase from 'lodash-es/camelCase';
import {
  FormError,
  Input,
} from '@amount/frontend-components';
import { useFormContext } from 'react-hook-form';

import { IText, Label } from './common';

export const Summary: React.FC<IText> = ({ name, formControlId, isRequired, labelName }) => {
  const { errors, register } = useFormContext();

  const inputId = `input-${name}`;
  const errorId = `input-${name}-error`;

  return (
    <>
      <Label inputId={inputId} labelName={labelName} required={isRequired} />
      <Input
        name={name}
        data-event={camelCase(`${name} summary`)}
        id={formControlId}
        ref={register({ required: isRequired })}
        aria-describedby={errorId}
        required={isRequired}
      />
      {errors[name] && <FormError id={errorId}>This field is required</FormError>}
    </>
  );
};
