import * as React from 'react';
import { ActionButton, media, Spinner, SVGIcon } from '@amount/frontend-components';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import ErrorHandler from '@avant/crm-frontend-utils/error';
import { DataProxy } from 'apollo-cache';

import EditStatus, {
  EditStatus as IEditStatus,
  EditStatusInput,
} from '../queries/editStatus.graphql';
import { ErrorLogger } from '../../../services/error';
import GetRequestDetailQuery, {
  GetRequestDetail,
  GetRequestDetailInput,
} from '../queries/getRequestDetail.graphql';
import { GetOwnedRequests } from '../queries/getOwnedRequests.graphql';
import { client } from '../../../client';
import { MeContext } from '../../Context';
import { ICurrentStatus } from '../../../../gql';
import { ButtonLabel } from '../../CommonComponents/ButtonLabel';
import { canUpdateStatus } from '../__helpers__/permissioning';
import { SelectionOnMe } from '../../Context/me.graphql';

export const AddButton = styled(ActionButton)`
  border: none;
  padding-left: 0;
  width: 10em;
  margin: 0.25em 0 0em 0.25em;
  justify-content: flex-start;

  ${media.medium`
    margin-left: 0;
  `}

  svg {
    width: 1.75rem;
  }
`;
interface IProps {
  issueId: string;
  requestKey: string;
  requestType: string;
  requestStatus: string;
  context: SelectionOnMe;
}

type  EditStatusType = (_name: string, _id: string) => Promise<void>;

const updatePriorityFragment = gql`
fragment ownedRequests on IssueType {
  __typename
  fields {
    __typename
    customfield_10010 {
      __typename
      currentStatus {
        __typename
        status
        statusCategory
        statusDate {
          __typename
          iso8601
        }
      }
    }
  }
}
`;

interface ITransition {
  status: string;
  label: string;
  id: string;
}

const CANCELED_TRANSITION: ITransition = {
  status: 'Canceled',
  label: 'Cancel Request',
  id: '111'
};

const CLOSED_TRANSITION: ITransition = {
  status: 'Closed',
  label: 'Close Request',
  id: '101'
};

const CLOSED_TRANSITION_OP: ITransition = {
  status: 'Closed',
  label: 'Close Request',
  id: '131'
};

const editStatusHook = (requestKey: string, issueId: string): { editStatus: EditStatusType; loading: boolean } => {
  const context = React.useContext(MeContext);
  const [updateStatus, { loading }] = useMutation<IEditStatus, EditStatusInput>(EditStatus);
  const editStatus: EditStatusType = async (statusName: string, transitionId: string) => {
    await updateStatus({ variables: { id: requestKey, status: transitionId || '' } } );
    // Update the ownedRequests cache
    try {
      const ownedRequestsQuery: DataProxy.Fragment<{}> = {
        fragment: updatePriorityFragment,
        id: `IssueType:${issueId}`
      };

      let requestData: GetOwnedRequests | null;

      try {
        requestData = client.readFragment(ownedRequestsQuery);

        if (!requestData) {
          throw Error('ownedRequest data not read from cache');
        }

        client.writeFragment({
          fragment: updatePriorityFragment,
          data: {
            __typename: 'IssueType',
            fields: {
              __typename: 'RequestFields',
              customfield_10010: {
                __typename: 'RequestMetaType',
                currentStatus: {
                  __typename: 'RequestStatus',
                  status: statusName,
                  statusCategory: 'INDETERMINATE',
                  statusDate: {
                    __typename: 'RequestDate',
                    iso8601: new Date().toString(),
                  }
                }
              }
            }
          },
          id: `IssueType:${issueId}`
        });
      } catch {
        requestData = null;
      }

    } catch (e) {
      ErrorLogger.captureException(e);
    }

    try {
      const queryDetails: { query: DocumentNode; variables: GetRequestDetailInput } = {
        query: GetRequestDetailQuery,
        variables: { key: requestKey, partner: context.currentPartner || '' }
      };

      const requestData: GetRequestDetail | null = client.readQuery<GetRequestDetail, GetRequestDetailInput>(queryDetails);
      if (!requestData || !requestData.requestDetail || !requestData.requestDetail.requestFieldValues) {
        ErrorHandler.notify('Cannot display new Status. Please refresh the page.', 'Helpdesk', 'error');
        throw Error('requestDetail data not read from cache');
      }

      const currentStatus: ICurrentStatus = {
        __typename: 'CurrentStatus',
        status: statusName,
        statusCategory: 'DONE',
        statusDate: {
          __typename: 'RequestDate',
          iso8601: new Date().toString(),
          jira: '',
          friendly: ''
        }
      };

      client.writeQuery<GetRequestDetail>({
        ...queryDetails,
        data: {
          requestComments: { ...requestData.requestComments },
          requestDetail: {
            ...requestData.requestDetail,
            currentStatus
          }
        },
      });
    } catch (e) {
      ErrorLogger.captureException(e);
    }
  };

  return { editStatus, loading };
};

interface ITypeAndStatus {
  requestType: string[];
  statusWhiteList: string[];
  statusBlackList: string[];
}

const anyStatusConfig: ITypeAndStatus = {
  requestType: ['Access Request', 'Reported Bug', 'General Inquiry', 'BPO Support'],
  statusWhiteList: [],
  statusBlackList: ['Closed', 'Canceled']
};

const changeRequestConfig: ITypeAndStatus = {
  requestType: ['Change Requests'],
  statusWhiteList: ['Created', 'Information Required', 'Consultation', 'In Discovery', 'Awaiting Partner Approval'],
  statusBlackList: []
};

const deliveredStatusConfig: ITypeAndStatus = {
  requestType: ['Access Request', 'Reported Bug', 'General Inquiry', 'Change Requests'],
  statusWhiteList: ['Delivered'],
  statusBlackList: []
};

const operationalSupportConfig: ITypeAndStatus = {
  requestType: ['Operational Support'],
  statusWhiteList: ['Created', 'In Progress', 'Information Required', 'Canceled'],
  statusBlackList: []
};

const matchConfig: (t: string, s: string, config: ITypeAndStatus) => boolean = (t, s, config) => {
  const { statusWhiteList, statusBlackList, requestType } = config;
  const checkWhiteList: boolean = statusWhiteList.length !== 0;

  if (checkWhiteList) {
    return (requestType.includes(t) && (statusWhiteList.includes(s)) && !statusBlackList.includes(s));
  } else {
    return (requestType.includes(t) && !statusBlackList.includes(s));
  }
};

const determinedTransition: (requestType: string, requestStatus: string) => ITransition | null = (requestType, requestStatus) => {
  // just trying to identify particular requestType and status configurations here:
  if (matchConfig(requestType, requestStatus, operationalSupportConfig)) { return CLOSED_TRANSITION_OP; }
  if (matchConfig(requestType, requestStatus, deliveredStatusConfig)) { return CLOSED_TRANSITION; }
  if (matchConfig(requestType, requestStatus, changeRequestConfig)) { return CANCELED_TRANSITION; }
  if (matchConfig(requestType, requestStatus, anyStatusConfig)) { return CANCELED_TRANSITION; }

  return null;
};

const RequestStatus: React.FC<IProps> = ({ requestType, requestStatus, requestKey, issueId, context }) => {
  const { editStatus, loading } = editStatusHook(requestKey, issueId);
  const transition: ITransition | null = determinedTransition(requestType, requestStatus);

  const handleClick: React.FormEventHandler<HTMLButtonElement> = event => {
    const { name, value } = event.currentTarget;
    editStatus(name, value).catch(error => console.error(error));
  };

  return (
    <div>
      {loading ? (
        <Spinner style={{ height: '2.5em' }} small={true} />
      ) : (
        transition && canUpdateStatus(requestType, context) &&
        <AddButton name={transition.status} value={transition.id} onClick={handleClick}>
          <SVGIcon icon='close' />
          <ButtonLabel>{transition.label}</ButtonLabel>
        </AddButton>
      )}
    </div>
  );
};

export default RequestStatus;
