import * as React from 'react';
import { SVGIcon } from '@amount/frontend-components';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em 0em 0.25em 0em;
`;

// really long, unbroken filenames would tend to overflow the container,
// so we add some wrapping/line-breaking behavior to force it into its box.
// see https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
const Name = styled.span`
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  word-break: break-word;
`;

const Icon = styled(SVGIcon)`
  margin-right: .5em;

  & svg {
    width: 24px;
    display: block;
  }
`;

const Remove = styled.button`
  text-decoration: underline;
  margin-left: .5em;
`;

interface IRemoveableFileProps {
  id: string;
  fileName: string;
  onRemove (id: string): void;
}

const RemoveableFile: React.FC<IRemoveableFileProps> = ({ id, fileName, onRemove }) => {
  const onClick = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onRemove(event.currentTarget.id);
  };

  return (
    <Container>
      <Icon icon='file' />
      <Name>{fileName}</Name>
      <Remove onClick={onClick} id={id}>remove</Remove>
    </Container>
  );
};

export default RemoveableFile;
