import * as React from 'react';
import styled from 'styled-components';
import {
  ActionButton,
  FormError,
  Headline,
  SVGIcon
} from '@amount/frontend-components'
import {
  format,
  isAfter,
  startOfYear,
} from 'date-fns';
import ErrorHandler from '@avant/crm-frontend-utils/error';
import { MutationFn } from 'react-apollo';

import { SelectionOnInBranchStore } from '../../queries/getInBranchStoreData.graphql';
import { DownloadInBranchCSV, DownloadInBranchCSVInput } from '../../queries/getCSV.graphql';
import { stringToFileDownload } from '../../../../services/downloadFile';

import {
  ISummaryData,
  Spacer,
  FilterLabel,
  isDateWithin,
  ColumnContainer,
  GroupedFilter,
  DATE_INPUT_FORMAT,
  SELECT_ALL,
  CHART_WIDTH,
  CHART_HEIGHT,
  PaddingContainer,
  Row,
  ButtonLabel,
  DATE_DOWNLOAD_FORMAT
} from './ChartCommon';
import { getBarChartData, getSummaryViewData, useIssuanceChartData } from './InBranchDataMappers';

import BranchFunnelChart from './BranchFunnelChart';
import IssuanceChart, { IChartDatum } from './IssuanceChart';
import BranchBarChart, { IBarChartData } from './BranchBarChart';
import DebouncedDatePicker from './DebouncedDatePicker';
import DebouncedInput from './DebouncedInput';
import DropDownInput from './DropDownInput';

// const DATE_DOWNLOAD_FORMAT = 'MM/DD/YYYY';
const MAX_ID_LENGTH: number = 4;

interface IProps {
  data: SelectionOnInBranchStore[];
  currentDate: Date;
  getCSVFn: MutationFn<DownloadInBranchCSV, DownloadInBranchCSVInput>
}

const FilterFormError = styled(FormError)`
  margin-bottom: 0;
`;

// const Row = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;

// const ButtonLabel = styled.span`
//   margin-right: 0.5em;
//   margin-bottom: -0.25em;
// `;

const StoreDashboard: React.FC<IProps> = ({ data, currentDate, getCSVFn }) => {
  const [appsBranchId, setAppsBranchId] = React.useState<string>(SELECT_ALL)
  const [appsAgentId, setAppsAgentId] = React.useState<string>(SELECT_ALL);

  const [appsError, setAppsError] = React.useState<boolean>(false);
  const [issuedError, setIssuedError] = React.useState<boolean>(false);

  const [issuedBranchID, setIssuedBranchID] = React.useState<string>(SELECT_ALL);
  const [issuedAgentID, setIssuedAgentID] = React.useState<string>(SELECT_ALL);

  const [appsFrom, setAppsFrom] = React.useState<string>(format(startOfYear(currentDate), DATE_INPUT_FORMAT));
  const [appsTo, setAppsTo] = React.useState<string>(format(currentDate, DATE_INPUT_FORMAT));

  const [issuedFrom, setIssuedFrom] = React.useState<string>(format(startOfYear(currentDate), DATE_INPUT_FORMAT))
  const [issuedTo, setIssuedTo] = React.useState<string>(format(currentDate, DATE_INPUT_FORMAT))

  const [appsDateError, setAppsDateError] = React.useState<boolean>(false);
  const [issuedDateError, setIssuedDateError] = React.useState<boolean>(false);

  const [downloadDisabled, setDownloadDisabled] = React.useState<boolean>(false)

  React.useEffect(() => {
    setAppsDateError(isAfter(appsFrom, appsTo))
    setIssuedDateError(isAfter(issuedFrom, issuedTo))

  }, [appsFrom, appsTo, issuedFrom, issuedTo]);

  const appsAgentPredicate = (data: SelectionOnInBranchStore): boolean =>
    [SELECT_ALL, '', `${data.agentId}`].includes(appsAgentId)

  const appsBranchIdFilter = (data: SelectionOnInBranchStore) =>
    [SELECT_ALL, '', `${data.branchId}`].includes(appsBranchId)

  const issuedBranchIdFilter = (data: SelectionOnInBranchStore) =>
    [SELECT_ALL, '', `${data.branchId}`].includes(issuedBranchID)

  const issuedAgentIdFilter = (data: SelectionOnInBranchStore) =>
    [SELECT_ALL, '', `${data.agentId}`].includes(issuedAgentID)

  const appsFilteredData = React.useMemo(
    () => {
      if (!data) {
        return [];
      }

      const filteredData = data
        .filter(d => isDateWithin(d.createdTime, appsFrom, appsTo))
        .filter(appsBranchIdFilter)
        .filter(appsAgentPredicate);

      if ((filteredData.length === 0) && (appsBranchId.length === MAX_ID_LENGTH)) {
        setAppsError(true)
      } else {
        setAppsError(false)
      }

      return filteredData
    },
    [data, appsBranchId, appsAgentId, appsFrom, appsTo]
  );

  const issuedFilteredData = React.useMemo(
    () => {
      if (!data) {
        return [];
      }

      const filteredData = data
        .filter(({ issued }) => issued)
        .filter(d => isDateWithin(d.issuedTime, issuedFrom, issuedTo))
        .filter(issuedBranchIdFilter)
        .filter(issuedAgentIdFilter)

      if ((filteredData.length === 0) && (issuedBranchID.length === MAX_ID_LENGTH)) {
        setIssuedError(true)
      } else {
        setIssuedError(false)
      }

      return filteredData
    },
    [data, issuedFrom, issuedTo, issuedBranchID, issuedAgentID]
  )

  const summaryData: ISummaryData = React.useMemo(
    () => getSummaryViewData(appsFilteredData),
    [appsFilteredData]
  );

  const barChartData: IBarChartData[] = React.useMemo(
    () => getBarChartData(issuedFilteredData), [issuedFilteredData]
  );

  const issuanceChartData: IChartDatum[] = useIssuanceChartData(issuedFilteredData, issuedFrom, issuedTo);

  const onClickSelectBranchId = (input: string): React.MouseEventHandler<HTMLButtonElement> => () => {
    setAppsBranchId(input)
  }

  const onClickSelectIssuedBranchId = (input: string): React.MouseEventHandler<HTMLButtonElement> => () => {
    setIssuedBranchID(input)
  }

  const handleDownloadCSV = async () => {
    setDownloadDisabled(true)

    try {
      const fields = appsFilteredData.map(d => ({
        branchId: d.branchId,
        agentId: d.agentId,
        customerAppId: d.id.toString(),
        createdDate: format(d.createdTime, DATE_DOWNLOAD_FORMAT),
        status: d.status,
        stage: d.stage,
        appStartType: d.appStartType,
        phoneNumber: d.phoneNumber,
        email: d.email
      }))

      const res = await getCSVFn({
        variables: {
          fields
        }
      })

      if (!res || !res.data || !res.data.downloadInBranchCSV) { throw new Error('invalid response'); }
      const data: string | null = res.data.downloadInBranchCSV.data;
      if (!data) { throw new Error('response data is undefined'); }
      const csvTitle = `TDInBranchData-StoreReporting-${format(new Date, DATE_DOWNLOAD_FORMAT)}.csv`

      stringToFileDownload(data, csvTitle);

    } catch (e) {
      console.error('CSV Download unsuccessful', e)
      ErrorHandler.notify('Unable to download CSV', 'Download CSV', 'error')
    }
    setDownloadDisabled(false)
  }


  return (
    <>
      <Row>
        <Headline scale='medium'>Sales Funnel</Headline>
        <ActionButton
          onClick={handleDownloadCSV}
          disabled={downloadDisabled}
        >
          <SVGIcon icon='download' />
          <ButtonLabel>CSV</ButtonLabel>
        </ActionButton>
      </Row>
      <Spacer padding='1em' />
      <PaddingContainer>
        <div>
          <FilterLabel>Branch ID (4-digit)</FilterLabel>
          <DropDownInput
            name='appsBranchId'
            allData={data.map(d => d.branchId)}
            maxLength={MAX_ID_LENGTH}
            onDebouncedChange={setAppsBranchId}
            onSelect={onClickSelectBranchId}
          />
          {appsError && (
            <FilterFormError>No Data Present</FilterFormError>
          )}
        </div>
        <div>
          <FilterLabel>Agent ID (Alphanumeric)</FilterLabel>
          <DebouncedInput
            name='appsAgentId'
            onDebouncedChange={setAppsAgentId}
          />
        </div>

        <ColumnContainer>
          <GroupedFilter>
            <div id='reset'>
              <FilterLabel>App Created - From</FilterLabel>
              <DebouncedDatePicker
                defaultValue={appsFrom}
                name='appsFrom'
                onDebouncedChange={setAppsFrom}
              />
            </div>
            <div>
              <FilterLabel>App Created - To</FilterLabel>
              <DebouncedDatePicker
                defaultValue={appsTo}
                name='appsTo'
                onDebouncedChange={setAppsTo}
              />
            </div>
          </GroupedFilter>
          {appsDateError && (
            <FormError>Invalid Date Range</FormError>
          )}
        </ColumnContainer>
      </PaddingContainer>
      <BranchFunnelChart {...summaryData} width={CHART_WIDTH} height={CHART_HEIGHT} />
      <Spacer padding='3em' />
      <Headline scale='medium'>Loan Issuance Over Time/Top Branches</Headline>
      <Spacer padding='1em' />
      <PaddingContainer>
        <div>
          <FilterLabel>Branch ID (4-digit)</FilterLabel>
          <DropDownInput
            name='issuedBranchID'
            allData={data.filter(({ issued }) => issued).map(d => d.branchId)}
            maxLength={MAX_ID_LENGTH}
            onDebouncedChange={setIssuedBranchID}
            onSelect={onClickSelectIssuedBranchId}
          />
          {issuedError && (
            <FilterFormError>No Data Present</FilterFormError>
          )}
        </div>
        <div>
          <FilterLabel>
            Agent ID (Alphanumeric)
          </FilterLabel>
          <DebouncedInput
            name='issuedAgentID'
            onDebouncedChange={setIssuedAgentID}
          />
        </div>
        <ColumnContainer>
          <GroupedFilter>
            <div id='reset'>
              <FilterLabel>Loan Issued - From</FilterLabel>
              <DebouncedDatePicker
                defaultValue={issuedFrom}
                name='issuedFrom'
                onDebouncedChange={setIssuedFrom}
              />
            </div>
            <div>
              <FilterLabel>Loan Issued - To</FilterLabel>
              <DebouncedDatePicker
                defaultValue={issuedTo}
                name='issuedTo'
                onDebouncedChange={setIssuedTo}
              />
            </div>
          </GroupedFilter>
          {issuedDateError && (
            <FormError>Invalid Date Range</FormError>
          )}
        </ColumnContainer>
      </PaddingContainer>
      <IssuanceChart data={issuanceChartData} width={CHART_WIDTH} height={CHART_HEIGHT} />
      <Spacer padding='2em' />
      <BranchBarChart data={barChartData} width={CHART_WIDTH} height={CHART_HEIGHT} />
      <Spacer padding='4em' />
    </>
  );
};

export default StoreDashboard;
StoreDashboard.displayName = 'StoreDashboard';
