import * as React from 'react';
import {
  Headline,
} from '@amount/frontend-components';

import { ErrorLogger } from '../services/error';

import { MaxWidthContainer } from './MaxWidthContainer';
import { PartnersContainer } from './PartnersContainer';
import { InnerContainer } from './InnerContainer';

interface IErrorBoundaryState {
  error: boolean;
}

export class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
  public state = {
    error: false
  };

  public componentDidCatch (error: Error, errorInfo: React.ErrorInfo) {
    ErrorLogger.captureException(error);
    ErrorLogger.setExtra('errorInfo', JSON.stringify(errorInfo));
    this.setState({ error: true });
  }

  public render () {
    if (this.state.error) {
      return (
        <PartnersContainer>
          <MaxWidthContainer>
            <InnerContainer>
              <Headline scale='large'>We're sorry.</Headline>
              <p>We are having an unplanned outage and our team is working on it.</p>
            </InnerContainer>
          </MaxWidthContainer>
        </ PartnersContainer>
      );
    }

    return this.props.children;
  }
}
