import * as React from 'react';
import { ApolloQueryResult } from 'apollo-client';
import { Observable, Subscription } from 'rxjs';
// tslint:disable no-import-side-effect no-submodule-imports
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/takeWhile';
// tslint:enable

import { GetPage } from '../getPage.graphql';

const isElementInViewport: (el: HTMLElement) => boolean = el => {
  const rect: ClientRect = el.getBoundingClientRect();

  return (
    rect.top >= 0
      && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
          && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export type LiftedProps<Base> = Base & {
  loading: boolean;
  // tslint:disable-next-line: no-any
  refFn: React.Ref<any>;
  loadMore (): void;
};

export interface IVisibleProps {
  loadMoreEntries? (): Promise<ApolloQueryResult<GetPage>>; // optional for SarSearch data
}

// tslint:disable-next-line only-arrow-functions
export function withVisible<T extends IVisibleProps> (Component: React.ComponentType<LiftedProps<T>>): React.ComponentClass<T> {
  return class extends React.PureComponent<T, { loading: boolean }> {
    public static displayName: string = `withVisible(${Component.name})`;
    public state: { loading: boolean } = {
      loading: false,
    };
    private _ref: HTMLElement | undefined;
    private _isMounted: boolean = false;
    private observable: Subscription | undefined;

    public componentDidMount (): void {
      this._isMounted = true;
      this.loadMore();

      const element: Element | null = document.querySelector('[class^=style__sarBody]');
      if (!element) { return; }
      this.observable = Observable
        // tslint:disable-next-line: no-non-null-assertion
        .fromEvent(element, 'scroll')
        .debounceTime(100)
        .takeWhile(() => this._isMounted)
        .subscribe(() => this.loadMore());
    }

    public componentDidUpdate (): void {
      this.loadMore();
    }

    public componentWillUnmount (): void {
      this._isMounted = false;
      if (this.observable) { this.observable.unsubscribe(); }
    }

    public loadMore: () => void = () => {
      if (!this.state.loading && this._ref && isElementInViewport(this._ref)) {
        this.setState({ loading: true });
        if (!this.props.loadMoreEntries) { return; }
        void this.props.loadMoreEntries().then(_ => this._isMounted && this.setState({ loading: false }));
      }
    }

    public render (): JSX.Element {
      return (
        <Component
          {...this.props}
          loading={this.state.loading}
          // tslint:disable-next-line jsx-no-lambda
          refFn={r => this._ref = r}
          loadMore={this.loadMore}
        />
      );
    }
  };
}
