import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ButtonWithSpinner } from '@amount/frontend-components';

import { Consumer } from '../Context';

import { Section } from './Config';
import {
  defaultEditFields,
  defaultState,
  defaultTrainingFields,
  IAttachment,
  IHelpdeskSections,
  ISection,
} from './Common';
import {
  CategorySection,
  EditFieldsSection,
  PrioritySection,
  RegionSection,
  TitleSection,
  TrainingFieldsSection
} from './Common/HelpdeskSections';

const PRIORITY_SECTIONS: Set<string> = new Set([
  Section.ServiceIncident,
  Section.SiteReliabilityEngineering,
  Section.AmountChangeRequest,
  Section.AmountBugReport,
  Section.AmountGeneralInquiry
]);

type RouteProps = RouteComponentProps<{ section: string }>;

interface IHelpdeskContentProps extends RouteProps {
  helpdeskSections?: IHelpdeskSections;
  section: ISection;
  submitting: boolean;
  submitHelpdeskTicket (helpdeskSections: IHelpdeskSections): void;
  onError (message: string): void;
}

export class HelpdeskContent extends React.Component<IHelpdeskContentProps, IHelpdeskSections> {
  constructor (props: IHelpdeskContentProps) {
    super(props);

    const helpdeskSections: IHelpdeskSections = props.helpdeskSections || defaultState;

    this.state = helpdeskSections;
  }

  public render (): JSX.Element {
    return (
      <>
        <CategorySection
          category={this.state.category}
          categories={this.props.section.categories}
          onSelect={this.onCategoryChange}
          section={this.props.match.params.section}
        />
        {!!this.state.category && (
          <>
            {this.showRegionSection() && (
              <RegionSection
                region={this.state.region}
                onSelect={this.onRegionChange}
                section={this.props.match.params.section}
              />
            )}
            {this.showPrioritySection() && (
              <PrioritySection
                priority={this.state.priority}
                onSelect={this.onPriorityChange}
                section={this.props.match.params.section}
              />
            )}
            {this.showTrainingFieldsSection() && (
              <TrainingFieldsSection
                locationValue={this.state.location}
                departmentValue={this.state.department}
                productValue={this.state.product}
                onSelectLocation={this.onLocationChange}
                onSelectDepartment={this.onDepartmentChange}
                onSelectProduct={this.onProductChange}
              />
            )}
            {this.showTitleSection() && (
              <Consumer>
                {me => (
                  <TitleSection
                    me={me}
                    categoryTitles={this.props.section.categories[this.state.category].titles}
                    titleValue={this.state.title}
                    priorityValue={this.state.priority}
                    onSelect={this.onTitleChange}
                    section={this.props.match.params.section}
                    categoryValue={this.state.category}
                  />
                )}
              </Consumer>
            )}
            {!!this.state.title && (
              <EditFieldsSection
                onChangeNotes={this.onNoteChange}
                onAttachmentRemove={this.onAttachmentRemove}
                helpdeskSections={this.state}
                attachmentChangeError={this.onAttachmentError}
                attachmentOnLoad={this.onAttachmentLoad}
              />
            )}
            <ButtonWithSpinner
              key='submitButton'
              inline={true}
              onClick={this.submitTicket}
              disabled={this.props.submitting}
              loading={this.props.submitting}
              data-event='submit'
            >
              Submit
            </ButtonWithSpinner>
          </>
        )}
      </>
    );
  }

  private readonly onCategoryChange: React.FormEventHandler<HTMLSelectElement> = e => {
    if (e) {
      this.setState({
        category: e.currentTarget.value ,
        region: '',
        ...defaultEditFields,
        ...defaultTrainingFields
      });
    }
  }

  private readonly onRegionChange: React.FormEventHandler<HTMLSelectElement> = e => {
    this.setState({ region: e.currentTarget.value });
  }

  private readonly onPriorityChange: React.FormEventHandler<HTMLSelectElement> = e => {
    this.setState({ priority: e.currentTarget.value });
  }

  private readonly onLocationChange: React.FormEventHandler<HTMLSelectElement> = e => {
    this.setState({ location: e.currentTarget.value });
  }

  private readonly onDepartmentChange: React.FormEventHandler<HTMLSelectElement> = e => {
    this.setState({ department: e.currentTarget.value });
  }

  private readonly onProductChange: React.FormEventHandler<HTMLSelectElement> = e => {
    this.setState({ product: e.currentTarget.value });
  }

  private readonly onTitleChange: (title: string, note: string) => void = (title, note) => {
    this.setState({ title, note });
  }

  private readonly onNoteChange: React.FormEventHandler<HTMLTextAreaElement> = e => {
    this.setState({ note: e.currentTarget.value });
  }

  // tslint:disable-next-line: no-any
  private readonly onAttachmentLoad: (attachment: IAttachment) => any = attachment => {
    this.setState({ attachment });
  }

  private readonly onAttachmentRemove: React.FormEventHandler<HTMLButtonElement> = () => {
    this.setState({ attachment: null });
  }

  private readonly onAttachmentError: (error: string) => void = err => {
    this.props.onError(err);
  }

  private showRegionSection (): boolean {
    return this.props.match.params.section === Section.ProductionEngineering;
  }

  private showPrioritySection (): boolean {
    return PRIORITY_SECTIONS.has(this.props.match.params.section);
  }

  private showTrainingFieldsSection (): boolean {
    return this.props.match.params.section === Section.TrainingOperations;
  }

  private showTitleSection (): boolean {
    return (
      (this.showRegionSection() && !!this.state.region) ||
      (this.showPrioritySection() && !!this.state.priority) ||
      (this.showTrainingFieldsSection() && (!!this.state.location && !!this.state.department && !!this.state.product)) ||
      (!this.showRegionSection() && !this.showPrioritySection() && !this.showTrainingFieldsSection())
    );
  }

  private readonly submitTicket: () => void = () => {
    this.props.submitHelpdeskTicket(this.state);
  }
}

export default HelpdeskContent;
