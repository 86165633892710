import {
  Cell as FCCell,
  media,
  TableContain,
  TBody,
} from '@amount/frontend-components';
import styled, { css } from 'styled-components';

/*
  This file is a modified copy of Kevin's UserTable, in the User-Admin module.
  This file should be merged with the pre-existing table components, for use throughout the project.
*/

export const Table = styled(TableContain)`
  margin: 1em 0;
  table-layout: fixed;
`;

export const HeaderRow = styled.tr`
  ${({ theme }) => theme.name === 'amountDark' ? `
    background-color: rgba(242,242,242,0.1);
  ` : `
    background-color: rgba(2,42,58,0.05);
  `}

  height: 2.625em;
`;

interface IHeaderCell {
  mobile?: boolean;
}

export const HeaderCell = styled.th<IHeaderCell>`
  font-size: 1.2rem;
  color: ${({ theme: { text } }) => text.colorText};
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1em 1em 0.75em;

  &:last-child {
    text-align: right;
  }

  ${({ mobile }) => mobile ? css`
    display: table-cell;
    width: 100%;

    ${media.small`
      display: none;
    `}
  ` : css`
    display: none;

    ${media.small`
      display: table-cell;
    `}
  `}
`;

export { TBody };

const darkItemStyles = css`
  &:hover {
    color: ${({ theme: { link }}) => link.colorLinkHover};
  }
`;

const lightItemStyles = css`
  &:hover {
    background: ${({ theme }) => theme.colorSlate5};
  }
`;

export const Row = styled.tr<{ notClickable?: boolean }>`
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colorSlate20};
  color: ${({ theme: { text } }) => text.colorText};

  ${({ notClickable }) => !notClickable && css`
    cursor: pointer;

    ${({ theme }) => theme.name === 'amountDark' ? darkItemStyles : lightItemStyles}
  `}

  &:focus {
    outline: ${({ theme }) => theme.outline};
  }
`;

export const Cell = styled(FCCell)`
  display: block;
  position: relative;
  color: unset;
  font-size: 1.4rem;
  padding: 0.5em 1em 0.25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(:first-child):last-child {
    display: table-cell;
    text-align: right;
  }

  ${media.medium`
    padding: 1em 1em .75em;

    &:first-child,
    &:nth-child(3) {
      padding: 1.5em 1em 1.25em;
    }
  `}
`;
