import * as React from 'react';
import camelCase from 'lodash-es/camelCase';
import {
  FormError,
  InputSelectField,
  SelectOptionItem,
} from '@amount/frontend-components';
import { Controller, useFormContext } from 'react-hook-form';

import { ISelectInput } from './common';

export const Select: React.FC<ISelectInput> = ({ name, isRequired, labelName, options }) => {
  const { errors, control } = useFormContext();

  const inputId = `input-${name}`;
  const errorId = `input-${name}-error`;
  const emptyOption = { value: '', label: '' };

  return (
    <>
      <Controller
        control={control}
        rules={{ required: isRequired }}
        aria-describedby={errorId}
        name={name}
        render={({ onChange, value }) => {
          // this effectively assigns a default value to a select input
          if (!value) {
            // tslint:disable-next-line: no-parameter-reassignment
            value = {id: options[options.length - 1].value};
            onChange(value);
          }

          return (
            <InputSelectField
              label={labelName}
              data-event={camelCase(`${name} select`)}
              inputProps={{
                name,
                required: isRequired || false,
                id: inputId,
                value: value.id,
                onChange: e => {
                  onChange({ id: e.target.value });
                }
              }}
            >
              {[...options, emptyOption].map((o, index) => (
                <SelectOptionItem
                  key={o.value}
                  value={o.value}
                  optionProps={index === options.length ? { disabled: true, hidden: true } : {}}
                >
                  {o.label}
                </SelectOptionItem>
              ))}
            </InputSelectField>
          );
        }}
      />
      {errors[name] && <FormError id={errorId}>This field is required</FormError>}
    </>
  );
};

