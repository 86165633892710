import * as React from 'react';
import Helmet from 'react-helmet';
import { Headline } from '@amount/frontend-components';
import styled from 'styled-components';

import { ModuleRoutes } from '../../../../shared/routes';
import HelpdeskBody from '../Common/HelpdeskBody';
import { APS_SERVICEDESK_ID, SHOW_TEST_SERVICEDESK, SI_SERVICEDESK_ID, TEST_SERVICEDESK_ID } from '../../../../shared/config/helpdesk';
import { PermissionedComponent } from '../../PermissionedComponent';
import { PartneredComponent } from '../../PartneredComponent';
import { AVANT_PERMISSION_KEY, SUBMIT_SERVICE_INCIDENT_HELPDESK } from '../../../../shared/config/permissions';
import { SelectionOnRequestTypes } from '../queries/getRequestCategories.graphql';

import CategoryList from './HelpdeskCategoryList';
import { RequestLink } from './RequestTypeList';

const HeadlineMarginBottom = styled(Headline)`
  margin: 0 0 1em 0;
`;

const SI_REQUEST: SelectionOnRequestTypes = {
  __typename: 'Requesttype',
  id: '280',
  name: 'Service Incident',
  description: '',
  helpText: '',
  issueTypeId: '10078',
  serviceDeskId: SI_SERVICEDESK_ID,
  groupIds: ['78'],
};
const SIComponent: React.FC = () => <RequestLink request={SI_REQUEST} />;

const AVANT_SI_REQUEST: SelectionOnRequestTypes = {
  __typename: 'Requesttype',
  id: '281',
  name: 'Amount Service Incident',
  description: '',
  helpText: '',
  issueTypeId: '10096',
  serviceDeskId: SI_SERVICEDESK_ID,
  groupIds: ['76'],
};

const Categories: React.FC = () => (
  <HelpdeskBody backPath={ModuleRoutes.helpdesk}>
    <Helmet>
      <title>Categories · Helpdesk · Partner Portal</title>
    </Helmet>
    <HeadlineMarginBottom scale='large' as='h1'>Create New Request</HeadlineMarginBottom>
    <ul>
      <CategoryList serviceDeskId={APS_SERVICEDESK_ID} />
      <PermissionedComponent allowedPermissions={[SUBMIT_SERVICE_INCIDENT_HELPDESK]}>
        <li>
          <PartneredComponent allowedPartners={[AVANT_PERMISSION_KEY]} NoAccessComponent={SIComponent}>
            <RequestLink request={AVANT_SI_REQUEST} />
          </PartneredComponent>
        </li>
      </PermissionedComponent>
      {SHOW_TEST_SERVICEDESK && <CategoryList serviceDeskName='Test' serviceDeskId={TEST_SERVICEDESK_ID} />}
    </ul>
  </HelpdeskBody>
);

export default Categories;
