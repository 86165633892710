import * as React from 'react';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import { SVGIcon, Upload, } from '@amount/frontend-components';
import styled from 'styled-components';

interface IDropzoneProps {
  canEdit: boolean;
  passRef: React.Ref<DropzoneRef>;
  onDrop (acceptedFiles: File[]): Promise<void>;
}

const DropzoneWrapper = styled.div`
  flex: 1;
  position: relative;
  min-height: 22em;
`;

const ActiveDropzone = styled(Upload)`
  position: absolute;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 2.5em 0;
  font-size: 1.75rem;
  height: 100%;
`;

const DropzoneIcon = styled(SVGIcon)`
  padding-bottom: 0.75em;
  display: block;
  width: 40px;
`;

const DropzoneComponent: React.FC<IDropzoneProps> = ({
  passRef,
  onDrop,
  canEdit,
  children
}) => (
  <Dropzone
    onDrop={onDrop}
    noClick={true}
    disabled={!canEdit}
    ref={passRef}
  >
    {({ getRootProps, getInputProps, isDragActive }) => (
      <DropzoneWrapper {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive && canEdit && (
          <ActiveDropzone>
            <DropzoneIcon icon='file' />
            <p>Drag and drop to add new files</p>
          </ActiveDropzone>
        )}
        {children}
      </DropzoneWrapper>
    )}
  </Dropzone>
);

export default DropzoneComponent;
