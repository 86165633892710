export enum FILTER_NAMES {
  status = 'Status',
  priority = 'Priority',
  reporter = 'Reporter',
  search = 'Search',
  sort = 'Sort',
  product = 'Product',
  requestTypes = 'Category'
}

export interface IActionState {
  [FILTER_NAMES.search]: string;
  [FILTER_NAMES.sort]: string;
  [FILTER_NAMES.reporter]: Set<string>;
  [FILTER_NAMES.status]: Set<string>;
  [FILTER_NAMES.priority]: Set<string>;
  [FILTER_NAMES.product]: Set<string>;
  [FILTER_NAMES.requestTypes]: Set<string>;
}

export interface IActionBar {
  actionBarState: IActionState;
  handleChange: React.FormEventHandler<HTMLInputElement>;
  handleClear: React.FormEventHandler<HTMLButtonElement>;
  handleSearchChange: React.FormEventHandler<HTMLInputElement>;
  handleSelectChange: React.FormEventHandler<HTMLSelectElement>;
}
