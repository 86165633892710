import * as React from 'react';
import {
  Headline,
  LayoutSkinny,
  MaxWidthContainer,
  media,
  ScreenReaderOnly,
} from '@amount/frontend-components';
import styled from 'styled-components';

import { InnerContainer } from '../InnerContainer';
import { PartnersContainer } from '../PartnersContainer';
import { Card, ICardProps } from '../Card';
import { HeaderContain, HeaderWrapper } from '../Header/Presentational/common';
import { CurrentLogo } from '../Logo';
import { AMOUNT_PERMISSION_KEY } from '../../../shared/config/permissions';

const PartnersSkinny = styled(LayoutSkinny)`
  ${media.small`
    max-width: 20em;
  `}
`;

const LoginMethod = styled(Card)<ICardProps>`
  width: 100%;
  padding: 1.25em 1.25em 1em;
`;

export const LoginButtons: React.FC = () => (
  <a title='Login with Amount' href='/auth/oidc'>
    <ScreenReaderOnly>
      Login with Amount
    </ScreenReaderOnly>
    <LoginMethod
      icon='crm'
      name='Login with Amount'
    />
  </a>
);

const Login: React.FC = () => (
  <>
    <HeaderWrapper>
      <HeaderContain>
        <CurrentLogo partner={AMOUNT_PERMISSION_KEY} />
      </HeaderContain>
    </HeaderWrapper>
    <PartnersContainer>
      <MaxWidthContainer>
        <InnerContainer>
          <PartnersSkinny as='div'>
            <div>
              <Headline scale='large'>Partner Portal</Headline>
              <p>Select how you would like to log in.</p>
            </div>
            <LoginButtons />
          </PartnersSkinny>
        </InnerContainer>
      </MaxWidthContainer>
    </PartnersContainer>
  </>
);

export default Login;
