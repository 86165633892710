import * as React from 'react';
import { QueryResult } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import Events from '@avant/crm-frontend-utils/events';

import HeapHelper from '../../services/HeapHelper';
import { IPusherConfig, PusherConfigOptions } from '../../../shared/config/pusher';
import { pusherClient } from '../../services/pusher';
import { SwitchablePartner } from '../../../shared/config/partners';
import { INVALID_ORGANIZATION, PARTNER_CHANGED_EVENT } from '../../../shared/config/constants';
import { ErrorLogger } from '../../services/error';

import { Me as MeResult, SelectionOnMe } from './me.graphql';

// tslint:disable-next-line: no-any
const MeContext: React.Context<SelectionOnMe> = React.createContext({} as any);
const { Provider, Consumer }: React.Context<SelectionOnMe> = MeContext;

export type WithData = Pick<QueryResult<MeResult, {}>, 'data' | 'loading'>;
type IProviderProps = RouteComponentProps & WithData;

const authenticate: (props: IProviderProps) => void = ({ data, loading, history }) => {
  if (!data) { return; }
  if (!loading && !data.me) {
    history.replace('/login');

    return;
  }
};

export class ProviderComponent extends React.Component<IProviderProps> {
  public static getDerivedStateFromProps (nextProps: IProviderProps): null {
    authenticate(nextProps);

    return null;
  }

  public state = {};

  public componentDidMount (): void {
    authenticate(this.props);
    this.setUser();
    Events.subscribe(PARTNER_CHANGED_EVENT, this.changeClient);
  }

  public componentDidUpdate (prevProps: IProviderProps): void {
    if (prevProps.data && this.props.data && prevProps.data.me !== this.props.data.me) {
      this.setUser();
    }
  }

  public componentWillUnmount (): void {
    Events.unsubscribe(PARTNER_CHANGED_EVENT, this.changeClient);
    this.setUser(true);
  }

  public render () {
    if (!this.props.data) { return null; }

    return (
      <Provider value={this.props.data.me}>
        {this.props.children}
      </Provider>
    );
  }

  private readonly setUser: (setUndefined?: boolean) => void = (setUndefined = false) => {
    if (this.props.data && this.props.data.me) {
      const { id, email, currentPartner, organization } = this.props.data.me;
      ErrorLogger.setUser({ id, email, organization });
      ErrorLogger.setExtra('currentPartner', currentPartner || INVALID_ORGANIZATION);
      HeapHelper.identify(email);
      HeapHelper.addUserProperties({ organization });
      HeapHelper.addEventProperties({ currentPartner: currentPartner || '' });
      this.changeClient();
    } else if (setUndefined) {
      ErrorLogger.setUser(null);
      HeapHelper.clearEventProperties();
      HeapHelper.resetIdentity();
    }
  }

  private readonly changeClient: () => void = () => {
    if (this.props.data && this.props.data.me) {
      const { currentPartner: partner } = this.props.data.me;

      const pusherConfig: IPusherConfig | undefined = (PusherConfigOptions.get(partner as SwitchablePartner) as IPusherConfig);
      if (pusherConfig) {
        pusherClient.changeClient(pusherConfig);
      }
    }
  }
}

export { Consumer, ProviderComponent as ContextProvider, MeContext };
