import * as React from 'react';
import camelCase from 'lodash-es/camelCase';
import { useFormContext } from 'react-hook-form';
import { FormError, Input } from '@amount/frontend-components';

import { IText, Label } from './common';

export const NumberInput: React.FC<IText> = ({ name, labelName, formControlId, isRequired }) => {
  const { errors, register } = useFormContext();

  const inputId = `input-${name}`;
  const errorId = `input-${name}-error`;

  return (
    <>
      <Label inputId={inputId} labelName={labelName} required={isRequired || false} />
      <Input
        name={name}
        type='number'
        data-event={camelCase(`${name} number`)}
        ref={register({ required: isRequired, valueAsNumber: true })}
        id={formControlId}
        required={isRequired}
        aria-describedby={errorId}
      />
      {errors[name] && <FormError id={errorId}>This field is required</FormError>}
    </>
  );
};
