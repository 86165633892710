/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { FieldSet, FormLabel , Button_t2_ as Buttontheme2 } from '@amount/frontend-components';
import styled from 'styled-components';
import camelCase from 'lodash-es/camelCase';
import { FormProvider, useForm, UseFormMethods } from 'react-hook-form';
// import { DevTool } from '@hookform/devtools';

import { SelectionOnJiraSchema, SelectionOnRequestTypeFields, SelectionOnValidValues } from '../../queries/getRequestFields.graphql';
import { JIRA_PARTNER_FIELD_ID } from '../../../../../shared/config/helpdesk';
import FormMessage from '../Message';

import { FormAttachment } from './SubmitAttachmentInput';
import { DateInput, Description, MultiCheckBoxes, NumberInput, Select, Summary } from './Fields';
import { LabelInput, UserInput } from './SubmitArrayInputs';
import { CascadeSelect, ICascadeValue } from './SubmitSelectInputs';
import Section from './FormSection';
import createRequiredFieldsRefs from './createRequiredFieldsRefs';

const InputSection = styled(Section)`
  & > ${FormLabel} {
    transition: color 0.1s ease-out;


    &:focus-within, &:hover {
      color: ${({ theme: { input } }) => input.colorFocus};
    }

    & > select:focus, select:hover {
      transition: background-image 0.1s ease-out;
      background-image:  ${({ theme: { input } }) => `url(${input.selectInputBackgroundSvgFocused})`};
    }
  }
`;

interface IProps {
  fields: SelectionOnRequestTypeFields[];
  submitting: boolean;
  serviceDeskId: string;
  error: string | null;
  submitRequest (data: any): void;
}

interface ISubmitForm extends UseFormMethods {
  fields: SelectionOnRequestTypeFields[];
  submitting: boolean;
  serviceDeskId: string;
  error: string | null;
  submitRequest (data: any): void;
}

class SubmitForm extends React.Component<ISubmitForm> {
  public state = {
    requiredFieldsRefs: createRequiredFieldsRefs(this.props.fields),
  };

  constructor (props: ISubmitForm) {
    super(props);

    for (const f of props.fields) {
      if (f.jiraSchema.type === 'array' && !!f.jiraSchema.items) {
        this.state[f.fieldId] = f.defaultValues;
      } else if (f.jiraSchema.type === 'string' && f.description) {
        this.state[f.fieldId] = f.description;
      } else if (f.fieldId === JIRA_PARTNER_FIELD_ID) {
        this.state[f.fieldId] = true;
      } else {
        const defaultValue = f.defaultValues[0] && { id: f.defaultValues[0].value };
        this.state[f.fieldId] = defaultValue || '';
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onSubmit = (data: any) => {
    this.props.submitRequest({
      ...data,
      // dynamically add the partner fieldID field into the submission
      // because it is not tracked by the form.
      ...(this.state[JIRA_PARTNER_FIELD_ID] !== undefined && {
        [JIRA_PARTNER_FIELD_ID]: this.state[JIRA_PARTNER_FIELD_ID]
      })
    });
  };

  public render () {
    return (
      <>
        <form autoComplete='off' onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate={true}>
          {this.props.fields.filter(f => f.fieldId !== JIRA_PARTNER_FIELD_ID).map((f: SelectionOnRequestTypeFields, i) =>
            (
              <InputSection key={f.fieldId || i}>
                {
                  f.jiraSchema.items === 'option' ?
                    (
                      <FieldSet>
                        {this.inputComponent(f.fieldId || '', f.jiraSchema, f.validValues || [], f.name, f.required || false)}
                      </FieldSet>
                    ) :
                    (
                      <>
                        {this.inputComponent(f.fieldId || '', f.jiraSchema, f.validValues || [], f.name, f.required || false)}
                      </>
                    )
                }
              </InputSection>
            ))}
          {this.props.error && <FormMessage message={this.props.error} />}
          <Buttontheme2
            variant={'primary'}
            type='submit'
            width={'auto'}
            disabled={Object.keys(this.props.errors).length > 0}
            isLoading={this.props.submitting}
            data-event='submitHelpdeskRequest'
          >
          Submit
          </Buttontheme2>
        </form>
        {/* V handy for debugging react-hook-form stuff */}
        {/* <DevTool control={this.props.control} /> */}
      </>
    );
  }
  // tslint:disable-next-line: max-func-body-length
  private readonly inputComponent = (
    name: string,
    schema: SelectionOnJiraSchema,
    validValues: SelectionOnValidValues[],
    labelName: string,
    isRequired: boolean
  ) => {
    const fieldType: string = schema.type || '';
    const customType: string = schema.custom && schema.custom.indexOf(':') && schema.custom.split(':')[1] || '';
    const systemType: string = schema.system || '';
    const serviceDeskId = this.props.serviceDeskId;
    const formControlId: string = camelCase(labelName);

    switch (fieldType) {
    case 'string':
      if (systemType === 'description' || customType === 'textarea') {
        return (
          <Description
            name={name}
            labelName={labelName}
            formControlId={formControlId}
            isRequired={isRequired}
            placeholder={this.state[name]}
          />
        );
      } else {
        return (
          <Summary
            name={name}
            labelName={labelName}
            formControlId={formControlId}
            isRequired={isRequired}
          />
        );
      }
    case 'number':
      return (
        <NumberInput
          name={name}
          labelName={labelName}
          formControlId={formControlId}
          isRequired={isRequired}
        />
      );
    case 'priority':
    case 'option':
      return (
        <Select
          name={name}
          labelName={labelName}
          options={validValues}
          formControlId={formControlId}
          isRequired={isRequired}
        />
      );
    case 'option-with-child':
      return (
        <CascadeSelect
          name={name}
          onChange={this.handleCascadeChange}
          value={this.state[name]}
          options={validValues}
          isRequired={isRequired}
        />
      );
    case 'array':
      if (systemType === 'attachment') {
        return (
          <FormAttachment
            id={name}
            setAttachments={this.handleArrayChange}
            value={this.state[name]}
            formControlId={formControlId}
          />
        );
      } else if (customType === 'multiuserpicker') {
        const value = this.state[name].map((v: { id: string }) => v.id);

        return (
          <UserInput
            id={name}
            onChange={this.handleMultiUserChange}
            value={value}
            serviceDeskId={serviceDeskId}
          />
        );
      } else if (systemType === 'labels') {
        return (
          <LabelInput
            id={name}
            onChange={this.handleArrayChange}
            value={this.state[name]}
            serviceDeskId={serviceDeskId}
          />
        );
      } else { // (customType === 'multicheckboxes' || systemType === 'components' || customType === 'multiselect')
        return (
          <MultiCheckBoxes
            name={name}
            boxes={validValues}
            formControlId={formControlId}
            labelName={labelName}
            isRequired={isRequired}
          />
        );
      }
    case 'date':
      return (
        <DateInput
          name={name}
          labelName={labelName}
          formControlId={formControlId}
          isRequired={isRequired}
        />
      );
    default:
      return <div>Field does not have a valid type.</div>;
    }
  };

  private readonly handleArrayChange = (name: string, arr: string[]): void => this.setState({ [name]: arr });

  private readonly handleMultiUserChange = (name: string, arr: string[]): void => {
    const arrOfIds = arr.map((v: string) => ({ id: v }));
    this.setState({ [name]: arrOfIds });
  };

  private readonly handleCascadeChange = (id: string, value: ICascadeValue): void => this.setState({ [id]: value });
}

const SubmitFormWithProvider: React.FC<IProps> = props => {
  const { fields, submitRequest, submitting, serviceDeskId, error } = props;
  const formValues = fields
    .reduce<{[x: string]: unknown} | undefined>(
    (acc, field) => {
      const { fieldId, defaultValues } = field;

      let value = null;

      // attachment defaults
      if (fieldId === 'attachment') {
        value = [];
      }

      // ?
      if (defaultValues.length > 0) {
        value = {id: defaultValues[0].value};
      }

      // product type defaults
      if (fieldId === 'customfield_10168') {
        value = defaultValues.map(val => ({ id: val.value }));
      }

      return {...acc, [fieldId]: value};
    },
    {});

  const hookForm = useForm<Record<string, unknown>>({mode: 'onSubmit', defaultValues: formValues});

  return (
    <FormProvider {...hookForm}>
      <SubmitForm
        fields={fields}
        submitRequest={submitRequest}
        submitting={submitting}
        serviceDeskId={serviceDeskId}
        error={error}
        {...hookForm}
      />
    </FormProvider>
  );
};

export default SubmitFormWithProvider;
