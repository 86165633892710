import {
  Alert, SVGIcon
} from '@amount/frontend-components';
import styled from 'styled-components';

export const StyledAlert = styled(Alert)`
  display: inline-flex;
  align-items: center;
  padding: 1em;
  transition: none;

  &:hover, &:focus, &:active {
    border: 1px solid transparent;
  }
`;

export const AlertIcon = styled(SVGIcon)`
  display: flex;
  margin-right: 0.5em;
  color: ${({ theme: { alert: { inactive } } }) => inactive.colorText};
`;

export const AlertLabel = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 0.125em;
`;
