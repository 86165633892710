import * as React from 'react';
import { Query } from 'react-apollo';
import { Spinner } from '@amount/frontend-components';

import {
  AMOUNT_SERVICE_INCIDENT,
  APS_SERVICEDESK_ID,
  AVANT_DATA_SET_SPLIT,
  BPO_SUPPORT,
  EMAILED_REQUEST,
  OPERATIONAL_SUPPORT,
  SERVICE_INCIDENT
} from '../../../../shared/config/helpdesk';
import { permissionsConfig } from '../RequestCategoryList/HelpdeskCategoryList';
import GetOwnedRequestTypesQuery, {
  GetOwnedRequestTypes,
  GetOwnedRequestTypesInput,
  SelectionOnRequestTypes,
} from '../queries/getOwnedRequestTypes.graphql';
import { SelectionOnIssues } from '../queries/getOwnedRequests.graphql';
import { canViewOperationalSupportFilter, isAmountUser, isAvantUser,  } from '../__helpers__/permissioning';
import { MeContext } from '../../Context';
import { FILTER_NAMES, IActionBar } from '../constants';

import { SelectionOnMe } from '../../Context/me.graphql';
import FilterButton from './FilterButton';

interface IProps  extends IActionBar {
  requestList: SelectionOnIssues[];
}

interface IFilter extends IProps {
  data?: GetOwnedRequestTypes;
  loading: boolean;
}

const filteredByPermissions: (arr: SelectionOnRequestTypes[], context: SelectionOnMe) => string[] = (requestTypes, context) => {
  const config: string[] = Object.keys(permissionsConfig);
  const isInGroup: (groupIds: string[]) => boolean = groupIds => groupIds.some(id => config.includes(id));
  const isDataSplit: (name: string) => boolean = name => name === AVANT_DATA_SET_SPLIT;
  const isBPOSupport: (name: string) => boolean = name => name === BPO_SUPPORT;
  const isAvantOrAmount = isAmountUser(context) || isAvantUser(context);

  return requestTypes.reduce<string[]>(
    (acc, curr) => {
      if (isInGroup(curr.groupIds) && (
        !isDataSplit(curr.name) ||
        (isDataSplit(curr.name) && isAvantOrAmount) ||
        (!isBPOSupport(curr.name)))) {
        return [...acc, curr.name];
      }

      return [...acc];
    },
    []);

};

const RequestTypeFilter: React.FC<IFilter> = ({ loading, data, ...props}) => {
  const { requestTypes } = FILTER_NAMES;
  const changeHandlers = { onClear: props.handleClear, onChange: props.handleChange };
  const context = React.useContext(MeContext);

  if (loading) {
    return (
      <FilterButton {...changeHandlers} value={props[requestTypes]} options={[]} name={requestTypes}>
        <Spinner withMargin={true} />
      </FilterButton>
    );
  }

  // tslint:disable-next-line: no-null-keyword
  if (!data) { return null; }

  const filteredRequestTypes: string[] = filteredByPermissions(data.requestTypes, context);

  // push Service Incident on to cover SI_ServiceDesk types. This is a temporary fix
  // because the way we handle service desks per partner is likely to change soon.
  filteredRequestTypes.push(EMAILED_REQUEST);
  filteredRequestTypes.push(isAmountUser(context) || isAvantUser(context) ? AMOUNT_SERVICE_INCIDENT : SERVICE_INCIDENT);
  if (canViewOperationalSupportFilter(context)) { filteredRequestTypes.push(OPERATIONAL_SUPPORT); }

  return (
    <FilterButton
      {...changeHandlers}
      value={props[requestTypes]}
      options={filteredRequestTypes}
      name={requestTypes}
    />
  );
};

export const LiftedRequestTypeFilter: React.FC<IProps> = props => (
  <Query<GetOwnedRequestTypes, GetOwnedRequestTypesInput> query={GetOwnedRequestTypesQuery} variables={{ id: APS_SERVICEDESK_ID }}>
    {({ data, loading }) => (
      <RequestTypeFilter loading={loading} data={data} {...props} />
    )}
  </Query>
);
