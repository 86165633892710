import * as React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { DocumentNode, ExecutionResult } from 'graphql';
import ErrorHandler from '@avant/crm-frontend-utils/error';
import {
  ConditionalRenderWrapper,
  DrawerTitle,
  media,
  ModalClose,
  Spinner,
  SVGIcon,
} from '@amount/frontend-components';
import styled from 'styled-components';

import GetRequestDetailQuery, { GetRequestDetail, GetRequestDetailInput, SelectionOnValues } from '../queries/getRequestDetail.graphql';
import AddRequestParticipants, {
  AddRequestParticipants as IAddRequestParticipants,
  AddRequestParticipantsInput,
} from '../queries/addRequestParticipants.graphql';
import { UserInput } from '../Submit/SubmitForm/SubmitArrayInputs';
import { client } from '../../../client';
import { ErrorLogger } from '../../../services/error';
import { MeContext } from '../../Context';
import Modal from '../../Modal';
import { ButtonLabel } from '../../CommonComponents';

import RequestField from './RequestField';
import DissapearingButton, { AddButton } from './DissapearingButton';

const CloseModal = styled(ModalClose)`
  svg {
    width: 65%;
  }

  ${media.small`
    svg {
      width: 100%;
    }
  `}
`;

type  AddParticipantsType = (_name: string, accountIds: string[]) => Promise<void>;

const addParticipantHook = (requestKey: string): { addParticipants: AddParticipantsType; loading: boolean } => {
  const context = React.useContext(MeContext);
  const [getSuggestions, { loading }] = useMutation<IAddRequestParticipants, AddRequestParticipantsInput>(AddRequestParticipants);
  const addParticipants: AddParticipantsType = async (_name: string, accountIds: string[]) => {
    const mutationData: ExecutionResult<IAddRequestParticipants> = await getSuggestions({ variables: { accountIds, requestKey } });
    try {
      if (!mutationData.data || !mutationData.data.addHelpdeskParticipants || !mutationData.data.addHelpdeskParticipants.values) {
        ErrorHandler.notify('Failed to add Participant. Please try again.', 'Helpdesk', 'error');
        throw Error('addParticipants mutation failed');
      }
      const newParticipants: SelectionOnValues[] = mutationData.data.addHelpdeskParticipants.values;

      const queryDetails: { query: DocumentNode; variables: GetRequestDetailInput } = {
        query: GetRequestDetailQuery,
        variables: { key: requestKey, partner: context.currentPartner || '' }
      };
      const requestData: GetRequestDetail | null = client.readQuery<GetRequestDetail, GetRequestDetailInput>(queryDetails);
      if (!requestData || !requestData.requestComments || !requestData.requestDetail) {
        ErrorHandler.notify('Cannot display new Participant. Please refresh the page.', 'Helpdesk', 'error');
        throw Error('requestDetail data not read from cache');
      }

      client.writeQuery<GetRequestDetail, GetRequestDetailInput>({
        ...queryDetails,
        data: {
          requestComments: requestData.requestComments,
          requestDetail: {
            ...requestData.requestDetail,
            participants: {
              ...requestData.requestDetail.participants,
              values: newParticipants,
            },
          }
        },
      });
    } catch (e) {
      ErrorLogger.captureException(e);
    }
  };

  return { addParticipants, loading };
};

interface IParticipants {
  participants: SelectionOnValues[];
  drawer: string;
}

const DisplayParticipants: React.FC<IParticipants> = props => (
  <>
    {
      props.participants.map(user => (
        React.createElement(props.drawer, {key: user.accountId || user.displayName}, user.displayName)
      ))
    }
  </>
);

interface IProps {
  participants: SelectionOnValues[];
  requestKey: string;
  serviceDeskId: string;
}

const RequestParticipantList: React.FC<IProps> = props => {
  const [showModal, toggleModal] = React.useState(false);
  const { participants, requestKey, serviceDeskId } = props;
  const { addParticipants, loading } = addParticipantHook(requestKey);

  const closeModal: React.MouseEventHandler & React.FormEventHandler = () => { toggleModal(false); };
  const openModal: React.MouseEventHandler = () => { toggleModal(true); };

  const participantsIds: string[] = participants.reduce(
    (acc: string[], p) => {
      if (!p.accountId) {
        ErrorHandler.notify('There was a problem displaying all participants.', 'Helpdesk', 'error');

        return acc;
      }

      return [...acc, p.accountId];
    },
    []
  );

  return (
    <>
      <ConditionalRenderWrapper hiddenOnMobile={true}>
        <RequestField label='Participants'>
          <DisplayParticipants participants={participants} drawer='div' />
          <DissapearingButton icon='add-participant' buttonText='Add Participants'>
            {loading ? <Spinner small={true} /> : (
              <UserInput
                id='input'
                onChange={addParticipants}
                value={participantsIds}
                hideTags={true}
                placeholder='Add Participants'
                serviceDeskId={serviceDeskId}
              />
            )}
          </DissapearingButton>
        </RequestField>
      </ConditionalRenderWrapper>
      <ConditionalRenderWrapper hiddenOnMobile={false}>
        <AddButton value={'true'} onClick={openModal} className='no-print'>
          <SVGIcon icon='add-participant' />
          <ButtonLabel>Add Participants</ButtonLabel>
        </AddButton>
        <Modal close={closeModal} show={showModal} drawer={false}>
          <CloseModal close={closeModal}>
            <SVGIcon icon='close' />
          </CloseModal>
          {loading ? <Spinner small={true} /> : (
            <UserInput
              id='input'
              onChange={(addParticipants)}
              value={participantsIds}
              hideTags={true}
              placeholder='Add Participants'
              serviceDeskId={serviceDeskId}
            />
          )}
          <DrawerTitle style={{ padding: '1em 0'}}>Current Participants</DrawerTitle>
          <DisplayParticipants participants={participants} drawer='DrawerItem' />
        </Modal>
      </ConditionalRenderWrapper>
    </>
  );
};

export default RequestParticipantList;
