import * as React from 'react';
import styled from 'styled-components';
import { ActionButton, NavButton } from '@amount/frontend-components';

import { FieldFormLabel, FieldForm } from '../common';
import { ButtonLabel } from '../../../CommonComponents';

interface IProps {
  label: string;
  onSubmit: React.FormEventHandler;
  onCancel(): void;
}

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: .5em;

  & > :first-child {
    margin-right: 1em;
  }

  & > :last-child {
    margin-top: 0.25em;
  }
`;

const FieldEditor: React.FC<IProps> = ({ children, onSubmit, onCancel, label }) => {
  const onKeyDown = async (e: React.KeyboardEvent<HTMLFormElement>): Promise<void> => {
    if (e.key === 'Escape') {
      onCancel();
    }
  };

  return (
    <FieldForm onKeyDown={onKeyDown} onSubmit={onSubmit}>
      <FieldFormLabel>{label}</FieldFormLabel>
      {children}
      <ButtonGroup>
        <ActionButton type="submit">
          <ButtonLabel>Save</ButtonLabel>
        </ActionButton>
        <NavButton onClick={onCancel}>Cancel</NavButton>
      </ButtonGroup>
    </FieldForm>
  );
};

export default FieldEditor;
