import * as uuid from 'uuid';

const ONE_SECOND: number = 1000;
const TEN_SECONDS: number = ONE_SECOND * 10;
// tslint:disable-next-line: no-magic-numbers
const THIRTY_SECONDS: number = TEN_SECONDS * 3;

export class GlobalTimer {
  private readonly _callbacks: Map<string, Function> = new Map<string, Function>();

  constructor (interval: number) {
    if (setInterval) {
      setInterval(() => this._runCallbacks(), interval);
    }
  }

  public addCallback (callback: Function): string {
    const id: string = uuid.v4();

    this._callbacks.set(id, callback);

    return id;
  }

  public removeCallback (id: string): void {
    this._callbacks.delete(id);
  }

  private _runCallbacks (): void {
    [...this._callbacks.values()].forEach(fn => fn());
  }
}

export const ThirtySecondTimer: GlobalTimer = new GlobalTimer(THIRTY_SECONDS);
