import {
  Banner,
  BannerLink,
  Label,
  media,
  MenuButton,
  SVGIcon,
} from '@amount/frontend-components';
import styled from 'styled-components';
import * as React from 'react';

import { SelectionOnMe } from '../../Context/me.graphql';
import NotificationSystem from '../../NotificationSystem';
import { SmartLink } from '../../SmartLink';
import { INVALID_NAME, INVALID_ORGANIZATION, INVALID_USERNAME } from '../../../../shared/config/constants';
import { CurrentLogo } from '../../Logo';

import { HeaderContain, HeaderWrapper } from './common';
import { Menu } from './Menu';

export interface ILiftedProps {
  me: SelectionOnMe;
  darkModeDisabled?: boolean;
  forceLink?: boolean;
  togglePartnerSwitcher (): void;
}

interface IHeaderState {
  showMenu: boolean;
}

const PrintStyles = styled.div`
  @media print {
    font-size: 12px;
    .no-print {
      display: none;
    }
  }
`;

const ErrorBanner = styled(Banner)`
  padding: 1em 0.75em 0.75em;
  text-align: center;
  font-size: 1.5rem;
`;

const ButtonLabel = styled(Label)`
  color: unset;
  margin-bottom: -0.25em;
`;

const MenuIcon = styled(SVGIcon)`
  ${media.small`
    margin-left: 0.625em;
  `}

  svg {
    display: flex;
    width: 20px;
    height: 20px;
    margin: 0;
  }
`;

export class Header extends React.Component<ILiftedProps, IHeaderState> {
  public state: IHeaderState = {
    showMenu: false
  };

  public render (): JSX.Element {
    const accountProblem: boolean =
      this.props.me.organization === INVALID_ORGANIZATION ||
      this.props.me.username === INVALID_USERNAME ||
      this.props.me.firstName === INVALID_NAME ||
      this.props.me.lastName === INVALID_NAME ||
      !this.props.me.roles.length;

    return (
      <PrintStyles>
        <HeaderWrapper>
          <HeaderContain>
            <SmartLink forceLink={this.props.forceLink} href='/' data-event='logo'>
              <CurrentLogo partner={this.props.me.currentPartner} />
            </SmartLink>
            <MenuButton
              onClick={this.toggleMenu}
              data-menubutton={true}
              aria-controls='menu-control'
              ariaExpanded={this.state.showMenu}
              buttonLabel='Menu'
            >
              <ButtonLabel>Menu</ButtonLabel>
              <MenuIcon icon='menu' />
            </MenuButton>
          </HeaderContain>
        </HeaderWrapper>
        <Menu
          switchablePartners={this.props.me.switchablePartners}
          togglePartnerSwitcher={this.props.togglePartnerSwitcher}
          showMenu={!!this.state.showMenu}
          toggleMenu={this.toggleMenu}
          forceLink={this.props.forceLink}
          darkModeDisabled={this.props.darkModeDisabled}
        />
        {accountProblem && (
          <ErrorBanner bannerStyle='warning'>
            There is a problem with your account.
            Please contact <BannerLink><a href='mailto:partnerportalsupport@amount.com'>partnerportalsupport@amount.com</a></BannerLink>.
          </ErrorBanner>
        )}
        {this.props.children}
        <NotificationSystem />
      </PrintStyles>
    );
  }

  private readonly toggleMenu: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void = e => {
    if (e) { e.stopPropagation(); }

    this.setState(({ showMenu }) => ({ showMenu: !showMenu }));
  };
}
