/* tslint:disable */
import * as React from "react";

const SvgIconTdLogo = (props: React.SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 401 122" {...props} role="img" aria-labelledby="logoTitle logoDesc">
    <title id="logoTitle">Go to home page</title>
    <desc id="logoDesc">TD Bank Logo</desc>
    <path
      d="M249.5 78.7c-6.7-3.1-9.2-10.3-6-17.1 2.6-5.5 8-7.9 18.8-8.4 4.8-.2 8.7-.8 8.7-1.3 0-3.7-12.1-5.2-19.9-2.4l-4.1 1.4V39.1l4.8-.8c2.6-.4 8.8-.8 13.8-.7 7.5 0 9.9.4 13.9 2.5 8.3 4.1 9 5.7 9.3 23.5l.3 15.4h-9c-9.1 0-9.1 0-9.1-2.6v-2.5l-3.6 2.7c-4.3 3.3-13 4.3-17.9 2.1zm19-11.2c1.4-1.3 2.5-3.3 2.5-4.5 0-1.6-.6-2-3.3-2-6.1 0-10.2 5.4-5.9 7.9 2.7 1.6 4.1 1.3 6.7-1.4zm-83.5-16V23.8l20.8.4c23.2.4 26.6 1.2 30.2 7 3.9 6.4 1.5 14.9-5.1 18.3l-3.1 1.7 3.3 1.6c5.1 2.6 8.2 7.8 7.6 12.9-.6 5.3-2.7 8.7-7 11-2.7 1.5-6.4 1.8-24.9 2.1l-21.8.3V51.5zm29.3 15.4c2.6-1.4 3.7-2.8 3.7-5.1 0-3.2-2.9-4.8-8.6-4.8H204v4.8c0 2.7.3 5.2.7 5.5 1 1.1 7.2.8 9.6-.4zm.8-22.9c2.2-1.2 2.6-5.9.7-7.8-.7-.7-3.6-1.2-6.5-1.2H204v10h4.6c2.5 0 5.4-.5 6.5-1zm79.1 14.8l.3-20.3 9.3-.3 9.2-.3v2.6c0 3.1.5 3.1 4.1.1 5.6-4.7 16.1-4.2 21 1 2.3 2.5 2.4 3.1 2.7 20l.3 17.4h-20l-.3-14.2c-.3-13.2-.4-14.3-2.2-14.6-3.7-.8-4.6 2.4-4.6 16V79h-20l.2-20.2zm53.8-9.3V20h19v16.5c0 9.1.4 16.5.8 16.5.5 0 3.2-3.4 6.1-7.5l5.2-7.5h11c8.6 0 10.8.3 10.2 1.3-.4.6-3.2 4.4-6.1 8.2-3 3.9-5.7 7.6-5.9 8.2-.3.7 2.5 5.9 6.1 11.6C398 73 401 78 401 78.3c0 .4-5.1.7-11.2.7h-11.3l-4.9-9c-2.7-5-5.3-9-5.8-9-.4 0-.8 4.1-.8 9v9h-19V49.5z"
      fill='var(--color-1, #003f2d)'
    />
    <rect
      width="125"
      height="100"
      fill="#fff"
      x="10"
      y="10"
    />
    <path
      d="M0 61V0h145v122H0V61zm57 4.5V35h21.3c19.5 0 21.5.2 24.5 2 6.3 3.8 9.4 17.2 7.4 31.2-2.2 14.7-6.6 18.1-22.4 17.6l-9.3-.3-.3-23.2L78 39H61v57.2l23.8-.4 23.7-.3 5.8-2.8c9.3-4.6 13.4-12.6 14.4-28.2.6-9.8-.8-20.8-3.4-25.9-2.2-4.4-6.2-8.4-10.8-10.9-3.9-2.2-5-2.2-51.7-2.5L15 24.9V35h25v61h17V65.5z"
      fill="#54b848"
    />
  </svg>
);

export default SvgIconTdLogo;
