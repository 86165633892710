import * as React from 'react';
import { MutationFn } from 'react-apollo';
import { ApolloQueryResult } from 'apollo-client';
import ErrorHandler from '@avant/crm-frontend-utils/error';
import { Helmet } from 'react-helmet';
import {
  ActionButton,
  ButtonWithSpinner,
  Headline,
  HorizontalDivider,
  SVGIcon,
} from '@amount/frontend-components';
import styled from 'styled-components';
import { Link, RouteComponentProps } from 'react-router-dom';

import { ErrorLogger } from '../../../services/error';
import { ModuleRoutes } from '../../../../shared/routes';
import { BackArrow, Breadcrumb, BreadcrumbWrap } from '../../Breadcrumb';
import { Organizations, SWITCH_ENABLED_PARTNER_SET, SwitchablePartner } from '../../../../shared/config/partners';
import { ButtonActionRow } from '../../Modal/common';
import { EditUserRoles, EditUserRolesInput } from '../queries/editUserRoles.graphql';
import { GetUser as getUser, GetUserInput, SelectionOnRoles as SelectionOnOktaRole } from '../queries/getUser.graphql';
import { ButtonLabel } from '../../CommonComponents/ButtonLabel';
import { LiftedUserRoleFields } from '../UserRoleEditFields';

import {
  FormFieldsSection,
  HeaderWrap,
  UserPartnersModal,
} from './common';

export type UserRoleIds = { [key in SwitchablePartner]?: string };
export const oktaRoleIdsByOrg: (roles: SelectionOnOktaRole[], organization: string) => UserRoleIds = (groups, organization) => {
  if (!groups) { return { [organization]: '' }; }

  const roles: UserRoleIds = groups.reduce<UserRoleIds>(
    (acc: UserRoleIds, role: SelectionOnOktaRole) => {
      acc[role.partner] = role.id;

      return acc;
    },
    {}
  );

  // if you don't have any roles for your organization, we should allow an admin to grant you one.
  if (!roles[organization]) { roles[organization] = ''; }

  return roles;
};
export type ILiftedProps = RouteComponentProps<{ id: string }>;

interface IUserRoleEditProps extends ILiftedProps {
  organization: string;
  data: getUser | undefined;
  editUser: MutationFn<EditUserRoles, EditUserRolesInput>;
  refetchUser (variables?: GetUserInput | undefined): Promise<ApolloQueryResult<getUser>>;
}

interface IUserRoleEditState {
  roles: UserRoleIds;
  showPartnersModal: boolean;
  submitting: boolean;
  partners: SwitchablePartner[];
  tempPartners: SwitchablePartner[];
}

const noRolesChanged = (oldRoles: string[], newRoles: string[]): boolean => (
  (oldRoles.length === newRoles.length) && oldRoles.every(role => newRoles.includes(role))
);

const AddPartnerButton = styled(ActionButton)`
  margin-bottom: 2.5em;
`;

export class UserRoleEdit extends React.Component<IUserRoleEditProps, IUserRoleEditState> {
  public state: IUserRoleEditState = {
    showPartnersModal: false,
    submitting: false,
    partners: [],
    tempPartners: [],
    roles: {}
  };

  constructor (props: IUserRoleEditProps) {
    super(props);

    if (props.data && props.data.user) {
      const roles: UserRoleIds = oktaRoleIdsByOrg(props.data.user.roles || [], props.data.user.organization);
      const partners: SwitchablePartner[] = Object.keys(roles).sort() as SwitchablePartner[];

      this.state = {
        submitting: false,
        showPartnersModal: false,
        partners,
        roles,
        tempPartners: partners
      };
    }
  }

  public render (): JSX.Element {
    const { data } = this.props;
    const canSubmit: boolean = Object.values(this.state.roles).length === this.state.partners.length && !this.state.submitting;

    return (
      <>
        <Helmet>
          <title>Manage Permissions · User Admin</title>
        </Helmet>
        <BreadcrumbWrap>
          <Link to={`${ModuleRoutes.userManagement}/${data && data.user && data.user.id || '' }`} data-event='editUserRolesBackArrow'>
            <Breadcrumb>
              <BackArrow icon='return-arrow' />
              <span>Back</span>
            </Breadcrumb>
          </Link>
        </BreadcrumbWrap>
        <HeaderWrap>
          <Headline scale='large'>Manage Permissions</Headline>
        </HeaderWrap>
        {(data && data.user) ? (
          <>
            <UserPartnersModal
              partners={this.state.tempPartners}
              show={this.state.showPartnersModal}
              close={this.dismissModal}
              selectPartner={this.selectPartner}
              submit={this.savePartnerChanges}
              currentPartner={data.user.organization}
            />
            {this.state.partners.map((org, index) => (
              <React.Fragment key={org}>
                <LiftedUserRoleFields
                  partner={org}
                  // tslint:disable-next-line: no-non-null-assertion
                  currentPartner={data.user!.organization}
                  selectedRole={this.state.roles[org]}
                  selectRole={this.selectRole}
                  removePartner={this.removePartner}
                />
                {index !== this.state.partners.length - 1 && <HorizontalDivider />}
              </React.Fragment>
            ))}
            {SWITCH_ENABLED_PARTNER_SET.has(data.user.organization as Organizations) && (
              <ButtonActionRow>
                <AddPartnerButton onClick={this.showPartnersModal}>
                  <SVGIcon icon='plus' />
                  <ButtonLabel> Add Partner Permissions </ButtonLabel>
                </AddPartnerButton>
              </ButtonActionRow>
            )}
            <ButtonActionRow>
              <ButtonWithSpinner
                key='submitButton'
                data-event='submitEditUserInfo'
                inline={true}
                onClick={this.submitChanges}
                disabled={!canSubmit}
                loading={this.state.submitting}
              >
                Save
              </ButtonWithSpinner>
            </ButtonActionRow>
          </>
        ) : (
          <FormFieldsSection>
            <p>Error loading user info.</p>
          </FormFieldsSection>
        )}
      </>
    );
  }

  private readonly showPartnersModal:  React.MouseEventHandler<HTMLButtonElement> = () => {
    this.setState({ showPartnersModal: true, tempPartners: this.state.partners });
  }

  private readonly dismissModal:  React.MouseEventHandler<HTMLButtonElement> = () => {
    this.setState({ showPartnersModal: false });
  }

  private readonly removePartner: React.FormEventHandler<HTMLButtonElement> = ({ currentTarget: { dataset: { partner } } }) => {
    if (!partner) { return; }

    this.setState(({ partners, roles }) => {
      // tslint:disable-next-line: no-dynamic-delete
      delete roles[partner];

      return {
        partners: partners.filter(p => p !== partner),
        roles
      };
    });
  }

  private readonly savePartnerChanges: React.FormEventHandler<HTMLButtonElement> = () => {
    this.setState({ partners: this.state.tempPartners, showPartnersModal: false });
  }

  private readonly selectPartner: React.FormEventHandler<HTMLInputElement> = ({ currentTarget: { dataset: { partner } } }) => {
    if (!partner) { return; }

    this.setState(({ tempPartners, roles }) => {
      if (tempPartners.includes(partner as SwitchablePartner)) {
        // tslint:disable-next-line: no-dynamic-delete
        delete roles[partner];

        return {
          tempPartners: tempPartners.filter(p => p !== partner),
          roles
        };
      }

      return {
        tempPartners: [
          ...tempPartners,
          partner as SwitchablePartner
        ],
        roles
      };
    });
  }

  private readonly selectRole: React.FormEventHandler<HTMLInputElement> = ({ currentTarget: { dataset: { role, organization } } }) => {
    if (!role) { return; }
    if (!organization) { return; }

    this.setState(({ roles }) => ({ roles: {
      ...roles,
      [organization as SwitchablePartner]: role
    }}));
  }

  private readonly redirectOnSuccess: () => void = async () => {
    await this.props.refetchUser();
    this.setState({ submitting: false });
    ErrorHandler.notify('Successfully changed role(s)', 'User Info', 'success');
    this.props.history.push(`${ModuleRoutes.userManagement}/${this.props.match.params.id}`);
  }

  private readonly submitChanges: React.MouseEventHandler = async () => {
    if (this.state.submitting || !this.props.data || !this.props.data.user) { return; }

    this._submitChanges();
  }

  private readonly _submitChanges: () => void = async () => {
    try {
      if (!this.props.data) { throw new Error('No data'); }
      if (!this.props.data.user) { throw new Error('No user'); }
      if (!this.props.data.user.roles) { throw new Error('No roles'); }

      const oldRoles: string[] = this.props.data.user.roles.map(r => r.id);
      const roles: string[] = Object.values(this.state.roles)
        .filter((x: string | undefined): x is string => !!x);

      if (noRolesChanged(oldRoles, roles)) {
        this.redirectOnSuccess();

        return;
      }

      this.setState({ submitting: true });
      const response = await this.props.editUser({
        variables: {
          organization: this.props.organization,
          id: this.props.match.params.id,
          roles,
          // tslint:enable
          clientMutationId: '0',
        }
      });

      if (!response) { throw new Error('Failed to update user role'); }
      const { data, errors } = response;

      if (errors && errors.length) { throw new Error('Failed to update user role'); }
      if (!data || !data.editUserRoles) { throw new Error('Failed to update user role'); }
      if (!!data.editUserRoles.error) { throw new Error(data.editUserRoles.error); }
      if (!data.editUserRoles.success) { throw new Error('Failed to update user role'); }

      this.redirectOnSuccess();
    } catch (e) {
      console.error(e);
      ErrorLogger.captureException(e);
      ErrorHandler.notify(`${e}`, 'User Role', 'error');

      this.setState({ submitting: false });
    }
  }
}
