import * as React from 'react';
import { format } from 'date-fns';
import {
  Headline,
  Label,
  TableContain,
  TBody,
  VTableCell,
  VTableHeaderCell,
  VTableRow,
} from '@amount/frontend-components';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { PartnersContainer } from '../../PartnersContainer';
import { MaxWidthContainer } from '../../MaxWidthContainer';
import { InnerContainer } from '../../InnerContainer';
import { MyAccountInfo } from '../queries/getMyAccount.graphql';

const AccountResponsiveTable = styled(TableContain)`
  margin-top: 3em;
`;

const TableItem = styled.p`
  font-size: 1.4rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface IMyAccount {
  data?: MyAccountInfo;
}

export const MyAccount: React.FC<IMyAccount> = ({ data }) => (
  <PartnersContainer>
    <Helmet>
      <title>My Account · Partner Portal</title>
    </Helmet>
    <MaxWidthContainer>
      <InnerContainer>
        <Headline scale='large'>My Account</Headline>
        {!!data ? (
          <>
            <AccountResponsiveTable>
              <TBody>
                <VTableRow>
                  <VTableHeaderCell>
                    <Label>Email</Label>
                  </VTableHeaderCell>
                  <VTableHeaderCell>
                    <Label>Username</Label>
                  </VTableHeaderCell>
                  <VTableHeaderCell>
                    <Label>Created On</Label>
                  </VTableHeaderCell>
                </VTableRow>
                <VTableRow>
                  <VTableCell collapsedDescription='Email'>
                    <TableItem>{data.me.email}</TableItem>
                  </VTableCell>
                  <VTableCell collapsedDescription='Username'>
                    <TableItem>{data.me.username}</TableItem>
                  </VTableCell>
                  <VTableCell collapsedDescription='Created On'>
                    <TableItem>{format(data.me.createdAt, 'M/D/YY')}</TableItem>
                  </VTableCell>
                </VTableRow>
              </TBody>
            </AccountResponsiveTable>
            <AccountResponsiveTable>
              <TBody>
                <VTableRow>
                  <VTableHeaderCell>
                    <Label>Roles</Label>
                  </VTableHeaderCell>
                </VTableRow>
                <VTableRow>
                  <VTableCell collapsedDescription='Roles'>
                    {[...data.me.roles].sort().map(role => (
                      <TableItem key={role}>{role}</TableItem>
                    ))}
                  </VTableCell>
                </VTableRow>
              </TBody>
            </AccountResponsiveTable>
          </>
        ) : (
          <p>We ran into a problem loading your info.</p>
        )}
      </InnerContainer>
    </MaxWidthContainer>
  </PartnersContainer>
);
