/* tslint:disable */
import * as React from "react";

const SvgIconDemoBankLogo = (props: React.SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 612 612" {...props}>
    <title>Demo Bank Logo</title>
    <g fill="#ea2c3a">
      <path d="M92.587 228.599h426.826c9.319 0 17.762-5.872 21.011-14.617 3.24-8.743.675-18.7-6.385-24.779L320.622 5.431a22.4 22.4 0 0 0-29.248 0L77.963 189.203c-7.063 6.079-9.629 16.038-6.385 24.786 3.247 8.738 11.691 14.61 21.009 14.61zM519.413 532.341H92.587c-12.358 0-22.411 10.054-22.411 22.411v34.837C70.176 601.946 80.23 612 92.587 612h426.826c12.358 0 22.411-10.054 22.411-22.411v-34.837c0-12.356-10.055-22.411-22.411-22.411zM106.11 258.429a5.835 5.835 0 0 0-5.829 5.829v221.918a5.836 5.836 0 0 0 5.829 5.829h73.56a5.835 5.835 0 0 0 5.827-5.829V264.258a5.835 5.835 0 0 0-5.827-5.829h-73.56zM269.219 258.429a5.835 5.835 0 0 0-5.829 5.829v221.918a5.836 5.836 0 0 0 5.829 5.829h73.56a5.835 5.835 0 0 0 5.829-5.829V264.258a5.836 5.836 0 0 0-5.829-5.829h-73.56zM432.33 258.429a5.835 5.835 0 0 0-5.829 5.829v221.918a5.836 5.836 0 0 0 5.829 5.829h73.56a5.835 5.835 0 0 0 5.829-5.829V264.258a5.836 5.836 0 0 0-5.829-5.829h-73.56z" />
    </g>
  </svg>
);

export default SvgIconDemoBankLogo;
