import * as React from 'react';
import { Headline, VALID_HEADLINE_SCALE } from '@amount/frontend-components';
import styled, { css } from 'styled-components';

import SummaryEditAutoFocus from './SummaryEditAutoFocus';

export const SummaryContainer = styled.div<{ isEditing: boolean; isHelpdeskAdmin: boolean }>`
  margin-bottom: 0.5em;
  padding: 0.625em 0;
  ${({ isHelpdeskAdmin, isEditing }) => isHelpdeskAdmin && !isEditing && css`
    &:hover {
      background-color: ${({ theme }) => theme.input.colorTextAreaBackground};
    }
  `}
`;

export interface IRequestSummaryProps {
  summaryText: string;
  scale: VALID_HEADLINE_SCALE;
  editedSummary: string;
  loading: boolean;
  isEditing: boolean;
  isHelpdeskAdmin: boolean;
  enterEditMode (): void;
  saveSummary (): void;
  onChange (e: React.ChangeEvent<HTMLInputElement>): void;
  onKeyDown (e: React.KeyboardEvent<HTMLInputElement>): void;
}

export const RequestSummary: React.FC<IRequestSummaryProps> = (
  {
    isHelpdeskAdmin,
    summaryText,
    scale,
    isEditing,
    editedSummary,
    loading,
    enterEditMode,
    saveSummary,
    onChange,
    onKeyDown
  }) => (
  <SummaryContainer isEditing={isEditing} onClick={enterEditMode} isHelpdeskAdmin={isHelpdeskAdmin}>
    {isEditing ?
      <SummaryEditAutoFocus
        scale={scale}
        onBlur={saveSummary}
        value={editedSummary}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      : <Headline scale={scale}>{loading ? editedSummary : summaryText}</Headline>}
  </SummaryContainer>
);
