import * as React from 'react';
import camelCase from 'lodash-es/camelCase';
import {
  FormLabel,
  TextAreaInput
} from '@amount/frontend-components';
import styled from 'styled-components';

import { Section } from './';

interface INoteSection {
  sectionName: string;
  label: string;
  value: string;
  onChange: React.FormEventHandler<HTMLTextAreaElement>;
}

const TextArea = styled(TextAreaInput)`
  min-width: 100%;
  max-width: 100%;
  min-height: 10em;
  height: 16em;
`;

export const NoteSection: React.FC<INoteSection> = props => (
  <Section key={props.sectionName}>
    <FormLabel>{props.label}</FormLabel>
    <TextArea
      // autoFocus={true}
      onChange={props.onChange}
      value={props.value}
      data-event={camelCase(`${props.label} textarea`)}
    />
  </Section>
);
