import * as React from 'react';
import {
  ConditionalRenderWrapper,
  Container,
  ICell,
  media,
  SVGIcon,
  VALID_SVG_ICONS
} from '@amount/frontend-components';
import styled, { css } from 'styled-components';
import { format } from 'date-fns';

import { Cell, HeaderCell, HeaderRow, Row, Table, TBody } from '../Table';
import { SelectionOnIssues, SelectionOnPriority } from '../queries/getOwnedRequests.graphql';
import { HELPDESK_DATE_FORMAT, HELPDESK_TIME_FORMAT } from '../../../../shared/config/helpdesk';
import UserStatusPill from '../Common/UserStatusPill';

const FULL_SPAN: number = 6;

interface IResponsiveTableCell extends ICell {
  isEmpty?: boolean;
  isHiddenOnMobile?: boolean;
  isHiddenOnDesktop?: boolean;
}

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

const ResponsiveTableCell =
  styled(Cell)<IResponsiveTableCell & React.HTMLProps<HTMLTableCellElement>>`
    width: 100%;
    padding: 0.25em 1em 0;

    &:first-child {
      padding-top: .75em;
    }

    &:nth-child(even) {
      text-align: right;
      ${FlexCenter} {
        margin-left: auto;
      }
    }
    &:last-child {
      text-align: right;
    }
    ${props => props.isHiddenOnMobile && 'display: none;'}
    ${media.small`
      width: initial;
      text-align: initial;
      padding: 1em;
      &:nth-child(even) {
        text-align: left;
        margin-left: initial;
        ${FlexCenter} {
          margin-left: initial;
        }
      }
    `}
  `;

const DualCell = styled(ResponsiveTableCell)<{ textRight?: boolean; growingAndShrink?: boolean; alignTop?: boolean; isMobile?: boolean }>`
  ${({ growingAndShrink }) => !growingAndShrink ? '' : css`
    width: 100%;
    min-width: 9.2em; /* wide enough to still read the date text in 'Summary' */
    ${media.medium`
      min-width: 12.2em;
    `}

    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}

  ${({ isMobile }) => !isMobile ? '' : css`
    padding: 0;
    float: right;
    max-width: 50%;

    ${media.small`
      display: none !important;
    `}
  `}

  ${({ alignTop }) => !alignTop ? '' : css`
    vertical-align: top;
  `}

  & > div {
    text-align: ${({ textRight }) => textRight ? 'right' : 'left'};
  }
`;

const LightText = styled.div`
  color: ${({ theme }) => theme.text.colorCaption};
`;

const IconWrapper = styled.div<{ color: string }>`
  display: flex;
  flex-wrap: nowrap;

  svg {
    color: ${({ color }) => color};
  }
`;

const StyledConditionalRender = styled(ConditionalRenderWrapper)`
  display: flex;
  float: left;
`;

const EmptyCell = styled(Cell)`
  height: 4em;
  display: table-cell;
`;

const PriorityIcon: React.FC<{ priority: SelectionOnPriority | null }> = ({ priority }) => {
  const POSSIBLE_ICONS: { [key: string]: { icon: VALID_SVG_ICONS; color: string } } = {
    1: { icon: 'arrow-double-upward', color: '#EA2C3A' },
    2: { icon: 'arrow-upward', color: '#EA2C3A' },
    3: { icon: 'arrow-upward', color: '#FB974B' },
    4: { icon: 'arrow-downward', color: '#677F89' },
    5: { icon: 'arrow-downward', color: 'dimgrey' },
    None: { icon: 'arrow-downward', color: '#CCD4D8' }
  };
  const { icon, color } = !!priority && POSSIBLE_ICONS[priority.id] || POSSIBLE_ICONS.None;

  return (
    <IconWrapper color={color}>
      <ConditionalRenderWrapper hiddenOnMobile={true} breakpoint='small'>
        <SVGIcon icon={icon} />
        <span>{!!priority && priority.name || 'None'}</span>
      </ConditionalRenderWrapper>
      <ConditionalRenderWrapper hiddenOnMobile={false} breakpoint='small'>
        <SVGIcon icon={icon} />
      </ConditionalRenderWrapper>
    </IconWrapper>
  );
};

interface IRequestTable {
  requests: SelectionOnIssues[];
  handleRowClick: React.MouseEventHandler<HTMLTableRowElement>;
}

const handleEnterKeyPress: React.KeyboardEventHandler<HTMLTableRowElement & HTMLButtonElement> = e => {
  const ENTER_KEYCODE: number = 13;
  if (e.type === 'keypress' && e.key === 'Enter' || e.which === ENTER_KEYCODE) {
    e.currentTarget.click();
  }
};

const RequestTable: React.FC<IRequestTable> = ({ requests, handleRowClick }) => (
  <Container>
    <Table>
      <TBody>
        <HeaderRow>
          <HeaderCell scope='col' mobile={true}>Summary</HeaderCell>
          <HeaderCell scope='col'>ID</HeaderCell>
          <HeaderCell scope='col'>Summary</HeaderCell>
          <HeaderCell scope='col'>Status</HeaderCell>
          <HeaderCell scope='col'>Category</HeaderCell>
          <HeaderCell scope='col'>Product</HeaderCell>
          <HeaderCell scope='col'>Priority</HeaderCell>
          <HeaderCell scope='col'>Reporter</HeaderCell>
        </HeaderRow>
        {!requests.length && (
          <Row notClickable={true}>
            <EmptyCell colSpan={FULL_SPAN}>There are no requests found.</EmptyCell>
          </Row>
        )}
        {requests.map(({ key, id, fields }) => (
          <Row
            key={id}
            data-id={key}
            onClick={handleRowClick}
            onKeyPress={handleEnterKeyPress}
          >
            <ResponsiveTableCell as='th' scope='row'>
              <button onKeyPress={handleEnterKeyPress} aria-label={`View request: ${key}`}>
                <StyledConditionalRender hiddenOnMobile={false} breakpoint='small'>
                  <PriorityIcon priority={fields.priority} />
                </StyledConditionalRender>{key}
                <DualCell as='div' textRight={true} growingAndShrink={true} alignTop={true} isMobile={true} isHiddenOnDesktop={true}>
                  <div>{fields.reporter && fields.reporter.displayName || 'None'}</div>
                  <LightText>on {format(new Date(fields.created), HELPDESK_DATE_FORMAT)}</LightText>
                </DualCell>
              </button>
            </ResponsiveTableCell>
            <DualCell growingAndShrink={true}>
              <div>{fields.summary}</div>
              <LightText>
                Updated {format(new Date(fields.updated), 'MM/DD/YYYY')} at {format(new Date(fields.updated), HELPDESK_TIME_FORMAT)}
              </LightText>
            </DualCell>
            <ResponsiveTableCell>
              <UserStatusPill status={fields.customfield_10010.currentStatus.status} />
            </ResponsiveTableCell>
            <ResponsiveTableCell isHiddenOnMobile={true}>
              <div>{fields.customfield_10010.requestType && fields.customfield_10010.requestType.name}</div>
            </ResponsiveTableCell>
            <DualCell>
              {fields.customfield_10168?.map(product => <div key={product.value}>{product.value}</div>) || '--'}
            </DualCell>
            <ResponsiveTableCell isHiddenOnMobile={true}>
              <PriorityIcon priority={fields.priority} />
            </ResponsiveTableCell>
            <DualCell textRight={true} growingAndShrink={true} alignTop={true} isHiddenOnMobile={true}>
              <div>{fields.reporter && fields.reporter.displayName || 'None'}</div>
              <LightText>on {format(new Date(fields.created), HELPDESK_DATE_FORMAT)}</LightText>
            </DualCell>
          </Row>
        ))}
      </TBody>
    </Table>
  </Container>
);

export default RequestTable;
