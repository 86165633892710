import has from 'lodash-es/has';
import pickBy from 'lodash-es/pickBy';
import { VALID_SVG_ICONS } from '@amount/frontend-components';
import styled from 'styled-components';

import {
  AVANT_HELPDESK,
  SUBMIT_ACCESS_REQUEST_HELPDESK,
  SUBMIT_BUG_REPORT_HELPDESK,
  SUBMIT_CHANGE_REQUEST_HELPDESK,
  SUBMIT_GENERAL_INQUIRY_HELPDESK,
  SUBMIT_SERVICE_INCIDENT_HELPDESK,
  VIEW_HELPDESK,
  VIEW_OPS_TICKETS,
  VIEW_OPS_TICKETS_RO
} from '../../../../shared/config/permissions';
import { Organizations } from '../../../../shared/config/partners';
import { ErrorLogger } from '../../../services/error';

export interface IAttachment {
  name: string;
  // tslint:disable-next-line: no-reserved-keywords
  type: string;
  content: string;
}

export type SectionTitleType =
  | 'InformationTechnology'
  | 'ProductionEngineering'
  | 'Facility'
  | 'ServiceIncident'
  | 'SiteReliabilityEngineering'
  | 'OnboardingOffboarding'
  | 'TrainingOperations'
  | 'PartnerAccessRequest'
  | 'SingleCustomerIncident'
  | 'AmountChangeRequest'
  | 'AmountBugReport'
  | 'AmountGeneralInquiry';

export interface IConfig {
  [sectionName: string]: ISection;
}

export type IHelpdeskIconType = (
  'AmountChangeRequest' |
  'AmountGeneralInquiry' |
  'AmountReportBug' |
  'Facilities' |
  'OnboardingOffboarding' |
  'PartnerPortalAccessRequest' |
  'Production' |
  'Service' |
  'Sre' |
  'Ticket' |
  'TrainingOperations'
);

export type HelpdeskPermission =
  | typeof AVANT_HELPDESK
  | typeof SUBMIT_CHANGE_REQUEST_HELPDESK
  | typeof SUBMIT_BUG_REPORT_HELPDESK
  | typeof SUBMIT_ACCESS_REQUEST_HELPDESK
  | typeof SUBMIT_GENERAL_INQUIRY_HELPDESK
  | typeof SUBMIT_SERVICE_INCIDENT_HELPDESK
  | typeof VIEW_OPS_TICKETS
  | typeof VIEW_OPS_TICKETS_RO
  | typeof VIEW_HELPDESK;

export type IPlaceholder = string | null;

export type IPartnerPlaceholder = { [key in Organizations]?: string };

export type ITitle = IPlaceholder | IPartnerPlaceholder;

export interface ITitleDefinition {
  placeholder?: ITitle;
}

export type HelpdeskSubtitle = 'Technical Requests' | 'Business Requests and Inquiries' | 'Production Escalations' | '';

export interface ISection {
  id: string;
  name: string;
  permissions?: HelpdeskPermission[];
  helpdeskSubtitle: HelpdeskSubtitle;
  iconType: VALID_SVG_ICONS;
  title: string;
  subtitle: string;
  categories: { [id: string]: ICategory };
}

export interface ICategory {
  name: string;
  permissions?: string[];
  titles: { [title: string]: ITitle };
}

export interface IHelpdeskSections {
  category: string;
  region: string;
  priority: string;
  product: string;
  department: string;
  location: string;
  title: string;
  note: string | IPartnerPlaceholder;
  attachment: IAttachment | null;
}

export const defaultTrainingFields = {
  priority: '',
  location: '',
  department: ''
};

export const defaultEditFields = {
  title: '',
  note: ''
};

export const defaultState: IHelpdeskSections = {
  category: '',
  region: '',
  product: '',
  attachment: null,
  ...defaultTrainingFields,
  ...defaultEditFields
};

export const getPlaceholder: (key: ITitle) => ITitle = key => {
  if (!key) { return null; }

  return key;
};

export interface IGetTitlesResult { [title: string]: ITitle; }

export const getTitles: (sectionName: string, categoryId: string, config: IConfig) => IGetTitlesResult | null =
  (sectionName, categoryId, config) => {
    const section: ISection | undefined = config[sectionName];
    if (!section) {
      const e = new Error('Missing helpdesk section');
      ErrorLogger.captureException(e);
      console.error(e);

      return null;
    }

    const category: ICategory | undefined = section.categories[categoryId];
    if (!category) {
      const e = new Error('Missing helpdesk category');
      ErrorLogger.captureException(e);
      console.error(e);

      return null;
    }

    return category.titles;
  };

const hasTitlesCache: { [sectionName: string]: { [categoryName: string]: boolean } } = {};

const hasTitles: (sectionName: string, categoryName: string, config: IConfig) => boolean = (
  sectionName,
  categoryName,
  config
) => {
  if (!has(hasTitlesCache, sectionName)) {
    hasTitlesCache[sectionName] = {};
  }
  if (!has(hasTitlesCache[sectionName], categoryName)) {
    hasTitlesCache[sectionName][categoryName] = Object.keys(getTitles(sectionName, categoryName, config) || {}).length > 0;
  }

  return hasTitlesCache[sectionName][categoryName];
};

export interface IGetCategoriesResult { [categoryId: string]: ICategory; }

export const getCategories: (sectionName: string, config: IConfig) =>
  IGetCategoriesResult = (sectionName, config) => {
    const section: ISection | undefined = config[sectionName];

    return pickBy(section.categories, (_, key) =>
      hasTitles(sectionName, key, config)
    ) as IGetCategoriesResult;
  };

const hasCategoriesCache: { [sectionName: string]: boolean } = {};

const hasCategories: (sectionName: string, config: IConfig) =>
  boolean = (sectionName, config) => {
    if (!has(hasCategoriesCache, sectionName)) {
      hasCategoriesCache[sectionName] = Object.keys(getCategories(sectionName, config)).length > 0;
    }

    return hasCategoriesCache[sectionName];
  };

export type IGetSectionResult = ISection | null;

export const getSection: (sectionName: string, config: IConfig) => IGetSectionResult = (sectionName, config) => {
  const section: ISection | undefined = config[sectionName];
  if (!section) {
    return null;
  }

  if (!hasCategories(sectionName, config)) {
    return null;
  }

  return section;
};

export const Section = styled.section`
  margin: 2.25em 0;
`;
