import * as React from 'react';
import { MutationFn } from 'react-apollo';
import { ApolloQueryResult } from 'apollo-client';
import { Helmet } from 'react-helmet';
import ErrorHandler from '@avant/crm-frontend-utils/error';
import {
  ButtonWithSpinner,
  FormError,
  Headline,
} from '@amount/frontend-components';
import { Link, RouteComponentProps } from 'react-router-dom';

import { ErrorLogger } from '../../../services/error';
import { EditUserInfo, EditUserInfoInput } from '../queries/editUserInfo.graphql';
import { GetUser, GetUserInput } from '../queries/getUser.graphql';
import { ModuleRoutes } from '../../../../shared/routes';
import { BackArrow, Breadcrumb, BreadcrumbWrap } from '../../Breadcrumb';
import { Organizations } from '../../../../shared/config/partners';

import {
  FormFieldsSection,
  getFormInputField,
  isBlank,
  UserInfoFields
} from './common';
import { VALID_POINTER_KEYS } from './constants';

type ILiftedProps = RouteComponentProps<{ id: string }>;

interface IUserInfoEditProps extends ILiftedProps {
  organization: Organizations;
  data?: GetUser;
  editUser: MutationFn<EditUserInfo, EditUserInfoInput>;
  refetchUser (variables?: GetUserInput | undefined): Promise<ApolloQueryResult<GetUser>>;
}

interface IUserInfoEditState {
  submitting: boolean;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  username: string | undefined;
  errorField: Map<string, string>;
}

// tslint:disable-next-line: max-func-body-length
export class UserInfoEdit extends React.Component<IUserInfoEditProps, IUserInfoEditState> {
  public state: IUserInfoEditState = {
    submitting: false,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    username: undefined,
    errorField: new Map<string, string>()
  };

  public constructor (props: IUserInfoEditProps) {
    super(props);

    if (props.data && props.data.user) {
      this.state = {
        submitting: false,
        firstName: props.data.user.firstName,
        lastName: props.data.user.lastName,
        email: props.data.user.email,
        username: props.data.user.login,
        errorField: new Map<string, string>(),
      };
    }
  }

  public render (): JSX.Element {
    const { data } = this.props;

    return (
      <>
        <Helmet>
          {(data && data.user) ? (
            <title>Edit {data.user.firstName} {data.user.lastName} · User Admin</title>
          ) : (
            <title>User Admin · Partner Portal</title>
          )}
        </Helmet>
        <BreadcrumbWrap>
          <Link to={`${ModuleRoutes.userManagement}/${ (data && data.user) ? data.user.id : '' }`} data-event='editUserInfoBackArrow'>
            <Breadcrumb>
              <BackArrow icon='return-arrow' />
              <span>Back</span>
            </Breadcrumb>
          </Link>
        </BreadcrumbWrap>
        <Headline scale='large'>Edit User Information</Headline>
        {(data && data.user) ? (
          <>
            <UserInfoFields
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              email={this.state.email}
              organization={data.user.organization}
              username={this.state.username}
              handleInputChange={this.handleInputChange}
              errorFields={this.state.errorField}
            />
            {this.state.errorField.has(VALID_POINTER_KEYS.GENERAL) &&
              <FormError>{this.state.errorField.get(VALID_POINTER_KEYS.GENERAL)}</FormError>
            }
            <ButtonWithSpinner
              key='submitButton'
              data-event='submitEditUserInfo'
              inline={true}
              onClick={this.submitChanges}
              disabled={!this.canSubmit()}
              loading={this.state.submitting}
            >
              Save
            </ButtonWithSpinner>
          </>
        ) : (
          <FormFieldsSection>
            <p>Error loading user info.</p>
          </FormFieldsSection>
        )}
      </>
    );
  }

  private readonly canSubmit: () => boolean = () => (
    !!this.state.email &&
    !!this.state.email.length &&
    !!this.state.username &&
    !!this.state.username.length &&
    !isBlank(this.state.firstName) &&
    !isBlank(this.state.lastName) &&
    !this.state.submitting
  )

  private readonly handleInputChange: React.FormEventHandler<HTMLInputElement> = event => {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const value: string = target.value;
    // tslint:disable-next-line: no-any
    const name: any = target.name;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  private readonly submitChanges: React.MouseEventHandler = async () => {
    if (!this.canSubmit) { return; }
    this.state.errorField.clear();
    this.setState({
      submitting: true
    });

    try {
      const response = await this.props.editUser({
        variables: {
          organization: this.props.organization,
          id: this.props.match.params.id,
          // tslint:disable: no-non-null-assertion
          email: this.state.email!,
          username: this.state.username!,
          firstName: this.state.firstName!,
          lastName: this.state.lastName!,
          // tslint:enable
          clientMutationId: '0',
        }
      });

      if (!response) { throw new Error('Failed to update user info'); }
      const { data, errors } = response;
      if (errors && errors.length) { throw new Error('Failed to update user info'); }
      if (!data || !data.editUserInfo) { throw new Error('Failed to update user info'); }
      if (!!data.editUserInfo.error) {
         data.editUserInfo.error.forEach(err => {
           if (err) {
            const formInputField: string = getFormInputField(err.errorCode || 'None');
            this.state.errorField.set(formInputField, err.errorMessage);
           }
         });
      }
      if (!data.editUserInfo.success) { throw new Error('Failed to update user info'); }
      await this.props.refetchUser();
      ErrorHandler.notify('Successfully changed the user info', 'User Info', 'success');
      this.setState({ submitting: false });
      this.props.history.push(ModuleRoutes.userManagement);
    } catch (e) {
      console.error(e);
      ErrorLogger.captureException(e);
      ErrorHandler.notify(`${e}`, 'User Info', 'error');
      this.setState({ submitting: false });
    }
  }
}
