import * as React from 'react';
import { Banner, BannerLink } from '@amount/frontend-components';
import styled from 'styled-components';

import { AMOUNT_PERMISSION_KEY } from '../../../shared/config/permissions';
import { Consumer } from '../Context';
import { SelectionOnMe } from '../Context/me.graphql';

const ErrorBanner = styled(Banner)`
  padding: 1em 0.75em 0.75em;
  text-align: center;
  font-size: 1.5rem;
`;

interface IBannerProps {
  me: SelectionOnMe;
}

const TempBanner: React.FC<IBannerProps> = ({ me }) => (
  <>
    {me.currentPartner === AMOUNT_PERMISSION_KEY && me.organization === AMOUNT_PERMISSION_KEY && (
      <ErrorBanner bannerStyle='warning'>
        <span>If you are part of Amount, please use </span>
        <BannerLink>
          <a href='https://amount.atlassian.net/servicedesk/customer/portals'>Service Desk</a>
        </BannerLink>
        <span>.</span>
      </ErrorBanner>
    )}
  </>
);

export const LiftedBanner: React.FC = () => (
  <Consumer>
    {me => (
      <TempBanner me={me} />
    )}
  </Consumer>
);

export default LiftedBanner;
