import * as React from 'react';
import camelCase from 'lodash-es/camelCase';
import { Mutation, MutationFn } from 'react-apollo';
import Error from '@avant/crm-frontend-utils/error';

import { Button, IButtonProps } from '../Buttons';
import { IWorkflowTransitionInformation } from '../../../../shared/transitions/sar';

import TransitionQuery, { TransitionSAR, TransitionSARInput } from './transition.graphql';

interface ITransitionButtonPropsBase {
  info: IWorkflowTransitionInformation;
  status: string;
  sarId: string;
  validation?(): boolean;
  afterTransition (): void;
}
interface ITransitionButtonProps extends ITransitionButtonPropsBase, React.HTMLProps<HTMLButtonElement | HTMLDivElement> {
  transition: MutationFn<TransitionSAR, TransitionSARInput>;
}

interface ITransitionButtonState {
  loading: boolean;
}

class TransitionButtonBase extends React.Component<ITransitionButtonProps, ITransitionButtonState> {
  public state: ITransitionButtonState = {
    loading: false
  };

  public render (): JSX.Element {
    const props: IButtonProps = {
      ...this.props.info,
      disabled: this.props.disabled || this.state.loading,
      onClick: this.onclick,
    };

    return (
      <Button {...props} data-event={camelCase(`${this.props.info.name} Button`)}>
        {this.props.info.name}
      </Button>
    );
  }

  private readonly onclick: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement> = e => {
    e.preventDefault();

    if (this.props.validation) {
      const isValid = this.props.validation();
      if (!isValid) { return; }
    }

    if (this.props.onClick) {
      this.props.onClick(e);
    }

    this.setState({ loading: true });

    let reason: string | undefined = e.currentTarget.dataset.reason || undefined;
    const requestInput: string | undefined = e.currentTarget.dataset.allowInput || undefined;

    if (requestInput === 'true') {
      const addendum: string | null = window.prompt('Enter a reason (blank for none)');
      if (!!addendum) {
        reason += ` - ${addendum}`;
      }
    }

    this.props.transition({
      variables: {
        sarId: this.props.sarId,
        event: this.props.info.event,
        clientMutationId: '0',
        reason
      }
    })
    .then(this.props.afterTransition)
    .then(() => {
      Error.notify('Successfully transitioned', 'PSAEs Transition', 'success');
    })
    .catch(err => {
      console.error(err);
      Error.notify('Failed to transition item', 'PSAEs Transition', 'error');
    });
  }
}

export const TransitionButton: React.FC<ITransitionButtonPropsBase> = props => (
  <Mutation<TransitionSAR, TransitionSARInput>
    mutation={TransitionQuery}
  >
    {transition =>
      <TransitionButtonBase
        {...props}
        transition={transition}
      />
    }
  </Mutation>
);
