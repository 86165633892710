import {
  AMOUNT_PERMISSION_KEY,
  AVANT_PERMISSION_KEY,
  BARCLAYS_PERMISSION_KEY,
  BBVA_PERMISSION_KEY,
  CAPITAL_ONE_PERMISSION_KEY,
  DEMO_BANK_PERMISSION_KEY,
  ELOAN_PERMISSION_KEY,
  GUARANTEED_RATE_PERMISSION_KEY,
  HSBC_PERMISSION_KEY,
  ICON_PERMISSION_KEY,
  JOMASHOP_PERMISSION_KEY,
  PNC_PERMISSION_KEY,
  POLLEN_STREET_PERMISSION_KEY,
  REGIONS_PERMISSION_KEY,
  TD_PERMISSION_KEY,
  JENIUS_PERMISSION_KEY,
} from './permissions';
import { SwitchablePartner } from './partners';
import { PERSONAL_LOAN_PATH, POINT_OF_SALE_PATH } from './constants';

export interface ILookerDashboard {
  name: string;
  url: string;
  location?: string;
}

export type ILookerDashboardConfig = {
  [partner in SwitchablePartner]: ILookerDashboard[];
};

const LookerDashboardConfig: ILookerDashboardConfig = {
  [AMOUNT_PERMISSION_KEY]: [],
  [AVANT_PERMISSION_KEY]: [],
  [DEMO_BANK_PERMISSION_KEY]: [
    {
      name: 'Application Funnel Dashboard.looker',
      url: ''
    },
    {
      name: 'Marketing KPIs.looker',
      url: ''
    },
    {
      name: 'SLA Dashboard.looker',
      url: ''
    },
    {
      name: 'Verification KPIs.looker',
      url: ''
    }
  ],
  [ELOAN_PERMISSION_KEY]: [
    {
      name: 'Eloan Verifications.looker',
      url: '/dashboards/eloan_dw::eloan_weekly_verifications'
    },
    {
      name: 'Eloan Marketing.looker',
      url: '/dashboards/eloan_dw::eloan_weekly_marketing_kpis'
    },
    {
      name: 'Eloan Monthly Collections.looker',
      url: '/dashboards/eloan_dw::eloan_monthly_collections'
    },
    {
      name: 'Eloan Daily Funnel.looker',
      url: '/dashboards/eloan_dw::eloan_daily_funnel'
    },
    {
      name: 'Eloan Credit Karma PreQual Performance.looker',
      url: '/dashboards/eloan_dw::eloan_ck_prequal_performance'
    },
    {
      name: 'Eloan Credit Karma ITA Performance.looker',
      url: '/dashboards/eloan_dw::eloan_ck_ita_performance'
    },
    {
      name: 'Eloan Decline Monetization Summary By Month.looker',
      url: '/dashboards/eloan_dw::eloan_decline_monetization'
    }
  ],
  [GUARANTEED_RATE_PERMISSION_KEY]: [
    {
      name: 'Guaranteed Rate Application At A Glance.looker',
      url: '/dashboards/grate_dw::grate_applications_dashboard_at_a_glance_view',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'Guaranteed Rate Application Detailed.looker',
      url: '/dashboards/grate_dw::monitor_the_funnel_conversions',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'Guaranteed Rate Verifications At A Glance.looker',
      url: '/dashboards/grate_dw::grate_verification_at_a_glance',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'Guaranteed Rate Verifications Detailed.looker',
      url: '/dashboards/grate_dw::grate_verification_detailed_view',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'Guaranteed Rate Issuance At A Glance.looker',
      url: '/dashboards/grate_dw::grate_issuance_at_a_glance',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'GRate Launch Monitoring.looker',
      url: '/dashboards/grate_prod::grate_launch_monitoring',
      location: PERSONAL_LOAN_PATH,
    }
  ],
  [HSBC_PERMISSION_KEY]: [
    {
      name: 'HSBC Originations.looker',
      url: '/dashboards/hsbc_dw::hsbc_daily_originations'
    },
    {
      name: 'HSBC Daily Funnel Dashboard.looker',
      url: '/dashboards/hsbc_prod::hsbc_daily_funnel'
    },
    {
      name: 'HSBC Verifications.looker',
      url: '/dashboards/hsbc_dw::hsbc_weekly_verifications'
    },
    {
      name: 'HSBC Marketing.looker',
      url: '/dashboards/hsbc_dw::hsbc_weekly_marketing_kpis'
    },
    {
      name: 'HSBC Daily Fraud.looker',
      url: '/dashboards/hsbc_dw::hsbc_daily_fraud_dashboard'
    },
    {
      name: 'HSBC Daily Complaints.looker',
      url: '/dashboards/hsbc_dw::hsbc_daily_complaints_report'
    },
    {
      name: 'HSBC Direct Mail Performance.looker',
      url: '/dashboards/hsbc_dw::hsbc_dm_performance'
    },
  ],
  [PNC_PERMISSION_KEY]: [
    {
      name: 'PNC Daily Dashboard.looker',
      url: '/dashboards/pnc_prod::pnc_daily_funnel'
    },
    {
      name: 'PNC Credit Karma Dashboard.looker',
      url: '/dashboards/pnc_prod::pnc_credit_karma_prequal_dashboard'
    },
    {
      name: 'PNC Marketing.looker',
      url: '/dashboards/pnc_dw::pnc_weekly_marketing_kpis'
    },
    {
      name: 'PNC Verifications.looker',
      url: '/dashboards/pnc_dw::pnc_weekly_verifications'
    },
    {
      name: 'PNC Credit Reporting Dashboard.looker',
      url: '/dashboards/pnc_dw::pnc_credit_reporting_dashboard'
    },
    {
      name: 'PNC Daily Originations.looker',
      url: '/dashboards/pnc_dw::pnc_daily_originations'
    },
    {
      name: 'PNC Application Funnel At-A-Glance.looker',
      url: '/dashboards-next/pnc_dw::pnc_applications_dashboard_at_a_glance_view',
    },
    {
      name: 'PNC Application Funnel Detailed.looker',
      url: '/dashboards-next/pnc_dw::pnc_applications_dashboard_detailed_view',
    },
    {
      name: 'PNC Issuance Overview.looker',
      url: '/dashboards-next/pnc_dw::pnc_issuance_dashboard',
    },
    {
      name: 'PNC Verification At-A-Glance.looker',
      url: '/dashboards-next/pnc_dw::pnc_verifications_dashboard_at_a_glance_view',
    },
    {
      name: 'PNC Verification Detailed.looker',
      url: '/dashboards/pnc_dw::pnc_verifications_dashboard_detailed_view'
    },
  ],
  [POLLEN_STREET_PERMISSION_KEY]: [],
  [REGIONS_PERMISSION_KEY]: [
    {
      name: 'INSTL KPIs by Channel.looker',
      url: '/dashboards/regions_dw::regions_instl_kpi_by_channel',
    },
    {
      name: 'Application Funnel At-A-Glance.looker',
      url: '/dashboards-next/regions_dw::regions_applications_dashboard_at_a_glance_view',
    },
    {
      name: 'Application Funnel Detailed.looker',
      url: '/dashboards-next/regions_dw::regions_applications_dashboard_detailed_view',
    },
    {
      name: 'Issuance Overview.looker',
      url: '/dashboards-next/regions_dw::regions_issuance_dashboard',
    },
    {
      name: 'Verification At-A-Glance.looker',
      url: '/dashboards-next/regions_dw::regions_verifications_dashboard_at_a_glance_view',
    },
    {
      name: 'Verification Detailed.looker',
      url: '/dashboards/regions_dw::regions_verifications_dashboard_detailed_view'
    },
  ],
  [TD_PERMISSION_KEY]: [
    {
      name: 'TD Monthly Performance.looker',
      url: '/dashboards/td_dw_dc::td_monthly_performance',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'TD Verifications.looker',
      url: '/dashboards/td_dw_dc::td_weekly_verifications',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'TD Marketing.looker',
      url: '/dashboards/td_dw_dc::td_weekly_marketing_kpis',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'TD Fraud KPI.looker',
      url: '/dashboards/td_dw_dc::td_fraud_kpi',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'TD Teller Payments.looker',
      url: '/dashboards/td_dw_dc::td_teller_payments',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'TD Direct Mail.looker',
      url: '/dashboards/td_dw_dc::td_dm_model_dashboard',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'TD Daily Funnel.looker',
      url: '/dashboards/td_dw_dc::td_daily_funnel',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'TD Merchant.looker',
      url: '/dashboards/td_dw_dc::td_merchant',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'TD Launch Monitoring.looker',
      url: '/dashboards/td_prod::td_pos_launch_monitoring',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'TD Promo Plans.looker',
      url: '/dashboards-next/td_prod::td_promo_plan_table',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'TD Verifications Summary.looker',
      url: '/dashboards-next/td_dw_dc::td_verifications_dashboard_at_a_glance_view?date_filter=13+months&date_granularity_filter=Month&product_filter=installment&status_filter=closed&source_filter=&identity_status_filter=&income_type_filter=&final_application_decision_filter=&returning_customer_filter=&branch_flag_filter=',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'TD Verifications Summary.looker',
      url: '/dashboards-next/td_dw_dc::td_verifications_dashboard_at_a_glance_view?date_filter=13+months&date_granularity_filter=Month&product_filter=point_of_sale&status_filter=closed&source_filter=&identity_status_filter=&income_type_filter=&final_application_decision_filter=&returning_customer_filter=&branch_flag_filter=',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'TD Verifications Detail.looker',
      url: '/dashboards-next/td_dw_dc::td_verifications_dashboard_detailed_view?date_filter=4 weeks ago for 4 weeks&date_granularity_filter=Week&source_filter=&product_filter=installment&status_filter=closed&identity_status_filter=&income_type_filter=&final_application_decision_filter=&returning_customer_filter=&branch_flag_filter=&filter_config={"date_filter":[{"type":"past","values":[{"constant":"4","unit":"c_wk"},{}],"id":20}],"date_granularity_filter":[{"type":"is","values":[{"constant":"Week"},{}],"id":21}],"source_filter":[{"type":"%3D","values":[{"constant":""},{}],"id":22}],"product_filter":[{"type":"%3D","values":[{"constant":"installment"},{}],"id":23}],"status_filter":[{"type":"%3D","values":[{"constant":"closed"},{}],"id":24}],"identity_status_filter":[{"type":"%3D","values":[{"constant":""},{}],"id":25}],"income_type_filter":[{"type":"%3D","values":[{"constant":""},{}],"id":26}],"final_application_decision_filter":[{"type":"%3D","values":[{"constant":""},{}],"id":27}],"returning_customer_filter":[{"type":"any","values":[{"constant":""},{}],"id":28}],"branch_flag_filter":[{"type":"any","values":[{"constant":""},{}],"id":29}]}',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'TD Verifications Detail.looker',
      url: '/dashboards-next/td_dw_dc::td_verifications_dashboard_detailed_view?date_filter=4 weeks ago for 4 weeks&date_granularity_filter=Week&source_filter=&product_filter=point_of_sale&status_filter=closed&identity_status_filter=&income_type_filter=&final_application_decision_filter=&returning_customer_filter=&branch_flag_filter=&filter_config={"date_filter":[{"type":"past","values":[{"constant":"4","unit":"c_wk"},{}],"id":20}],"date_granularity_filter":[{"type":"is","values":[{"constant":"Week"},{}],"id":21}],"source_filter":[{"type":"%3D","values":[{"constant":""},{}],"id":22}],"product_filter":[{"type":"advanced","values":[{"constant":"point_of_sale"},{}],"id":23}],"status_filter":[{"type":"%3D","values":[{"constant":"closed"},{}],"id":24}],"identity_status_filter":[{"type":"%3D","values":[{"constant":""},{}],"id":25}],"income_type_filter":[{"type":"%3D","values":[{"constant":""},{}],"id":26}],"final_application_decision_filter":[{"type":"%3D","values":[{"constant":""},{}],"id":27}],"returning_customer_filter":[{"type":"any","values":[{"constant":""},{}],"id":28}],"branch_flag_filter":[{"type":"any","values":[{"constant":""},{}],"id":29}]}',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'TD Application Summary.looker',
      url: '/dashboards-next/td_dw_dc::td_applications_dashboard_at_a_glance_view?date_filter=13+months&date_granularity_filter=Month&product_filter=installment&source_filter=',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'TD Application Summary.looker',
      url: '/dashboards-next/td_dw_dc::td_applications_dashboard_at_a_glance_view?date_filter=13+months&date_granularity_filter=Month&product_filter=point_of_sale&source_filter=',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'TD Application Detail.looker',
      url: '/dashboards-next/td_dw_dc::td_applications_dashboard_detailed_view?date_filter=4+weeks+ago+for+4+weeks&date_granularity_filter=Week&product_filter=installment&source_filter=&split_by_filter=Fico+Tier',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'TD Application Detail.looker',
      url: '/dashboards-next/td_dw_dc::td_applications_dashboard_detailed_view?date_filter=4+weeks+ago+for+4+weeks&date_granularity_filter=Week&product_filter=point_of_sale&source_filter=&split_by_filter=Fico+Tier',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'TD Issuance.looker',
      url: '/dashboards-next/td_dw_dc::td_issuance_dashboard?created_date_filter=13+months&date_granularity_filter=Month&product_filter=installment&loan_purpose_filter=&source_filter=',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'TD Issuance.looker',
      url: '/dashboards-next/td_dw_dc::td_issuance_dashboard?created_date_filter=13+months&date_granularity_filter=Month&product_filter=point_of_sale&loan_purpose_filter=&source_filter=',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'TD Untouched App.looker',
      url: '/dashboards/td_prod::td_specialist_queue',
      location: PERSONAL_LOAN_PATH,
    },
  ],
  [ICON_PERMISSION_KEY]: [],
  [BBVA_PERMISSION_KEY]: [
    {
      name: 'Weekly Verification KPI.looker',
      url: '/dashboards/bbva_dw::bbva_weekly_verifications'
    },
    {
      name: 'Installment KPIs by Channel.looker',
      url: '/dashboards/bbva_dw::bbva_instl_kpi_by_channel'
    }
  ],
  [CAPITAL_ONE_PERMISSION_KEY]: [
    {
      name: 'Capital One Application At A Glance.looker',
      url: '/dashboards-next/cof_dw::cof_applications_dashboard_at_a_glance_view',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'Capital One Application Detailed.looker',
      url: '/dashboards-next/cof_dw::monitor_the_funnel_conversions',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'Capital One Verifications At A Glance.looker',
      url: '/dashboards-next/cof_dw::cof_verifications_summary',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'Capital One Verifications Detailed.looker',
      url: '/dashboards/cof_dw::cof_verification_process_monitoring',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'Capital One Issuance At A Glance.looker',
      url: '/dashboards-next/cof_dw::cof_issuance_at_a_glance',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'Capital One Merchant.looker',
      url: '/dashboards-next/cof_dw::cof_merchant',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'Capital One Credit Performance At-A-Glance.looker',
      url: '/dashboards-next/cof_dw::cof_credit_performance_summary',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'Capital One Credit Performance Detailed.looker',
      url: '/dashboards-next/cof_dw::cof_credit_performance_detail',
      location: POINT_OF_SALE_PATH,
    },
    {
      name: 'Capital One POS Launch Monitoring.looker',
      url: '/dashboards/cof_prod::cof_pos_launch_monitoring',
      location: POINT_OF_SALE_PATH,
    }
  ],
  [BARCLAYS_PERMISSION_KEY]: [
    {
      name: 'Barclays Application At A Glance.looker',
      url: '/dashboards-next/bcs_dw::barclays_application_dashboard_at_a_glance_view',
    },
    {
      name: 'Barclays Application Detailed.looker',
      url: '/dashboards-next/bcs_dw::barclays_application_dashboard_detailed_view',
    },
    {
      name: 'Barclays Issuance At A Glance.looker',
      url: '/dashboards-next/bcs_dw::barclays_issuance_at_a_glance',
    },
    {
      name: 'Barclays Merchant.looker',
      url: '/dashboards-next/bcs_dw::barclays_merchant_dashboard',
    },
    {
      name: 'Barclays Verifications At A Glance.looker',
      url: '/dashboards-next/bcs_dw::barclays_verifications_dashboard_at_a_glance',
    }
  ],
  [JOMASHOP_PERMISSION_KEY]: [],
  [JENIUS_PERMISSION_KEY]: [
    {
      name: 'Launch Monitoring Dashboard.looker',
      url: '/dashboards/jenius_prod::jenius_launch_monitoring',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'LT Launch Monitoring Dashboard.looker',
      url: '/dashboards/jenius_prod::jenius_launch_monitoring_leads',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'Applications at a Glance Dashboard.looker',
      url: '/dashboards/jenius_dw::smbc_applications_dashboard_at_a_glance_view',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'Applications Detail View Dashboard.looker',
      url: '/dashboards/jenius_dw::smbc_applications_dashboard_detailed_view',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'Issuance Dashboard.looker',
      url: '/dashboards/jenius_dw::smbc_issuance_dashboard',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'Verifications at a Glance Dashboard.looker',
      url: '/dashboards/jenius_dw::smbc_verifications_dashboard_at_a_glance_view',
      location: PERSONAL_LOAN_PATH,
    },
    {
      name: 'Verifications Detail View Dashboard.looker',
      url: '/dashboards/jenius_dw::smbc_verifications_dashboard_detailed_view',
      location: PERSONAL_LOAN_PATH,
    }
  ],
};

export default LookerDashboardConfig;
