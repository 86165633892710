import * as React from 'react';
import {
  Card as FrontendCard,
  Label,
  media,
  SVGIcon,
  VALID_SVG_ICONS,
} from '@amount/frontend-components';
import styled, { css } from 'styled-components';

export const styledCard = styled(FrontendCard)`
  transition: border 100ms ease, border-color 100ms ease;

  &:hover {
    color: ${({ theme: { text } }) => text.colorText};
  }
`;

export const CardLabel = styled(Label)`
  color: unset;
`;

export const CardText = styled.p`
  font-size: 1.4rem;
  margin-bottom: 0;
`;

const CardIcon = styled(SVGIcon)`
  position: absolute;
  width: 20px;
  height: 20px;
  color: ${({ theme: { brand } }) => brand.colorAlert};
`;

const CardWrap = styled.div`
  padding-left: 2.25em;
`;

const LightModeCard = css`
  transition: border 100ms ease, border-color 100ms ease, transform 100ms ease;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    border: ${({ theme: { card } }) => card.borderWidth} solid transparent;
  }

  &:hover {
    border-bottom: ${({ theme: { card } }) => card.borderWidth} solid ${({ theme: { brand } }) => brand.colorPrimary};
    transform: translateY(-8px);

    &::before {
      top: 10px;
    }
  }

  &:active {
    border: ${({ theme: { card } }) => card.borderWidth} solid ${({ theme: { brand } }) => brand.colorPrimary};
  }
`;

const DarkModeCard = css`
  ${CardIcon} {
    color: #fff;
  }

  &:hover {
    color: ${({ theme: { link } }) => link.colorLinkHover};
    border: ${({ theme: { card } }) => card.borderWidth} solid ${({ theme: { link } }) => link.colorLinkHover};

    ${CardIcon} {
      color: ${({ theme: { link } }) => link.colorLinkHover};
    }
  }
`;

const HelloCard = styled(styledCard)`
  position: relative;
  padding: 1.25em;
  height: 100%;
  color: ${({ theme: { text } }) => text.colorText};

  ${({ theme }) => theme.name === 'amountDark' ? css`
    ${DarkModeCard}
  ` : css`
    ${LightModeCard}
  `}

  &:hover {
    box-shadow: ${({ theme: { radio } }) => radio.boxShadowHover};
  }
`;

export interface ICardProps {
  icon: VALID_SVG_ICONS;
  name: string;
  label?: string;
  onClick?: React.MouseEventHandler;
}

export const Card: React.FC<ICardProps> = ({ icon, name, label, ...rest }) => (
  <HelloCard {...rest}>
    {!!icon && <CardIcon icon={icon} />}
    <CardWrap>
      <CardLabel>{name}</CardLabel>
      <CardText>{label}</CardText>
    </CardWrap>
  </HelloCard>
);

export const CardContain = styled.div<{ oneColumn?: boolean; margin?: string }>`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  grid-gap: 10px;
  width: 100%;
  margin-top: ${({ margin }) => margin || '2.5em'};

  ${({ oneColumn }) => css`
    ${media.small`
      grid-auto-rows: 1fr;
      grid-template-columns: ${oneColumn ? '100%' : 'repeat(2, 1fr)'};
      grid-gap: 16px;
    `}

    ${media.medium`
      grid-template-columns: ${oneColumn ? '100%' : 'repeat(3, 1fr)'};
    `}
  `}
`;
