import {
  media,
  VerContainer,
} from '@amount/frontend-components';
import styled from 'styled-components';

export const PartnersContainer = styled(VerContainer.withComponent('div'))`
  padding-top: 4.375em;

  ${media.small`
    padding-top: 5.5em;
  `}
`;
