import * as React from 'react';
import { TextInput, VALID_HEADLINE_SCALE } from '@amount/frontend-components';
import styled from 'styled-components';

const SummaryEdit = styled(TextInput)<{ scale: string }>`
  font-size: ${({ theme: { headline }, scale }) => headline[scale].sizeResponsive};
  min-height: ${({ theme: { headline }, scale }) => headline[scale].lineHeightResponsive};
  padding: 0;
`;

interface ISummaryEditProps {
  scale: VALID_HEADLINE_SCALE;
  value: string;
  onBlur (): void;
  onKeyDown (event: React.KeyboardEvent<HTMLInputElement>): void;
  onChange (event: React.ChangeEvent<HTMLInputElement>): void;
}

const SummaryEditAutoFocus: React.FC<ISummaryEditProps> = props => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(
    () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    []
  );

  return (
    <SummaryEdit
      ref={inputRef}
      {...props}
    />
  );
};

export default SummaryEditAutoFocus;
