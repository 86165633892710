import * as React from 'react';

import { SelectionOnRequestTypeFields } from '../../queries/getRequestFields.graphql';

export interface IRequiredRefs {
  [x: string]: React.RefObject<HTMLTextAreaElement> & React.RefObject<HTMLInputElement> & React.RefObject<HTMLSelectElement>;
}

export default (fields: SelectionOnRequestTypeFields[]) =>
  fields.reduce<IRequiredRefs>(
    (acc, { required, name }: SelectionOnRequestTypeFields) => {
      if (required && name) {
        acc[name] = React.createRef();
    }

      return acc;
    },
    {}
  );
