import * as React from 'react';
import {
  Button,
  ButtonWithSpinner,
  ConditionalRenderWrapper,
  DrawerClose,
  DrawerTitle,
  DropdownButton,
  SVGIcon,
} from '@amount/frontend-components';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ButtonLabel, MenuItemContain, MenuItemLabel } from '../../CommonComponents';
import { EDIT_USERS } from '../../../../shared/config/permissions';
import { PermissionedComponent } from '../../PermissionedComponent';
import Modal, { IModalProps } from '../../Modal';
import { ButtonActionRow, CancelButton, ModalText } from '../../Modal/common';
import { Spinner } from '../../Spinner';
import { ModuleRoutes } from '../../../../shared/routes';

import {
  ACTIVE_STATUS,
  DISABLED_STATUS,
  INACTIVE_STATUS,
  LOCKED_STATUS,
  MODIFIED_VALID_STATUS,
  PENDING_STATUS,
  PROCESSING_STATUS,
  RECOVERY_STATUS,
  STATUS_LIST_READABLE_MAP,
  VALID_STATUS
} from './constants';
import { USER_MANAGEMENT_ROUTES, UserActions } from './common';

interface IResendModal extends IModalProps {
  email: string;
}

export const ActivationResentModal: React.FC<IResendModal> = props => (
  <Modal
    data-modal={true}
    close={props.close}
    show={props.show}
    maxWidth='30em'
  >
    <ModalText>
      The activation email has been re-sent to <strong>{props.email}</strong>
    </ModalText>
    <Button
      inline={true}
      buttonStyle='secondary'
      data-event='dismissResentModal'
      onClick={props.close}
    >
      Done
    </Button>
  </Modal>
);

const statusToConfirmMap: { [x in MODIFIED_VALID_STATUS]: string } = {
  [ACTIVE_STATUS]: 'activate',
  [INACTIVE_STATUS]: 'deactivate',
  [PENDING_STATUS]: 'remove',
  [DISABLED_STATUS]: 'remove',
  [PROCESSING_STATUS]: '',
  [RECOVERY_STATUS]: 'remove',
  [LOCKED_STATUS]: 'remove'
};

interface IActivationModal extends IModalProps {
  firstName: string;
  lastName: string;
  status: string;
  submitting: boolean;
  confirm: React.MouseEventHandler;
}

export const statusToChangeMap: { [x in VALID_STATUS]: MODIFIED_VALID_STATUS } = {
  [ACTIVE_STATUS]: INACTIVE_STATUS,
  [INACTIVE_STATUS]: ACTIVE_STATUS,
  [PENDING_STATUS]: DISABLED_STATUS,
  [PROCESSING_STATUS]: INACTIVE_STATUS,
  [RECOVERY_STATUS]: DISABLED_STATUS,
  [LOCKED_STATUS]: DISABLED_STATUS
};

const statusToReadableMap: { [x in MODIFIED_VALID_STATUS]: string } = {
  [ACTIVE_STATUS]: STATUS_LIST_READABLE_MAP[ACTIVE_STATUS],
  [INACTIVE_STATUS]: STATUS_LIST_READABLE_MAP[INACTIVE_STATUS],
  [PENDING_STATUS]: DISABLED_STATUS,
  [DISABLED_STATUS]: DISABLED_STATUS,
  [PROCESSING_STATUS]: STATUS_LIST_READABLE_MAP[INACTIVE_STATUS],
  [RECOVERY_STATUS]: DISABLED_STATUS,
  [LOCKED_STATUS]: DISABLED_STATUS,
};

export const ConfirmActivationModal: React.FC<IActivationModal> = props => {
  const status: MODIFIED_VALID_STATUS = statusToChangeMap[props.status];

  return (
    <Modal
      data-modal={true}
      close={props.close}
      show={props.show}
      maxWidth='30em'
    >
      <ModalText>
        Are you sure you want to change the status of <strong>{props.firstName} {props.lastName}</strong> to <strong>
          {statusToReadableMap[status]}</strong>?
      </ModalText>
      <ButtonActionRow>
        <ButtonWithSpinner
          inline={true}
          buttonStyle='secondary'
          onClick={props.confirm}
          disabled={props.submitting}
          loading={props.submitting}
          data-event='confirmDeactivateUser'
        >
          {statusToConfirmMap[status]}
        </ButtonWithSpinner>
        <CancelButton
          hidden={props.submitting}
          onClick={props.close}
          data-event='cancelInactivate'
        >
          Go Back
        </CancelButton>
      </ButtonActionRow>
    </Modal>
  );
};

const DropdownSpinner = styled(Spinner)`
  height: 2em;
  width: 2em;
  margin-right: 1em;
`;

const UserDropdownButton = styled(DropdownButton)`
  div {
    padding: 0;
    border: none;
    box-shadow: 0 16px 48px 0 rgba(0,0,0,0.1);
  }
`;

const MenuButton = styled.button`
  display: block;
  width: 100%;
`;

const MobileSettingsButton = styled.button`
  width: 20px;
  height: 20px;
`;

const buttonContent: React.ReactNode = (
  <>
    <SVGIcon icon='settings' />
    <ButtonLabel>User Settings</ButtonLabel>
  </>
);

export type DropdownActionsInput = Pick<IUserActions, 'status' | 'handleStatus' | 'handleResend'>;
export const DropdownActions: (input: DropdownActionsInput) => JSX.Element | undefined = ({
  status, handleStatus, handleResend
}) => {
  switch (status) {
    case PENDING_STATUS:
    case LOCKED_STATUS:
      return (
        <>
          <MenuButton onClick={handleStatus}>
            <MenuItemContain as='span'>
              <MenuItemLabel>Remove User Account</MenuItemLabel>
            </MenuItemContain>
          </MenuButton>
          <MenuButton onClick={handleResend}>
            <MenuItemContain as='span'>
              <MenuItemLabel>Resend Activation Email</MenuItemLabel>
            </MenuItemContain>
          </MenuButton>
        </>
      );
    case ACTIVE_STATUS:
      return (
        <MenuButton onClick={handleStatus}>
          <MenuItemContain as='span'>
            <MenuItemLabel>Deactivate User Account</MenuItemLabel>
          </MenuItemContain>
        </MenuButton>
      );
    case INACTIVE_STATUS:
      return (
        <MenuButton onClick={handleStatus}>
          <MenuItemContain as='span'>
            <MenuItemLabel>Activate User Account</MenuItemLabel>
          </MenuItemContain>
        </MenuButton>
      );
    case RECOVERY_STATUS:
      return (
        <MenuButton onClick={handleStatus}>
          <MenuItemContain as='span'>
            <MenuItemLabel>Remove User Account</MenuItemLabel>
          </MenuItemContain>
        </MenuButton>
      );
    default:
      return undefined;
  }
};

export interface IUserActions {
  submitting: boolean;
  id: string;
  status: string;
  handleDrawer: React.MouseEventHandler;
  handleStatus: React.FormEventHandler;
  handleResend: React.FormEventHandler;
}
export const UserDetailActions: React.FC<IUserActions> = ({ id, submitting, handleDrawer, ...rest }) => (
  <PermissionedComponent allowedPermissions={[EDIT_USERS]}>
    <UserActions>
      {submitting && <DropdownSpinner />}
      <ConditionalRenderWrapper breakpoint='small' hiddenOnMobile={false}>
        <MobileSettingsButton
          data-event='mobileUserSettings'
          onClick={handleDrawer}
        >
          <SVGIcon icon='settings' />
        </MobileSettingsButton>
      </ConditionalRenderWrapper>
      <ConditionalRenderWrapper breakpoint='small' hiddenOnMobile={true}>
        <UserDropdownButton
          data-event='userSettings'
          buttonContent={buttonContent}
          minWidth='16em'
          alignRight={true}
        >
          <Link to={`${ModuleRoutes.userManagement}/${id}${USER_MANAGEMENT_ROUTES.editInfo}`}>
            <MenuItemContain as='span'>
              <MenuItemLabel>Edit User Information</MenuItemLabel>
            </MenuItemContain>
          </Link>
          <Link to={`${ModuleRoutes.userManagement}/${id}${USER_MANAGEMENT_ROUTES.editPermission}`}>
            <MenuItemContain as='span'>
              <MenuItemLabel>Manage Permissions</MenuItemLabel>
            </MenuItemContain>
          </Link>
          {DropdownActions(rest)}
        </UserDropdownButton>
      </ConditionalRenderWrapper>
    </UserActions>
  </PermissionedComponent>
);

type ActionsDrawerProps = IModalProps & Omit<IUserActions, 'handleDrawer'>;
export const UserDetailActionsDrawer: React.FC<ActionsDrawerProps> = ({ id, close, show, ...rest }) => (
  <Modal show={show} close={close} drawer={true}>
    <DrawerClose onClick={close}>
      <SVGIcon icon='close' />
    </DrawerClose>
    <DrawerTitle>Manage User</DrawerTitle>
    <Link to={`${ModuleRoutes.userManagement}/${id}${USER_MANAGEMENT_ROUTES.editInfo}`}>
      <MenuItemContain as='span'>
        <MenuItemLabel>Edit User Information</MenuItemLabel>
      </MenuItemContain>
    </Link>
    <Link to={`${ModuleRoutes.userManagement}/${id}${USER_MANAGEMENT_ROUTES.editPermission}`}>
      <MenuItemContain as='span'>
        <MenuItemLabel>Manage Permissions</MenuItemLabel>
      </MenuItemContain>
    </Link>
    {DropdownActions(rest)}
  </Modal>
);
