import { SwitchablePartner } from './partners';
import { HSBC_PERMISSION_KEY } from './permissions';

export const LoggedOutError: Error = new Error('Unauthenticated User');
export const InvalidPartnerError: Error = new Error('Invalid Partner');

export const FILE_GENERATION_TASK_ID: string = 'sar-automation';
export const FILE_GENERATION_TASK_ID_HSBC: string = 'sar-automation-hsbc';
export const FILE_GENERATION_TASK_MAP: { [key in SwitchablePartner]?: string } = {
  [HSBC_PERMISSION_KEY]: FILE_GENERATION_TASK_ID_HSBC,
};

export const GARDEN_SUCCEEDED_STATE: string = 'succeeded';

export const GARDEN_END_STATES: Set<string> = new Set(['failed', GARDEN_SUCCEEDED_STATE, 'terminated']);

export const PARTNER_CHANGED_EVENT: string = 'currentPartner.changed';

export const DASHBOARDS_PATH = 'Dashboards';
export const INDIVIDUAL_REPORTING_PATH = 'Individual Reporting';
export const STORE_REPORTING_PATH = 'Store Reporting';
export const INBRANCH_INDIVIDUAL_DASHBOARD = 'IndiDashboard';
export const INBRANCH_STORE_DASHBOARD = 'StoreDashboard';
export const PERSONAL_LOAN_PATH = 'Personal Loan';
export const POINT_OF_SALE_PATH = 'Point of Sale';

/*
 * If a user's organization in the login payload is undefined or is an organization
 * that partner portal doesn't recognize, it gets defaulted to this.
 */
export const INVALID_ORGANIZATION = 'invalid_organization';
export const INVALID_USERNAME = 'invalid_username';
export const INVALID_NAME = 'invalid_name';
