import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Query, Mutation, MutationFn } from 'react-apollo';

import { HUMAN_READABLE_S3_TYPES } from '../../../shared/reports/constants';
import { Spinner } from '../Spinner';
import {
  INBRANCH_INDIVIDUAL_DASHBOARD,
  INBRANCH_STORE_DASHBOARD,
  INDIVIDUAL_REPORTING_PATH,
  STORE_REPORTING_PATH
} from '../../../shared/config/constants';
import { PermissionedComponent } from '../PermissionedComponent';
import { VIEW_IN_BRANCH_INDIVIDUAL, VIEW_IN_BRANCH_STORE } from '../../../shared/config/permissions';

import IndividualDashboard from './Presentational/InBranchDashboard/IndividualDashboard';
import InBranchQuery, { GetInBranchData } from './queries/getInBranchData.graphql';
import { EmptyRow, FileIcon, FileText, FileTextContain, ListEntry } from './Presentational/common';
import InBranchStoreQuery, { GetInBranchStoreData } from './queries/getInBranchStoreData.graphql';
import GetCSVMutation, { DownloadInBranchCSV, DownloadInBranchCSVInput} from './queries/getCSV.graphql';
import StoreDashboard from './Presentational/InBranchDashboard/StoreDashboard';

interface IProps {
  root: HUMAN_READABLE_S3_TYPES;
  isVisualizationFolder?: boolean;
  currentDate: Date;
}

interface IIndividualDashboardProps {
  data?: GetInBranchData;
  loading: boolean;
  currentDate: Date;
  getCSVFn: MutationFn<DownloadInBranchCSV, DownloadInBranchCSVInput>
}
interface IStoreDashboardProps {
  data?: GetInBranchStoreData;
  loading: boolean;
  currentDate: Date;
  getCSVFn: MutationFn<DownloadInBranchCSV, DownloadInBranchCSVInput>
}
const IndividualDashboardWrapper: React.FC<IIndividualDashboardProps> = ({ data, loading, currentDate, getCSVFn }) => {

  if (loading) {
    return <Spinner />;
  } else if (!data) {
    return <div>Unable to Retrive Individual Dashboard Data</div>;
  }

  return <IndividualDashboard data={data.inBranchIndividual} currentDate={currentDate} getCSVFn={getCSVFn} />;
};

const StoreDashboardWrapper: React.FC<IStoreDashboardProps> = ({ data, loading, currentDate, getCSVFn }) => {

  if (loading) {
    return <Spinner />;
  } else if (!data) {
    return <div>Unable to Retrive Store Dashboard Data</div>;
  }
  return <StoreDashboard data={data.inBranchStore} currentDate={currentDate} getCSVFn={getCSVFn}/>;
};

interface IWrapperProps {
  allowedPermissions: string[];
}

const InBranchDashboardWrapper: React.FC<IWrapperProps> = ({ allowedPermissions, children }) => (
  <PermissionedComponent allowedPermissions={allowedPermissions} >
    {children}
  </PermissionedComponent>
);

const VizLink: React.FC<{ permissions: string[]; link: string; folderName: string }> = ({ permissions, link, folderName }) => (
  <InBranchDashboardWrapper allowedPermissions={permissions}>
    <Link to={link}>
      <ListEntry>
        <FileIcon icon='looker' />
        <FileTextContain>
          <FileText>{folderName}</FileText>
        </FileTextContain>
      </ListEntry>
    </Link>
  </InBranchDashboardWrapper>
);

const InBranchDashboard: React.FC<IProps & RouteComponentProps> = ({ root, isVisualizationFolder, location, currentDate }) => {

  const individualDashboardPath = `/${root}/${INDIVIDUAL_REPORTING_PATH}`;
  const storeDashboardPath = `/${root}/${STORE_REPORTING_PATH}`;

  const isIndiDashboard: boolean = !!isVisualizationFolder && location.pathname === individualDashboardPath;
  const isStoreDashboard: boolean = !!isVisualizationFolder && location.pathname === storeDashboardPath;
  const isIndividualViz: boolean = !!isVisualizationFolder
    && location.pathname === `${individualDashboardPath}/${INBRANCH_INDIVIDUAL_DASHBOARD}`;
  const isStoreViz: boolean = location.pathname === `${storeDashboardPath}/${INBRANCH_STORE_DASHBOARD}`;

  if (isVisualizationFolder && isIndiDashboard) {
    return (
      <VizLink
        permissions={[VIEW_IN_BRANCH_INDIVIDUAL]}
        link={`${individualDashboardPath}/${INBRANCH_INDIVIDUAL_DASHBOARD}`}
        folderName='Individual Dashboard'
      />
    );
  }

  if (isVisualizationFolder && isStoreDashboard) {
    return (
      <VizLink
        permissions={[VIEW_IN_BRANCH_STORE]}
        link={`${storeDashboardPath}/${INBRANCH_STORE_DASHBOARD}`}
        folderName='Store Dashboard'
      />
    );
  }

  if (isIndividualViz) {
    return (
      <InBranchDashboardWrapper allowedPermissions={[VIEW_IN_BRANCH_INDIVIDUAL]}>
        <Query<GetInBranchData>
          query={InBranchQuery}
        >
          {({ data, loading }) => (
            <Mutation<DownloadInBranchCSV, DownloadInBranchCSVInput>
              mutation={GetCSVMutation}
            >
              {mutation => (
                <IndividualDashboardWrapper
                  data={data}
                  loading={loading}
                  currentDate={currentDate}
                  getCSVFn={mutation}
                />
              )}
            </Mutation>
          )}
        </Query>
      </InBranchDashboardWrapper>
    );
  }

  if (isStoreViz) {
    return (
      <InBranchDashboardWrapper allowedPermissions={[VIEW_IN_BRANCH_STORE]}>
        <Query<GetInBranchStoreData>
          query={InBranchStoreQuery}
        >
          {({ data, loading }) => (
            <Mutation<DownloadInBranchCSV, DownloadInBranchCSVInput>
              mutation={GetCSVMutation}
            >
              {mutation => (
                <StoreDashboardWrapper
                  data={data}
                  loading={loading}
                  getCSVFn={mutation}
                  currentDate={currentDate}
                />
              )}
            </Mutation>
          )}
        </Query>

      </InBranchDashboardWrapper>
    );
  }

  return <EmptyRow><span>This folder is empty.</span></EmptyRow>;
};

export default InBranchDashboard;
InBranchDashboard.displayName = 'InBranchDashboard';
