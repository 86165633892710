import * as React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';

import { Spinner } from '../Spinner';

import getUsersQuery, {
  GetUsers as getUsers,
  GetUsersInput,
} from './queries/getUsers.graphql';
import {
  ILiftedProps,
  UserList,
  UserTable,
} from './Presentational/UserTable';
import { UserManagementContext } from './UserManagement.context';

const SpinnerContain = styled.div`
  margin-top: 1em;
  width: 100%;
`;

const LiftedUserTable: React.FC<ILiftedProps> = props => {
  const {state} = React.useContext(UserManagementContext);

  return (
    <Query<getUsers, GetUsersInput>
      query={getUsersQuery}
      variables={{
        organization: state.currentOrg,
        status: props.status[0],
        roles: props.roles,
        search: props.search
      }}
    >
      {({ data, loading, refetch }) => (
        <>
          {loading ? (
            <UserList>
              <SpinnerContain>
                <Spinner />
              </SpinnerContain>
            </UserList>
          ) : (
            <UserTable {...props} data={data} refetch={refetch} />
          )}
        </>
      )}
    </Query>
  );
};

export default LiftedUserTable;
