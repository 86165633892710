import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

export const HomeRedirectComponent: React.FC<RouteComponentProps> = props => {
  const redirectToRoot: () => void = () => {
    props.history.replace('/');
  };

  React.useEffect(() => {
    redirectToRoot();
  });

  return null;
};

// tslint:disable-next-line: no-any
export default withRouter(HomeRedirectComponent) as React.ComponentClass<any>;
