import { invert } from 'lodash';
import { parse } from 'date-fns';

import { FINAL_REVIEW_SEND_BACK_PSAE } from '../../config/permissions';

import {
  ANALYST_REVIEW_STEP,
  COMPLETED_STEP,
  CREATED_STEP,
  DRAFTING_STEP,
  FRAUD_REVIEW_STEP,
  PARTNER_REJECTED_STEP,
  PARTNER_REVIEW_STEP,
  PARTNER_STEPS,
  REJECTED_STEP,
  SUBMITTED_STEP
} from './steps';

export type WorkflowTransitionPermissions = typeof FINAL_REVIEW_SEND_BACK_PSAE;

export interface IWorkflowTransitionInformation {
  name: string;
  event: string;
  nextStep: string;
  reasons?: ISarRejectReason[];
  permission?: WorkflowTransitionPermissions[];
}

export interface IWorkflowTransition {
  // event => nextState
  [event: string]: IWorkflowTransitionInformation;
}

export interface IWorkflow {
  [currentState: string]: IWorkflowTransition | null;
}

export interface ISarRejectReason {
  value: string;
  name: string;
  allowInput: boolean;
}

export const SAR_REJECT_REASONS: ISarRejectReason[] = [
  {
    value: 'communal',
    name: 'Communal',
    allowInput: false
  },
  {
    value: 'transunion_communal',
    name: 'Transunion Communal',
    allowInput: false
  },
  {
    value: 'sar_rubric',
    name: 'SAR Rubric',
    allowInput: false
  },
  {
    value: 'known_business_phone',
    name: 'Known Business Phone',
    allowInput: false
  },
  {
    value: 'invalid_phone',
    name: 'Invalid Phone',
    allowInput: false
  },
  {
    value: 'not_fraudulent',
    name: 'Not Fraudulent',
    allowInput: false
  },
  {
    value: 'routing_number',
    name: 'Routing Number',
    allowInput: false
  },
  {
    value: 'invalid_account_number',
    name: 'Invalid Account Number',
    allowInput: false
  },
  {
    value: 'different_routing_numbers',
    name: 'Different Routing Numbers',
    allowInput: false
  },
  {
    value: 'soft_fraud_threshold',
    name: 'Soft Fraud Threshold',
    allowInput: false
  },
  {
    value: 'hard_fraud_threshold',
    name: 'Hard Fraud Threshold',
    allowInput: false
  },
  {
    value: 'already_filed',
    name: 'Already Filed',
    allowInput: false
  },
  {
    value: 'avant_device',
    name: 'Avant Device',
    allowInput: false
  },
  {
    value: 'merge',
    name: 'Merge',
    allowInput: false
  },
  {
    value: 'other',
    name: 'Other',
    allowInput: true
  }
];

const REJECT_TRANSITION: IWorkflowTransitionInformation = {
  name: 'Reject',
  event: 'reject',
  nextStep: REJECTED_STEP,
  reasons: SAR_REJECT_REASONS,
};

export const SARTransitions: IWorkflow = {
  [CREATED_STEP]: {
    [DRAFTING_STEP]: {
      name: 'Approve',
      event: DRAFTING_STEP,
      nextStep: DRAFTING_STEP,
    },
    reject: REJECT_TRANSITION,
  },
  [DRAFTING_STEP]: {
    [FRAUD_REVIEW_STEP]: {
      name: 'Approve',
      event: FRAUD_REVIEW_STEP,
      nextStep: FRAUD_REVIEW_STEP,
    },
    reject: REJECT_TRANSITION,
  },
  [FRAUD_REVIEW_STEP]: {
    [ANALYST_REVIEW_STEP]: {
      name: 'Approve',
      event: ANALYST_REVIEW_STEP,
      nextStep: ANALYST_REVIEW_STEP,
    },
    reject: REJECT_TRANSITION,
  },
  [ANALYST_REVIEW_STEP]: {
    [PARTNER_REVIEW_STEP]: {
      name: 'Approve',
      event: PARTNER_REVIEW_STEP,
      nextStep: PARTNER_REVIEW_STEP,
    },
    revert: {
      name: 'Send Back',
      event: 'revert',
      nextStep: FRAUD_REVIEW_STEP,
      permission: [FINAL_REVIEW_SEND_BACK_PSAE],
      reasons: [
        {
          name: 'Report Incomplete',
          value: 'report_incomplete',
          allowInput: false
        }, {
          name: 'Narrative Continuity',
          value: 'narrative_continuity',
          allowInput: false
        }, {
          name: 'Other',
          value: 'other',
          allowInput: true
        }
      ]
    },
    reject: REJECT_TRANSITION,
  },
  [PARTNER_REVIEW_STEP]: {
    submit: {
      name: 'Submit',
      event: 'submit',
      nextStep: COMPLETED_STEP,
    },
    reject: {
      ...REJECT_TRANSITION,
      nextStep: COMPLETED_STEP
    },
  },
  [COMPLETED_STEP]: null,
  [SUBMITTED_STEP]: null,
  [REJECTED_STEP]: {
    unreject: {
      name: 'Unreject',
      event: 'unreject',
      nextStep: CREATED_STEP
    },
  },
  [PARTNER_REJECTED_STEP]: {
    unreject: {
      name: 'Unreject',
      event: 'unreject',
      nextStep: PARTNER_REVIEW_STEP
    }
  }
};

export interface ITabInformation {
  name: string;
  path: string;
  filterKey: string;
  // the newly added completed step is hidden behind mulitple permissions
  permissionKey: string[];
}

export const TabInformation: ITabInformation[] = [
  {
    name: 'Open',
    path: 'narrative',
    filterKey: 'narrative',
    permissionKey: [CREATED_STEP],
  }, {
    name: 'Drafting',
    path: 'drafting',
    filterKey: 'drafting',
    permissionKey: [DRAFTING_STEP],
  }, {
    name: 'Peer Review',
    path: 'fraudApproval',
    filterKey: 'fraudApproval',
    permissionKey: [FRAUD_REVIEW_STEP],
  }, {
    name: 'Final Review',
    path: 'legalApproval',
    filterKey: 'legalApproval',
    permissionKey: [ANALYST_REVIEW_STEP],
  }, {
    name: 'Partner Approve',
    path: 'partnerApproval',
    filterKey: 'partnerApproval',
    permissionKey: [PARTNER_REVIEW_STEP],
  // }, {
  //   name: 'Submitted to FINCEN',
  //   path: 'fincen',
  //   filterKey: 'fincen',
  //   permissionKey: SUBMITTED_STEP,
  }, {
    name: 'Avant Rejected',
    path: 'rejected',
    filterKey: 'rejected',
    permissionKey: [REJECTED_STEP],
  }, {
    name: 'Partner Rejected',
    path: 'partnerRejected',
    filterKey: 'partnerRejected',
    permissionKey: [PARTNER_REJECTED_STEP],
  }, {
    name: 'Completed',
    path: 'completed',
    filterKey: 'completed',
    permissionKey: [PARTNER_REVIEW_STEP, PARTNER_REJECTED_STEP]
  }
];

export const SARStatusToUI: { [state: string]: string } = {
  [CREATED_STEP]: 'narrative',
  [DRAFTING_STEP]: 'drafting',
  [FRAUD_REVIEW_STEP]: 'fraudApproval',
  [ANALYST_REVIEW_STEP]: 'legalApproval',
  [PARTNER_REVIEW_STEP]: 'partnerApproval',
  [SUBMITTED_STEP]: 'fincen',
  [COMPLETED_STEP]: 'completed',
  [REJECTED_STEP]: 'rejected',
  [PARTNER_REJECTED_STEP]: 'partnerRejected',
};

const REQUIRE_SECONDARY_AUTH: Set<string> = new Set([...PARTNER_STEPS]);

export const statusRequiresSecondaryAuthorization: (status: string) => boolean = status => REQUIRE_SECONDARY_AUTH.has(status);

export const UIToSARStatus: { [ui: string]: string } = invert(SARStatusToUI) as { [ui: string]: string };

export interface IEditOption {
  href: string;
  text: string;
}

export const EditOptionsAtStep: { [ui: string]: IEditOption[] } = {
  [CREATED_STEP]: [{
    href: 'narrative',
    text: 'Write Narrative'
  }]
};

export const NON_EDITABLE_STEPS: Set<string> = new Set([COMPLETED_STEP, SUBMITTED_STEP]);

export type FormatterType = (value: number) => string;
export type AttributesType = INewAttribute;

interface INewAttribute {
  key: string;
  display?: string; // defaults to display
  formatter?: FormatterType;
}

// the ISO Date format is already in the YYYY-MM-DD format, so we can take off the Time part of it
const dateFormatter: FormatterType = value => (
  value ? parse(value).toISOString().split('T')[0]  : ''
);

const BASE_ATTRIBUTES: AttributesType[] = [
  { key: 'customer_id' },
  { key: 'first_name' },
  { key: 'last_name' },
  { key: 'email' },
  { key: 'address_1' },
  { key: 'city' },
  { key: 'state' },
  { key: 'zip' },
  { key: 'phone_number' },
  { key: 'ssn_last_4' },
  {
    key: 'date_of_birth',
    formatter: dateFormatter
  },
  {
    key: 'created_at',
    display: 'customer_application_created_at',
    formatter: dateFormatter
  },
  { key: 'employer' },
  { key: 'loan_amount', display: 'amount_for_customer' },
  { key: 'sar_amount', display: 'cumulative_amount_for_query' },
  { key: 'loan_status', display: 'status' },
];

export const MATCHING_PHONE_OR_DEVICE_ATTRIBUTES: AttributesType[] = [
  ...BASE_ATTRIBUTES,
  { key: 'device_alias' },
  { key: 'last_sign_in_ip', display: 'ip_address' },
];

export const MATCHING_BANK_ATTRIBUTES: AttributesType[] = [
  ...BASE_ATTRIBUTES,
  { key: 'bank_account_number' },
  { key: 'last_sign_in_ip', display: 'ip_address' },
];

export const FRAUDULENT_DOCUMENT_ATTRIBUTES: AttributesType[] = [
  ...BASE_ATTRIBUTES,
  { key: 'document_type' },
  { key: 'submittal_date' },
  { key: 'ip_address' },
];

export const SUSPECT_ATTRIBUTES: AttributesType[] = [
  ...BASE_ATTRIBUTES,
  { key: 'ip_address' },
];

export type SARTypes =
  | 'fraudulent_document'
  | 'matching_device'
  | 'matching_phone'
  | 'matching_bank'
  | 'suspect'
  | 'fraud_ring'
  | 'cyber_event';

export const ATTRIBUTES_BY_TYPE: { [type in SARTypes]: AttributesType[] } = {
  fraudulent_document: FRAUDULENT_DOCUMENT_ATTRIBUTES,
  matching_device: MATCHING_PHONE_OR_DEVICE_ATTRIBUTES,
  matching_phone: MATCHING_PHONE_OR_DEVICE_ATTRIBUTES,
  suspect: SUSPECT_ATTRIBUTES,
  matching_bank: MATCHING_BANK_ATTRIBUTES,
  fraud_ring: SUSPECT_ATTRIBUTES,
  cyber_event: SUSPECT_ATTRIBUTES,
};

export interface IAdditionalSARCategoryInformation {
  id: string;
  name: string;
  options: IAdditionalSARCategoryOption[];
}

export interface IAdditionalSARCategoryOption {
  code: string;
  description: string;
  needsComment: boolean;
}

export const ADDITIONAL_SAR_CATEGORIES: IAdditionalSARCategoryInformation[] = [
  {
    id: 'item_29',
    name: 'Structuring',
    options: [
      { code: 'A', description: 'Alters transaction to avoid BSA recordkeeping requirement', needsComment: false },
      { code: 'B', description: 'Alters transaction to avoid CTR requirement', needsComment: false },
      { code: 'C', description: 'Customer cancels transaction to avoid BSA reporting and recordkeeping requirements', needsComment: false },
      { code: 'D', description: 'Multiple transactions below BSA recordkeeping threshold', needsComment: false },
      { code: 'E', description: 'Multiple transactions below CTR threshold', needsComment: false },
      {
        code: 'F',
        description: 'Suspicious inquiry by customer regarding BSA reporting or recordkeeping requirements',
        needsComment: false
      },
      { code: 'Z', description: 'Other', needsComment: true },
    ]
  }, {
    id: 'item_30',
    name: 'Terrorist Financing',
    options: [
      { code: 'A', description: 'Known or suspected terrorist/terrorist organization', needsComment: false },
      { code: 'Z', description: 'Other', needsComment: true }
    ]
  }, {
    id: 'item_31',
    name: 'Fraud',
    options: [
      { code: 'A', description: 'ACH', needsComment: false },
      { code: 'B', description: 'Business loan', needsComment: false },
      { code: 'C', description: 'Check', needsComment: false },
      { code: 'D', description: 'Consumer loan', needsComment: false },
      { code: 'E', description: 'Credit/Debit card', needsComment: false },
      { code: 'F', description: 'Healthcare', needsComment: false },
      { code: 'G', description: 'Mail', needsComment: false },
      { code: 'H', description: 'Mass-marketing', needsComment: false },
      { code: 'I', description: 'Pyramid scheme', needsComment: false },
      { code: 'J', description: 'Wire', needsComment: false },
      { code: 'Z', description: 'Other', needsComment: true },
    ]
  }, {
    id: 'item_32',
    name: 'Casinos',
    options: [
      { code: 'A', description: 'Inquiry about end of business day', needsComment: false },
      { code: 'B', description: 'Minimal gaming with large transactions', needsComment: false },
      { code: 'C', description: 'Suspicious intra-casino funds transfers', needsComment: false },
      { code: 'D', description: 'Suspicious use of counter checks or markers', needsComment: false },
      { code: 'Z', description: 'Other', needsComment: true },
    ]
  }, {
    id: 'item_33',
    name: 'Money Laundering',
    options: [
      { code: 'A', description: 'Exchanges small bills for large bills or vice versa', needsComment: false },
      { code: 'B', description: 'Suspicion concerning the physical condition of funds', needsComment: false },
      { code: 'C', description: 'Suspicion concerning the source of funds', needsComment: false },
      { code: 'D', description: 'Suspicious designation of beneficiaries, assignees or joint owners', needsComment: false },
      { code: 'E', description: 'Suspicious EFT/wire transfers', needsComment: false },
      { code: 'F', description: 'Suspicious exchange of currencies', needsComment: false },
      { code: 'G', description: 'Suspicious receipt of government payments/benefits', needsComment: false },
      { code: 'H', description: 'Suspicious use of multiple accounts', needsComment: false },
      { code: 'I', description: 'Suspicious use of noncash monetary instruments', needsComment: false },
      { code: 'J', description: 'Suspicious use of third-party transactors (straw-man)', needsComment: false },
      { code: 'K', description: 'Trade Based Money Laundering/Black Market Peso Exchange', needsComment: false },
      { code: 'L', description: 'Transaction out of pattern for customer(s)', needsComment: false },
      { code: 'Z', description: 'Other', needsComment: true },
    ]
  }, {
    id: 'item_34',
    name: 'Identification/Documentation',
    options: [
      { code: 'A', description: 'Changes spelling or arrangement of name', needsComment: false },
      { code: 'B', description: 'Multiple individuals with same or similar identities', needsComment: false },
      { code: 'C', description: 'Provided questionable or false documentation', needsComment: false },
      { code: 'D', description: 'Refused or avoided request for documentation', needsComment: false },
      { code: 'E', description: 'Single individual with multiple identities', needsComment: false },
      { code: 'Z', description: 'Other', needsComment: true },
    ]
  }, {
    id: 'item_35',
    name: 'Other Suspicious Activities',
    options: [
      { code: 'A', description: 'Account takeover', needsComment: false },
      { code: 'B', description: 'Bribery or gratuity', needsComment: false },
      { code: 'C', description: 'Counterfeit instruments', needsComment: false },
      { code: 'D', description: 'Elder financial exploitation', needsComment: false },
      { code: 'E', description: 'Embezzlement/theft/disappearance of funds', needsComment: false },
      { code: 'F', description: 'Forgeries', needsComment: false },
      { code: 'G', description: 'Identity theft', needsComment: false },
      { code: 'H', description: 'Little or no concern for product performance penalties, fees, or tax consequences', needsComment: false },
      { code: 'I', description: 'Misuse of “free look”/cooling-off/right of rescission', needsComment: false },
      { code: 'J', description: 'Misuse of position or self-dealing', needsComment: false },
      { code: 'K', description: 'Suspected public/private corruption (domestic)', needsComment: false },
      { code: 'L', description: 'Suspected public/private corruption (foreign)', needsComment: false },
      { code: 'M', description: 'Suspicious use of informal value transfer system', needsComment: false },
      { code: 'N', description: 'Suspicious use of multiple transaction locations', needsComment: false },
      { code: 'O', description: 'Transaction with no apparent economic, business, or lawful purpose', needsComment: false },
      { code: 'P', description: 'Two or more individuals working together', needsComment: false },
      { code: 'Q', description: 'Unauthorized electronic intrusion', needsComment: false },
      { code: 'R', description: 'Unlicensed or unregistered MSB', needsComment: false },
      { code: 'Z', description: 'Other', needsComment: true },
    ]
  }, {
    id: 'item_36',
    name: 'Insurance',
    options: [
      { code: 'A', description: 'Excessive insurance', needsComment: false },
      { code: 'B', description: 'Excessive or unusual cash borrowing against policy/annuity', needsComment: false },
      { code: 'C', description: 'Proceeds sent to or received from unrelated third party', needsComment: false },
      { code: 'D', description: 'Suspicious life settlement sales insurance (e.g. STOLI’s, Viaticals)', needsComment: false },
      { code: 'E', description: 'Suspicious termination of policy or contract', needsComment: false },
      { code: 'F', description: 'Unclear or no insurable interest', needsComment: false },
      { code: 'Z', description: 'Other', needsComment: true },
    ]
  }, {
    id: 'item_37',
    name: 'Securities/Futures/Options',
    options: [
      { code: 'A', description: 'Insider trading', needsComment: false },
      { code: 'B', description: 'Market manipulation/wash trading', needsComment: false },
      { code: 'C', description: 'Misappropriation', needsComment: false },
      { code: 'D', description: 'Unauthorized pooling', needsComment: false },
      { code: 'Z', description: 'Other', needsComment: true },
    ]
  }, {
    id: 'item_38',
    name: 'Mortgage Fraud',
    options: [
      { code: 'A', description: 'Appraisal fraud', needsComment: false },
      { code: 'B', description: 'Foreclosure fraud', needsComment: false },
      { code: 'C', description: 'Loan Modification fraud', needsComment: false },
      { code: 'D', description: 'Reverse mortgage fraud', needsComment: false },
      { code: 'Z', description: 'Other', needsComment: true },
    ]
  }
];

export const INSERT_REASON_BLACKLIST_TEXT: string = '<INSERT REASON>';
