
import * as d3 from 'd3';
import * as React from 'react';
import * as withFauxDom from 'react-faux-dom';
import { DefaultTheme, ThemeContext } from 'styled-components';
import { HorizontalDivider } from '@amount/frontend-components';

import { ISummaryData, IChartMargin, FunnelCard, FunnelLabel } from './ChartCommon';

// no way currently to just get 'black' or 'white' off theme
const white = '#fff';
const blackBerry = '#022a3a';

export interface IFunnelData {
  name: string;
  value: number;
  percent: number;
  funnelColor: string;
  textColor?: string;
}

export interface IChartData {
  isDarkMode?: boolean;
  theme: DefaultTheme;
  data: IFunnelData[];
  width?: number;
  height?: number;
}

const FunnelChart: React.FC<IChartData> = ({ data, theme, width, height }) => {
  const margin: IChartMargin = { top: 20, right: 20, bottom: 30, left: 80 };

  /* eslint-disable no-magic-numbers */
  const chartWidth: number = width || 800;
  const chartHeight: number = height || 300;
  const textHeight: number = 20;
  const boldFontWeight: number = 700;
  /* eslint-enable no-magic-numbers */

  const chart: withFauxDom.Element = React.useMemo(
    () => {
      const svg = withFauxDom.createElement('svg');
      const tag = d3.select(svg)
        .attr('viewBox', `0 0 ${chartWidth + margin.left + margin.right} ${chartHeight + margin.top + margin.bottom}`);

      const h: number = 55;
      const rectGutter: number = 1;
      const numberOffset: number = 290;
      const chartOffset: number = 165;

      tag.selectAll('g')
        .data(data)
        .enter()
        .each(function (d: IFunnelData, i: number) {
          // tslint:disable-next-line: no-invalid-this
          const el = d3.select(this);
          const textY: number = i * h + h - (textHeight);

          el.append('text')
            .attr('x', 0)
            .attr('y', textY)
            .attr('fill', theme.text.colorText)
            .attr('font-weight', boldFontWeight)
            .text(d.name);

          el.append('text')
            .attr('x', numberOffset)
            .attr('y', textY)
            .attr('text-anchor', 'end')
            .attr('fill', theme.text.colorText)
            .text(d.value.toString());

          const rectWidth: number = i !== 0
            ? Math.round((d.percent * (chartWidth - chartHeight)) / 100)
            : d.percent !== 0 ? (chartWidth - chartHeight) : 0;
          const rectX: number = ((chartWidth - rectWidth) / 2) + chartOffset;
          const textX: number = rectX + (rectWidth / 2);

          el.append('rect')
            .attr('width', rectWidth + margin.left + margin.right)
            .attr('height', h - rectGutter)
            .attr('x', rectX)
            .attr('y', i * h)
            .attr('fill', d.funnelColor)
            .attr('stroke', theme.colorBackground);

          el.append('text')
            .attr('x', textX + (margin.left + margin.right) / 2)
            .attr('y', textY)
            .attr('font-size', '2rem')
            .attr('fill', d.textColor || blackBerry)
            .attr('font-weight', 'bold')
            .attr('text-anchor', 'middle')
            .text(`${d.percent}%`);
        });

      return svg;
    },
    [data, theme]
  );

  return (
    <>
      {chart.toReact()}
    </>
  );
};

export interface IFunnelChartData extends ISummaryData {
  width?: number;
  height?: number;
}

const BranchFunnelChart: React.FC<IFunnelChartData> = ({
  linksSent,
  appsStarted,
  loansIssued,
  appsSubmitted,
  appsApproved,
  contractsSubmitted,
  appsEnteredVer,
  avgLoanAmount,
  issued,
  ...rest }) => {

  const theme: DefaultTheme = React.useContext<DefaultTheme>(ThemeContext);
  const cache: IFunnelData[] = React.useMemo<IFunnelData[]>(
    () => [
      {
        name: 'Links Sent',
        value: linksSent,
        percent: linksSent !== 0 ? 100 : 0,
        funnelColor: '#e2f2f8',
      },
      {
        name: 'Apps Started',
        value: appsStarted,
        percent: Math.round((appsStarted * 100) / linksSent) || 0,
        funnelColor: '#ccece6'
      },
      {
        name: 'Apps Submitted',
        value: appsSubmitted,
        percent: Math.round((appsSubmitted * 100) / linksSent) || 0,
        funnelColor: '#99d8c9'
      },
      {
        name: 'Apps Approved',
        value: appsApproved,
        percent: Math.round((appsApproved * 100) / linksSent) || 0,
        funnelColor: '#66c2a4'
      },
      {
        name: 'Apps Entered Ver',
        value: appsEnteredVer,
        percent: Math.round((appsEnteredVer * 100) / linksSent) || 0,
        funnelColor: '#41ae76'
      },
      {
        name: 'Contracts Submitted',
        value: contractsSubmitted,
        percent: Math.round((contractsSubmitted * 100) / linksSent) || 0,
        funnelColor: '#228743',
        textColor: white
      },
      {
        name: 'Loans Issued',
        value: loansIssued,
        percent: Math.round((loansIssued * 100) / linksSent) || 0,
        funnelColor: '#005824',
        textColor: white
      }
    ],
    [linksSent, appsStarted, loansIssued, appsEnteredVer, contractsSubmitted, appsApproved]
  );

  return (
    <FunnelCard>
      <FunnelLabel
        rows={[
          { label: 'Avg Loan Amount', value: `$${avgLoanAmount.toLocaleString()}` },
          { label: '$ Issued', value: `$${issued.toLocaleString()}` },
        ]}
      />
      <HorizontalDivider style={{marginBottom: '2em'}} />
      <FunnelChart theme={theme} data={cache} {...rest} />
    </FunnelCard>
  );
};

export default BranchFunnelChart;
BranchFunnelChart.displayName = 'BranchFunnelChart';
