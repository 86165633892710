import * as React from 'react';
import * as d3 from 'd3';
import * as withFauxDom from 'react-faux-dom';
import { DefaultTheme, ThemeContext } from 'styled-components';
import { compareAsc } from 'date-fns';

import { IChartMargin, ChartCard, ChartTitle } from './ChartCommon';

const STROKE_WIDTH = 1.5;

export interface IChartDatum {
  date: Date;
  amount: number;
  issued: number;
}

const getDate = (d: IChartDatum) => d.date;
const getAmount = (d: IChartDatum) => d.amount;
const getIssued = (d: IChartDatum) => d.issued;

interface IIssuanceChartProps {
  data: IChartDatum[];
  width?: number;
  height?: number;
}

interface IIssuanceLineChartProps extends IIssuanceChartProps {
  theme: DefaultTheme;
}

// tslint:disable-next-line: max-func-body-length
const IssuanceLineChart: React.FC<IIssuanceLineChartProps> = ({ data, theme, width, height }) => {

  const margin: IChartMargin = { top: 10, right: 35, bottom: 30, left: 60 };
  const labelPadding = { left: 8, bottom: 48, right: 16 };

  // tslint:disable: no-magic-numbers
  const chartWidth: number = width || 800;
  const chartHeight: number = height || 300;
  const tickNumber: number = 7;
  // tslint:enable: no-magic-numbers

  const fauxSvg = React.useMemo(
    () => {
      const root = withFauxDom.createElement('svg');
      const sortedLoans = data.sort((loan1, loan2) => compareAsc(loan1.date, loan2.date));

      const svg = d3
        .select(root)
        .attr('viewBox', `0 0 ${chartWidth + margin.left + margin.right} ${chartHeight + (margin.top * 2) + margin.bottom}`);

      const x = d3.scaleTime()
        .domain((d3.extent(sortedLoans, getDate) as [Date, Date]))
        .range([0, chartWidth - margin.right]);
      svg.append('g')
        .attr('transform', `translate(${margin.left},${chartHeight + margin.top})`)
        .call(d3.axisBottom(x).ticks(tickNumber));

      // text label for the x axis
      svg.append('text')
        .attr(
          'transform',
          `translate(${(chartWidth / 2) + margin.left},${(chartHeight + labelPadding.bottom)})`
        )
        .style('text-anchor', 'middle')
        .attr('fill', theme.colorBlack)
        .text('Issued Date');

      // create the loans issued line and axis
      // Add left-Y axis
      const leftY = d3.scaleLinear()
        .domain(([0, d3.max(sortedLoans, getIssued)] as [number, number]))
        .range([chartHeight, 0]);
      svg.append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)
        .call(d3.axisLeft(leftY));

      // left axis label
      svg.append('text')
        .attr('transform', 'rotate(-90)')
        .attr('fill', theme.brand.colorSecondary)
        .attr('y', 0)
        .attr('x', 0 - (chartHeight / 2))
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .text('Loans Issued');

      // create issuance Amount line and axis
      // Add right-Y axis
      const rightY = d3.scaleLinear()
        .domain(([0, d3.max(sortedLoans, getAmount)] as [number, number]))
        .range([chartHeight, 0]);
      svg.append('g')
        .attr('transform', `translate(${chartWidth + (margin.left - margin.right)},${margin.top})`)
        .call(d3.axisRight(rightY));

      // right axis label
      svg.append('text')
        .attr('transform', 'rotate(-90)')
        .attr('fill', theme.colorSlate)
        .attr('y', chartWidth + margin.left + labelPadding.right)
        .attr('x', 0 - (chartHeight / 2))
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .text('$ Issued');

      // we want to only draw graph lines if data exists to
      // actually graph
      const isGraphable = sortedLoans
        .some(datum => datum.amount > 0 || datum.issued > 0);

      if (isGraphable) {
        // graph line path - loans issued
        svg.append('path')
          .datum(sortedLoans)
          .attr('fill', 'none')
          .attr('stroke', theme.brand.colorSecondary)
          .attr('stroke-width', STROKE_WIDTH)
          .attr('transform', `translate(${margin.left},${margin.top})`)
          .attr('d', d3.line<IChartDatum>()
            .x((d: IChartDatum) => x(getDate(d)))
            .y((d: IChartDatum) => leftY(getIssued(d)))
          );

        // graph line path - issuance amount
        svg.append('path')
          .datum(sortedLoans)
          .attr('fill', 'none')
          .attr('stroke', theme.colorSlate)
          .attr('stroke-width', STROKE_WIDTH)
          .attr('transform', `translate(${margin.left},${margin.top})`)
          .attr('d', d3.line<IChartDatum>()
            .x((d: IChartDatum) => x(getDate(d)))
            .y((d: IChartDatum) => rightY(getAmount(d)))
          );
      } else {
        // "no data"
        svg.append('text')
          .attr('fill', theme.colorSlate)
          .attr(
            'transform',
            `translate(
              ${(chartWidth / 2) + margin.left},
              ${(chartHeight / 2) + margin.top}
            )`
          )
          .style('text-anchor', 'middle')
          .style('font-size', '16pt')
          .text('No Data Available');
      }

      return root;
    },
    [data]
  );

  return (
    <>
      {fauxSvg.toReact()}
    </>
  );
};

export const IssuanceChart: React.FC<IIssuanceChartProps> = ({ data, ...rest }) => {

  const memoData = React.useMemo(() => data, [data]);
  const themeContext: DefaultTheme = React.useContext<DefaultTheme>(ThemeContext);

  return (
    <ChartCard>
      <ChartTitle scale='medium'>Issuance Over Time</ChartTitle>
      <IssuanceLineChart data={memoData} theme={themeContext} {...rest} />
    </ChartCard>
  );
};

export default IssuanceChart;
IssuanceChart.displayName = 'Issuance Chart';
