import { HELPDESK_PERMISSIONS, VIEW_ADMIN_HELPDESK, VIEW_HELPDESK } from './permissions';

export const CHANGE_REQUEST_ID = '10106';
export const CHANGE_REQUEST_DATA_KEY = 'changeRequestFieldValues';

export const changeRequestCustomFieldMap: Record<string, { label: string; editable: boolean; permission?: HELPDESK_PERMISSIONS }> = {
  customfield_10417: {
    label: 'Final Hours',
    editable: false,
  },
  customfield_10418: {
    label: 'Initial Estimated Hours Range',
    editable: false,
  },
  customfield_10421: {
    label: 'Cost',
    editable: false,
  },
  customfield_10427: {
    label: 'Target Delivery Date',
    editable: false,
  },
  customfield_10419: {
    label: 'Testing/Acceptance Support Hours',
    editable: false,
  },
  customfield_10420: {
    label: 'Development Hours',
    editable: false,
  },
  customfield_10425: {
    label: 'Amount Decision',
    editable: false,
  },
  customfield_10426: {
    label: 'Amount Signature',
    editable: false,
  },
  customfield_10422: {
    label: 'Documentation Updates',
    editable: true,
  },
  customfield_10423: {
    label: 'Partner Decision',
    editable: true,
    permission: VIEW_ADMIN_HELPDESK,
  },
  customfield_10424: {
    label: 'Partner Signature',
    editable: true,
    permission: VIEW_ADMIN_HELPDESK,
  },
};

export const getChangeRequestPermission = (id: string): HELPDESK_PERMISSIONS =>
  changeRequestCustomFieldMap[id]?.permission || VIEW_HELPDESK;
