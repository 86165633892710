/* tslint:disable */ import * as React from 'react';

const SvgIconlogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 87 32" {...props}>
    <title>{'logo-icon'}</title>
    <path
      fill="currentcolor"
      d="M32.062 13.333c.884 0 1.659.434 2.14 1.101l3.039-2.186.264-.202a6.716 6.716 0 00-5.442-2.791c-3.705 0-6.713 3.008-6.713 6.713s3.008 6.713 6.713 6.713a6.673 6.673 0 005.442-2.791l-.264-.202-3.039-2.171a2.628 2.628 0 01-2.14 1.101 2.65 2.65 0 01-2.651-2.651 2.647 2.647 0 012.651-2.636z"
    />
    <path
      fill="currentcolor"
      d="M17.147 9.628h4.248v12.698h-4.248V9.628z"
    />
    <path
      fill="currentcolor"
      d="M46.868 9.271c-3.705 0-6.713 3.008-6.713 6.713s3.008 6.713 6.713 6.713 6.713-3.008 6.713-6.713-3.008-6.713-6.713-6.713zm0 9.365a2.65 2.65 0 110-5.302 2.65 2.65 0 110 5.302z"
    />
    <path
      fill="currentcolor"
      d="M65.767 9.628v5.612l-4.512-5.612h-3.984v12.713h4.217v-5.876l4.713 5.876h3.783V9.628z"
    />
    <path
      fill="currentcolor"
      d="M10.403 0L0 32h76.713L87.116 0H10.403zm63.194 27.705H5.907l7.612-23.411h67.69l-7.612 23.411z"
    />
  </svg>
);

export default SvgIconlogo;
