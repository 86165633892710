import * as React from 'react';
import { VALID_HEADLINE_SCALE } from '@amount/frontend-components';
import { useMutation } from '@apollo/react-hooks';
import ErrorHandler from '@avant/crm-frontend-utils/error';

import EditRequestSummary, {
  EditRequestSummary as IEditRequestSummary,
  EditRequestSummaryInput,
} from '../../queries/editRequestSummary.graphql';
import { ErrorLogger } from '../../../../services/error';
import { MeContext } from '../../../Context';
import { SelectionOnMe } from '../../../Context/me.graphql';

import { updateGetOwnedRequestsCache, updateRequestDetailCache } from './requestSummaryCacheUpdate';
import { IRequestSummaryProps, RequestSummary } from './Presentational/RequestSummary';

export interface ILiftedRequestSummaryProps {
  isHelpdeskAdmin: boolean;
  summaryText: string;
  requestKey: string;
  requestId: string;
  scale: VALID_HEADLINE_SCALE;
}

const LiftedRequestSummary: React.FC<ILiftedRequestSummaryProps> = ({ isHelpdeskAdmin, summaryText, requestKey, requestId, scale }) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const [editedSummary, setEditedSummary] = React.useState(summaryText);
  const [editRequestSummary, { loading }] = useMutation<IEditRequestSummary, EditRequestSummaryInput>(EditRequestSummary);
  const context: SelectionOnMe = React.useContext(MeContext);

  const saveSummary: () => Promise<void> = async () => {
    setIsEditing(false);
    if (editedSummary === summaryText) {
      return;
    }

    try {
      await editRequestSummary({
        variables: {
          requestKey,
          summaryText: editedSummary
        }
      });
      updateGetOwnedRequestsCache(editedSummary, requestId);
      updateRequestDetailCache(editedSummary, context, requestKey);
    } catch (e) {
      ErrorHandler.notify('There was a problem updating the summary text.', 'Helpdesk', 'error');
      ErrorLogger.captureException(e);
      setEditedSummary(summaryText);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedSummary(e.currentTarget.value);
  };

  const onKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (e.key === 'Escape') {
      setEditedSummary(summaryText);
      setIsEditing(false);
    } else if (e.key === 'Enter') {
      await saveSummary();
    }
  };

  const enterEditMode = (): void => {
    if (!isEditing && isHelpdeskAdmin) {
      setIsEditing(true);
    }
  };

  const props: IRequestSummaryProps = {
    isHelpdeskAdmin,
    summaryText,
    scale,
    isEditing,
    editedSummary,
    loading,
    enterEditMode,
    saveSummary,
    onChange,
    onKeyDown
  };

  return <RequestSummary {...props} />;
};

export default LiftedRequestSummary;
