export const stringToFileDownload = (dataString: string, filename: string): void => {
  const revokeObjectURLTimeout: number = 100;
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const blob = new Blob([dataString], { type: 'text/plain' });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob);

    return;
  }

  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = filename;
  // Use dispatchEvent instead of '.click()' to be more browser compatible
  // Doesn't work on IE11
  try {
    const mouseEvent: MouseEvent = new MouseEvent('click', { bubbles: true, cancelable: true, view: window });
    link.dispatchEvent(mouseEvent);
  } catch (e) {
    // catch in IE
    link.click();
  }

  // For Firefox it is necessary to delay revoking the ObjectURL
  setTimeout(
    () => {
      window.URL.revokeObjectURL(downloadUrl);
    },
    revokeObjectURLTimeout
  );
};
