import * as React from 'react';

import { MeContext } from './Context';

interface IPermissionProps {
  allowedPermissions?: string[];
  // tslint:disable-next-line: no-any
  NoAccessComponent?: React.FC<any> | React.ComponentClass<any, any>;
}

const PermissionedComponent: React.FC<IPermissionProps> =
({ NoAccessComponent, children, allowedPermissions }) => {
  const me = React.useContext(MeContext);

  const mePermissions = new Set(me.permissions);
  const hasPermission = !allowedPermissions || allowedPermissions.some(p => mePermissions.has(p));

  if (hasPermission) {
    return !!children ? <>{children}</> : null;
  } else {
    return !!NoAccessComponent ? <NoAccessComponent /> : null;
  }
};

export { PermissionedComponent };
