import * as React from 'react';
import * as d3 from 'd3';
import * as withFauxDom from 'react-faux-dom';
import { DefaultTheme, ThemeContext } from 'styled-components';

import { Chart, ChartCard, ChartTitle, IChartMargin } from './ChartCommon';

export interface IBarChartData {
  branchId: string;
  count: number;
}
export interface IBarData {
  data: IBarChartData[];
  width?: number;
  height?: number;
}

const BranchBarChart: React.FC<IBarData> = ({ data: unsortedData, width, height }) => {
  const themeContext: DefaultTheme = React.useContext<DefaultTheme>(ThemeContext);
  const margin: IChartMargin = { top: 20, right: 30, bottom: 30, left: 80 };
  const data = unsortedData
    .sort((a, b) => b.count - a.count)
    .slice(0, 10)
    .reverse();

  // tslint:disable: no-magic-numbers
  const chartWidth: number = width || 800;
  const chartHeight: number = height || 300;
  // tslint:enable: no-magic-numbers
  const padding: number = 0.1;

  const BarChart: withFauxDom.Element = React.useMemo(
    () => {

      // FAUX_DOM api's allows us to build React DOM tree
      // even with D3 DOM manipulations and also gives us
      // the ability to capture proper snapshots in testing
      const chart = withFauxDom.createElement('svg');
      const svg = d3.select(chart)
        .attr('viewBox', `0 0 ${chartWidth + margin.left + margin.right} ${chartHeight + margin.top + margin.bottom}`);

      const max: number = d3.max(data.map((d: IBarChartData) => d.count)) || 0;

      const x = d3.scaleLinear().range([0, chartWidth]).domain([0, max]);
      const y = d3.scaleBand().range([chartHeight, 0]).domain(data.map(d => d.branchId)).padding(padding);
      const g = svg.append('g')
        .attr('transform', `translate(${margin.left - margin.right}, ${margin.top})`);

      const ticks: number = max > 10 ? (max / 10) : max;

      // Generates X-axis to the Chart
      g.append('g')
        .attr('transform', `translate(0, ${chartHeight})`)
        .call(d3.axisBottom(x).ticks(ticks).tickSizeInner(-chartHeight));

      // Adds Label to X-axis
      svg.append('text')
        .attr(
          'transform',
          `translate(${(chartWidth / 2) + margin.left - margin.right},${(chartHeight + margin.top + margin.bottom)})`
        )
        .style('text-anchor', 'middle')
        .attr('fill', themeContext.colorBlack)
        .text('Loans Issued');

      // Generates Y-axis to the chart
      g.append('g')
        .call(d3.axisLeft(y));

      // Adds Label to Y-axis
      svg.append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 0)
        .attr('x', 0 - ((chartHeight + margin.top + margin.bottom) / 2))
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .attr('fill', themeContext.colorBlack)
        .text('Branch');

      // we don't want to graph any bars if there's no data to graph
      const isGraphable = max > 0;

      if (isGraphable) {
        // Generates the rectangular bar for each branch
        // based on the count
        g.selectAll('.bar')
          .data(data)
          .enter()
          .append('rect')
          .attr('x', 0)
          .attr('height', y.bandwidth())
          .attr('y', d => y(d.branchId) || 0)
          .attr('width', d => x(d.count))
          .attr('fill', themeContext.colorSlate);
      } else {
        // "no data"
        svg.append('text')
          .attr('fill', themeContext.colorSlate)
          .attr(
            'transform',
            `translate(
            ${(chartWidth / 2) + margin.left - margin.right},
            ${(chartHeight / 2) + margin.top}
          )`
          )
          .style('text-anchor', 'middle')
          .style('font-size', '16pt')
          .text('No Data Available');
      }

      return chart;
    },
    [data]
  );

  return (
    <ChartCard>
      <ChartTitle scale='medium'>Top Branches</ChartTitle>
      <Chart>
        {BarChart.toReact()}
      </Chart>
    </ChartCard>
  );
};

export default BranchBarChart;
BranchBarChart.displayName = 'BranchBarChart';
