import ErrorHandler from '@avant/crm-frontend-utils/error';
import Events from '@avant/crm-frontend-utils/events';
import {
  ActionButton,
  ConditionalRenderWrapper,
  SVGIcon,
} from '@amount/frontend-components';
import styled from 'styled-components';
import { ApolloError, NetworkStatus } from 'apollo-client';
import partition from 'lodash-es/partition';
import * as React from 'react';
import { DropzoneRef } from 'react-dropzone';

import DropzoneComponent from '../../Dropzone';
import { ErrorLogger } from '../../../services/error';
import {
  DASHBOARDS_PATH,
  INDIVIDUAL_REPORTING_PATH,
  PARTNER_CHANGED_EVENT,
  STORE_REPORTING_PATH
} from '../../../../shared/config/constants';
import { 
  S3_TO_EDIT_FILE_PERMISSIONS,
  FolderType,
  PERSONAL_LOAN,
  POINT_OF_SALE,
  STANDARD,
  SERVICING_ORG
} from '../../../../shared/config/permissions';
import { FOLDER_KEY, isLookerDashboard } from '../../../../shared/reports/constants';
import { SelectionOnMe } from '../../Context/me.graphql';
import { InnerContainer } from '../../InnerContainer';
import { LiftedPusherProps } from '../../HOC/withPusher';
import { Spinner } from '../../Spinner';
import UploadButton from '../../UploadButton';
import { FEATURE_FLAGS } from '../../../../shared/config/featureFlag';
import { ButtonLabel } from '../../CommonComponents';

import Breadcrumbs from './Breadcrumbs';
import {
  EmptyRow,
  FileListContain,
  ILiftedProps,
  isValidFileName,
  restoreSlashes,
} from './common';
import FileList from './FileList';
import { CreateModal } from './Modals';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FileActions = styled.div`
  display: flex;
  align-items: right;
  white-space: nowrap;

  ${ActionButton} {
    margin-left: 0.75em;
  }
`;

const ReportSpinner = styled(Spinner)`
  margin-top: 3em;
`;

const DisplayError: React.FC<{ error: ApolloError | undefined }> = ({ error }) => (
  <>
    {error ? (
      <FileListContain>
        <EmptyRow>
          <span>{error.message.replace('GraphQL error:', '')}</span>
        </EmptyRow>
      </FileListContain>
    ) : (
      <FileListContain>
        <EmptyRow>
          <span>Failed to retrieve file list.</span>
        </EmptyRow>
      </FileListContain>
    )}
  </>
);

// tslint:disable-next-line: no-reserved-keywords
const canUserEdit: (me: SelectionOnMe, type: string, folderType: FolderType) => boolean = ({ permissions }, type, folderType) =>
  permissions.includes(S3_TO_EDIT_FILE_PERMISSIONS[type][folderType]);
const cloneFormDataAndSetReport: (fd: FormData, currentFile: File) => FormData = (fd, currentFile) => {
  const fdClone: FormData = new FormData();
  [...fd.entries()].forEach(([key, value]) => fdClone.append(key, value));
  fdClone.set('report', currentFile);

  return fdClone;
};

interface IFileListWrapperState {
  uploadingFile: boolean;
  showCreateModal: boolean;
}
export class FileListWrapper extends React.PureComponent<LiftedPusherProps<ILiftedProps>, IFileListWrapperState> {
  public state: IFileListWrapperState = {
    uploadingFile: false,
    showCreateModal: false,
  };

  private readonly _dropZoneRef = React.createRef<DropzoneRef>();

  public componentDidMount (): void {
    Events.subscribe(PARTNER_CHANGED_EVENT, this.redirectAndRefetch);
  }

  public componentWillUnmount (): void {
    Events.unsubscribe(PARTNER_CHANGED_EVENT, this.redirectAndRefetch);
  }

  // tslint:disable-next-line: max-func-body-length
  // eslint-disable-next-line complexity
  public render (): JSX.Element | null {
    const { currentPartner } = this.props.me;

    if (!currentPartner) { return null; }

    const path: string = (this.props.match.params.path || '');
    const pathNoRoot: string = restoreSlashes(path);
    const isRootFolder: boolean = this.props.type === '__reports__' && !pathNoRoot;
    const loading: boolean = this.props.fileQuery.loading || this.props.fileQuery.networkStatus === NetworkStatus.loading;
    const isDashboardsFolder: boolean = pathNoRoot.endsWith(DASHBOARDS_PATH);

    const canEdit: boolean = canUserEdit(this.props.me, this.props.type, this.getFolderType(pathNoRoot.split('/')));

    const restricted: boolean = !!this.props.fileQuery.data &&
      this.props.fileQuery.data.reports &&
      this.props.fileQuery.data.reports.restricted;

    const InBranchPaths = [INDIVIDUAL_REPORTING_PATH, STORE_REPORTING_PATH];
    const isVisualizationFolder: boolean = !!FEATURE_FLAGS.portalInBranch && !!currentPartner && InBranchPaths.includes(pathNoRoot);

    const displayFileActions: boolean = !loading && !!this.props.fileQuery.data && canEdit
      && !isDashboardsFolder && !isVisualizationFolder && !isRootFolder;

    return (
      <InnerContainer>
        <Header>
          <Breadcrumbs
            pathNoRoot={pathNoRoot}
            root={this.props.root}
            restricted={restricted}
          />
          {displayFileActions && (
            <ConditionalRenderWrapper breakpoint='small' hiddenOnMobile={true}>
              <FileActions>
                <UploadButton
                  {...this.props}
                  uploadFile={this.fileUpload}
                  uploadingFile={this.state.uploadingFile}
                />
                <ActionButton
                  onClick={this.showModal}
                  data-event='createFolder'
                >
                  <SVGIcon icon='new-folder' />
                  <ButtonLabel>Create Folder</ButtonLabel>
                </ActionButton>
              </FileActions>
            </ConditionalRenderWrapper>
          )}
        </Header>
        {loading ? (
          <ReportSpinner />
        ) : (
          <>
            {!this.props.fileQuery.data || this.props.fileQuery.error ? (
              <DisplayError error={this.props.fileQuery.error} />
            ) : (
              <DropzoneComponent
                passRef={this._dropZoneRef}
                onDrop={this.dropFilesEventHandler}
                canEdit={displayFileActions}
              >
                <FileList
                  {...this.props}
                  canEdit={displayFileActions}
                  currentPartner={currentPartner}
                  isDashboardsFolder={isDashboardsFolder}
                  isVisualizationFolder={isVisualizationFolder}
                  fileQuery={{
                    ...this.props.fileQuery,
                    data: this.props.fileQuery.data
                  }}
                  fileUpload={this.fileUpload}
                  pathNoRoot={pathNoRoot}
                  isRootFolder={isRootFolder}
                  uploadingFile={this.state.uploadingFile}
                  showCreateModal={this.showModal}
                  parentRestricted={restricted}
                />
              </DropzoneComponent>
            )}
          </>
        )}
        {displayFileActions && (
          <CreateModal
            show={this.state.showCreateModal}
            restricted={restricted}
            close={this.hideModal}
            kind={FOLDER_KEY}
            prefix={pathNoRoot}
            type={this.props.type}
            refetch={this.refetch}
            mutation={this.props.newFolder}
          />
        )}
        {displayFileActions && (
          <CreateModal
            show={this.state.showCreateModal}
            restricted={restricted}
            close={this.hideModal}
            kind={FOLDER_KEY}
            prefix={pathNoRoot}
            type={this.props.type}
            refetch={this.refetch}
            mutation={this.props.newFolder}
          />
        )}
      </InnerContainer>
    );
  }

  private readonly getFolderType: (splitPath: string[]) => FolderType = splitPath => {
    const isPointOfSaleFolder: boolean = splitPath.includes(POINT_OF_SALE);
    const isPersonalLoanFolder: boolean = splitPath.includes(PERSONAL_LOAN);
    const isServicingOrgFolder: boolean = splitPath.includes(SERVICING_ORG);

    if (isPointOfSaleFolder) {
      return POINT_OF_SALE;
    } else if (isPersonalLoanFolder) {
      return PERSONAL_LOAN;
    } else if (isServicingOrgFolder) {
      return SERVICING_ORG;
    } else {
      return STANDARD;
    }
  }

  private readonly showModal: () => void = () => {
    this.setState({ showCreateModal: true });
  };

  private readonly hideModal: () => void = () => {
    this.setState({ showCreateModal: false });
  };

  private readonly fileUpload: () => void = () => {
    if (!this._dropZoneRef.current) {
      ErrorHandler.notify('Failed to Upload', 'File Upload', 'error');
      ErrorLogger.captureException('Missing dropzone ref.');

      return;
    }

    this._dropZoneRef.current.open();
  };

  private readonly redirectAndRefetch: () => void = () => {
    const urlNoSlashes: string = this.props.match.url.replace(/\//g, '');
    const rootUrl: string = this.props.root;

    if (this.props.root !== urlNoSlashes && rootUrl !== urlNoSlashes) {
      this.props.history.push(`/${this.props.root}`);

      return;
    }

    this.refetch();
  };

  private readonly refetch: () => void = () => {
    this.props.fileQuery.refetch();
  };

  private readonly dropFilesEventHandler = async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      this.setState({ uploadingFile: false });

      return;
    }
    /*
    * Folders currently not supported. See https://github.com/react-dropzone/react-dropzone/pull/616
    * Folders currently have type === ''
    * Doing this also prevents users from dropping files that do not have a basic MIME type
    * or files without a file extension
    * This spilts files array into two arrays of valid and invalid files
    */
    const [validFiles, invalidFiles] = partition(
      acceptedFiles,
      file => (file.type !== '' || isLookerDashboard(file.name)) && isValidFileName(file.name));

    const fd: FormData = new FormData();
    fd.append('path', restoreSlashes(this.props.match.params.path));
    fd.append('type', this.props.type);
    this.setState({ uploadingFile: true });

    const promiseArr: Array<Promise<void | Response>> = validFiles.map(currentFile =>
      fetch(this.props.uploadPath, {
        credentials: 'same-origin',
        method: 'POST',
        body: cloneFormDataAndSetReport(fd, currentFile),
      })
        .then(res => {
          if (!res.ok) { throw res; }

          return res;
        })
        .catch(err => {
          ErrorLogger.captureException(err);
          ErrorHandler.notify(`Failed to upload file: ${currentFile.name}`, 'File Upload', 'error');
        })
    );

    // Wait for files to upload
    const uploads = await Promise.all(promiseArr);
    this.props.fileQuery.refetch();
    this.setState({ uploadingFile: false });

    const successfulUploads: Array<void | Response> = uploads.filter(upload => upload && upload.ok);
    if (successfulUploads.length) {
      ErrorHandler.notify(`Successfully uploaded ${validFiles.length} file(s)`, 'File Upload', 'success');
    }
    if (invalidFiles.length) {
      const message: string = `The following file(s) are not supported or have invalid names: ${invalidFiles.map(f => f.name).join(', ')}`;
      ErrorHandler.notify(message, 'File Upload', 'error');
    }
  };
}

export default FileListWrapper;
