import * as React from 'react';
import styled from 'styled-components';

import {
  AMOUNT_PERMISSION_KEY,
  AVANT_PERMISSION_KEY,
  BARCLAYS_PERMISSION_KEY,
  BBVA_PERMISSION_KEY,
  BPO_PERMISSION_KEY,
  CAPITAL_ONE_PERMISSION_KEY,
  DEMO_BANK_PERMISSION_KEY,
  ELOAN_PERMISSION_KEY,
  GUARANTEED_RATE_PERMISSION_KEY,
  HSBC_PERMISSION_KEY,
  JOMASHOP_PERMISSION_KEY,
  ICON_PERMISSION_KEY,
  PNC_PERMISSION_KEY,
  POLLEN_STREET_PERMISSION_KEY,
  REGIONS_PERMISSION_KEY,
  TD_PERMISSION_KEY,
  TELEPERFORMANCE_PERMISSION_KEY,
  JENIUS_PERMISSION_KEY,
  RADIUS_PERMISSION_KEY,
} from '../../../shared/config/permissions';
import {
  SwitchablePartner
} from '../../../shared/config/partners';

import IconDemoBankLogo from './IconDemoBankLogo';
import IconEloanLogo from './IconEloanLogo';
import IconAmountLogoHorizontal from './IconAmountLogoHorizontal';
import IconRegionsLogo from './IconRegionsLogo';
import IconTdLogo from './IconTdLogo';
import IconHsbcLogo from './IconHsbcLogo';
import IconPncLogo from './IconPncLogo';
import IconAvantLogo from './IconAvantLogo';
import IconPollenStreet from './IconPollenStreet';
import IconIconLogo from './IconIconLogo';
import IconBPOLogo from './IconBPOLogo';
import IconBbvaLogo from './IconBbvaLogo';
import IconCapitalOne from './IconCapitalOne';
import IconBarclays from './IconBarclaysLogo';
import IconGuaranteedRate from './IconGuaranteedRate';
// Replace logo once you get the logo
import IconJomashop from './IconJomashopLogo';
import IconTeleperformance from './IconTeleperformanceLogo';
import IconJenius from './IconJeniusLogo';
import IconAmountLogo from './IconAmountLogo';


const LogoWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > svg {
    height: 2.4rem;
  }

  ${({ theme }) => theme.name === 'amountDark' && `
    & > svg {
      --color-1: #fff;
    }
  `}
`;

type VALID_LOGOS = SwitchablePartner | typeof BPO_PERMISSION_KEY | typeof TELEPERFORMANCE_PERMISSION_KEY | typeof RADIUS_PERMISSION_KEY;

export const tenantLogoMap: { [ x in VALID_LOGOS]: JSX.Element } = {
  [AMOUNT_PERMISSION_KEY]: <IconAmountLogoHorizontal />,
  [AVANT_PERMISSION_KEY]: <IconAvantLogo />,
  [DEMO_BANK_PERMISSION_KEY]: <IconDemoBankLogo />,
  [ELOAN_PERMISSION_KEY]: <IconEloanLogo />,
  [HSBC_PERMISSION_KEY]: <IconHsbcLogo />,
  [PNC_PERMISSION_KEY]: <IconPncLogo />,
  [POLLEN_STREET_PERMISSION_KEY]: <IconPollenStreet />,
  [REGIONS_PERMISSION_KEY]: <IconRegionsLogo />,
  [TD_PERMISSION_KEY]: <IconTdLogo />,
  [ICON_PERMISSION_KEY]: <IconIconLogo />,
  [BPO_PERMISSION_KEY]: <IconBPOLogo />,
  [BBVA_PERMISSION_KEY]: <IconBbvaLogo />,
  [CAPITAL_ONE_PERMISSION_KEY]: <IconCapitalOne />,
  [BARCLAYS_PERMISSION_KEY]: <IconBarclays />,
  [GUARANTEED_RATE_PERMISSION_KEY]: <IconGuaranteedRate />,
  [JOMASHOP_PERMISSION_KEY]: <IconJomashop />,
  [TELEPERFORMANCE_PERMISSION_KEY]: <IconTeleperformance />,
  [RADIUS_PERMISSION_KEY]: <IconAmountLogo />,
  [JENIUS_PERMISSION_KEY]: <IconJenius />,
};

export interface ICurrentLogo {
  partner: string | null;
}

export const CurrentLogo: React.FC<ICurrentLogo> = ({ partner, ...rest }) => (
  <LogoWrap {...rest}>
    {!!partner ? tenantLogoMap[partner] : <IconAmountLogoHorizontal />}
  </LogoWrap>
);
