import * as React from 'react';
import { SVGIcon } from '@amount/frontend-components';
import styled from 'styled-components';

const Anchor = styled.a`
  display: flex;
  text-decoration: underline;
  align-items: center;
  margin-bottom: .5em;
`;

const Icon = styled(SVGIcon)`
  margin-right: .5em;

  & svg {
    width: 24px;
    display: block;
  }
`;

// really long, unbroken filenames would tend to overflow the container,
// so we add some wrapping/line-breaking behavior to force it into its box.
// see https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
const Name = styled.span`
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  word-break: break-word;
`;

interface IProps {
  name: string;
  url: string | null;
}

const FileLink: React.FC<IProps> = ({ name, url }) => (
  <Anchor href={`${url}` || ''}>
    <Icon icon='file' />
    <Name>{name}</Name>
  </Anchor>
);

export default FileLink;
