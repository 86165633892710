import {
  AVANT_HELPDESK,
} from '../../../shared/config/permissions';

import {
  IConfig,
  SectionTitleType
} from './Common';

export const Section: { [key in SectionTitleType]: string } = {
  InformationTechnology: 'information-technology',
  ProductionEngineering: 'production-engineering',
  Facility: 'facility',
  ServiceIncident: 'service-incident',
  SiteReliabilityEngineering: 'site-reliability-engineering',
  OnboardingOffboarding: 'onboarding-offboarding',
  TrainingOperations: 'training-operations',
  PartnerAccessRequest: 'access-request',
  SingleCustomerIncident: 'single-customer-incident',
  AmountChangeRequest: 'change-request',
  AmountBugReport: 'bug-report',
  AmountGeneralInquiry: 'general-inquiry'
};

export const Regions: string[] = [
  'Amount',
  'Canada',
  'Credit Card API',
  'eLoan',
  'FDR Gateway',
  'HSBC',
  'Payment Gateway',
  'Pollen Street Capital (UK)',
  'Regions',
  'TD',
  'US',
  'HSBC',
  'PNC',
  'BBVA',
  'Barclays',
  'Capital_one',
  'Guaranteed Rate',
];

export const Priorities: string[] = [
  'Level 1 - Critical',
  'Level 2 - High',
  'Level 3 - Normal',
  'Level 4 - Low'
];

export const Products: string[] = [
  'AvantCard',
  'Avant-Loan',
  'Eloan',
  'TD Fit',
  'Regions',
  'HSBC',
  'PNC',
  'BBVA',
  'Barclays',
  'Capital One',
  'Guaranteed Rate',
];

export const Departments: string[] = [
  'Servicing (CUS) - SERV',
  'Servicing (PAY) - SERV',
  'Originations - ORIG',
  'Quality Assurance',
  'Other'
];

export const Locations: string[] = [
  'Chicago - CHI',
  'Houston - (Alltran)',
  'Knoxville - KX',
  'Manila - MLA',
  'Montego Bay - MoBay'
];

// Use https://gist.github.com/gabelerner/28b395420783a23a1b1db46de6bd4347 to parse google doc as CSV
/* tslint:disable:object-literal-key-quotes max-line-length */
export const Config: IConfig = {
  [Section.InformationTechnology]: {
    id: '84812',
    name: 'IT Support',
    permissions: [AVANT_HELPDESK],
    helpdeskSubtitle: 'Technical Requests',
    iconType: 'settings',
    title: 'IT Requests',
    subtitle: 'IT Projects and Support',
    categories: {
      '325532': {
        name: 'Accounts - New Access & Permission Requests',
        titles: {
          'Acqueon': null,
          'Asana - Archive': "PLEASE NOTE: \nYou'll need a LastPass account to receive creds for the archive account. \n\nInstructions can be found here: \nhttps://avantinc.atlassian.net/wiki/spaces/IKB/pages/70396306/LastPass+-+Joining+Avant+s+Corporate+Account \n\nWhat projects do you require access to?   ",
          'Avant University': null,
          'AWS': 'Please supply us with any security groups, buckets, and any other general information on what access you need. ',
          'Buildkite': null,
          'Browserstack': null,
          'Calabrio': null,
          'CodeClimate': 'Github Handle: \nCodeClimate group (if known): \nRepos you need access to: ',
          'Confluence': 'Please supply us with information on any groups you may need to be in. ',
          'Credit Bureaus': null,
          'CSP/CRM, Partner Portal, LockBox, Template Flow, etc.': 'App Name: ',
          'Data Dog': null,
          'Data Robot': null,
          'Data Service Groups': null,
          'Finesse': null,
          'First Data Service Center': 'Please include your contact number for the account creation.',
          'First Data View Balance': null,
          'Garden': null,
          'Gemfury': null,
          'Github - Add to Group': 'Github Organization: AvantFinCo / Amount(avantcredit)\nPerson (Github Handle) to add: \nGroup Name: ',
          'Github - Add to Repo': 'Github Organization: Amount(avantcredit)\nGroup to add: \nPermission: ',
          'Github - Add to Repo - Avant': 'Github Organization: AvantFinCo\nGroup / Person (Github Handle) to remove: \nPermission: ',
          'Github - Archive Repo': 'Github Organization: AvantFinCo / Amount(avantcredit)\nRepo to be archived: \n\n*Please note that IT will be the only group to maintain access to the repo.',
          'Github - Create Group': 'Github Organization: AvantFinCo / Amount(avantcredit)\nNew Group Name: \nPlease provede a comma separate list of repos and permissions for this group (i.e. avant-basic, read)\n Group Members: ',
          'Github - Create New User': 'Github Organization: AvantFinCo / Amount(avantcredit)\nWork email: \nUser Github Handle: \nDepartment: \nTeam: \nIs the user responsible for maintaining repos: Yes / No\n\n*Ensure that the user has added their work email (entered above) to their Github profile. If they do not, they will be unable to authenicate through SSO, and subsequently removed.',
          'Github - Create New Repo': 'Github Organization: AvantFinCo / Amount(avantcredit)\nNew Repo name: \nGroups that need Read access: \nGroups that need Write access: \nGroups that will maintain the repo: \nIntegrations/app that need access to repo(i.e. Buildkite / CodeClimate)',
          'Github - New Access for Existing user': 'Github Handle: \nRepos and groups you need to be in: \nDo you want read or write permissions?',
          'Github - Permission change on Repo': 'Github Organization: AvantFinCo / Amount(avantcredit)\nGroup / Person (Github Handle) to change: \n\nPlease proved a comma separate list of repos and permissions for this group (i.e. avant-basic,read)',
          'Github - Remove from Group': 'Github Organization: AvantFinCo / Amount(avantcredit)\nPerson (Github Handle) to add: \nGroup Name: ',
          'Github - Remove Group': 'Github Organization: AvantFinCo / Amount(avantcredit)\nGroup Name: \n\n*Please ote that the members of this group will be notified and given a chance to respond before the group is removed.',
          'Github - Remove from Repo': 'Github Organization: Amount(avantcredit)\nGroup to remove: ',
          'Github - Remove from Repo - Avant': 'Github Organization: AvantFinCo\nGroup / Person (Github Handle) to remove: ',
          'Github - Remove User': 'Github Organization: AvantFinCo / Amount(avantcredit)\nWork email: \nUser Github Handle: \nDepartment: \nTeam: \nIs the user responsible for maintaining repos: Yes / No\n\n*Ensure that the user has added their work email (entered above) to their Github profile. If they do not, they will be unable to authenicate through SSO, and subsequently removed.',
          'Google Suite': null,
          'Greenscreen': null,
          'Heap': null,
          'Helpscout': null,
          'Hipchat - archive': null,
          'Invision': 'What permission level do you require? \n\nPLEASE NOTE: Approval of the design department is required.',
          'Jenkins': null,
          'Jira - Access and Permissions': 'Request: \n\nSee IT JIRA docs for more info: https://avantinc.atlassian.net/wiki/spaces/IKB/pages/70396317/Jira',
          'Jira - Create New Project': "Project Name: \nPreferred 3-6 letter Project Key: \nSelect Schema Option: \nYour Project's 'Project Owners': \n\nSee IT JIRA docs for more info: https://avantinc.atlassian.net/wiki/spaces/IKB/pages/70396317/Jira",
          'Jitbit': null,
          'LastPass': null,
          'Looker': null,
          'Mailgun': null,
          'Office 365': null,
          'PagerDuty': null,
          'Pingdom': null,
          'Pivotal - Archive': "PLEASE NOTE: \nYou'll need a LastPass account to receive creds for the archive account. \n\nInstructions can be found here: \nhttps://avantinc.atlassian.net/wiki/spaces/IKB/pages/70396306/LastPass+-+Joining+Avant+s+Corporate+Account \n\nWhat projects do you require access to?   ",
          'Production Console': 'Please give us details on which Prod console you need, how long you need it, and why you need it. \nPlease Note: For elevated or production consoles, IT requires approval from your manager, as well as Mike Esler or Michael Air. \nMike Esler and Michael Air have been emailed, but to expedite the request you should send this ticket to your manager.   ',
          'Rownet': null,
          'RubyMine': null,
          'Sentry': null,
          'ServiceNow - Archive': 'Please provide as much detail about the information you require from ServiceNow. \n\nWhat is this information being used for and what conclusions are you trying to show with this data? \n\nThe more information you provide, the better we will be able to build the needed query. \nIt is possible that a face-to-face meeting will be required in order to clarify and confirm the data desired.',
          'Sidekiq': null,
          'Slack': null,
          'SumoLogic': null,
          'Vault': 'Please give us details on what vault access you need, how long you need it, and why you need it. \nPlease Note: For prod access IT requires approval from your manager and Mike Esler. \nMike Esler has been emailed but to expedite the request you should send this ticket to your manager.   ',
          'VPN': null,
          'VPN - Credit Card': null,
          'Zendesk - archive': null,
        }
      },
      '325533': {
        name: 'Accounts - Issues & Password Resets',
        titles: {
          'Acqueon': null,
          'Active Directory': null,
          'Avant University': null,
          'AWS': null,
          'Buildkite': null,
          'Browserstack': null,
          'Calabrio': null,
          'Centrify': null,
          'Confluence': null,
          'Credit Bureaus': null,
          'CSP - Portal, Partners, LockBox, Template Flow, etc': 'App Name: ',
          'Data Dog': null,
          'Finesse': null,
          'First Data Service Center': null,
          'First Data View Balance': null,
          'Garden': null,
          'Gemfury': null,
          'Github': 'Github Handle: \nIssue: \nDo you want read or write permissions?',
          'Google Suite': null,
          'Greenscreen': null,
          'Heap': null,
          'Helpscout': null,
          'Jenkins': null,
          'Jira - Access and Permissions': null,
          'Jitbit': null,
          'LastPass': null,
          'Looker': null,
          'Mailgun': null,
          'Office 365': null,
          'PagerDuty': null,
          'Pingdom': null,
          'Production Console': null,
          'Rownet': null,
          'RubyMine': null,
          'Sentry': null,
          'Sidekiq': null,
          'Slack': null,
          'SumoLogic': null,
          'Vault': null,
          'VPN': null,
          'VPN - Credit Card': null
        }
      },
      '337331': {
        name: 'Followers, Forks, DB Creds',
        titles: {
          'Followers': 'Region: \nAccess level: ',
          'Forks': 'Region: \nAccess level: ',
          'DB Creds': 'Region: \nAccess level: '
        }
      },
      '325534': {
        name: 'Personal Hardware - Issues & Requests',
        titles: {
          'Computer': 'What kind of computer do you need / have:  \nIssue / Request: ',
          'Loaner': 'What kind of computer do you need / have:  \nIssue / Request: ',
          'Computer Accessories': 'What kind of computer accessories do you need / have:  \nIssue / Request: ',
          'Monitors': 'Issue / Request: ',
          'Phones': 'Issue / Request: ',
          'Phones - RONA': 'Issue / Request: ',
          'External USB': 'If you need access to an external USB or Harddrive, you will need approval from Mike Esler and the IT Lead. \nPlease provide details on what the use case for this device will be and when the equipment will be returned. '
        }
      },
      '325535': {
        name: 'Office Equipment & Badges',
        titles: {
          'Meeting Room Calendar Booking': null,
          'North Lasalle - Printer': 'Issue / Request: ',
          'Hawks Landing - Printer': 'Issue / Request: ',
          'North Lasalle - Meeting Room Equipment': 'Meeting Room: \nEquipment: ',
          'Hawks Landing - Meeting Room Equipment': 'Meeting Room: \nEquipment: ',
          'North Lasalle - Atrium Presentation Setup': 'Issue / Request: ',
          'Hawks Landing - Badge': 'Please attach or respond to the email you receive with a photograph to expedite your request. ',
          'North Lasalle - Badge': 'Please attach or respond to the email you receive with a photograph to expedite your request. ',
          'North Lasalle - Office TVs - Display Media': null,
          'Hawks Landing - Office TVs - Display Media': null
        }
      },
      '325538': {
        name: 'File Sharing Management',
        titles: {
          'Create New File Sharing Setup': null,
          'Update Existing File Sharing Setup': null
        }
      }
    }
  },
  [Section.Facility]: {
    id: '86594',
    name: 'Facilities',
    permissions: [AVANT_HELPDESK],
    helpdeskSubtitle: 'Business Requests and Inquiries',
    iconType: 'helpdesk',
    title: 'Facilities',
    subtitle: 'Mail services, guest registration, kitchen or bathroom issues',
    categories: {
      '331241': {
        name: 'North Lasalle - Office Facilities',
        titles: {
          'Cleaning Needed': 'Floor: \nArea: \nDescription:',
          'Electrical Problem': 'Floor: \nArea: \nDescription:',
          'Furniture': 'Floor: \nArea: \nDescription:',
          'Lighting': 'Floor: \nArea: \nDescription:',
          'Temperature Maintenance': 'Floor: \nArea: \nDescription:',
          'Pest Control': 'Floor: \nArea: \nDescription:',
          'Plumbing': 'Floor: \nArea: \nDescription:',
          'Trash Removal': 'Floor: \nArea: \nDescription:',
          'USPS/Certified Mail': 'Is this mail being sent certified?: [Y/N]\nCompany:\nName:\nAddress Line 1:\nAddress Line 2:\nCity:\nState:\nZip Code:',
          'FedEx': 'Package Type: [Documentation, Small box, Large box]\nNumber of Packages:\nEstimated Weight per Package(for boxed packages):\nCompany:\nName:\nAddress Line 1:\nAddress Line 2:\nCity:\nState:\nZip Code:\nPhone Number:\nSignature Required: [Y/N]\nService: [Standard 4-5 Days, 2 Day, Overnight]\nAdditional Notes:',
          'Guest Registration': 'Guest(s) First and Last Name(s):\nDate of Arrival:\nArrival Time:\nDate of Departure (for multiple day visits):\nWho to Contact Upon Arrival: \nHow to Contact Upon Arrival: GChat, Slack, Other\nAlternative Person to Contact: \nMeeting Room / Location: \nReason for Visit: [visiting company, interview, friends/family, food/catering, Delivery (outside of traditional mail), other] \nAdditional Notes: \n'
        }
      },
      '331242': {
        name: 'Hawks Landing - Office Facilities',
        titles: {
          'Cleaning Needed': 'Area: \nDescription:',
          'Electrical Problem': 'Area: \nDescription:',
          'Furniture': 'Area: \nDescription:',
          'Lighting': 'Area: \nDescription:',
          'Temperature Maintenance': 'Area: \nDescription:',
          'Pest Control': 'Area: \nDescription:',
          'Plumbing': 'Area: \nDescription:',
          'Trash Removal': 'Area: \nDescription:',
          'USPS/Certified Mail': 'Is this mail being sent certified?: [Y/N]\nCompany:\nName:\nAddress Line 1:\nAddress Line 2:\nCity:\nState:\nZip Code:',
          'FedEx': 'Package Type: [Documentation, Small box, Large box]\nNumber of Packages:\nEstimated Weight per Package(for boxed packages):\nCompany:\nName:\nAddress Line 1:\nAddress Line 2:\nCity:\nState:\nZip Code:\nPhone Number:\nSignature Required: [Y/N]\nService: [Standard 4-5 Days, 2 Day, Overnight]',
          'Guest Registration': 'Guest(s) First and Last Name(s):\nDate of Arrival:\nArrival Time:\nDate of Departure (for multiple day visits):\nWho to Contact Upon Arrival: \nHow to Contact Upon Arrival: GChat, Hipchat, Other\nAlternative Person to Contact: \nMeeting Room / Location: \nReason for Visit: [visiting company, interview, friends/family, food/catering, Delivery (outside of traditional mail), other] \nAdditional Notes: \n'
        }
      },
      '394708': {
        name: 'Oak Ridge - Office Facilities',
        titles: {
          'Cleaning Needed': 'Area: \nDescription:',
          'Electrical Problem': 'Area: \nDescription:',
          'Furniture': 'Area: \nDescription:',
          'Lighting': 'Area: \nDescription:',
          'Temperature Maintenance': 'Area: \nDescription:',
          'Pest Control': 'Area: \nDescription:',
          'Plumbing': 'Area: \nDescription:',
          'Trash Removal': 'Area: \nDescription:',
          'USPS/Certified Mail': 'Is this mail being sent certified?: [Y/N]\nCompany:\nName:\nAddress Line 1:\nAddress Line 2:\nCity:\nState:\nZip Code:',
          'FedEx': 'Package Type: [Documentation, Small box, Large box]\nNumber of Packages:\nEstimated Weight per Package(for boxed packages):\nCompany:\nName:\nAddress Line 1:\nAddress Line 2:\nCity:\nState:\nZip Code:\nPhone Number:\nSignature Required: [Y/N]\nService: [Standard 4-5 Days, 2 Day, Overnight]',
          'Guest Registration': 'Guest(s) First and Last Name(s):\nDate of Arrival:\nArrival Time:\nDate of Departure (for multiple day visits):\nWho to Contact Upon Arrival: \nHow to Contact Upon Arrival: GChat, Hipchat, Other\nAlternative Person to Contact: \nMeeting Room / Location: \nReason for Visit: [visiting company, interview, friends/family, food/catering, Delivery (outside of traditional mail), other] \nAdditional Notes: \n'
        }
      }
    }
  },
  [Section.OnboardingOffboarding]: {
    id: '84812',
    name: 'Onboarding and Offboarding',
    permissions: [AVANT_HELPDESK],
    helpdeskSubtitle: 'Business Requests and Inquiries',
    iconType: 'onboarding',
    title: 'Onboarding and Offboarding',
    subtitle: 'Onboarding, Offboarding, Internal Transfers, Contractor Management',
    categories: {
      '325537': {
        name: 'Provisioning, Termination, & Contractors',
        titles: {
          'New Hire': 'First name: \nLast name: \nDate: \nTime: \nLocation: \nDepartment: \nManager:\nManager Email: \nEquipment Options: Mac, PC \nGmail Distro Groups: \nSpecial Instructions: \n----\nIT Remember to document the assigned computer asset tag.',
          'New Hire - TelePerformance': 'First name: \nLast name: \nPlease create accounts for US portal, knowledge base, and avant university.',
          'New Hire - Alltran': 'First name: \nLast name: \nPlease create accounts for US portal, knowledge base, and avant university.',
          'New Hire - NCO': 'First name: \nLast name: \nPlease create accounts for US portal, knowledge base, and avant university.',
          'Terminations': 'First name: \n Last name: \nDate: \nTime:\nCode: \nDepartment: \nManager:\nManager Email:\n----\nLinda Ko - End Benefits\nUmang Desai - Visa\nRobert Adler - Review Looker Access\nDan Seikel - Disable Finance Systems Access\nIT - Disable Service Accounts ( https://avantinc.atlassian.net/wiki/spaces/IT/pages/90801856/Offboarding+Application+List+living+document ) \n-----\nIT Remember to document the assigned computer asset tag and whether the assets were recovered in this ticket.',
          'Contractor Management': null,
          'Seat Move': null,
          'Auditor Management': null
        }
      }
    }
  },
  [Section.TrainingOperations]: {
    id: '94787',
    name: 'Training and Operational Policy',
    permissions: [AVANT_HELPDESK],
    helpdeskSubtitle: 'Business Requests and Inquiries',
    iconType: 'policy',
    title: 'Training / Operational Policy',
    subtitle: 'Knowledge Base, Training Lessons, Scripting/FAQs, and Avant University requests',
    categories: {
      '362275': {
        name: 'Knowledge Base',
        titles: {
          'Inaccurate Information': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Policy Clarification': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Policy Suggestion': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Distribution List': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Typo': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Other': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: '
        }
      },
      '362276': {
        name: 'Training Lesson',
        titles: {
          'Align to Policy Update': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Content Suggestion': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Typo': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Other': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: '
        }
      },
      '362277': {
        name: 'Scripting / FAQs',
        titles: {
          'Align to Policy Update': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'New Script Request': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Script Adjustment': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Script Not Applicable': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Typo': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Other': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: '
        }
      },
      '362278': {
        name: 'Helpscout',
        titles: {
          'Align to Policy Update': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'New Template Request': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Template Adjustment': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Template Not Applicable': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Typo': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Other': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: '
        }
      },
      '362279': {
        name: 'Avant University / Assessments',
        titles: {
          'Reset Assessment Request': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Assessment Typo': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Align Assessment to Policy': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Lesson Enrollment': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Department Assignment / Transition': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Lesson Certification': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: ',
          'Other': 'What is the issue?: \nWhat is your observation?: \nWhat is your expectation?: \nPlease provide an example, an image, and/or a link to the content if available: '
        }
      }
    }
  },
  [Section.SingleCustomerIncident]: {
    id: '56683',
    name: 'Single Customer Incident',
    permissions: [AVANT_HELPDESK],
    helpdeskSubtitle: '',
    iconType: 'service-incident',
    title: '',
    subtitle: '',
    categories: {
      '369793': {
        name: 'Single Customer Incident',
        titles: {
          'Single Customer Incident': "Please include as much information as possible without including customer PII. \n\nPartner name that owns this customer's account / product: \nWhat is the customer's name: \nEmail: \nPhone number: \nLast 4 SSN: \n(If available) Application id: \nPlease describe, in as much detail as possible, the reason for submitting this ticket: \nPlease include any KBs or other documentation followed which directs to submit this ticket: "
        }
      }
    }
  },
};
/* tslint:enable */
