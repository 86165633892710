/* tslint:disable */
import * as React from "react";

const SvgLogoPnc: React.FC<
  React.SVGAttributes<SVGElement>
> = props => (
  <>
    <svg viewBox="0 0 95 27.9" width={95} height={28} aria-hidden='true' {...props}>
      <title>{`PNC Logo`}</title>
      <path
        fill="#FFF"
        d="M13.9.6C6.6.6.6 6.6.6 13.9c0 7.3 5.9 13.3 13.3 13.3 7.3 0 13.3-5.9 13.3-13.3 0-7.3-6-13.3-13.3-13.3"
      />
      <path
        fill="#F88007"
        d="M25.6 25.3h.2c.2 0 .3.1.3.3 0 .1-.1.3-.3.3h-.2v-.6m0 .9h.1c.4 0 .3.2.3.5 0 .1 0 .3.1.4h.5c0-.1-.1-.5-.1-.6 0-.4-.3-.4-.4-.4.3 0 .4-.3.4-.5 0-.4-.2-.6-.6-.6H25v2.2h.5v-1zm.2-2.1c-1 0-1.9.8-1.9 1.9 0 1 .8 1.9 1.9 1.9 1 0 1.9-.8 1.9-1.9 0-1.1-.9-1.9-1.9-1.9m0 3.4c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5m-5-11.6h-2.9c.1-.4.1-.8-.2-1.3L13 6.4c-.7-1.2-1.4-2.1-2-2.8 1.9 1 5.6 3.1 12.8 7.3l.4.2c1.3.8 2.1 2 1.9 2.8-.3 1.3-2.2 2-5.3 2m-9.4 8l-.4.3c-1.3.8-2.8.8-3.4.2-.8-.8-.4-2.8 1.1-5.5l1.4-2.5c.3.3.7.5 1.2.5h9.4c1.4 0 2.5-.1 3.5-.4-1.8 1.2-5.5 3.3-12.8 7.4m-5-17.2v-.5c0-1.5.6-2.8 1.5-3 1.1-.3 2.7 1.1 4.2 3.7l1.4 2.5c-.4.1-.8.4-1 .8l-4.7 8.1c-.7 1.2-1.2 2.2-1.4 3.2V6.7M13.9 0C6.2 0 0 6.2 0 13.9s6.2 13.9 13.9 13.9 13.9-6.2 13.9-13.9S21.6 0 13.9 0"
      />
      <path
        fill='var(--color-1, #0e4d8b)'
        d="M42.2 3.8h-9.3v.9h.2c3.2.4 3.6.8 3.6 2.5v17.2h3.7v-9h1.5c3.5 0 10.8 0 10.8-5.8s-6.8-5.8-10.5-5.8m-.4 10.1h-1.4V7.2c0-1.6 0-1.8 3-1.8 3.4 0 5.2 1.5 5.2 4.3 0 3.7-3.5 4.2-6.8 4.2M73.5 3.8h-2.2v16.1c-.5-.8-11.5-16.1-11.5-16.1H52v.9h.2c3.2.4 3.6.6 3.6 2.8v16.9h2.4V7.1c.6.8 12.5 17.3 12.5 17.3h3.2V3.8h-.4zm3.1 9.6c0 5.6 3.4 11.3 11.1 11.3 3.6 0 5.4-.4 7.1-.8h.2v-1.7l-.3.1c-1.8.5-3.5.7-4.9.7-6.1 0-9.1-3.4-9.1-10.3 0-4.6 2.2-7.4 5.9-7.4 2.5 0 3.9 1 4.3 2.9v.2H95V8c-.1-1.1-1.1-4.6-8.2-4.6-6.8 0-10.2 3.4-10.2 10"
      />
    </svg>
  </>
);

export default SvgLogoPnc;
