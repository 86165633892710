import * as React from 'react';
import { ActionButton, TextAreaInput } from '@amount/frontend-components';
import styled from 'styled-components';
import { Mutation, MutationFn } from 'react-apollo';
import { ApolloQueryResult } from 'apollo-client';
import ErrorHandler from '@avant/crm-frontend-utils/error';

import { Spinner } from '../../Spinner';
import CreateCommentMutation, { CreateComment, CreateCommentInput } from '../queries/createRequestComment.graphql';
import { GetRequestDetail } from '../queries/getRequestDetail.graphql';
import UploadAttachment from '../RequestDetail/RequestAttachmentInput';
import { SelectionOnCreateAttachment, SelectionOnTemporaryAttachments } from '../queries/createRequestAttachment.graphql';
import { ErrorLogger } from '../../../services/error';

import RemoveableFile from './RemoveableFile';

const CommentForm = styled.form`
  margin: 1em 0;
`;

const CommentBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CommentButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  margin-left: 1em;

  ${ActionButton} {
    margin-left: .5em;
    width: max-content;
  }
`;

const FILE_EXTENSION_REGEX: RegExp = new RegExp('^.*\.(jpg|JPG|gif|GIF|png|PNG|tiff|TIFF|bmp|BMP|jpeg|JPEG)$');
const getEmbedFormat = (fileName: string): string => FILE_EXTENSION_REGEX.test(fileName) ? `!${fileName}!` : `[^${fileName}]`;

type MapType = Map<string, string>;

interface ILiftedProps {
  serviceDeskId: string;
  requestKey: string;
  refetch (): Promise<ApolloQueryResult<GetRequestDetail>>;
}

interface IProps extends ILiftedProps {
  createComment: MutationFn<CreateComment, CreateCommentInput>;
}

interface IState {
  body: string;
  loading: boolean;
  attachmentMap: MapType;
}

class RequestComment extends React.Component<IProps, IState> {
  public state: IState = { body: '', loading: false, attachmentMap: new Map() };

  public render () {
    if (this.state.loading) {
      return <Spinner withMargin={true} />;
    }

    return (
      <CommentForm onSubmit={this.handleSubmit}>
        <TextAreaInput
          id='comment'
          value={this.state.body}
          onChange={this.handleChange}
        />
        <CommentBar>
          <div>
            {this.state.attachmentMap.size > 0 && <div><strong>Attachments:</strong></div>}
            {[...this.state.attachmentMap.entries()].map(([name]) => (
              <RemoveableFile key={name} id={name} fileName={name} onRemove={this.removeAttachment} />
            ))}
          </div>
          <CommentButtons>
            <UploadAttachment setAttachments={this.handleAttachments} serviceDeskId={this.props.serviceDeskId} />
            {this.state.body.length > 0 && <ActionButton>Submit</ActionButton>}
          </CommentButtons>
        </CommentBar>
      </CommentForm>
    );
  }

  private readonly handleChange: React.FormEventHandler<HTMLTextAreaElement> = e => this.setState({ body: e.currentTarget.value });

  private readonly handleSubmit: React.FormEventHandler = async e => {
    e.preventDefault();
    this.setState({ loading: true });
    const { createComment, requestKey } = this.props;
    const { body, attachmentMap } = this.state;
    const attachmentIds = attachmentMap.size > 0 ? [...attachmentMap.values()] : undefined;

    try {
      const comment = await createComment({ variables: { requestKey, body, attachmentIds } });
      if (comment) {
        await this.props.refetch();
        this.setState({ body: '', attachmentMap: new Map() });
      }
    } catch (e) {
      ErrorLogger.captureException(e);
      ErrorHandler.notify('Comment submission failed', 'Helpdesk', 'error');
    }

    this.setState({ loading: false });
  }

  private readonly handleAttachments = async ({ temporaryAttachments }: SelectionOnCreateAttachment): Promise<void> => {
    const map: MapType = new Map([...this.state.attachmentMap]);
    let bodyWithFileNames: string = this.state.body;
    temporaryAttachments.forEach((a: SelectionOnTemporaryAttachments) => {
      map.set(a.fileName, a.temporaryAttachmentId);
      bodyWithFileNames += `${this.state.body.endsWith(' ') ? '' : ' '}${getEmbedFormat(a.fileName)}`;
    });

    this.setState({ attachmentMap: map, body: bodyWithFileNames });
    this.props.refetch().catch(ErrorLogger.captureException);
  }

  private readonly removeAttachment = (id: string) => {
    const map: MapType = new Map([...this.state.attachmentMap]);
    map.delete(id);
    this.setState({ attachmentMap: map });
  }
}

const LiftedComponent: React.FC<ILiftedProps> = ({ requestKey, refetch, serviceDeskId }) => (
  <Mutation<CreateComment, CreateCommentInput> mutation={CreateCommentMutation}>
    {createComment => (
      <RequestComment
        createComment={createComment}
        requestKey={requestKey}
        refetch={refetch}
        serviceDeskId={serviceDeskId}
      />
    )}
  </Mutation>
);

export default LiftedComponent;
