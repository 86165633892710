import * as React from 'react';

import {
  Config,
  Departments,
  Locations,
  Priorities,
  Products,
  Regions,
} from '../Config';
import { SelectionOnMe } from '../../Context/me.graphql';
import { SWITCHABLE_PARTNER_LIST, SwitchablePartner } from '../../../../shared/config/partners';

import {
  getCategories,
  getPlaceholder,
  getTitles,
  IAttachment,
  ICategory,
  IHelpdeskSections,
  ITitle,
} from './';
import { HelpdeskTicketSection, IOption } from './HelpdeskTicketSection';
import { AttachmentSection } from './HelpdeskAttachmentSection';
import { NoteSection } from './HelpdeskNoteSection';

const ATTACHMENT_MAX_SIZE: number = 21000000;

interface ITrainingFieldsSection {
  locationValue: string;
  departmentValue: string;
  productValue: string;
  onSelectLocation: React.FormEventHandler<HTMLSelectElement>;
  onSelectDepartment: React.FormEventHandler<HTMLSelectElement>;
  onSelectProduct: React.FormEventHandler<HTMLSelectElement>;
}

export const TrainingFieldsSection: React.FC<ITrainingFieldsSection> = props => (
  <>
    <HelpdeskTicketSection
      sectionName='location'
      label='Location'
      selectedValue={props.locationValue}
      onSelect={props.onSelectLocation}
      options={Locations.map(location => ({ id: location, name: location }))}
    />
    <HelpdeskTicketSection
      sectionName='department'
      label='Department'
      selectedValue={props.departmentValue}
      onSelect={props.onSelectDepartment}
      options={Departments.map(department => ({ id: department, name: department }))}
    />
    <HelpdeskTicketSection
      sectionName='product'
      label='Product'
      selectedValue={props.productValue}
      onSelect={props.onSelectProduct}
      options={Products.map(product => ({ id: product, name: product }))}
    />
  </>
);

interface ITitleSection {
  me: SelectionOnMe;
  section: string;
  categoryTitles: { [title: string]: ITitle };
  categoryValue: string;
  priorityValue: string;
  titleValue: string;
  onSelect (title: string, note: string): void;
}

export class TitleSection extends React.Component<ITitleSection> {
  public render (): JSX.Element {
    const options: IOption[] = Object.keys(getTitles(this.props.section, this.props.categoryValue, Config) || {})
      .map(title => ({ id: title, name: title }));

    return (
      <HelpdeskTicketSection
        sectionName='title'
        label='Title'
        selectedValue={this.props.titleValue}
        onSelect={this.onSelect}
        options={options}
      />
    );
  }

  private readonly onSelect: React.FormEventHandler<HTMLSelectElement> = e => {
    let defaultNoteValue: ITitle = getPlaceholder(this.props.categoryTitles[e.currentTarget.value]);

    if (!!defaultNoteValue && typeof defaultNoteValue !== 'string') {
      // tslint:disable-next-line: prefer-conditional-expression
      if (SWITCHABLE_PARTNER_LIST.includes(this.props.me.organization as SwitchablePartner)) {
        defaultNoteValue = !this.props.me.currentPartner ? '' : defaultNoteValue[this.props.me.currentPartner] as string;
      } else {
        defaultNoteValue = defaultNoteValue[this.props.me.organization] as string;
      }
    }
    let noteContent: string = defaultNoteValue || '';
    if (this.props.priorityValue) {
      noteContent = `Priority: ${this.props.priorityValue}
Submitter Email: ${this.props.me.email}
Partner Name: ${this.props.me.currentPartner}


${noteContent}`;
    }
    this.props.onSelect(e.currentTarget.value, noteContent);
  }
}

interface ICategorySection {
  category: string;
  categories: { [id: string]: ICategory };
  onSelect: React.FormEventHandler<HTMLSelectElement>;
  section: string;
}

export const CategorySection: React.FC<ICategorySection> = props =>
  (
    <HelpdeskTicketSection
      sectionName='category'
      label='Category'
      selectedValue={props.category}
      onSelect={props.onSelect}
      options={
        Object.entries(getCategories(props.section, Config))
          .map(([id, { name, permissions }]) => ({ id, name, permissions }))
      }
    />
  );

interface IPrioritySection {
  priority: string;
  onSelect: React.FormEventHandler<HTMLSelectElement>;
  section: string;
}

export const PrioritySection: React.FC<IPrioritySection> = props =>
  (
    <HelpdeskTicketSection
      sectionName='priority'
      label='Priority'
      selectedValue={props.priority}
      onSelect={props.onSelect}
      options={Priorities.map(s => ({ id: s, name: s }))}
    />
  );

interface IRegionSection {
  region: string;
  onSelect: React.FormEventHandler<HTMLSelectElement>;
  section: string;
}

export const RegionSection: React.FC<IRegionSection> = props =>
  (
    <HelpdeskTicketSection
      sectionName='region'
      label='Region'
      selectedValue={props.region}
      onSelect={props.onSelect}
      options={Regions.map(s => ({ id: s, name: s }))}
    />
  );

interface IEditFieldsSection {
  onChangeNotes: React.FormEventHandler<HTMLTextAreaElement>;
  onAttachmentRemove: React.FormEventHandler<HTMLButtonElement>;
  helpdeskSections: IHelpdeskSections;
  attachmentChangeError (error: string): void;
  // tslint:disable-next-line: no-any
  attachmentOnLoad (attachment: IAttachment): any;
}

export class EditFieldsSection extends React.Component<IEditFieldsSection> {
  public render (): JSX.Element {
    const attactmentName: string = !!this.props.helpdeskSections.attachment ? this.props.helpdeskSections.attachment.name : '';

    return (
      <>
        <NoteSection
          sectionName='note'
          label='Note'
          onChange={this.props.onChangeNotes}
          value={this.props.helpdeskSections.note as string || ''}
        />
        <AttachmentSection
          sectionName='attachment'
          label='Attachments'
          value={this.props.helpdeskSections.attachment}
          attachmentName={attactmentName}
          onRemove={this.props.onAttachmentRemove}
          onChange={this.onChange}
        />
      </>
    );
  }

  private readonly onChange: React.FormEventHandler<HTMLInputElement> = e => {
    const files: FileList | null = e.currentTarget.files;

    if (!files || !files[0]) {
      return;
    }

    const file: File = files[0];

    if (file.size > ATTACHMENT_MAX_SIZE) {
      this.props.attachmentChangeError('The attachment size exceeds the max 20 MB file size.');

      return;
    }

    const reader: FileReader = new FileReader();

    reader.onload = () => {
      this.props.attachmentOnLoad({
        name: file.name,
        type: file.type,
        content: btoa(reader.result as string)
      });
    };

    reader.readAsBinaryString(file);
  }
}
