import * as React from 'react';

import { Consumer } from './Context';
import { SelectionOnMe } from './Context/me.graphql';

interface IPermissionProps {
  allowedPartners?: string[];
  excludedPartners?: string[];
  // tslint:disable-next-line: no-any
  NoAccessComponent?: React.FC<any> | React.ComponentClass<any, any>;
}

interface ILiftedPermissionProps extends IPermissionProps {
  me: SelectionOnMe;
}

const PartneredComponent: React.FC<ILiftedPermissionProps> = ({ NoAccessComponent, children, allowedPartners, excludedPartners, me }) => {
  const hasPartner: boolean = !allowedPartners || allowedPartners.some(p => me.currentPartner === p);
  const hasExcludedPartner: boolean = (excludedPartners || []).some(p => me.currentPartner === p);

  if (children && hasPartner && !hasExcludedPartner) {
    return <>{children}</>;
  } else {
    return !!NoAccessComponent ? <NoAccessComponent /> : null;
  }
};

const LiftedPartneredComponent: React.FC<IPermissionProps> = props => (
  <Consumer>
    {me => (
      <PartneredComponent {...props} me={me} />
    )}
  </Consumer>
);

export { LiftedPartneredComponent as PartneredComponent };
