import * as React from 'react';
import {
  DatePickerInput, FormError
} from '@amount/frontend-components';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';

import { Label } from './common';

interface IText {
  name: string;
  formControlId: string;
  labelName: string;
  isRequired?: boolean;
}

// This is a style fix for DatePickerInput, which should be implemented in @amount/frontend-components
const AmountDatePicker = styled.div`
  .DayPickerInput > input {
    border: none;
    border-bottom: ${({ theme: { input } }) => `${input.borderWidth} solid ${input.colorBorder}`};
  }

  .DayPickerInput > input:focus, .DayPickerInput > input:hover {
    border: none;
    border-bottom: ${({ theme: { input } }) => `${input.borderWidth} solid ${input.colorFocus}`};
  }
`;

export const DateInput: React.FC<IText> = ({ name, labelName, formControlId, isRequired }) => {
  const { control, errors } = useFormContext();

  const inputId = `input-${name}`;
  const errorId = `input-${name}-error`;

  const formatDate = (day: Date | null): string => day
    ? day.toISOString().split('T')[0]
    : '';

  return (
    <>
      <Label inputId={inputId} labelName={labelName} required={isRequired || false} />
      <Controller
        name={name}
        control={control}
        rules={{ required: isRequired }}
        render={({onChange}) => (
          <AmountDatePicker>
            <DatePickerInput
              onDayChange={day => onChange(formatDate(day))}
              format='YYYY-MM-DD' // This does not seem to effect the format of the DatePicker, but it is required.
              inputProps={{ name, readOnly: true, id: formControlId }}
              formatDate={formatDate}
              placeholder='YYYY-MM-DD'
              aria-describedby={errorId}
            />
          </AmountDatePicker>
        )}
      />
      {errors[name] && <FormError id={errorId}>This field is required</FormError>}
    </>
  );
};
