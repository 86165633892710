import * as React from 'react';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';

import { client } from './client';
import Login from './components/Login';
import Modules from './modules';
import { ThemeProvider } from './components/ThemeProvider';

export default () => (
  <ThemeProvider>
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <BrowserRouter>
          <Switch>
            <Route path='/login' exact={true} >
              <Login />
            </Route>
            <Route path='/' component={Modules} />
          </Switch>
        </BrowserRouter>
      </ApolloHooksProvider>
    </ApolloProvider>
  </ThemeProvider>
);
