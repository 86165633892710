import * as React from 'react';
import { v4 } from 'uuid';
import Styled, { css } from 'styled-components';
import { ActionButton, SVGIcon } from '@amount/frontend-components';

interface IProps {
  visible: boolean;
  maxWidth?: string;
}

const Input = Styled.input<IProps>`
  width: 100%;
  position: relative;
  outline: none;
  border: none;
  background: none;
  font-size: 1.4rem;
  padding: 0.25em 0 0 0.5em;

  &::placeholder {
    color: ${({ theme: { input } }) => input.colorPlaceholder};
  }

  ${({ visible }) =>
    !visible &&
    `
    pointer-events: none;
  `}
`;

const InputWrapper = Styled(ActionButton)<IProps>`
  padding: 0.25em 1.125em;
  overflow: hidden;
  transition: max-width 100ms ease;
  max-width: 3.75em;
  justify-content: initial;

  ${({ visible, maxWidth }) =>
    !!visible &&
    `
    width: 100%;
    max-width: ${maxWidth || '17.5em'};
  `}

  ${({ active, theme: { actionButton } }) =>
    active &&
    `
    color: ${actionButton.colorText};
    background: unset;
    cursor: default;

    svg {
      fill: ${actionButton.colorText};
    }
  `}

  &:active {
    color: ${({ theme: { actionButton } }) => actionButton.colorText};
    background: unset;

    svg {
      fill: ${({ theme: { actionButton } }) => actionButton.colorText};
    }
  }
`;

const SearchIcon = Styled(SVGIcon)<{
  setIconRight?: boolean;
  iconClickable?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  order: -1;

  ${({ setIconRight }) =>
    !!setIconRight &&
    `
    order: 2;
  `}

  ${({ iconClickable, theme }) =>
    !!iconClickable &&
    css`
      cursor: pointer;

      &:hover {
        color: ${theme.link.colorLinkHover};
      }
    `}

  /* stylelint-disable no-descending-specificity */
  svg {
    margin-right: 0;
    width: 1.5em;
    height: 1.5em;
  }
  /* stylelint-enable */
`;

export interface ISearchButtonProps {
  maxWidth?: string;
  initialValue?: string;
  placeholder?: string;
  setIconRight?: boolean;
  inputChangeHandler?: React.FormEventHandler<HTMLInputElement>;
  closeHandler?(e?: Event): void;
  submitHandler?(e?: Event): void;
}

const SearchButton: React.FC<ISearchButtonProps> = (
  props: ISearchButtonProps
) => {
  const [inputValue, setInputValue] = React.useState<string>(
    props.initialValue || ''
  );

  const inputRef = React.createRef<HTMLInputElement>();
  const searchBarRef = React.createRef<HTMLDivElement>();
  const _id: string = v4();

  const changeInput: React.FormEventHandler<HTMLInputElement> = e => {
    e.preventDefault();

    const inputValue = e.currentTarget.value;
    setInputValue(inputValue);

    if (props.inputChangeHandler) {
      props.inputChangeHandler(e);
    }
  };

  const handleIconClick: React.MouseEventHandler = () => {
    if (!!props.submitHandler) {
      props.submitHandler();
    }
  };


  return (
    <InputWrapper
      {...props}
      // @ts-ignore
      as="div"
      aria-controls={_id}
      aria-label="Search"
      title="Search"
      visible={true}
      ref={searchBarRef}
    >
      <Input
        id={_id}
        aria-label="Search"
        onChange={changeInput}
        ref={inputRef}
        visible={true}
        value={inputValue}
        placeholder={props.placeholder}
      />
      <SearchIcon
        icon="search"
        onClick={handleIconClick}
        setIconRight={props.setIconRight}
        iconClickable={!!props.submitHandler}
      />
    </InputWrapper>
  );
};

export default SearchButton;
