import * as React from 'react';
import { Spinner, SVGIcon } from '@amount/frontend-components';
import styled from 'styled-components';
import { MutationFunctionOptions, ExecutionResult } from '@apollo/react-common';
import { ApolloQueryResult } from 'apollo-client';
import ErrorHandler from '@avant/crm-frontend-utils/error';

import { SelectionOnChangeRequestFieldValues, GetRequestDetail } from '../../queries/getRequestDetail.graphql';
import { FieldHeader, Field, FieldLabel, FieldData } from '../common';
import { UpdateChangeRequest, UpdateChangeRequestInput } from '../../queries/updateChangeRequest.graphql';
import { ErrorLogger } from '../../../../services/error';
import { PermissionedComponent } from '../../../PermissionedComponent';
import { getChangeRequestPermission } from '../../../../../shared/config/helpdeskChangeRequest';

import FieldEditor from './FieldEditor';
import FieldInput from './FieldInput';

interface IProps {
  field: SelectionOnChangeRequestFieldValues;
  requestKey: string;
  editField(options?: MutationFunctionOptions<UpdateChangeRequest, UpdateChangeRequestInput> | undefined):
  Promise<ExecutionResult<UpdateChangeRequest>>;
  refetch(): Promise<ApolloQueryResult<GetRequestDetail>>;
}

const StyledButton = styled.button`
  & > * {
    display: inline;
  }
  
  & svg {
    padding-left: .5em;
    width: 1.25em;
    color: ${({ theme }) => theme.colorSlate};
  }
`;

const EditableFields: React.FC<IProps> = ({ editField, field, requestKey, refetch }) => {
  const [isEditing, setEditing] = React.useState(false);
  const [editedValue, setEditedValue] = React.useState(field.value.textData || '');
  const [loading, setLoading] = React.useState(false);

  const handleCancel = () => setEditing(false);
  const handleSubmit: React.FormEventHandler = async e => {
    e.preventDefault();
    setEditing(false);
    setLoading(true);

    try {
      await editField({ variables: { requestKey, [field.fieldId]: editedValue }});
      await refetch();
    } catch (err) {
      ErrorLogger.captureException(err);
      ErrorHandler.notify('The field could not be updated', 'Helpdesk', 'error');
    }

    setLoading(false);
  };

  if (!isEditing) {
    return (
      <Field>
        <FieldHeader>
          <FieldLabel>
            {field.label}
          </FieldLabel>
        </FieldHeader>
        <FieldData>
          <PermissionedComponent
            allowedPermissions={[getChangeRequestPermission(field.fieldId)]}
            NoAccessComponent={() => <div>{field.value.textData}</div>}
          >
            {loading ? <Spinner small={true} /> : (
              <StyledButton onClick={() => setEditing(editing => !editing)}>
                <span>{field.value.textData}</span>
                <SVGIcon icon='edit' />
              </StyledButton>
            )}
          </PermissionedComponent>
        </FieldData>
      </Field>
    );
  }

  return (
    <FieldEditor onSubmit={handleSubmit} label={field.label} onCancel={handleCancel}>
      <FieldInput
        fieldId={field.fieldId}
        label={field.label}
        onChange={setEditedValue}
        value={editedValue}
      />
    </FieldEditor>
  );
};

export default EditableFields;
