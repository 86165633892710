import * as React from 'react';
import { Query } from 'react-apollo';

import GetPageQuery, { GetPage, GetPageInput } from '../getPage.graphql';
import { ISortOption, SortComp, SortOptions } from '../Sort';

import { ISARListPropsBase, SARList } from './List';

const DEFAULT_AMOUNT: number = 10;

  // tslint:disable-next-line: arrow-return-shorthand
const component: React.FC<ISARListPropsBase> = ({ status, type }) => {
  const [sortState, setSortState] = React.useState<ISortOption>(SortOptions.createdAsc);
  const [open, setOpen] = React.useState<boolean>(false);
  const toggleOpen = () => setOpen(openState => !openState);

  const handleSortChange: (option: ISortOption) => void = option => {
    setSortState(option);
  };

  const linkRef = React.createRef<HTMLDivElement>();

  return (
  <Query<GetPage, GetPageInput>
    query={GetPageQuery}
    variables={{
      status,
      first: DEFAULT_AMOUNT,
      field: sortState.field,
      direction: sortState.direction
    }}
  >
    {({ data, loading, refetch, fetchMore }) => (
      <>
        <SortComp linkRef={linkRef} toggleOpen={toggleOpen} open={open} handleSortChange={handleSortChange} />
        <SARList
          status={status}
          type={type}
          loading={loading}
          refetch={refetch}
          data={data}
          // tslint:disable-next-line: jsx-no-lambda no-suspicious-comment
          loadMoreEntries={async () => ( // TODO: rework this.
            fetchMore({
              variables: {
                status,
                first: DEFAULT_AMOUNT,
                after: data ? data.sarsConnection.pageInfo.endCursor : null
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) { return previousResult; }
                const { edges, pageInfo } = fetchMoreResult.sarsConnection;

                return {
                  sarsConnection: {
                    ...previousResult.sarsConnection,
                    edges: [
                      ...(previousResult.sarsConnection.edges || []),
                      ...(edges || [])
                    ],
                    pageInfo
                  }
                };
              }
            })
          )}
        />
      </>
    )}
  </Query>
  );
};

export default component;
