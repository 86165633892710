import { generateLocalStorageKey, getLocalStorageInfo } from './localstorage';

export const THEME_CHANGED_EVENT = 'theme.changed';
export const PARTNERS_THEME_KEY = 'theme:dark';

const LS_THEME_KEY: string = generateLocalStorageKey(PARTNERS_THEME_KEY);

export const partnersThemeKey = getLocalStorageInfo(LS_THEME_KEY);
// Detects if the OS is in dark mode. https://paulmillr.com/posts/using-dark-mode-in-css/
const darkOS: boolean = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

export const getDarkModeEnabled: () => boolean = () => partnersThemeKey.isSet() ? partnersThemeKey.isTrue() : darkOS;
