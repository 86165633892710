import * as React from 'react';
import { FormLabel } from '@amount/frontend-components';

import { SelectionOnValidValues } from '../../../queries/getRequestFields.graphql';

export interface IText {
  name: string;
  formControlId: string;
  labelName: string;
  isRequired: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: any;
  placeholder?: string;
}

export interface ISelectInput extends IText {
  options:  SelectionOnValidValues[];
}

export const Label: React.FC<{inputId: string; labelName: string; required: boolean}> = ({ inputId, labelName, required}) =>
  (
    <FormLabel htmlFor={inputId}>{labelName} {!required && <span>(optional)</span>}</FormLabel>
  );
