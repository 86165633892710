import { DocumentNode } from 'graphql';
import ErrorHandler from '@avant/crm-frontend-utils/error';
import gql from 'graphql-tag';

import { ErrorLogger } from '../../../../services/error';
import GetRequestDetailQuery, {
  GetRequestDetail,
  GetRequestDetailInput,
  SelectionOnRequestFieldValues,
} from '../../queries/getRequestDetail.graphql';
import { SelectionOnMe } from '../../../Context/me.graphql';
import { client } from '../../../../client';

const updateSummaryFragment = gql`
fragment ownedRequestsForSummaryUpdate on IssueType {
  __typename
  fields {
    __typename
    summary
  }
}
`;

const insertSummaryOnFields: (requestFields: SelectionOnRequestFieldValues[], summary: string) => SelectionOnRequestFieldValues[] = (
  requestFields,
  summary
) => {
  const summaryFieldId: string = 'summary';

  return requestFields.map(field => {
    if (field.fieldId === summaryFieldId) {
      field.value.textData = summary;
    }

    return field;
  });
};

export const updateGetOwnedRequestsCache = (summary: string, id: string) => {
  try {
    client.writeFragment({
      fragment: updateSummaryFragment,
      data: {
        __typename: 'IssueType',
        fields: {
          __typename: 'RequestFields',
          summary
        }
      },
      id: `IssueType:${id}`
    });
  } catch (e) {
    ErrorLogger.captureException(e);
  }
};

export const updateRequestDetailCache = (summary: string, context: SelectionOnMe, key: string) => {
  try {
    const queryDetails: {query: DocumentNode; variables: GetRequestDetailInput} = {
      query: GetRequestDetailQuery,
      variables: {key, partner: context.currentPartner || ''}
    };
    const requestData: GetRequestDetail | null = client.readQuery<GetRequestDetail, GetRequestDetailInput>(queryDetails);

    if (!requestData || !requestData.requestDetail || !requestData.requestDetail.requestFieldValues) {
      ErrorHandler.notify('Cannot display new Priority. Please refresh the page.', 'Helpdesk', 'error');
      throw Error('requestDetail data not read from cache');
    }

    const newFields: SelectionOnRequestFieldValues[] = insertSummaryOnFields(
      JSON.parse(JSON.stringify(requestData.requestDetail.requestFieldValues)), summary);

    client.writeQuery<GetRequestDetail>({
      ...queryDetails,
      data: {
        requestComments: {...requestData.requestComments},
        requestDetail: {
          ...requestData.requestDetail,
          requestFieldValues: newFields
        }
      },
    });

  } catch (e) {
    ErrorLogger.captureException(e);
  }
};
