import * as React from 'react';
import {
  DateInput,
  theme
} from '@amount/frontend-components'

const borderBox = `${theme.input.borderWidth} solid ${theme.input.colorBorder}`;

const DebouncedDatePicker: React.FC<{
  defaultValue: string,
  name: string,
  onDebouncedChange(buffer: string): void;
}> = ({
  defaultValue,
  name,
  onDebouncedChange,
}) => {
  const [buffer, setBuffer] = React.useState<string>('-1')
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timer>()

  React.useEffect(() => {
    if (buffer !== '-1') {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      const id = setTimeout(() => {
        onDebouncedChange(buffer)
      }, 1000)
      setTimeoutId(id)
    }

  }, [buffer])

  return (
    <DateInput
      name={name}
      value={buffer === '-1' ? defaultValue : buffer}
      onChange={({ target: { value }}) => setBuffer(value)}
      style={{
        border: borderBox
      }}
    />
  )
}

export default DebouncedDatePicker
