import * as React from 'react';
import { ApolloQueryResult, FetchMoreOptions, FetchMoreQueryOptions } from 'apollo-client';
import { useHistory } from 'react-router-dom';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { media } from '@amount/frontend-components';
import styled from 'styled-components';

import { Spinner } from '../../Spinner';
import GetOwnedRequestsQuery, {
  GetOwnedRequests,
  GetOwnedRequestsInput,
  SelectionOnIssues,
  SelectionOnOwnedRequests,
} from '../queries/getOwnedRequests.graphql';
import { ModuleRoutes } from '../../../../shared/routes';
import { HELPDESK_ROUTES } from '../helpdesk-routes';
import TableActionBar from '../Table/ActionBar';
import { MeContext } from '../../Context';
import { SelectionOnMe } from '../../Context/me.graphql';
import { IActionBar } from '../constants';
import { canViewTicket } from '../__helpers__/permissioning';

import RequestTableView from './RequestTable';
import TablePagination from './Pagination';

const DEFAULT_LIMIT: number = 50;

const TableContain = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${media.small`
    flex-direction: column;
  `}
`;

interface IProps {
  loading: boolean;
  requestList: SelectionOnOwnedRequests | undefined;
  // tslint:disable-next-line: no-any
  fetchMore (fetchMoreOptions: FetchMoreQueryOptions<GetOwnedRequestsInput, any> & FetchMoreOptions):
  Promise<ApolloQueryResult<SelectionOnOwnedRequests>>; // This is a garbage typing.
}

const RequestContainer: React.FC<IProps & IActionBar> = ({ requestList, fetchMore, loading, ...props }) => {
  const history = useHistory();
  const context = React.useContext(MeContext);

  if (!loading && (!requestList || !requestList.issues)) {
    return <div>Error getting requests. Please try again</div>;
  }

  const navigateToRequest: React.MouseEventHandler<HTMLTableRowElement> = e => {
    e.stopPropagation();
    if (!e.currentTarget.dataset.id) { return; }
    const id: string = e.currentTarget.dataset.id;
    const url: string = `${ModuleRoutes.helpdesk}${HELPDESK_ROUTES.viewRequest}/${id}`;
    if (e.metaKey) {
      window.open(url, '_blank');

      return;
    }
    if (e.shiftKey) {
      window.open(url);

      return;
    } else { history.push(url); }
  };

  const getPage = (start: number) => fetchMore({
    variables: { start },
    updateQuery: (prev: GetOwnedRequests, { fetchMoreResult }: { fetchMoreResult?: GetOwnedRequests}) => fetchMoreResult || prev
  });

  const { startAt = 0, total = 0, issues = [] } = requestList || {};

  const filteredIssues = issues.filter((request: SelectionOnIssues) =>
    !request.fields.customfield_10010.requestType || canViewTicket(request.fields.customfield_10010.requestType.name, context));

  return (
    <>
      <TableActionBar requestList={filteredIssues} {...props.actionBarState} {...props} />
      {loading ? <Spinner withMargin={true} /> : (
        <TableContain>
          <RequestTableView requests={filteredIssues} handleRowClick={navigateToRequest} />
          <TablePagination
            getPage={getPage}
            current={startAt}
            limit={DEFAULT_LIMIT}
            total={total}
            count={filteredIssues.length}
          />
        </TableContain>
      )}
    </>
  );
};

const LiftedRequests: React.FC<IActionBar> = props => {
  const context: SelectionOnMe = React.useContext(MeContext);
  const options: QueryHookOptions<GetOwnedRequests, GetOwnedRequestsInput> = {
    notifyOnNetworkStatusChange: true,
    variables: {
      partner: context.currentPartner || '',
      Status: [...props.actionBarState.Status],
      Search: props.actionBarState.Search,
      Sort: props.actionBarState.Sort,
      Reporter: [...props.actionBarState.Reporter],
      Priority: [...props.actionBarState.Priority],
      Product: [...props.actionBarState.Product],
      Category: [...props.actionBarState.Category]
    },
  };

  const { data, loading, fetchMore } = useQuery<GetOwnedRequests, GetOwnedRequestsInput>(GetOwnedRequestsQuery, options);

  return (
    <RequestContainer
      {...props}
      requestList={data && data.ownedRequests}
      fetchMore={fetchMore}
      loading={loading}
    />
  );
};

export default LiftedRequests;
