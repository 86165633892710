import * as React from 'react';
import * as classnames from 'classnames';

import { ISarInfo } from '../../interfaces';
import {
  narrative__Entry__Form__Group__Base,
} from './style.css';

type FormGroupType = React.FC<{className?: string; passRef?: React.Ref<HTMLDivElement>}>;

export const FormGroup: FormGroupType = ({ children, passRef, className }) => (
  <div ref={passRef} className={classnames(narrative__Entry__Form__Group__Base, className)}>
    {children}
  </div>
);

export const shouldDisplayTeamFilter: (sar: ISarInfo) => boolean = sar => {
  const metadata = sar.metadata.userDefinedMetadata || {};

  // return if teamFilter already set
  if (!!metadata['teamFilter' as keyof typeof metadata]) {
    return false;
  }

  // return true if SAR is in Final Review folder
  return sar.status === 'analystReview';
};
