import * as React from 'react';

import { PermissionedComponent } from '../PermissionedComponent';
import { VIEW_USERS } from '../../../shared/config/permissions';
import HomeRedirectComponent from '../HomeRedirectComponent';
import { MeContext } from '../Context';

import { UserList } from './Presentational/UserList';
import { IUserManagement } from './Presentational/common';
import { UserManagementContext } from './UserManagement.context';
import { OrganizationSwitcher } from './Presentational/OrganizationSwitcher';

const OrganizationSwitcherModal = () => {

  const me = React.useContext(MeContext);
  const { state, setState } = React.useContext(UserManagementContext);

  const onOrgChange = (currentOrg: string) => setState({ currentOrg, showOrgSwitcher: false });
  const onClose = () => setState({ showOrgSwitcher: false });

  return (
    <OrganizationSwitcher
      me={me}
      currentOrg={state.currentOrg}
      onChange={onOrgChange}
      show={state.showOrgSwitcher}
      close={onClose}
    />
  );
};

const LiftedUserList: React.FC<IUserManagement> = props => {
  const { state } = React.useContext(UserManagementContext);

  return (
    <PermissionedComponent allowedPermissions={[VIEW_USERS]} NoAccessComponent={HomeRedirectComponent}>
      <UserList
        {...props}
        organization={state.currentOrg}
      />
      <OrganizationSwitcherModal />
    </PermissionedComponent>
  );
};

export default LiftedUserList;
