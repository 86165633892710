import * as React from 'react';
import { Input, SelectInput, TextAreaInput, SelectOption } from '@amount/frontend-components';

interface IProps {
  fieldId: string;
  label: string;
  value: string;
  onChange(input: string): void;
}

const FieldInput: React.FC<IProps> = ({ fieldId, label, value, onChange }) => {
  const inputRef = React.useRef<HTMLElement>(null);

  React.useEffect(
    () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    []
  );

  const handleChange = (e: { currentTarget: { value: string }}) => onChange(e.currentTarget.value);

  switch (fieldId) {
  case 'customfield_10422':
    return (
      <TextAreaInput
        name={label}
        onChange={handleChange}
        value={value}
        ref={inputRef as React.RefObject<HTMLTextAreaElement>}
      />
    );
  case 'customfield_10423':
    return (
      <SelectInput
        name={label}
        onChange={handleChange}
        value={value}
        ref={inputRef as React.RefObject<HTMLSelectElement>}
      >
        <SelectOption value='13555'>None</SelectOption>
        <SelectOption value='13556'>Approve</SelectOption>
        <SelectOption value='13558'>On hold</SelectOption>
        <SelectOption value='13557'>Reject</SelectOption>
      </SelectInput>
    );
  case 'customfield_10424':
    return (
      <Input
        name={label}
        onChange={handleChange}
        value={value}
        ref={inputRef as React.RefObject<HTMLInputElement>}
      />
    );
  default:
    return <div>The field can not be edited.</div>;
  }
};

export default FieldInput;
