import * as React from 'react';
import { Link } from 'react-router-dom';
import camelCase from 'lodash-es/camelCase';
import { format, parse } from 'date-fns';
import { ConditionalRenderWrapper } from '@amount/frontend-components';
import styled from 'styled-components';

import { appendQueryParams } from '../../../../shared/reports/util';
import { isLookerDashboard } from '../../../../shared/reports/constants';
import { MeContext } from '../../Context';

import {
  FileActionContain,
  FileActionIcon,
  FileIcon,
  FileText,
  FileTextContain,
  getFileUrl,
  IFileBaseProps,
  ListEntry,
  MobileActionButton,
} from './common';

const DateText = styled.span`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0.1em;
`;

const MobileFileActionIcon = styled(FileActionIcon)`
  margin-left: 0;
`;

export const removeLookerType: (path: string) => string = path => path.replace('.looker', '');

interface ILookerFileProps extends Pick<IFileBaseProps, 'fileName' | 'Key' | 'location'> {
  fileName: string;
}

const LookerFile: React.FC<ILookerFileProps> = ({ Key, fileName, location }) => (
  <Link
    to={appendQueryParams(`/looker-dash/${Key}`, { location })}
    data-event={camelCase(`${fileName} file`)}
  >
    <ListEntry>
      <FileIcon icon='looker' />
      <FileTextContain>
        <FileText>{removeLookerType(fileName)}</FileText>
      </FileTextContain>
    </ListEntry>
  </Link>
);

const File: React.FC<IFileBaseProps> = ({ canEdit, fileName, Key, LastModified, root, showDrawer }) => {
  const context = React.useContext(MeContext);
  if (!context.currentPartner) { return null; }
  const fileUrl: string = getFileUrl(Key, root, context.currentPartner);
  let dateString: string | undefined;
  if (LastModified) { dateString = format(parse(LastModified), 'MM/DD/YYYY hh:mm:ss A'); }

  return (
    <a
      href={fileUrl}
      download={fileName}
      target='_blank'
      rel='noopener noreferrer'
      data-event={camelCase(`${fileName} file`)}
    >
      <ListEntry>
        <FileIcon icon='file' />
        <FileTextContain>
          <FileText>{fileName}</FileText>
          {dateString && (
            <DateText>{dateString}</DateText>
          )}
        </FileTextContain>
        <FileActionContain>
          <ConditionalRenderWrapper breakpoint='small' hiddenOnMobile={true}>
            <FileActionIcon icon='download' />
          </ConditionalRenderWrapper>
          {canEdit && (
            <ConditionalRenderWrapper breakpoint='small' hiddenOnMobile={false}>
              <MobileActionButton onClick={showDrawer}>
                <MobileFileActionIcon icon='ellipses' />
              </MobileActionButton>
            </ConditionalRenderWrapper>
          )}
        </FileActionContain>
      </ListEntry>
    </a>
  );
};

const FileBase: React.FC<IFileBaseProps> = ({ canEdit, Key, LastModified, fileName, location, root, showDrawer }) => (
  <React.Fragment key={Key}>
    {isLookerDashboard(fileName) ? (
      <LookerFile
        Key={Key}
        location={location}
        fileName={fileName}
      />
    ) : (
      <File
        canEdit={canEdit}
        Key={Key}
        fileName={fileName}
        LastModified={LastModified}
        showDrawer={showDrawer}
        root={root}
      />
    )}
  </React.Fragment>
);

export default FileBase;
