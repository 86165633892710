import * as React from 'react';
import { Query } from 'react-apollo';

import { InnerContainer } from '../InnerContainer';
import { Spinner } from '../Spinner';

import MyAccountInfoQuery, { MyAccountInfo } from './queries/getMyAccount.graphql';
import { MyAccount } from './Presentational/MyAccount';

const LiftedMyAccount: React.FC = () => (
  <Query<MyAccountInfo>
    query={MyAccountInfoQuery}
  >
    {({ data, loading }) => (
      <InnerContainer>
        {loading ? (
          <Spinner />
        ) : (
          <MyAccount data={data} />
        )}
      </InnerContainer>
    )}
  </Query>
);

export default LiftedMyAccount;
