export const CREATED_STEP = 'open';
export const DRAFTING_STEP = 'drafting';
export const FRAUD_REVIEW_STEP = 'fraudReview';
export const ANALYST_REVIEW_STEP = 'analystReview';
export const REJECTED_STEP = 'rejected';
export const SUBMITTED_STEP = 'submitted';
export const PARTNER_REJECTED_STEP = 'partnerRejected';
export const PARTNER_REVIEW_STEP = 'partnerReview';
export const COMPLETED_STEP = 'completed';

export type partnerSteps = typeof COMPLETED_STEP | typeof PARTNER_REJECTED_STEP | typeof PARTNER_REVIEW_STEP;
export type amountSteps =
  | typeof CREATED_STEP
  | typeof DRAFTING_STEP
  | typeof FRAUD_REVIEW_STEP
  | typeof ANALYST_REVIEW_STEP
  | typeof REJECTED_STEP
  | typeof SUBMITTED_STEP;
export type allSteps =
  | amountSteps
  | partnerSteps;

export const PARTNER_STEPS: Set<partnerSteps> = new Set([COMPLETED_STEP, PARTNER_REJECTED_STEP, PARTNER_REVIEW_STEP]);
export const AMOUNT_STEPS: Set<amountSteps> = new Set([
  CREATED_STEP,
  DRAFTING_STEP,
  FRAUD_REVIEW_STEP,
  ANALYST_REVIEW_STEP,
  REJECTED_STEP,
  SUBMITTED_STEP
]);
export const ALL_STEPS: allSteps[] = [
  ...AMOUNT_STEPS,
  ...PARTNER_STEPS
];
