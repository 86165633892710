import * as React from 'react';
import * as Pusher from 'pusher-js';

import { pusherClient } from '../../services/pusher';
import { SelectionOnMe } from '../Context/me.graphql';

// can add Props later, if desired (perhaps Channel obj?)
export type LiftedPusherProps<Base> = Base;

export type PusherCallbackFn<PusherUpdate> = (pusherResult: PusherUpdate) => void;
export type SubscribeCallback<PropsBase, PusherUpdate> =
  (props: LiftedPusherProps<PropsBase>) => PusherCallbackFn<PusherUpdate>;

export type LiftType =
  <PropsBase, PusherUpdate>(
    channelName: string,
    event?: string,
    callback?: SubscribeCallback<PropsBase, PusherUpdate>
  ) =>
    (Component: React.ComponentClass<LiftedPusherProps<PropsBase>>) =>
      React.ComponentClass<PropsBase>;

export const withPusher: LiftType =  <PropsBase, PusherUpdate>(
  channelName: string,
  event?: string,
  callback?: SubscribeCallback<PropsBase, PusherUpdate>
) => (
  Component => (
    class extends React.PureComponent<PropsBase & { me: SelectionOnMe }> {
      public static displayName: string = `withPusher(${Component.name})`;

      public componentDidMount (): void {
        pusherClient.subscribe(this.pusherChannel, event, this.wrappedCallback);
      }

      public componentWillUnmount (): void {
        pusherClient.unsubscribe(this.pusherChannel);
      }

      public render (): JSX.Element {
        // TS#13288
        // tslint:disable-next-line: no-any
        return <Component {...this.props as any} />;
      }

      private get pusherChannel (): string {
        // tslint:disable-next-line: no-any
        return channelName || (this.props as any).pusherChannel;
      }

      private readonly wrappedCallback: (update: PusherUpdate) => void = update => {
        if (callback) { callback(this.props)(update); }
      }
    }
  )
);

interface IPusherMember {
  id: string;
  email: string;
}

interface IPusherPresencePassProps {
  members: Map<string, IPusherMember>;
}

interface IPusherPresenceState extends IPusherPresencePassProps {
  me: string;
}

type StringFromProps<T> = (props: T) => string;
type PossibleFunction<T> = string | StringFromProps<T>;
// tslint:disable-next-line only-arrow-functions
function callIfFunction<T> (possibleFunction: PossibleFunction<T>): StringFromProps<T> {
  return props => typeof possibleFunction === 'function' ? possibleFunction(props) : possibleFunction;
}

export type WithPusherPresence<T> = T & IPusherPresencePassProps;

type WithPusherPresenceReturn<T> = (Component: React.ComponentClass<WithPusherPresence<T>>) => React.ComponentClass<T>;

// tslint:disable-next-line only-arrow-functions
function removeFromMap <T, U> (map: Map<T, U>, id: T): Map<T, U> {
  const newMap: Map<T, U> = new Map(map);
  newMap.delete(id);

  return newMap;
}

// tslint:disable-next-line only-arrow-functions
function addToMap <T, U> (map: Map<T, U>, id: T, info: U): Map<T, U> {
  const newMap: Map<T, U> = new Map(map);
  newMap.set(id, info);

  return newMap;
}

// tslint:disable-next-line only-arrow-functions
export function withPusherPresence<T> (getChannelId: PossibleFunction<T>): WithPusherPresenceReturn<T> {
  return Component => (
    class extends React.PureComponent<T, IPusherPresenceState> {
      public static displayName: string = `withPusherPresence(${Component.name})`;
      public state: IPusherPresenceState = {
        members: new Map(),
        me: '',
      };

      get channelName (): string {
        return `presence-${callIfFunction(getChannelId)(this.props)}`;
      }

      public componentDidMount (): void {
        pusherClient.subscribe(this.channelName, 'pusher:subscription_succeeded', this.subscriptionSucceeded);
        pusherClient.subscribe(this.channelName, 'pusher:member_added', this.memberAdded);
        pusherClient.subscribe(this.channelName, 'pusher:member_removed', this.memberRemoved);
      }

      public componentWillUnmount (): void {
        pusherClient.unsubscribe(this.channelName);
      }

      public subscriptionSucceeded: (members: Pusher.Members<IPusherMember>) => void = members => {
        const newMap: Map<string, IPusherMember> = new Map();
        members.each((member: Pusher.UserInfo<IPusherMember>) => {
          if (member.id !== members.me.id) { newMap.set(member.id.toString(), member.info); }
        });
        this.setState({ members: newMap, me: members.me.id.toString() });
      }

      public memberAdded: (member: Pusher.UserInfo<IPusherMember>) => void = member => {
        if (this.isMe(member)) { return; }
        this.setState(({ members }) => ({
          members: addToMap(members, member.id.toString(), member.info)
        }));
      }

      public memberRemoved: (member: Pusher.UserInfo<IPusherMember>) => void = member => {
        if (this.isMe(member)) { return; }
        this.setState(({ members }) => ({
          members: removeFromMap(members, member.id.toString())
        }));
      }

      public isMe: (member: Pusher.UserInfo<IPusherMember>) => boolean = ({ id }) => id.toString() === this.state.me;

      public render (): JSX.Element {
        // tslint:disable-next-line: no-any
        return <Component members={this.state.members} {...this.props as any} />;
      }
    }
  );
}
