import * as React from 'react';
import {
  Input,
  theme
} from '@amount/frontend-components'

import { SELECT_ALL} from './ChartCommon'

const borderBox = `${theme.input.borderWidth} solid ${theme.input.colorBorder}`

const DebouncedInput: React.FC<{
  name: string,
  onDebouncedChange(buffer: string): void
}> = ({
  name,
  onDebouncedChange
}) => {
  const [buffer, setBuffer] = React.useState<string>('-1')
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timer>()

  React.useEffect(() => {
    if (buffer !== '-1') {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      const id = setTimeout(() => {
        onDebouncedChange(buffer)
      }, 1000)
      setTimeoutId(id)
    }
  }, [buffer])

  return (
    <Input
      data-lpignore={true}
      autoComplete='off'
      type='text'
      name={name}
      value={buffer === SELECT_ALL ? '' : buffer}
      onChange={({ target: { value }}) => setBuffer(value)}
      style={{
        border: borderBox,
        width: '175px'
      }}
    />
  )
}

export default DebouncedInput