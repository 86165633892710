import * as React from 'react';
import { Link } from 'react-router-dom';
import * as classNames from 'classnames';

import { button, buttonPrimary } from '../Buttons/style.css';
import { ModuleRoutes } from '../../../../shared/routes';

import Base from './Base';

export class SARWebBankApproval extends Base {
  protected readonly _type: string = 'partnerApproval';

  get postfixElement (): JSX.Element {
    return (
      <Link
        style={{ marginTop: '0.5em' }}
        className={classNames(button, buttonPrimary)}
        to={`${ModuleRoutes.psaes}/generateFile`}
        data-event='generateFlatFile'
      >
        Generate Flat File
      </Link>
    );
  }
}
