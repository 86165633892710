import * as React from 'react';
import { Mutation } from 'react-apollo';

import { removeParentDirectories } from '../../../shared/reports/util';

import Folder, { ILiftedFolderProps } from './Presentational/Folder';
import FolderBase from './Presentational/FolderBase';
import MoveFileMutate, { MoveFile, MoveFileInput } from './queries/moveFile.graphql';
import DeleteFolderMutate, { DeleteFolder, DeleteFolderInput } from './queries/deleteFolder.graphql';
import { DeleteFolderPropsToVariables } from './Presentational/common';

const LiftedFolder: React.FC<ILiftedFolderProps> = props => (
  <Mutation<DeleteFolder, DeleteFolderInput>
    mutation={DeleteFolderMutate}
    {...DeleteFolderPropsToVariables({ type: props.type, Prefix: props.Prefix })}
  >
    {deleteFolder => (
      <Mutation<MoveFile, MoveFileInput>
        mutation={MoveFileMutate} // the mutation is called MoveFileMutate, but it works on folders too.
      >
        {renameFolder => <Folder {...props} renameFolder={renameFolder} deleteFolder={deleteFolder} />}
      </Mutation>
    )}
  </Mutation>
);

export const ConditionalLiftedFolder: React.FC<ILiftedFolderProps> = props => (
  props.canEdit ? (
    <LiftedFolder {...props} />
  ) : (
    <FolderBase {...props} prefixNoParents={removeParentDirectories(props.Prefix || '')} />
  )
);
