/* tslint:disable */
import * as React from "react";

const IconAmountLogoHorizontal = (props: React.SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 638.068 106.875" {...props}>
    <path
      fill='var(--color-1, #002a3a)'
      d="M289.08 89.871h-12.9l-4.631-10.927h-23.6l-4.7 10.927H230.41L254.774 33.1h9.87zm-36.963-20.488h15.333l-7.664-18.14zM365.829 89.871h-11.612V59.205l-15.636 30.06h-7.741l-15.714-30.06v30.666h-11.76V33.1h10.4l20.944 39.012 20.8-39.008h10.321zM434.005 40.31c5.541 5.309 8.275 12.372 8.275 21.177s-2.734 15.858-8.275 21.25q-8.313 7.973-21.173 7.972c-8.656 0-15.713-2.657-21.254-7.972-5.542-5.392-8.271-12.45-8.271-21.25s2.729-15.868 8.271-21.177c5.541-5.387 12.6-8.045 21.254-8.045 8.574 0 15.631 2.658 21.173 8.045zm-33.926 7.438c-3.264 3.341-4.857 7.9-4.857 13.739 0 5.768 1.593 10.322 4.857 13.735s7.516 5.089 12.753 5.089 9.412-1.676 12.6-5.089c3.264-3.413 4.857-7.967 4.857-13.735 0-5.844-1.593-10.4-4.857-13.739-3.187-3.419-7.366-5.084-12.6-5.084s-9.488 1.665-12.753 5.084zM507.644 33.1v33.7c0 7.509-2.123 13.431-6.451 17.6s-10.172 6.3-17.682 6.3-13.437-2.128-17.765-6.3-6.446-10.088-6.446-17.6V33.1h11.689v33.548c0 8.877 4.328 13.663 12.527 13.663 8.116 0 12.444-4.786 12.444-13.663V33.1zM573.239 89.871h-9.946L538.4 54.728v35.143h-11.762V33.1h9.941l24.971 35.213V33.1h11.689zM638.068 43.425H619.1v46.446h-11.693V43.425h-19.05V33.1h49.711z"
    />
    <path
      d="M140.643 25.715h-14.55l-5.964 11.32L100.422 0H82.844L63.332 37.032l-6.021-11.317h-14.55L0 106.875h183.831zM89.075 10.332h5.138l20.092 37.755L91.87 90.668 69.2 48.061zM17.12 96.543l31.871-60.495H51.1l6.405 12.039-25.527 48.456zm26.539 0l19.717-37.428 19.915 37.428zm56.8 0l19.718-37.43 19.919 37.43zm51.341 0L126 48.059l6.327-12.011h2.112l32.194 60.5z"
      fill="#df2742"
    />
  </svg>
);
export default IconAmountLogoHorizontal;
