import {
  default as pusherClient,
} from '@avant/crm-frontend-utils/pusher';

export {
  ChannelOrUndefined
} from '@avant/crm-frontend-utils/pusher';

pusherClient.changeClient({ enabled: true });

export { pusherClient };
