import * as React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  ActionButton,
  ConditionalRenderWrapper,
  media,
  MobileCTAButton,
  SVGIcon
} from '@amount/frontend-components';
import styled from 'styled-components';

import { ButtonLabel, MarginlessH1Headline } from '../../CommonComponents';
import { InnerContainer } from '../../InnerContainer';
import { PermissionedComponent } from '../../PermissionedComponent';
import { EDIT_USERS } from '../../../../shared/config/permissions';
import { ModuleRoutes } from '../../../../shared/routes';
import LiftedUserTable from '../UserTable';
import LiftedUserFilters from '../Filters';
import LiftedUserDetail from '../UserDetail';
import { MeContext } from '../../Context';
import { UserManagementContext } from '../UserManagement.context';
import { FLOATING_ORGANIZATION_MAP, FLOATING_ORGANIZATION_SWITCH_VIEW_MAP, READABLE_PARTNER_NAME_MAP } from '../../../../shared/config/partners';

import { ActionLinkButton } from '../../ActionLinkButton';
import { HeaderRow, IUserManagement, USER_MANAGEMENT_ROUTES } from './common';

const Contain = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 3em;

  ${media.small`
    flex-direction: row;
  `}
`;

const ActionsContain = styled.div`
  display: flex;

  ${media.small`
    ${ActionButton} {
      margin-left: 0.5em;
    }
  `}
`;

const HeaderButtons = styled(HeaderRow)`
  width: 100%;
  margin: 1.5em 0 1em 0;
  flex-direction: column;
  background: ${({ theme }) => theme.colorBackground};
  position: sticky;
  top: 0;
  padding: 1em 0;
  z-index: 9;

  ${media.small`
    margin: 1em 0 3em 0;
    flex-direction: row;
  `}
`;

const UserDetailWrapper = styled(ConditionalRenderWrapper)`
  width: 100%;
`;

// This component used to have a download csv function that we stripped due to backend contstraints. If this functionality is ever
// re-implemented, reference https://github.com/amount/partners/pull/1232.
const Actions = () => (
  <ConditionalRenderWrapper hiddenOnMobile={true} breakpoint='small'>
    <ActionsContain>
      <PermissionedComponent allowedPermissions={[EDIT_USERS]}>
        <ActionLinkButton
          href={`${ModuleRoutes.userManagement}${USER_MANAGEMENT_ROUTES.createUser}`}
          target='_blank'
          data-event="requestCSV"
        >
          <SVGIcon icon='plus' />
          <ButtonLabel>Add User</ButtonLabel>
        </ActionLinkButton>
      </PermissionedComponent>

      <ActionLinkButton href={`${ModuleRoutes.helpdesk}`} target='_blank' data-event="requestCSV">
        <SVGIcon icon="helpdesk" />
        <ButtonLabel>RequestCSV</ButtonLabel>
      </ActionLinkButton>
    </ActionsContain>
  </ConditionalRenderWrapper>
);

interface IUserListProps extends IUserManagement {
  organization: string | null;
}

interface IUserListState {
  readableOrgName: string;
}

const isOrgSwitcherViewable = (org: string, currentPartner: string | null): boolean =>
  org in FLOATING_ORGANIZATION_MAP && FLOATING_ORGANIZATION_MAP[org].includes(currentPartner) && org in FLOATING_ORGANIZATION_SWITCH_VIEW_MAP;

const OrgSwitcherButton = () => {
  const { setState } = React.useContext(UserManagementContext);
  const meContext = React.useContext(MeContext);

  const showOrgSwitcher = () => setState({ showOrgSwitcher: true });
  const canViewOrgSwitcher = isOrgSwitcherViewable(meContext.organization, meContext.currentPartner);

  return (
    <>
      {canViewOrgSwitcher &&
        <ActionButton
          data-event='switchParter'
          onClick={showOrgSwitcher}
        >
          <SVGIcon icon='sync' />
          <ButtonLabel>Switch Organization</ButtonLabel>
        </ActionButton>
      }
    </>
  );
};

export class UserList extends React.Component<IUserListProps, IUserListState> {

  public state: IUserListState = {
    readableOrgName: READABLE_PARTNER_NAME_MAP[this.props.organization || '']
  };

  public componentDidUpdate (prevProps: IUserListProps) {
    if ( this.props.organization !== prevProps.organization ) {
      this.setState({ readableOrgName: READABLE_PARTNER_NAME_MAP[ this.props.organization || '' ] });
    }
  }

  public render (): JSX.Element {
    return (
      <InnerContainer>
        <Helmet>
          <title>User List · User Admin</title>
        </Helmet>
        <HeaderRow>
          <MarginlessH1Headline scale='large'>
            {this.state.readableOrgName} User Admin
          </MarginlessH1Headline>
          <OrgSwitcherButton />
        </HeaderRow>
        <HeaderButtons>
          <LiftedUserFilters
            roles={this.props.roles}
            search={this.props.search}
            status={this.props.status}
            handleInputChange={this.props.handleInputChange}
            handleStatusChange={this.props.handleStatusChange}
            handleSearchChange={this.props.handleSearchChange}
            handleClearFilter={this.props.handleClearFilter}
            handleSearchClear={this.props.handleSearchClear}
          />
          <Actions />
        </HeaderButtons>
        <Contain>
          <LiftedUserTable
            {...this.props}
            roles={this.props.roles}
            status={this.props.status}
            search={this.props.search}
            selectUser={this.props.selectUser}
            selectedUser={this.props.selectedUser}
          />
          {!!this.props.selectedUser && (
            <UserDetailWrapper hiddenOnMobile={true} breakpoint='small'>
              <LiftedUserDetail {...this.props} id={this.props.selectedUser} />
            </UserDetailWrapper>
          )}
        </Contain>
        <PermissionedComponent allowedPermissions={[EDIT_USERS]}>
          <ConditionalRenderWrapper hiddenOnMobile={false} breakpoint='small'>
            <Link to={`${ModuleRoutes.userManagement}${USER_MANAGEMENT_ROUTES.createUser}`}>
              <MobileCTAButton>
                <SVGIcon icon='plus' />
              </MobileCTAButton>
            </Link>
          </ConditionalRenderWrapper>
        </PermissionedComponent>
      </InnerContainer>
    );
  }
}
