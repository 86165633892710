/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  Checkbox, FormError, FormGroup, ICheckboxProps, ScreenReaderOnly,
} from '@amount/frontend-components';
import { Controller, useFormContext } from 'react-hook-form';

import { SelectionOnValidValues } from '../../../queries/getRequestFields.graphql';

import { Label } from './common';

export interface IMultiStateEntry extends ICheckboxProps {
  id: string;
}

interface ICheckBoxInput extends ICheckboxProps {
  name: string;
  boxes: SelectionOnValidValues[];
  formControlId: string;
  labelName: string;
  isRequired: boolean;
}

interface IControlledCheckboxProps {
  boxes: SelectionOnValidValues[];
  value: IMultiStateEntry[];
  ariaId: string;
  onChange (e: IMultiStateEntry[]): void;
  onBlur (): void;
}

// tslint:disable-next-line: no-any
const Checkboxes: React.FC<IControlledCheckboxProps> = ({onChange, onBlur, value, boxes, ariaId}) => {
  // tslint:disable-next-line: no-parameter-reassignment
  if (!value) {
    value = [];
    onChange(value);
  }

  const changleHandler = (checked: boolean, v: SelectionOnValidValues) => {
    if (checked) {
      onChange(value.concat({ id: v.value }));
    } else {
      onChange(value.filter((val: IMultiStateEntry) => val.id !== v.value));
    }
  };

  return (
    <div style={{ marginTop: '.75em' }}>
      {boxes.map(b => (
        <Checkbox
          key={b.value}
          onBlur={onBlur}
          onChange={e => changleHandler(e.target.checked, b)}
          checked={value.some((v: IMultiStateEntry) => v.id === b.value)}
          aria-describedby={ariaId}
          name={b.label}
        >
          {b.label}
        </Checkbox>
      ))}
    </div>
  );
};

export const MultiCheckBoxes: React.FC<ICheckBoxInput> = ({ name, boxes, formControlId, labelName, isRequired })  => {
  const { errors, control } = useFormContext();
  const inputId = `input-${name}`;
  const errorId = `input-${name}-error`;

  return (
    <>
      <FormGroup>
        <Label inputId={inputId} labelName={labelName} required={isRequired || false} />
        <ScreenReaderOnly id={`${formControlId}__helper-text`}>
          Please select at least one of the following:
        </ScreenReaderOnly>
        <Controller
          control={control}
          name={name}
          aria-describedby={errorId}
          rules={{
            validate: formBoxes =>
              isRequired ? Object.values(formBoxes).length > 0 : true,
          }}
          render={({onChange, onBlur, value}) => (
            <Checkboxes
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              boxes={boxes}
              ariaId={errorId}
            />
          )}
        />
        {errors[name] && <FormError id={errorId}>This field is required</FormError>}
      </FormGroup>
    </>
  );
};
