import * as React from 'react';
import { Pill } from '@amount/frontend-components';

import { SelectionOnArrayData, SelectionOnRequestFieldValues } from '../queries/getRequestDetail.graphql';

import RequestField from './RequestField';
import FileLink from './FileLink';

interface IRequestFieldListProps {
  fields: SelectionOnRequestFieldValues[];
  issueId: string;
  attachmentIds: string[];
}

// attachments pulled from file metadata are all rooted at this url
const ATTACHMENT_URL: string = 'https://amount.atlassian.net/secure/attachment/';

// the partners backend base path to initiate file downloads
const FILE_DOWNLOAD_BASE_PATH: string = '/api/helpdesk/download';

const parseMetaInfo = (meta: string | null) => {
  const splitURL = (meta || '')
    .replace(ATTACHMENT_URL, '') // remove base url
    .split('/');

  return { id: splitURL[0], name: splitURL[1] };
};

const downloadUrlFor = (issueId: string, data: SelectionOnArrayData): string => {
  const metaInfo = parseMetaInfo(data.meta);

  return [
    FILE_DOWNLOAD_BASE_PATH,
    `?issue=${issueId}`,
    `&id=${metaInfo.id}`,
    `&name=${metaInfo.name}`,
  ].join('');
};

const isPublicAttachment = (attachmentIds: string[], meta: string | null) =>
  attachmentIds.includes(parseMetaInfo(meta).id);

const RequestFieldList: React.FC<IRequestFieldListProps> = ({ fields, issueId, attachmentIds }) => (
  <>
    {fields.map(({ fieldId, label, value }: SelectionOnRequestFieldValues) => (
      <RequestField key={fieldId} label={label}>
        {value.isAttachmentData && value.arrayData ? (
          value.arrayData.map((v: SelectionOnArrayData) => (
            isPublicAttachment(attachmentIds, v.meta) && (
            <FileLink
              key={v.text}
              url={downloadUrlFor(issueId, v)}
              name={v.text}
            /> )
          ))
        ) : (
          <div>
            {value.textData && <div>{value.textData}</div>}
            {value.metaData && <div>{value.metaData}</div>}
            {value.arrayData && value.arrayData.map(v => <Pill key={v.text} margin='0 .5em .5em 0' pillColor='inactive'>{v.text}</Pill>)}
          </div>
        )}
      </RequestField>
    ))}
  </>
);

export default RequestFieldList;
