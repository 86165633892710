import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { Button } from '../../Buttons';
import { TransitionButton } from '../Button';
import { SAR_TAB_PERMISSIONS } from '../../../../../shared/config/permissions';
import { PermissionedComponent } from '../../../PermissionedComponent';
import {
  IWorkflowTransition,
  NON_EDITABLE_STEPS,
  SARTransitions,
} from '../../../../../shared/transitions/sar';
import { ModuleRoutes } from '../../../../../shared/routes';

import {
  narrative__Entry__Form__Button__Group,
} from './style.css';
import { FormGroup } from './common';

interface IEditableButtons extends RouteComponentProps {
  submit: React.FormEventHandler<HTMLButtonElement | HTMLDivElement>;
  dirty: boolean;
  sarId: string;
  toggleCategoryEditor (): void;
}

const preventDefaultAndCallFn: (fn: Function) => React.MouseEventHandler<HTMLButtonElement> = fn => e => {
  e.preventDefault();
  fn();
};

// tslint:disable-next-line: no-suspicious-comment
// TODO: remove the lambdas in this file in a later rework of this module.

const EditableButtons: React.FC<IEditableButtons> = ({ history, sarId, submit, dirty, toggleCategoryEditor }) => (
  <FormGroup className={narrative__Entry__Form__Button__Group}>
    <div>
      <Button
        onClick={submit}
        disabled={!dirty}
        event='success'
        type='submit'
        data-event='saveButton'
      >
        Save
      </Button>
      <Button
        // tslint:disable-next-line: jsx-no-lambda
        onClick={e => { e.preventDefault(); history.push(`${ModuleRoutes.psaes}/${sarId}`); }}
        event='reject'
        data-event='cancelButton'
      >
        Cancel
      </Button>
      <Button
        onClick={preventDefaultAndCallFn(toggleCategoryEditor)}
        event='primary'
        data-event='editCategoriesButton'
      >
        Edit Categories
      </Button>
    </div>
  </FormGroup>
);

interface IReviewButton extends RouteComponentProps {
  submit: React.FormEventHandler<HTMLButtonElement>;
  status: string;
  sarId: string;
  validation?(): boolean;
}

const editURL: () => string = () => `${window.location.pathname.replace(/\?.*/, '')}/narrative`;

const ReviewButtons: React.FC<IReviewButton> = ({ sarId, status, history, validation }) => {
  const transitions: IWorkflowTransition | null = SARTransitions[status];

  if (!transitions) { return null; }
  if (NON_EDITABLE_STEPS.has(status)) { return null; }

  return (
    <FormGroup className={narrative__Entry__Form__Button__Group}>
      <div>
        <Button
          event='primary'
          // tslint:disable-next-line: jsx-no-lambda
          onClick={e => { e.preventDefault(); history.push(editURL()); }}
          data-event='editButton'
        >
          Edit
        </Button>
      </div>
      <div>
        {Object.keys(transitions || {})
          .map(k => transitions[k])
          .map(transition => (
            <PermissionedComponent
              allowedPermissions={transition.permission}
              key={transition.name}
            >
              <TransitionButton
                info={transition}
                sarId={sarId}
                status={status}
                validation={validation}
                // tslint:disable-next-line: jsx-no-lambda
                afterTransition={() => { history.push(`${ModuleRoutes.psaes}/nextInQueue/${status}`); }}
              />
            </PermissionedComponent>
          ))}
      </div>
    </FormGroup>
  );
};

interface IProps extends RouteComponentProps {
  editable: boolean;
  dirty: boolean;
  submit: React.FormEventHandler<HTMLButtonElement>;
  status: string;
  sarId: string;
  toggleCategoryEditor (): void;
  validation? (): boolean;
}

// TS#13288
const Actions: React.FC<IProps> = (props: IProps) => (
  <PermissionedComponent allowedPermissions={[SAR_TAB_PERMISSIONS[props.status]]}>
    {
      props.editable ?
        // tslint:disable-next-line: no-any
        <EditableButtons {...props as any} /> :
        // tslint:disable-next-line: no-any
        <ReviewButtons {...props as any} />
    }
  </PermissionedComponent>
);

// tslint:disable-next-line: no-any
export default withRouter<any, any>(Actions);
