import * as React from 'react';
import styled from 'styled-components';
import { Redirect, Route, RouteComponentProps, Switch, useRouteMatch } from 'react-router-dom';

import { MaxWidthContainer } from '../MaxWidthContainer';
import { PartnersContainer } from '../PartnersContainer';
import { PermissionedComponent } from '../PermissionedComponent';
import HomeRedirectComponent from '../HomeRedirectComponent';
import { VIEW_USERS } from '../../../shared/config/permissions';
import { MeContext } from '../Context';
import { FLOATING_ORGANIZATION_MAP } from '../../../shared/config/partners';

import UserList from './UserList';
import UserDetail from './UserDetail';
import UserInfoEdit from './UserInfoEdit';
import UserRoleEdit from './UserRoleEdit';
import UserCreate from './UserCreate';
import {
  IUserManagement,
  IUserManagementState,
  redirectOnPartnerSwitch,
  USER_MANAGEMENT_ROUTES,
} from './Presentational/common';
import { defaultState, useContext as useUserMgmtContext, UserManagementContext } from './UserManagement.context';

const SEARCH_THROTTLE_TIMEOUT: number = 2000;

const UserManagementContainer = styled(MaxWidthContainer)`
  max-width: 61.25em; /* 980px */
`;

const UserManagementDetail: React.FC<RouteComponentProps & IUserManagement> = ({ match: { path } }) => (
  <Switch>
    <Route path={`${path}${USER_MANAGEMENT_ROUTES.editInfo}`} component={UserInfoEdit} />
    <Route path={`${path}${USER_MANAGEMENT_ROUTES.editPermission}`} component={UserRoleEdit} />
    <Route path={path} component={UserDetail} />
    <Redirect
      exact={true}
      from='**'
      to={path}
    />
  </Switch>
);

const UserManagementRoot: React.FC<IUserManagement> = props => {
  const { path } = useRouteMatch();
  redirectOnPartnerSwitch();

  return (
    <Switch>
      <Route path={`${path}${USER_MANAGEMENT_ROUTES.createUser}`} component={UserCreate} />
      <Route path={`${path}/:id`} component={UserManagementDetail} />
      <Route
        path=''
        // tslint:disable-next-line: jsx-no-lambda
        render={() => <UserList {...props} />}
      />
      <Redirect
        exact={true}
        from='**'
        to={''}
      />
    </Switch>
  );
};

const CreateUserMgmtContext = (props: React.PropsWithChildren< {} >) => {
  const me = React.useContext(MeContext);
  const state = useUserMgmtContext({...defaultState, currentOrg: me.organization || '' });

  React.useEffect(
    () => {
      const floatingOrgs = FLOATING_ORGANIZATION_MAP[me.organization];
      if (floatingOrgs && floatingOrgs.includes(me.currentPartner)) {
        state.setState( {currentOrg: me.organization || ''} );
      } else {
        state.setState( {currentOrg: me.currentPartner || ''} );
      }
    },
    [me.currentPartner]
  );

  return (
    <UserManagementContext.Provider value={state}>
      {props.children}
    </UserManagementContext.Provider>
  );
};

class UserManagement extends React.Component<{}, IUserManagementState> {
  public searchTimeout: number | undefined;

  public state: IUserManagementState = {
    selectedUser: undefined,
    roles: [],
    status: [],
    search: undefined
  };

  public render (): JSX.Element {
    return (
      <PartnersContainer>
        <CreateUserMgmtContext>
          <UserManagementContainer>
            <PermissionedComponent allowedPermissions={[VIEW_USERS]} NoAccessComponent={HomeRedirectComponent}>
              <UserManagementRoot
                {...this.props}
                search={this.state.search}
                roles={this.state.roles}
                status={this.state.status}
                selectedUser={this.state.selectedUser}
                handleSearchChange={this.handleSearchChange}
                handleStatusChange={this.handleStatusChange}
                handleClearFilter={this.handleClearFilter}
                handleInputChange={this.handleInputChange}
                handleSearchClear={this.handleSearchClear}
                selectUser={this.selectUser}
              />
            </PermissionedComponent>
          </UserManagementContainer>
        </CreateUserMgmtContext>
      </PartnersContainer>
    );
  }

  private readonly createSearchTimeout: (value: string) => number = value => (
    window.setTimeout(() => this.setState({ search: value }), SEARCH_THROTTLE_TIMEOUT)
  )

  private readonly handleSearchChange: React.FormEventHandler<HTMLInputElement> = ({
    currentTarget: {
      value
    }
  }) => {
    if (!!this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = this.createSearchTimeout(value);
  }

  private readonly handleSearchClear = () => {
    if (!!this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.setState({ search: undefined });
  }

  /*
   * this is a temporary function to make status only have one selected at once.
   * this should be deleted when platform fixes the status filter to allow multiple.
   */
  private readonly handleStatusChange: React.FormEventHandler<HTMLInputElement> = ({
    currentTarget: {
      dataset: { filter },
      name,
      checked
    }
  }) => {
    if (!filter) { return; }
    if (!checked) {
      this.setState({
        [filter]: []
        // tslint:disable-next-line: no-any
      } as any);

      return;
    }

    this.setState({
      [filter]: [name]
      // tslint:disable-next-line: no-any
    } as any);
  }

  private readonly handleInputChange: React.FormEventHandler<HTMLInputElement> = ({
    currentTarget: {
      dataset: { filter },
      name,
      checked
    }
  }) => {
    if (!filter) { return; }
    let list: string[] = this.state[filter];
    list = checked ? [...list, name] : list.filter(n => n !== name);

    this.setState({
      [filter]: list
      // tslint:disable-next-line: no-any
    } as any);
  }

  private readonly handleClearFilter: React.FormEventHandler<HTMLButtonElement> = ({
    currentTarget: {
      dataset: { filter },
    }
  }) => {
    if (!filter) { return; }

    this.setState({
      [filter]: []
      // tslint:disable-next-line: no-any
    } as any);
  }

  private readonly selectUser: (id: string) => void = id => {
    this.setState({ selectedUser: id });
  }
}

export default UserManagement;
