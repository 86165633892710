/* tslint:disable */
import * as React from 'react';

const IconAmountLogo = (props: React.SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 180.942 144" {...props}>
    <path
      fill='var(--color-1, #002a3a)'
      d="M25.537 143.653h-6.645l-1.628-3.731H8.272l-1.633 3.731H0l10.212-23.439h5.108zm-15.228-8.369h4.919l-2.475-5.7zM59.4 143.653h-6.045v-10l-5.047 9.686h-3.981l-5.108-9.686v10h-6.048v-23.439h5.389l7.773 14.258 7.68-14.258H59.4zM89.634 123.189a11.479 11.479 0 0 1 3.481 8.742 11.6 11.6 0 0 1-3.481 8.777 12.339 12.339 0 0 1-8.9 3.292 12.564 12.564 0 0 1-8.992-3.292 11.673 11.673 0 0 1-3.45-8.777 11.552 11.552 0 0 1 3.45-8.742 12.456 12.456 0 0 1 8.992-3.322 12.234 12.234 0 0 1 8.9 3.322zm-15.167 8.742c0 4.138 2.414 6.7 6.267 6.7 3.792 0 6.206-2.567 6.206-6.7s-2.414-6.706-6.206-6.706c-3.853 0-6.267 2.575-6.267 6.706zM122.591 120.214v13.472c0 3.231-.908 5.767-2.761 7.584a10.265 10.265 0 0 1-7.583 2.73 10.422 10.422 0 0 1-7.645-2.73c-1.847-1.817-2.756-4.353-2.756-7.584v-13.472h6.048v13.38c0 3.353 1.439 5.042 4.353 5.042 2.883 0 4.327-1.689 4.327-5.042v-13.38zM152.124 143.653h-5.17l-8.869-12.284v12.284h-6.048v-23.439h5.139l8.9 12.344v-12.344h6.048zM180.942 125.572h-7.3v18.081h-6.047v-18.081h-7.334v-5.358h20.684z"
    />
    <path
      d="M134.671 23.8H123.03L116.6 36 97.442 0H83L64.031 36 57.54 23.8H45.9L7.915 95.893h165.112zM86.94 6.532h6.583l19.4 36.458-22.479 42.658-22.7-42.671zm-68.2 82.829l31.1-59.025h3.78l6.731 12.651-24.432 46.374zm24.562 0l20.756-39.4 20.964 39.4zm52.569 0l20.756-39.4 20.964 39.4zm49.114 0L120.31 42.978l6.66-12.642h3.781l31.406 59.025z"
      fill="#e92c3a"
    />
  </svg>
);
export default IconAmountLogo;
