import * as React from 'react';

export interface IUserManagementContext {
  state: IUserManagement;
  setState (state: Partial<IUserManagement>): void;
}

export interface IUserManagement {
  currentOrg: string;
  showOrgSwitcher: boolean;
}

export const defaultState: IUserManagement = {
  currentOrg: '',
  showOrgSwitcher: false
};

export const defaultContext: IUserManagementContext = {
  state: defaultState,
  setState: () => {/* */ }
};

export const UserManagementContext = React.createContext<IUserManagementContext>(defaultContext);
UserManagementContext.displayName = 'UserManagementContext';

export const useContext = (initialState: IUserManagement = defaultState): IUserManagementContext => {
  const [state, setState] = React.useState(initialState);

  return {
    state,
    setState: React.useCallback(
      (partialState: Partial<IUserManagement>): void => setState(_state => ({ ..._state, ...partialState })),
      []
    )
  };
};
