import * as React from 'react';
import { Link } from 'react-router-dom';

export const SmartLink: React.FC<React.HTMLAttributes<HTMLAnchorElement> & {
  forceLink?: boolean;
  href: string;
  newTab?: boolean;
  'data-event'?: string;
}> = ({ forceLink, href, newTab, children, ...props }) => {
  if (!forceLink) {
    return (
      <Link {...props} to={href} target={newTab ? '_blank' : undefined} data-event={props['data-event']}>
        {children}
      </Link>
    );
  } else {
    return (
      <a {...props} href={href} target={newTab ? '_blank' : undefined} data-event={props['data-event']}>
        {children}
      </a>
    );
  }
};
