import * as React from 'react';
import {
  ConditionalRenderWrapper,
  MobileCTAButton,
  SVGIcon
} from '@amount/frontend-components';
import styled from 'styled-components';

import {
  DASHBOARDS_PATH,
  INDIVIDUAL_REPORTING_PATH,
  STORE_REPORTING_PATH
} from '../../../../shared/config/constants';
import LookerDashboardConfig, { ILookerDashboard } from '../../../../shared/config/lookerDashboards';
import { HUMAN_READABLE_S3_TYPES } from '../../../../shared/reports/constants';
import { SelectionOnMe } from '../../Context/me.graphql';
import { ConditionalLiftedFolder } from '../LiftedFolder';
import { ConditionalLiftedFile } from '../LiftedFile';
import { FEATURE_FLAGS } from '../../../../shared/config/featureFlag';
import { VIEW_IN_BRANCH_INDIVIDUAL, VIEW_IN_BRANCH_STORE } from '../../../../shared/config/permissions';
import { PermissionedComponent } from '../../PermissionedComponent';
import InBranchDashboard from '../InBranchDashboard';

import FileBase from './FileBase';
import FolderBase from './FolderBase';
import {
  EmptyRow,
  FileListContain,
  IFileQuery,
  ILiftedProps,
  IProps,
} from './common';
import { ActionDrawer } from './ActionDrawer';

const DragText = styled.p`
  width: 100%;
  text-align: right;
  margin-top: 1em;
  color: ${({ theme }) => theme.colorSlate};
`;

interface IFileListProps extends Omit<ILiftedProps, 'fileQuery'>, Pick<IProps, 'root'> {
  canEdit: boolean;
  parentRestricted?: boolean;
  currentPartner: SelectionOnMe['currentPartner'];
  pathNoRoot: string;
  isRootFolder: boolean;
  fileQuery: Pick<IFileQuery, 'loading' | 'refetch'> & {
    data: NonNullable<IFileQuery['data']>;
  };
  isDashboardsFolder: boolean;
  isVisualizationFolder: boolean;
  uploadingFile: boolean;
  showCreateModal: React.MouseEventHandler;
  fileUpload (): void;
}

interface IFileListState {
  showMenu: boolean;
}

interface IDashboardFolderProps {
  root: HUMAN_READABLE_S3_TYPES;
  pathNoRoot: string;
}

interface IPartnersInBranchFolderProps {
  inBranchPath: string;
  root: HUMAN_READABLE_S3_TYPES;
}

const formatPath = (location: string) => !!location ? `${location}/${DASHBOARDS_PATH}` : DASHBOARDS_PATH;

const DashboardFolder: React.FC<IDashboardFolderProps> = ({ root, pathNoRoot }) => (
  <FolderBase
    canEdit={false}
    Prefix={formatPath(pathNoRoot)}
    prefixNoParents={DASHBOARDS_PATH}
    root={root}
    key={formatPath(pathNoRoot)}
    restricted={false}
  />
);

const IndividualReportingFolder: React.FC<IPartnersInBranchFolderProps> = ({ root, inBranchPath }) => (
  <FolderBase
    canEdit={false}
    Prefix={inBranchPath}
    prefixNoParents={inBranchPath}
    root={root}
    key={inBranchPath}
    restricted={false}
  />
);

const StoreReportingFolder: React.FC<IPartnersInBranchFolderProps> = ({ root, inBranchPath }) => (
  <FolderBase
    canEdit={false}
    Prefix={inBranchPath}
    prefixNoParents={inBranchPath}
    root={root}
    key={inBranchPath}
    restricted={false}
  />
);

interface IDashboardFilesProps {
  lookerDashboardList: ILookerDashboard[];
}

const DashboardFiles: React.FC<IDashboardFilesProps> = ({ lookerDashboardList }) => (
  <>
    {lookerDashboardList.map(lookerDashboard => (
      <FileBase
        Key={lookerDashboard.name}
        location={lookerDashboard.location}
        LastModified={null}
        fileName={lookerDashboard.name}
        canEdit={false}
        root='reports'
        key={lookerDashboard.name}
      />
    ))}
  </>
);

class FileList extends React.Component<IFileListProps, IFileListState> {
  public state: IFileListState = {
    showMenu: false
  };

  // tslint:disable-next-line: max-func-body-length
  public render (): JSX.Element {
    const {
      canEdit,
      currentPartner,
      isDashboardsFolder,
      isVisualizationFolder,
      pathNoRoot,
      isRootFolder,
      parentRestricted,
      fileUpload,
      fileQuery: { data: { reports }, refetch },
      root,
      type,
      uploadingFile
    } = this.props;
    const allLooker: ILookerDashboard[] = !!currentPartner && LookerDashboardConfig[currentPartner];
    const lookerDashboardList = allLooker?.filter(({ location = '' }) => location === pathNoRoot);
    const displayableLookerDashboardList = allLooker?.filter(({ location = '' }) => formatPath(location) === pathNoRoot);

    const showingDashboardsFolder: boolean = type === '__reports__' && lookerDashboardList.length > 0;
    const showingDashboardFiles: boolean = !!displayableLookerDashboardList.length && isDashboardsFolder;

    const isEmptyDirectory: boolean = !isVisualizationFolder && !reports.folders.length && !reports.files.length
      && !showingDashboardsFolder && !showingDashboardFiles && !lookerDashboardList.length;

    const allowedPermissions = [VIEW_IN_BRANCH_INDIVIDUAL, VIEW_IN_BRANCH_STORE];
    const showEditActions = canEdit && !isDashboardsFolder && !isVisualizationFolder;

    return (
      <FileListContain>
        <>
          {showingDashboardsFolder && (
            <DashboardFolder
              pathNoRoot={pathNoRoot}
              root={root}
            />
          )}
          {showingDashboardFiles && (
            <DashboardFiles
              lookerDashboardList={displayableLookerDashboardList}
            />
          )}
          {!!FEATURE_FLAGS.portalInBranch && isRootFolder &&
            <>
              <PermissionedComponent allowedPermissions={[VIEW_IN_BRANCH_INDIVIDUAL]} >
                <IndividualReportingFolder
                  root={root}
                  inBranchPath={INDIVIDUAL_REPORTING_PATH}
                />
              </PermissionedComponent>
              <PermissionedComponent allowedPermissions={[VIEW_IN_BRANCH_STORE]} >
                <StoreReportingFolder
                  root={root}
                  inBranchPath={STORE_REPORTING_PATH}
                />
              </PermissionedComponent>
            </>
          }
          {isVisualizationFolder &&
            <PermissionedComponent allowedPermissions={allowedPermissions} >
              <InBranchDashboard
                {...this.props}
                root={root}
                isVisualizationFolder={isVisualizationFolder}
                currentDate={new Date()}
              />
            </PermissionedComponent>
          }
          {isEmptyDirectory && (
            <EmptyRow>
              <span>This folder is empty.</span>
            </EmptyRow>
          )}
          {reports.folders.map(folder => (
            <ConditionalLiftedFolder
              {...folder}
              root={root}
              canEdit={canEdit}
              key={folder.Prefix}
              refetch={refetch}
              type={type}
              parentRestricted={parentRestricted}
            />
          ))}
          {reports.files.map(file => (
            <ConditionalLiftedFile {...file} root={root} canEdit={canEdit} key={file.Key} refetch={refetch} type={type} />
          ))}
          {showEditActions && (
            <>
              <ConditionalRenderWrapper breakpoint='small' hiddenOnMobile={true}>
                <DragText>Drag and drop to add new files</DragText>
              </ConditionalRenderWrapper>
              <ConditionalRenderWrapper breakpoint='small' hiddenOnMobile={false}>
                <MobileCTAButton onClick={this.toggleMenu}>
                  <SVGIcon icon='plus' />
                </MobileCTAButton>
                <ActionDrawer
                  type={type}
                  prefix={pathNoRoot}
                  refetch={refetch}
                  show={this.state.showMenu}
                  close={this.toggleMenu}
                  fileUpload={fileUpload}
                  uploadingFile={uploadingFile}
                  showCreateModal={this.props.showCreateModal}
                />
              </ConditionalRenderWrapper>
            </>
          )}
        </>
      </FileListContain>
    );
  }

  private readonly toggleMenu: React.MouseEventHandler = e => {
    if (e) { e.stopPropagation(); }

    this.setState(({ showMenu }) => ({ showMenu: !showMenu }));
  };
}

export default FileList;
