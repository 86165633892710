import * as React from 'react';
import camelCase from 'lodash-es/camelCase';
import { Controller, useFormContext } from 'react-hook-form';
import {
  SelectInput,
  SelectOption,
} from '@amount/frontend-components';
import styled from 'styled-components';

import { SelectionOnChildren, SelectionOnValidValues } from '../../queries/getRequestFields.graphql';

const CascadeSelectWrapper = styled.div`
  display: flex;
  & select:first-child {
    margin-right: 1em;
  }
`;

export interface ICascadeValue {
  id: string;
  child: {
    id: string;
  };
}

interface IInput {
  name: string;
  value: ICascadeValue;
  options: SelectionOnValidValues[];
  isRequired: boolean;
  onChange (id: string, obj: ICascadeValue): void;
}

export const CascadeSelect: React.FC<IInput> = ({ name, value, options, isRequired }) => {
  const valueWithChildren: SelectionOnValidValues | undefined = options.find(o => value.id === o.value);
  const children: SelectionOnChildren[] = valueWithChildren && valueWithChildren.children || [];

  const { control } = useFormContext();

  return (
    <CascadeSelectWrapper>
      <Controller
        control={control}
        rules={{ required: isRequired }}
        name={name}
        render={({ onChange }) => (
          <>
            <SelectInput
              name={name}
              onChange={onChange}
              value={value.id || ''} // When value does not exist, default to hidden/disabled option
              data-event={camelCase(`${name} select`)}
            >
              {options.map(o => <SelectOption key={o.value} value={o.value}>{o.label}</SelectOption>)}
              <SelectOption hidden={true} disabled={true} value='' />
            </SelectInput>
            <SelectInput
              name={`${name}-child`}
              onChange={onChange}
              value={value && value.child && value.child.id || ''}
              data-event={camelCase(`${name} select`)}
            >
              {children.map(o => <SelectOption key={o.value} value={o.value}>{o.label}</SelectOption>)}
              <SelectOption hidden={true} disabled={true} value='' />
            </SelectInput>
          </>
        )}
      />
    </CascadeSelectWrapper>
  );
};
