import * as React from 'react';
import camelCase from 'lodash-es/camelCase';
import { FormLabel, SelectInput } from '@amount/frontend-components';

import { PermissionedComponent } from '../../PermissionedComponent';

import { Section } from './';

export interface IOption {
  id: string;
  name: string;
  permissions?: string[];
}

interface IHelpdeskSectionSelectProps {
  autoFocus: boolean;
  value: string;
  label: string;
  options: IOption[];
  onChange: React.FormEventHandler<HTMLSelectElement>;
}

const HelpdeskSectionSelect: React.FC<IHelpdeskSectionSelectProps> = props => (
  <SelectInput
    // autoFocus={true}
    onChange={props.onChange}
    value={props.value}
    data-event={camelCase(props.label)}
  >
    <option role='option' aria-selected={props.value === ''} value='' />
    {props.options.map(({ id, name, permissions }) => (
      <PermissionedComponent key={id} allowedPermissions={permissions}>
        <option role='option' aria-selected={props.value === id} value={id}>{name}</option>
      </PermissionedComponent>
    ))}
  </SelectInput>
);

interface IHelpdeskTicketSection {
  sectionName: string;
  label: string;
  selectedValue: string;
  onSelect: React.FormEventHandler<HTMLSelectElement>;
  options: IOption[];
}

export const HelpdeskTicketSection: React.FC<IHelpdeskTicketSection> = props => (
  <Section key={props.sectionName}>
    <FormLabel>{props.label}</FormLabel>
    <HelpdeskSectionSelect
      autoFocus={true}
      onChange={props.onSelect}
      value={props.selectedValue}
      options={props.options}
      label={props.label}
    />
  </Section>
);
