import {
  media,
  SVGIcon
} from '@amount/frontend-components';
import styled from 'styled-components';

export const BreadcrumbWrap = styled.div`
  position: absolute;
  top: -3em;

  ${media.small`
    top: -3.5em;
  `}
`;

export const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.25em;
`;

export const BackArrow = styled(SVGIcon)`
  display: flex;
  color: ${({ theme: { brand } }) => brand.colorAlert};
  padding: 0 0.5em 0.125em 0;

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme: { brand } }) => brand.colorAlert};
  }
`;
