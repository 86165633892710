import {
  BannerSection,
  ConditionalRenderWrapper,
  FormLabel,
  HorizontalDivider,
  media,
} from '@amount/frontend-components';
import styled, { css } from 'styled-components';

const fullPageContainCss = css`
  flex-direction: row;
`;

export const RequestContain = styled.div`
  display: flex;
  flex-direction: column;
  ${media.mediumLarge`
    ${fullPageContainCss}
  `}

  @media print {
    ${fullPageContainCss}
  }
`;

export const RequestBody = styled.div`
  padding-right: 1em;
  flex: 1;
  min-width: 0;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

export const RequestMeta = styled.div`
  max-width: 15em;
  border-left: ${({ theme }) => `1px solid ${theme.colorSlate10}`};
  padding-left: 1em;

  @media print {
    max-width: 45%;
  }  
`;

export const StyledBannerSection = styled(BannerSection)`
  text-transform: uppercase;

  @media print {
    padding-left: 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.colorSlate10}`};
  }
`;

export const StyledDivider = styled(HorizontalDivider)`
  height: 2px;
  ${media.small`
    height: 1px;
  `}
`;

export const CommentWrapper = styled.div`
  @media print {
    position: absolute;
    top: calc(100% + 4em);
    width: 90%;
  }
`;

export const StyledRenderWrapper = styled(ConditionalRenderWrapper)`
  flex-direction: column;
`;

export const StyledFormLabel = styled(FormLabel)`
  margin-top: 16px;
`;

const formStyle = css`
  margin: 0.5em 0;
  padding: 0.5em 0;
`;

export const Field = styled.div`
  ${formStyle}
`;

export const FieldForm = styled.form`
  ${formStyle}
`;


const fullPageCommentHeaderCss = css`
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5em;
  flex-direction: row;
`;

export const FieldHeader = styled.div`
  flex-direction: column;
  display: inline-flex;

  ${media.small`
    ${fullPageCommentHeaderCss}
  `}

  @media print {
    ${fullPageCommentHeaderCss}
  }
`;

const labelStyle = css`
  margin-right: 0.5em;
  font-weight: 700;
  font-size: 1em;
`;

export const FieldFormLabel = styled.label`
  ${labelStyle}
`;

export const FieldLabel = styled.h2`
  ${labelStyle}
`;

export const FieldData = styled.div``;
