import * as React from 'react';
import { Mutation } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { EDIT_USERS, VIEW_USERS } from '../../../shared/config/permissions';
import { PermissionedComponent } from '../PermissionedComponent';
import HomeRedirectComponent from '../HomeRedirectComponent';
import { Organizations } from '../../../shared/config/partners';

import createUserMutation, { CreateUser, CreateUserInput } from './queries/createUser.graphql';
import { UserCreate } from './Presentational/UserCreate';
import { UserManagementContext } from './UserManagement.context';

const LiftedUserCreate: React.FC<RouteComponentProps> = props => {
  const { state: userMgmt } = React.useContext(UserManagementContext);

  return (
    <PermissionedComponent allowedPermissions={[VIEW_USERS]} NoAccessComponent={HomeRedirectComponent}>
      <PermissionedComponent allowedPermissions={[EDIT_USERS]} NoAccessComponent={HomeRedirectComponent}>
        <Mutation<CreateUser, CreateUserInput>
          mutation={createUserMutation}
        >
          {createUser => (
            <UserCreate
              {...props}
              organization={userMgmt.currentOrg as Organizations}
              createUser={createUser}
            />
          )}
        </Mutation>
      </PermissionedComponent>
    </PermissionedComponent>
  );
};

export default LiftedUserCreate;
