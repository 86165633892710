import {
  media,
  NavButton,
} from '@amount/frontend-components';
import styled, { css } from 'styled-components';

export const ButtonActionRow = styled.div`
  margin-top: 2.5em;
  display: block;
  width: 100%;
  flex-direction: column;
  align-items: center;

  ${media.small`
    display: flex;
    flex-direction: row;
  `}
`;

export const InputRow = styled.div`
  margin-top: 1.5em;
`;

export const CancelButton = styled(NavButton)<{ hidden?: boolean }>`
  display: ${({ hidden }) => hidden ? 'none' : 'block'};
  width: 100%;
  text-align: center;
  margin-top: 1.75em;
  /* sailec fix */
  padding-top: 0.125em;

  ${({ hidden }) => css`
    ${media.small`
      display: ${hidden ? 'none' : 'flex'};
      width: auto;
      margin-top: 0;
      margin-left: 2em;
    `}
  `}
`;

export const ModalText = styled.p`
  margin-bottom: 2em;
  line-height: 2.8rem;
`;
