import * as React from 'react';
import { Query } from 'react-apollo';

import { Consumer } from '../Context';

import getRolesQuery, { GetRoles as getRoles, GetRolesInput } from './queries/getRoles.graphql';
import { ILiftedProps, UserFilters } from './Presentational/Filters';

const LiftedUserFilters: React.FC<ILiftedProps> = props => (
  <Consumer>
    {me => (
      <Query<getRoles, GetRolesInput>
        query={getRolesQuery}
        // tslint:disable-next-line: no-non-null-assertion
        variables={{ organization: me.currentPartner! }}
      >
        {({ data, loading }) => (
          <UserFilters {...props} data={data} loading={loading} />
        )}
      </Query>
    )}
  </Consumer>
);

export default LiftedUserFilters;
