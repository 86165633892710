import * as React from 'react';
import { Mutation } from 'react-apollo';

import SetPartnerQuery, { SetPartner, SetPartnerInput } from './queries/setPartner.graphql';
import { ILiftedPartnerSwitcherProps, PartnerSwitcher } from './Presentational/PartnerSwitcher';

const LiftedPartnerSwitcher: React.FC<ILiftedPartnerSwitcherProps> = props => (
  <Mutation<SetPartner, SetPartnerInput>
    mutation={SetPartnerQuery}
  >
    {mutation => (
      <PartnerSwitcher
        {...props}
        setPartner={mutation}
      />
    )}
  </Mutation>
);

export default LiftedPartnerSwitcher;
