export const getParentDirectories: (path?: string) => string =
  path => (path || '').split('/').filter(x => !!x).slice(0, -1).join('/') || '';
export const removeParentDirectories: (path: string) => string | undefined = path => {
  const parentDirectoriesArr: string[] = (path.endsWith('/') ? path.slice(0, -1) : path)
    .split('/')
    .filter((x: string | null): x is string => !!x);

  return parentDirectoriesArr[parentDirectoriesArr.length - 1];
};


// Blindly string-appending a param onto a URL is bug-prone.
// It is not easy to know if you should append with ? or &.
// The URL class does that for us.
export const appendQueryParams = (urlString: string, params: Record<string, string | undefined>) => {
  const isRelativeUrl = urlString.startsWith('/');
  const fullUrl = isRelativeUrl ? `http://dummydomain.io${urlString}` : urlString;

  const url = new URL(fullUrl);
  for (const [key, value] of Object.entries(params)) {
    if (value) {
      url.searchParams.append(key, value);
    }
  }

  return isRelativeUrl ? `${url.pathname}${url.search}` : url.toString();
};
