import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Query } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import { Helmet } from 'react-helmet';

import { SARList } from '../List/List';
import { SelectionOnSarsConnection } from '../getPage.graphql';
import { Button } from '../Buttons';
import { ISortOption, SortComp, SortOptions } from '../Sort';
import { ModuleRoutes } from '../../../../shared/routes';

import SearchSARsQuery, { SearchSARs, SearchSARsInput } from './search.graphql';
import { search__Container, search__Inputs__Text, search__Input__Group, search__Input__Label } from './style.css';

type SearchRouteComponentProps = RouteComponentProps<{ searchQuery: string }>;

interface IState {
  query: string;
}

interface ISearchBaseProps extends SearchRouteComponentProps, IState {
  setQuery: React.FormEventHandler<HTMLInputElement>;
}

const FormattedError: React.FC<{ error: ApolloError }> = () => (
  <div style={{ color: 'red' }}>
    There was an error in your search.
    <br />
    Please make sure to use Boolean operators (&, |, etc.) if you are using spaces.
    <br /><br />
    For example:
    <ul>
      <li>matching_bank in Illinois<pre>IL & matching_bank</pre></li>
      <li>matching_bank or matching_phone<pre>matching_phone | matching_bank</pre></li>
    </ul>

  </div>
);

const SearchComponentBase: React.FC<ISearchBaseProps> = ({ match: { params: { searchQuery } }, query, setQuery, history }) => {
  const [sortState, setSortState] = React.useState<ISortOption>(SortOptions.createdAsc);
  const [open, setOpen] = React.useState<boolean>(false);
  const toggleOpen = () => setOpen(openState => !openState);
  const searchRef = React.createRef<HTMLInputElement>();

  React.useEffect(() => {
    const input = searchRef.current;
    if (input) {
      const elt = input as HTMLElement;
      elt.focus();
    }
  },              [open]);

  const linkRef = React.createRef<HTMLDivElement>();

  const handleSortChange: (option: ISortOption) => void = option => {
    setSortState(option);
  };

  const handleSearch: (event: React.FormEvent<HTMLFormElement>) => void = e => {
    history.push(`${ModuleRoutes.psaes}/search/${query}`);
    e.preventDefault();
  };

  // tslint:disable: jsx-no-lambda
  return (
    <Query<SearchSARs, SearchSARsInput>
      query={SearchSARsQuery}
      variables={{ query: searchQuery, field: sortState.field, direction: sortState.direction }}
      skip={!searchQuery}
    >
      {({ data, error, loading, refetch }) => (
        <>
          <Helmet>
            <title>Search · PSAEs · Partner Portal</title>
          </Helmet>
          <div>
            <form
              className={search__Container}
              onSubmit={handleSearch}
            >
              <div className={search__Input__Group}>
                <label className={search__Input__Label} htmlFor='search'>Search</label>
                <input
                  className={search__Inputs__Text}
                  type='text'
                  id='search'
                  ref={searchRef}
                  defaultValue={query}
                  onChange={setQuery}
                  data-event='searchInput'
                />
                <Button type='submit' data-event='searchButton'>Search</Button>
              </div>
            </form>
          </div>
          <SortComp linkRef={linkRef} toggleOpen={toggleOpen} open={open} handleSortChange={handleSortChange} />
          {error && <FormattedError error={error} />}
          {(data && data.searchSars && !error && !!searchQuery) && (
            <SARList
              data={{
                sarsConnection: data.searchSars as SelectionOnSarsConnection
              }}
              loading={loading}
              // tslint:disable-next-line: no-any
              refetch={refetch as any}
              type={'search'}
              status={'search'}
            />
          )}
        </>
      )}
    </Query>
  );
  // tslint:enable: jsx-no-lambda
};

const SearchComponent: React.FC<SearchRouteComponentProps> = props => {
  const [query, setQuery] = React.useState(props.match.params.searchQuery);

  const handleSetQuery: React.FormEventHandler<HTMLInputElement> = e => {
    setQuery(e.currentTarget.value);
  };

  return (
    <SearchComponentBase
      query={query}
      setQuery={handleSetQuery}
      {...props}
    />
  );
};

const RoutedSearchComponent = withRouter(SearchComponent);

export default RoutedSearchComponent;
