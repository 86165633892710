import {
  MaxWidthContainer,
  media,
} from '@amount/frontend-components';
import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  width: 100%;
  background-color: ${({ theme: { card } }) => card.colorBackground};
`;

export const HeaderContain = styled(MaxWidthContainer)`
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  z-index: 10;
  padding: 0 1.75rem;
  height: 4em;

  ${media.small`
    height: 5em;
  `}
`;
