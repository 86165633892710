import * as React from 'react';
import { partition } from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { ApolloQueryResult } from 'apollo-client';

import { SelectionOnChangeRequestFieldValues, GetRequestDetail } from '../../queries/getRequestDetail.graphql';
import { StyledDivider } from '../common';
import RequestFields from '../RequestFieldList';
import { changeRequestCustomFieldMap } from '../../../../../shared/config/helpdeskChangeRequest';
import UpdateChangeRequestMutation, {
  UpdateChangeRequest,
  UpdateChangeRequestInput,
} from '../../queries/updateChangeRequest.graphql';

import EditableField from './EditableField';

interface IProps {
  requestKey: string;
  fields: SelectionOnChangeRequestFieldValues[] | null;
  refetch (): Promise<ApolloQueryResult<GetRequestDetail>>;
}

const getFields = (field: SelectionOnChangeRequestFieldValues): boolean =>
  changeRequestCustomFieldMap[field.fieldId]?.editable;

const ChangeRequest: React.FC<IProps> = ({ fields, requestKey, refetch }) => {
  const [updateRequest] = useMutation<UpdateChangeRequest, UpdateChangeRequestInput>(UpdateChangeRequestMutation);

  if (!fields) {
    return null;
  }

  const [editableFields, displayFields] = partition(fields, getFields);

  return (
    <>
      <StyledDivider />
      {editableFields.map(field => (
        <EditableField
          key={field.fieldId}
          field={field}
          editField={updateRequest}
          requestKey={requestKey}
          refetch={refetch}
        />
      ))}
      <RequestFields fields={displayFields} issueId='' attachmentIds={[]} />
    </>
  );
};

export default ChangeRequest;
