import * as React from 'react';
import { Mutation, Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';

import { EDIT_USERS, VIEW_USERS } from '../../../shared/config/permissions';
import { InnerContainer } from '../InnerContainer';
import { PermissionedComponent } from '../PermissionedComponent';
import { Spinner } from '../Spinner';
import HomeRedirectComponent from '../HomeRedirectComponent';
import { Organizations } from '../../../shared/config/partners';

import getUserQuery, { GetUser, GetUserInput } from './queries/getUser.graphql';
import editUserInfoMutation, { EditUserInfo, EditUserInfoInput } from './queries/editUserInfo.graphql';
import { UserInfoEdit } from './Presentational/UserInfoEdit';
import { UserManagementContext } from './UserManagement.context';

type ILiftedProps = RouteComponentProps<{ id: string }>;

const LiftedUserInfoEdit: React.FC<ILiftedProps> = props => {
  const { state: userMgmt } = React.useContext(UserManagementContext);

  return (
    <PermissionedComponent allowedPermissions={[VIEW_USERS]} NoAccessComponent={HomeRedirectComponent}>
      <PermissionedComponent allowedPermissions={[EDIT_USERS]} NoAccessComponent={HomeRedirectComponent}>
        <Query<GetUser, GetUserInput>
          query={getUserQuery}
          variables={{ id: props.match.params.id, organization: userMgmt.currentOrg }}
        >
          {({ data, loading, refetch }) => (
            <Mutation<EditUserInfo, EditUserInfoInput>
              mutation={editUserInfoMutation}
            >
              {editUser => (
                <InnerContainer>
                  {loading ? (
                    <Spinner />
                  ) : (
                      <UserInfoEdit
                        {...props}
                        data={data}
                        editUser={editUser}
                        refetchUser={refetch}
                        organization={userMgmt.currentOrg as Organizations}
                      />
                    )}
                </InnerContainer>
              )}
            </Mutation>
          )}
        </Query>
      </PermissionedComponent>
    </PermissionedComponent>
  );
};

export default LiftedUserInfoEdit;
