import * as React from 'react';
import { Query } from 'react-apollo';
import {
  Headline,
} from '@amount/frontend-components';
import styled from 'styled-components';

import { Spinner } from '../Spinner';
import {
  READABLE_PARTNER_NAME_MAP,
} from '../../../shared/config/partners';

import getRolesQuery, { GetRoles as getRoles, GetRolesInput } from './queries/getRoles.graphql';
import {
  FormFieldsSection,
  IUserRolesFieldsProps,
  UserRoleFields,
} from './Presentational/common';

const PermissionHeader = styled.div`
  margin-top: 2.5em;
  display: flex;
  justify-content: space-between;
`;

const RemoveButton = styled.button`
  font-size: 1.4rem;
  color: ${({ theme: { brand } }) => brand.colorAlert};

  &:hover {
    color: ${({ theme: { button: { primary } } }) => primary.colorBackgroundHover};
  }
`;

interface IUserRolesProps extends Pick<IUserRolesFieldsProps, 'partner' | 'selectRole'> {
  selectedRole: string | undefined;
  currentPartner: string;
  removePartner: React.MouseEventHandler<HTMLButtonElement>;
}

export const LiftedUserRoleFields: React.FC<IUserRolesProps> = ({ partner, selectedRole, currentPartner, removePartner, ...props }) => (
  <Query<getRoles, GetRolesInput>
    query={getRolesQuery}
    // tslint:disable-next-line: no-non-null-assertion
    variables={{ organization: currentPartner }}
  >
    {({ data, loading }) => (
      <FormFieldsSection>
        <PermissionHeader>
          <Headline scale='small'>{READABLE_PARTNER_NAME_MAP[partner]} Role</Headline>
          {currentPartner !== partner && (
            <RemoveButton data-partner={partner} onClick={removePartner}>Remove</RemoveButton>
          )}
        </PermissionHeader>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {!data ? (
              <p>Failed to retrieve roles.</p>
            ) : (
              <UserRoleFields {...props} partner={partner} selectedRoleId={selectedRole} data={data} />
            )}
          </>
        )}
      </FormFieldsSection>
    )}
  </Query>
);
