import * as React from 'react';
import * as classnames from 'classnames';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Query } from 'react-apollo';
import camelCase from 'lodash-es/camelCase';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { TabInformation, UIToSARStatus } from '../../../shared/transitions/sar';
import { SAR_TAB_PERMISSIONS, VIEW_PSAE } from '../../../shared/config/permissions';
import { PermissionedComponent } from '../PermissionedComponent';
import { client as apolloClient } from '../../client';
import { LiftedPusherProps, SubscribeCallback, withPusher } from '../HOC/withPusher';
import { ISarPubsubInfo, PRIVATE_SARS_CHANNEL } from '../../../shared/pubsub';
import HomeRedirectComponent from '../HomeRedirectComponent';
import { ModuleRoutes } from '../../../shared/routes';
import { PartneredComponent } from '../PartneredComponent';
import { ModuleRouteMap } from '../../../shared/config/modules';
import { hidePartnerSwitcher } from '../../services/hidePartnerSwitcher';

import * as styles from './style.css';
import GetPage, { GetPage as IGetPage } from './getPage.graphql';
import SARCountQuery, { GetSARCount, GetSARCountInput } from './getSARCount.graphql';
import RoutedSearchComponent from './Search';
import SARInfo from './info';
import NextInQueue from './Queue';
import FileGeneration from './FileGeneration';
import {
  SARCompleted,
  SARDrafting,
  SARFINCEN,
  SARFraudApproval,
  SARLegalApproval,
  SARNarrative,
  SARPartnerRejected,
  SARRejected,
  SARWebBankApproval
} from './Steps';
import Narrative from './Workflow/Narrative';

// tslint:disable-next-line: no-suspicious-comment
// TODO: this is ugly and should be cleaned up immediately when we redesign this module.
const SarContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  /* temporary override to fix darkmode */
  background-color: #f8f8f8;

  h1 {
    margin: 0;
  }

`;

interface ILinkProps extends React.ClassAttributes<HTMLAnchorElement> {
  filterKey?: string;
  path: string;
}

const SidebarLinkExtra: React.FC = ({ children }) => (
  <span className={styles.sarMenu__item__count} aria-hidden='true'>
    {children}
  </span>
);

const SidebarContents: React.FC<{ data?: GetSARCount; loading: boolean }> = ({ data, loading }) => (
  (!data || loading) ? (
    <div className='spinner spinner-small spinner-white' />
  ) : (
      <span>{data.sarsConnection.count}</span>
    )
);

const SidebarLinkCounter: React.FC<{ filterKey: string }> = ({ filterKey }) => (
  <Query<GetSARCount, GetSARCountInput>
    query={SARCountQuery}
    variables={{ status: UIToSARStatus[filterKey || ''] }}
  >
    {({ data, loading }) => (
      <SidebarLinkExtra>
        <SidebarContents data={data} loading={loading} />
      </SidebarLinkExtra>
    )}
  </Query>
);

interface ISidebarLink extends ILinkProps {
  name: string;
}

const SidebarLink: React.FC<ISidebarLink> = ({ name, path, children, filterKey }) => (
  <NavLink
    className={styles.sarMenu__item}
    activeClassName={styles.sarMenu__item__active}
    to={`${ModuleRoutes.psaes}/${path}`}
    data-event={camelCase(`${name} SidebarLink`)}
  >
    {children}
    {!!filterKey && <SidebarLinkCounter filterKey={filterKey} />}
  </NavLink>
);

type IUIProps = RouteComponentProps<{ id?: string }>;

// TS#13288
class SARUI extends React.Component<LiftedPusherProps<IUIProps>> {
  public componentDidMount () {
    this.disablePartnerSwitcher();
  }

  public componentWillUnmount () {
    this.enablePartnerSwitcher();
  }

  public render (): JSX.Element {
    return (
      <SarContainer>
        <Helmet>
          <title>PSAEs · Partner Portal</title>
        </Helmet>
        <nav className={styles.sarMenuContainer}>
          <ul className={styles.sarMenu}>
            <li>
              <h1>
                <NavLink
                  className={classnames(styles.sarMenu__item, styles.sarMenu__header)}
                  to={`${ModuleRoutes.psaes}`}
                  data-event={camelCase(`${ModuleRoutes.psaes} Sidebar`)}
                >
                  PSAEs
                </NavLink>
              </h1>
            </li>

            <li>
              <SidebarLink path='search' name='search'>
                Search
                <SidebarLinkExtra>🔍</SidebarLinkExtra>
              </SidebarLink>
            </li>
            {
              TabInformation.map(info => (
                <PermissionedComponent
                  allowedPermissions={Array.from(info.permissionKey, key => SAR_TAB_PERMISSIONS[key])}
                  key={info.filterKey}
                >
                  <li key={info.filterKey}>
                    {/* tslint:disable-next-line: no-any */}
                    <SidebarLink {...info as any}>
                      {info.name}
                    </SidebarLink>
                  </li>
                </PermissionedComponent>
              ))
            }

          </ul>
        </nav>
        <div className={styles.sarBody}>
          <Switch>
            <Route path={`${this.props.match.path}/search/:searchQuery?`} >
              <RoutedSearchComponent />
            </Route>
            <Route exact={true} path={`${this.props.match.path}/rejected`} >
              <SARRejected />
            </Route>
            <Route exact={true} path={`${this.props.match.path}/narrative`}>
              <SARNarrative />
            </Route>
            <Route exact={true} path={`${this.props.match.path}/drafting`} >
              <SARDrafting />
            </Route>
            <Route exact={true} path={`${this.props.match.path}/fraudApproval`} >
              <SARFraudApproval />
            </Route>
            <Route exact={true} path={`${this.props.match.path}/legalApproval`} >
              <SARLegalApproval />
            </Route>
            <Route exact={true} path={`${this.props.match.path}/partnerApproval`} >
              <SARWebBankApproval />
            </Route>
            <Route exact={true} path={`${this.props.match.path}/fincen`}>
              <SARFINCEN />
            </Route>
            <Route exact={true} path={`${this.props.match.path}/partnerRejected`}>
              <SARPartnerRejected />
            </Route>
            <Route exact={true} path={`${this.props.match.path}/completed`}>
              <SARCompleted />
            </Route>
            <Route path={`${this.props.match.path}/nextInQueue/:queue?`} >
              <NextInQueue />
            </Route>
            <Route exact={true} path={`${this.props.match.path}/generateFile`} >
              <FileGeneration />
            </Route>
            <Route exact={true} path={`${this.props.match.path}/:id/narrative`} >
              <Narrative editable={true} />
            </Route>
            <Route exact={true} path={`${this.props.match.path}/:id`} >
              <SARInfo />
            </Route>
          </Switch>
        </div>
      </SarContainer>
    );
  }
  private readonly disablePartnerSwitcher = (): void => {
    hidePartnerSwitcher.setTrue();
  }

  private readonly enablePartnerSwitcher = (): void => {
    hidePartnerSwitcher.setFalse();
  }
}

type GetDataType = (oldData: IGetPage) => (IGetPage | Promise<IGetPage>);
type WriteToStore = (getData: GetDataType) => Promise<void>;
const generateWriteToStoreFn: (status: string) => WriteToStore = status => async getData => {
  let newPage: IGetPage | null;

  try {
    newPage = apolloClient.readQuery<IGetPage>({
      query: GetPage,
      variables: {
        status,
        first: 10
      }
    });
  } catch (e) {
    // we get here if the page hasn't been loaded and there's no data
    // so we shouldn't try to update the data :)
    return;
  }
  if (!newPage) { return; }

  try {
    apolloClient.writeQuery({
      data: await getData(newPage),
      query: GetPage,
      variables: { status, first: 10 }
    });
  } catch (e) {
    console.error('Error adding SAR');
    console.error(e);
  }
};

const buildRemoveFn: (id: string) => GetDataType = id => oldData => {
  const data: IGetPage = { ...oldData };
  // tslint:disable-next-line: no-non-null-assertion
  data.sarsConnection.edges = (data.sarsConnection.edges || []).filter(e => e!.node.id !== id);

  return data;
};
const buildQueryPageFn: (status: string, count: number) => () => Promise<IGetPage> = (status, count) => async () => (
  apolloClient.query<IGetPage>({ query: GetPage, variables: { status, first: count } }).then(x => x.data)
);

const updateStore: (id: string, status: string, adding: boolean) => void = async (id, status, adding) => {
  try {
    // update counts
    const addendOrSubtrahend: number = adding ? 1 : -1;
    const query: GetSARCount | null = apolloClient.readQuery<GetSARCount>({
      query: SARCountQuery,
      variables: { status },
    });
    if (!query) { return; }
    let newCount: number = (query.sarsConnection.count || 0) + addendOrSubtrahend;
    if (newCount < 0) { newCount = 0; }

    query.sarsConnection = {
      ...query.sarsConnection,
      count: newCount
    };

    apolloClient.writeQuery({
      query: SARCountQuery,
      variables: { status },
      data: query
    });

    const writeToStore: WriteToStore = generateWriteToStoreFn(status);
    // update store
    if (adding) {
      void writeToStore(buildQueryPageFn(status, newCount));
    } else {
      void writeToStore(buildRemoveFn(id));
    }
  } catch (e) {
    console.error(e);
  }
};

type PusherUpdateFnType = SubscribeCallback<IUIProps, ISarPubsubInfo>;
const PusherUpdateFn: PusherUpdateFnType = () => ({ id, status, previousStatus }) => {
  updateStore(id, status, true);
  updateStore(id, previousStatus, false);
};

const Component = withRouter(withPusher(PRIVATE_SARS_CHANNEL, 'transition', PusherUpdateFn)(SARUI));

const LiftedComponent: React.FC = () => (
  <PartneredComponent allowedPartners={ModuleRouteMap.psaes.partners} NoAccessComponent={HomeRedirectComponent}>
    <PermissionedComponent allowedPermissions={[VIEW_PSAE]} NoAccessComponent={HomeRedirectComponent}>
      <Component />
    </PermissionedComponent>
  </PartneredComponent>
);

export default LiftedComponent;
