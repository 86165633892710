/* eslint-disable max-lines */
import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import * as classname from 'classnames';
import ErrorNotification from '@avant/crm-frontend-utils/error';
import identity from 'lodash-es/identity';
import { format } from 'date-fns';

import { ISarInfo } from '../../interfaces';
import TimeWidget from '../../../TimeWidget';
import { ATTRIBUTES_BY_TYPE, AttributesType, FormatterType, SARTypes } from '../../../../../shared/transitions/sar';
import { table as tableStyle } from '../../List/style.css';
import { PSAE_DATE_FORMAT } from '../../common/constants';

import CreateSarMetadata, { CreateSARMetadata, CreateSARMetadataInput } from './createSARMetadata.graphql';
import {
  informationContainer,
  viewMatchContainer,
  viewMatchDetails__Information__Active,
  viewMatchDetails__Information__Container,
  viewMatchDetails__Information__Item,
  viewMatchDetails__Item__Information__Container,
  viewMatchDetails__Selectors__Container,
  viewMatchDetails__Selectors__Selector,
  viewMatchDetails__Selectors__Selector__Active,
} from './style.css';
import { shouldDisplayTeamFilter } from './common';

interface IMatchInformation {
  // tslint:disable-next-line: no-reserved-keywords
  type: SARTypes;
  // tslint:disable-next-line: no-any
  data: { [k: string]: any };
  selected: boolean;
}

const mapKeysToAttrType: (keys: string[]) => AttributesType[] = keys => keys.map(key => ({ key }));
const formatValue: (formatter?: FormatterType) => FormatterType = formatter => value => (
  formatter || identity as FormatterType
)(value);

const formatLoanType = (sar: ISarInfo): string => {
  const noLoanResult: string = '';
  if (!Array.isArray(sar.data)) { return noLoanResult; }

  return [...new Set(sar.data.map(loan => loan.loan_type))].join(' & ');
};

const MatchInformation: React.FC<IMatchInformation> = ({ type, data, selected }) => (
  <table className={classname(tableStyle, viewMatchDetails__Information__Item, { [viewMatchDetails__Information__Active]: selected })}>
    <tbody>
      {(ATTRIBUTES_BY_TYPE[type] || mapKeysToAttrType(Object.keys(data))).map(({ key, display = key, formatter }) => (
        <tr key={key}>
          <th>{display}</th>
          <td>{formatValue(formatter)(data[key])}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

type IViewMatchesProps = ISarInfo;

class ViewMatches extends React.Component<IViewMatchesProps, { view: string }> {
  // tslint:disable-next-line: no-any
  constructor (props: IViewMatchesProps, ctx: any) {
    super(props, ctx);

    this.state = {
      view: Object.keys(this.props.data)[0]
    };
  }

  public render (): JSX.Element {
    const keys: string[] = Object.keys(this.props.data);

    return (
      <div className={viewMatchContainer}>
        <div className={viewMatchDetails__Selectors__Container}>
          {keys.map(match => (

            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              key={match}
              role='button'
              data-view={match}
              className={
                classname(
                  viewMatchDetails__Selectors__Selector,
                  { [viewMatchDetails__Selectors__Selector__Active]: this.state.view === match }
                )
              }
              onClick={this.switchView}
              data-event={`viewMatchSelector-${parseInt(match, 10) + 1}`}
              tabIndex={0}
            >
              {parseInt(match, 10) + 1}
            </div>
          ))}
        </div>
        <div className={viewMatchDetails__Information__Container}>
          {
            keys.map(match => (
              <MatchInformation
                key={match}
                selected={match === this.state.view}
                data={this.props.data[match]}
                type={this.props.type as SARTypes}
              />
            ))
          }
        </div>
      </div>
    );
  }

  private readonly switchView: React.MouseEventHandler<HTMLDivElement> = ({ currentTarget: { dataset: { view } } }) => {
    if (!view) { return; }
    this.setState({ view });
  };
}

interface IFinalDataMetadataProps {
  id: string;
  metadata: object;
  selectableValues?: string[];
  keyDisabled?: boolean;
  defaultKey?: string;
}

interface IFinalMetadataProps extends IFinalDataMetadataProps {
  validation?(state: INewMetadataState): boolean;
}
interface INewMetadataState {
  inputKey: string | null;
  inputValue: string | null;
  disabled: boolean;
}
interface INewMetadata extends IFinalMetadataProps {
  submit: MutationFn<CreateSARMetadata, CreateSARMetadataInput>;
}

class TeamFilterMetadata extends React.Component<INewMetadata, INewMetadataState> {
  public state: INewMetadataState = {
    inputKey: 'teamFilter',
    inputValue: null,
    disabled: false,
  };

  public render (): JSX.Element {
    return (
      <>
        <select
          onBlur={this.setValue}
          onChange={this.setValue}
          value={this.state.inputValue || ''}
          data-event='newMetaDataKeySelect'
        >
          <option aria-selected={false} value='' disabled={true}>Select your option</option>
          {['aml', 'fraud'].map(v => (
            <option aria-selected={v === this.state.inputValue} key={v} value={v} id={v}>{v}</option>
          ))}
        </select>
        <input
          type='submit'
          disabled={this.state.disabled || !this.state.inputKey || !this.state.inputValue}
          onClick={this.submit}
          value='Add'
          data-event={`newMetaDataAdd-${!!this.props.selectableValues ? '0' : '1'}`}
        />
      </>
    );
  }

  private readonly submit: () => void = async () => {
    try {
      const { inputKey, inputValue } = this.state;
      if (!inputKey || !inputValue) {
        throw new Error('Missing input values.');
      }

      if (this.props.validation) {
        const isValid = this.props.validation(this.state);
        if (!isValid) { return; }
      }

      this.setState({ disabled: true });

      await this.props.submit({
        variables: { sarId: this.props.id, key: inputKey, value: inputValue, clientMutationId: '0' },
        optimisticResponse: {
          createSarMetadata: {
            __typename: 'CreateSARMetadataPayload',
            sar: {
              __typename: 'SAR',
              id: this.props.id,
              metadata: {
                __typename: 'SARMetadata',
                userDefinedMetadata: {
                  ...this.props.metadata,
                  [inputKey]: inputValue
                }
              }
            }
          }
        }
      });

      this.setState({ inputValue: null });
    } catch (e)  {
      ErrorNotification.notify('Error adding information', 'SAR Metadata', 'error');
    } finally {
      this.setState({ disabled: false });
    }
  };

  private readonly setValue: React.FormEventHandler<HTMLInputElement | HTMLSelectElement> = ({ currentTarget: { value } }) => {
    this.setState({ inputValue: value });
  };
}

class NewMetadata extends React.Component<INewMetadata, INewMetadataState> {
  public state: INewMetadataState = {
    inputKey: this.props.defaultKey || null,
    inputValue: null,
    disabled: false,
  };

  public render (): JSX.Element {
    return (
      <tr>
        <th>
          {
            !!this.props.selectableValues && !!this.props.selectableValues.length ? (
              <select
                onBlur={this.setKey}
                onChange={this.setKey}
                value={this.state.inputKey || ''}
                data-event='newMetaDataKeySelect'
              >
                <option aria-selected={false} value='' disabled={true}>Select your option</option>
                {this.props.selectableValues.map(v => (
                  <option aria-selected={v === this.state.inputKey} key={v} value={v} id={v}>{v}</option>
                ))}
              </select>
            ) : (
              <input
                type='text'
                disabled={this.props.keyDisabled || this.state.disabled}
                placeholder='name'
                value={this.state.inputKey || ''}
                onChange={this.setKey}
                data-event='newMetaDataKeyInput'
              />
            )
          }
        </th>
        <td>
          <input
            type='text'
            disabled={this.state.disabled}
            placeholder='value'
            value={this.state.inputValue || ''}
            onChange={this.setValue}
            data-event={`newMetaDataValue-${!!this.props.selectableValues ? '0' : '1'}`}
          />
          {' '}
          <input
            type='submit'
            disabled={this.state.disabled || !this.state.inputKey || !this.state.inputValue}
            onClick={this.submit}
            value='Add'
            data-event={`newMetaDataAdd-${!!this.props.selectableValues ? '0' : '1'}`}
          />
        </td>
      </tr>
    );
  }

  private readonly submit: () => void = async () => {
    try {
      const { inputKey, inputValue } = this.state;
      if (!inputKey || !inputValue) {
        throw new Error('Missing input values.');
      }

      if (this.props.validation) {
        const isValid = this.props.validation(this.state);
        if (!isValid) { return; }
      }

      this.setState({ disabled: true });

      await this.props.submit({
        variables: { sarId: this.props.id, key: inputKey, value: inputValue, clientMutationId: '0' },
        optimisticResponse: {
          createSarMetadata: {
            __typename: 'CreateSARMetadataPayload',
            sar: {
              __typename: 'SAR',
              id: this.props.id,
              metadata: {
                __typename: 'SARMetadata',
                userDefinedMetadata: {
                  ...this.props.metadata,
                  [inputKey]: inputValue
                }
              }
            }
          }
        }
      });

      this.setState({ inputKey: null, inputValue: null });
    } catch (e)  {
      ErrorNotification.notify('Error adding information', 'SAR Metadata', 'error');
    } finally {
      this.setState({ disabled: false });
    }
  };

  private readonly setKey: React.FormEventHandler<HTMLInputElement | HTMLSelectElement> = ({ currentTarget: { value } }) => {
    this.setState({ inputKey: value });
  };

  private readonly setValue: React.FormEventHandler<HTMLInputElement> = ({ currentTarget: { value } }) => {
    this.setState({ inputValue: value });
  };
}

const NewMetadataLifted: React.FC<IFinalMetadataProps> = ({ id, metadata, selectableValues, keyDisabled, defaultKey, validation }) => (
  <Mutation<CreateSARMetadata, CreateSARMetadataInput>
    mutation={CreateSarMetadata}
  >
    {submit => (
      <NewMetadata
        id={id}
        metadata={metadata}
        selectableValues={selectableValues}
        submit={submit}
        keyDisabled={keyDisabled}
        defaultKey={defaultKey}
        validation={validation}
      />
    )}
  </Mutation>
);

const NewTeamFilterMetadataLifted: React.FC<IFinalMetadataProps> = ({ id, metadata, keyDisabled, validation }) => (
  <Mutation<CreateSARMetadata, CreateSARMetadataInput>
    mutation={CreateSarMetadata}
  >
    {submit => (
      <TeamFilterMetadata
        id={id}
        metadata={metadata}
        submit={submit}
        keyDisabled={keyDisabled}
        validation={validation}
      />
    )}
  </Mutation>
);

const TableRow: React.FC<{label: string}> = ({ label, children }) => (
  <tr>
    <th>{label}</th>
    <td>{children}</td>
  </tr>
);

export const Information: React.FC<{ sar: ISarInfo }> = ({ sar }) => (
  <div className={viewMatchDetails__Item__Information__Container}>
    <table className={classname(tableStyle, viewMatchDetails__Information__Item, viewMatchDetails__Information__Active)}>
      <tbody>
        <TableRow label='ID'>
          {sar.id}
        </TableRow>
        <TableRow label='Name'>
          {sar.name}
        </TableRow>
        <TableRow label='Updated'>
          <TimeWidget timestamp={sar.updatedAt} />
        </TableRow>
        <TableRow label='Date Created'>
          {format(sar.createdAt, PSAE_DATE_FORMAT)}
        </TableRow>
        {sar.dateEscalatedToPartner &&
          <TableRow label='Date Escalated To Partner'>
            {format(sar.dateEscalatedToPartner, PSAE_DATE_FORMAT)}
          </TableRow>
        }
        <TableRow label='Status'>
          {sar.status}
        </TableRow>
        <TableRow label='Type'>
          {sar.type}
        </TableRow>
        <TableRow label='Type Count'>
          {sar.typeCount}
        </TableRow>
        <TableRow label='Loan Type'>
          {formatLoanType(sar)}
        </TableRow>
        <TableRow label='Product Type'>
          {sar.productType}
        </TableRow>
        <TableRow label='Reason'>
          {sar.reason}
        </TableRow>
        <TableRow label='Last Viewed'>
          {sar.lastViewedAt && <TimeWidget timestamp={sar.lastViewedAt} />}
        </TableRow>
        <TableRow label='Last Viewed By'>
          {sar.lastViewedBy}
        </TableRow>
        {Object.keys(sar.metadata.userDefinedMetadata || {}).map(k => (
          <TableRow key={k} label={k}>
            {sar.metadata.userDefinedMetadata?.[k as keyof typeof sar.metadata.userDefinedMetadata]}
          </TableRow>
        ))}
        {shouldDisplayTeamFilter(sar) && <TableRow label="Team Filter">
          <NewTeamFilterMetadataLifted
            id={sar.id}
            metadata={sar.metadata.userDefinedMetadata || {}}
          />
        </TableRow>}
        <NewMetadataLifted
          id={sar.id}
          metadata={sar.metadata.userDefinedMetadata || {}}
          selectableValues={['bsa_identifier', 'efile_id']}
        />
        <NewMetadataLifted id={sar.id} metadata={sar.metadata.userDefinedMetadata || {}} />
      </tbody>
    </table>
  </div>
);

export default (props: { sar: ISarInfo }) => (
  <div className={informationContainer}>
    <Information {...props} />
    <ViewMatches {...props.sar} />
  </div>
);
