import {
  InnerContainer as Inner,
  media,
} from '@amount/frontend-components';
import styled from 'styled-components';

export const InnerContainer = styled(Inner)`
  padding-bottom: 3em;

  ${media.small`
    padding-bottom: 1em;
  `}
`;
