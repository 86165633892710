import * as React from 'react';
import { FONT_MAP } from '@amount/frontend-components';

const font: string = FONT_MAP.sailec;

const SvgIconBPOLogo = (props: React.SVGAttributes<SVGElement>) => (
  <svg viewBox='0 -4 138 28' {...props}>
    <defs>
      <style>{`#bpo-logo{font:normal 22px ${font}}`}</style>
    </defs>
    <title>BPO Logo</title>
    <text id='bpo-logo' x='50%' y='50%' text-anchor='middle'>Avant BPO</text>
  </svg>
);

export default SvgIconBPOLogo;
