import * as React from 'react';
import { S3 } from 'aws-sdk';
import { MutationFn } from 'react-apollo';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import styled from 'styled-components';

import { FOLDER_KEY, VALID_S3_TYPES } from '../../../../shared/reports/constants';
import { removeParentDirectories } from '../../../../shared/reports/util';
import {
  Menu,
  MenuIcon,
  MenuItemContain,
  MenuItemLabel,
} from '../../CommonComponents';
import { DeleteFolder, DeleteFolderInput } from '../queries/deleteFolder.graphql';
import { MoveFile, MoveFileInput } from '../queries/moveFile.graphql';
import { SelectionOnFolders } from '../queries/fileList.graphql';
import LiftedFolderDrawer from '../LiftedFolderDrawer';

import FolderBase from './FolderBase';
import {
  IProps,
} from './common';
import { DeleteModal, RenameModal } from './Modals';

const StyledContextMenu = styled(ContextMenu)`
  z-index: 1;
`;

export interface ILiftedFolderProps extends SelectionOnFolders, Pick<IProps, 'root'> {
  // tslint:disable-next-line: no-reserved-keywords
  type: VALID_S3_TYPES;
  canEdit: boolean;
  parentRestricted?: boolean;
  refetch (): void;
}

interface IFolderComponentProps extends ILiftedFolderProps {
  deleteFolder: MutationFn<DeleteFolder, DeleteFolderInput>;
  renameFolder: MutationFn<MoveFile, MoveFileInput>;
}

interface IFolderComponentState {
  showDeleteModal: boolean;
  showRenameModal: boolean;
  showDrawer: boolean;
  submitting: boolean;
  folderName: string | undefined;
}
class Folder extends React.PureComponent<IFolderComponentProps, IFolderComponentState> {
  public state: IFolderComponentState = {
    showDeleteModal: false,
    showRenameModal: false,
    showDrawer: false,
    submitting: false,
    folderName: removeParentDirectories(this.props.Prefix || ''),
  };

  public render (): JSX.Element | null {
    const { Prefix }: S3.CommonPrefix = this.props;
    if (!Prefix) { return null; }
    // tslint:disable-next-line: no-non-null-assertion
    const name: string = removeParentDirectories(Prefix)!;

    return (
      <>
        <LiftedFolderDrawer
          Prefix={Prefix}
          folderName={name}
          close={this.hideModals}
          show={this.state.showDrawer}
          root={this.props.root}
          showEdit={this.showRenameModal}
          showDelete={this.showDeleteModal}
        />
        <DeleteModal
          show={this.state.showDeleteModal}
          close={this.hideModals}
          name={name}
          kind={FOLDER_KEY}
          refetch={this.props.refetch}
          mutation={this.props.deleteFolder}
        />
        <RenameModal
          show={this.state.showRenameModal}
          close={this.hideModals}
          name={name}
          kind={FOLDER_KEY}
          prefix={this.props.Prefix}
          restricted={this.props.restricted}
          type={this.props.type}
          refetch={this.props.refetch}
          mutation={this.props.renameFolder}
          parentRestricted={this.props.parentRestricted}
        />
        <ContextMenuTrigger id={Prefix}>
          <FolderBase {...this.props} prefixNoParents={name} showDrawer={this.showDrawer} />
        </ContextMenuTrigger>
        <StyledContextMenu id={Prefix}>
          <Menu>
            <MenuItem onClick={this.showRenameModal}>
              <MenuItemContain>
                <MenuIcon icon='edit' />
                <MenuItemLabel>Edit</MenuItemLabel>
              </MenuItemContain>
            </MenuItem>
            <MenuItem onClick={this.showDeleteModal}>
              <MenuItemContain>
                <MenuIcon icon='delete' />
                <MenuItemLabel>Delete</MenuItemLabel>
              </MenuItemContain>
            </MenuItem>
          </Menu>
        </StyledContextMenu>
      </>
    );
  }

  private readonly showDeleteModal: React.MouseEventHandler = () => {
    this.setState({ showDeleteModal: true });
  }

  private readonly showDrawer: React.MouseEventHandler = e => {
    e.preventDefault();
    this.setState({ showDrawer: true });
  }

  private readonly showRenameModal: React.MouseEventHandler = () => {
    this.setState({ showRenameModal: true });
  }

  private readonly hideModals: React.MouseEventHandler = () => {
    this.setState({ showDrawer: false, showDeleteModal: false, showRenameModal: false });
  }
}

export default Folder;
