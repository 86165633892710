import * as React from 'react';
import ErrorHandler from '@avant/crm-frontend-utils/error';
import {
  Link,
  RouteComponentProps,
  withRouter
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Mutation,
  MutationFn,
} from 'react-apollo';
import {
  BannerLink,
  Headline,
} from '@amount/frontend-components';
import styled from 'styled-components';

import { InnerContainer } from '../InnerContainer';
import { PartnersContainer } from '../PartnersContainer';
import { SelectionOnMe } from '../Context/me.graphql';
import { VIEW_HELPDESK } from '../../../shared/config/permissions';
import { PermissionedComponent } from '../PermissionedComponent';
import HomeRedirectComponent from '../HomeRedirectComponent';
import { Consumer } from '../Context';
import { MaxWidthContainer } from '../MaxWidthContainer';
import { ModuleRoutes } from '../../../shared/routes';
import { BackArrow, Breadcrumb, BreadcrumbWrap } from '../Breadcrumb';
import { ErrorLogger } from '../../services/error';

import { Config } from './Config';
import {
  getPlaceholder,
  IHelpdeskSections,
  IPlaceholder,
  ISection,
  ITitle,
} from './Common';
import { HelpdeskContent } from './HelpdeskContent';
import SubmitJitbitHelpdeskTicketQuery, {
  SubmitJitbitHelpdeskTicket,
  SubmitJitbitHelpdeskTicketInput
} from './submitJitbitHelpdeskTicket.graphql';

const Message = styled.span<{ error: boolean }>`
  display: block;
  color: ${({ error, theme: { brand, text } }) => error ? brand.colorAlert : text.colorText};
  margin-top: 2.5em;
`;

const JITBIT_TICKET_PATH: string = 'https://avant.jitbit.com/helpdesk/Ticket/';
const AUTO_LOGIN_TICKET_PATH: string = 'https://avant.jitbit.com/helpdesk/User/AutoLogin?username=';
const PAGERDUTY_CATEGORIES: Set<string> = new Set(['341469', '325536', '362417', '360265']);

const submitJitbitHelpdeskTicket: (
  helpdeskSections: IHelpdeskSections,
  helpdeskSectionTitle: ITitle,
  submitTicket: MutationFn<SubmitJitbitHelpdeskTicket, SubmitJitbitHelpdeskTicketInput>
) => Promise<Partial<ISubmitState>> = async (helpdeskSections, helpdeskSectionTitle, submitTicket) => {
  const sectionNote: string = helpdeskSections.note as string;

  if (sectionNote.trim().length < 1) {
    return Promise.resolve({ submitting: false, error: true, message: 'Please add a ticket note.' });
  }

  const template: ITitle = getPlaceholder(helpdeskSectionTitle) || '';

  if (typeof template === 'string' && (sectionNote.trim() === template.trim() && !helpdeskSections.attachment)) {
    return Promise.resolve({ submitting: false, error: true, message: 'Please edit the ticket note.' });
  }

  return submitTicket({
    variables: {
      categoryId: helpdeskSections.category,
      body: sectionNote,
      subject: helpdeskSections.region ? `${helpdeskSections.region}: ${helpdeskSections.title}` : helpdeskSections.title,
      attachment: helpdeskSections.attachment,
      region: helpdeskSections.region,
      priority: helpdeskSections.priority,
      location: helpdeskSections.location,
      department: helpdeskSections.department,
      product: helpdeskSections.product,
      clientMutationId: '0',
    }
  }).then(
    response => {
      if (!response) {
        const message = 'Ticket submission failed. Missing response.';
        ErrorLogger.captureException(message);

        return {
          submitting: false,
          error: true,
          message
        };
      }

      const { data } = response;

      if (!data || !data.submitJitbitHelpdeskTicket) {
        const message = 'Ticket submission failed. Missing data.';
        ErrorLogger.captureException(message);

        return {
          submitting: false,
          error: true,
          message
        };
      }

      return {
        submitting: false,
        error: !data.submitJitbitHelpdeskTicket.success,
        message: data.submitJitbitHelpdeskTicket.message,
        ticketId: data.submitJitbitHelpdeskTicket.id,
        hash: data.submitJitbitHelpdeskTicket.hash
      };
    },
    err => {
      ErrorLogger.captureException(err.message);
      let submitErrorMessage: string = `${err.message} Please email partnerportalsupport@amount.com with the ticket details.`;

      if (err.message.startsWith('Network')) {
        submitErrorMessage = 'Please ensure you are connected to the network and retry submission. ' +
          'If you experience this issue multiple times, please email partnerportalsupport@amount.com.';
      }

      return {
        submitting: false,
        error: true,
        message: `Ticket submission failed. ${submitErrorMessage} `
      };
    }
  );
};

const pagerDutyAlertWindow: (ticketCategory: string) => boolean = ticketCategory => {
  if (PAGERDUTY_CATEGORIES.has(ticketCategory)) {
    return confirm('PLEASE NOTE: Submitting this ticket will send a PagerDuty alert to the technician On Call!!');
  }

  return true;
};

interface IFormMessage {
  message: string;
  ticketId: IPlaceholder;
  error: boolean;
  buildLinkToTicket (): string;
}

const MessageText = styled.span`
  padding-right: 0.5em;
`;

const FormMessage: React.FC<IFormMessage> = ({ message, ticketId, error, buildLinkToTicket }) => (
  <Message error={error}>
    <MessageText>{message}</MessageText>
    {ticketId && (
      <BannerLink bannerStyle='inactive'>
        <a href={buildLinkToTicket()} target='_blank' rel='noopener noreferrer'>View Your Ticket</a>
      </BannerLink>
    )}
  </Message>
);

interface ISubmitProps extends RouteComponentProps<{ section: string }> {
  submitTicket: MutationFn<SubmitJitbitHelpdeskTicket, SubmitJitbitHelpdeskTicketInput>;
  helpdeskSections?: IHelpdeskSections;
  me: SelectionOnMe;
}

interface ISubmitState {
  submitting: boolean;
  error: boolean;
  message: string | null;
  ticketId: string | null;
  hash: string | null;
}

export class Submit extends React.Component<ISubmitProps, ISubmitState> {
  public state: ISubmitState = {
    submitting: false,
    error: false,
    message: null,
    ticketId: null,
    hash: null
  };

  public render (): JSX.Element {
    const sectionName: string = this.props.match.params.section;
    const section: ISection | null = Config[sectionName];
    if (!section) {
      const e = new Error(`Missing helpdesk section: ${sectionName}`);
      ErrorLogger.captureException(e);
      console.error(e);

      return <HomeRedirectComponent />;
    }
    const sectionPermissions: string[] = section.permissions || [];

    return (
      <PermissionedComponent allowedPermissions={[VIEW_HELPDESK]} NoAccessComponent={HomeRedirectComponent}>
        <PermissionedComponent allowedPermissions={sectionPermissions} NoAccessComponent={HomeRedirectComponent}>
          <Helmet>
            <title>{section.name} Ticket · Partner Portal</title>
          </Helmet>
          <PartnersContainer>
            <MaxWidthContainer>
              <InnerContainer>
                <BreadcrumbWrap>
                  <Link to={ModuleRoutes.jitbitHelpdesk} data-event='helpdeskBack'>
                    <Breadcrumb>
                      <BackArrow icon='return-arrow' />
                      <span>Back</span>
                    </Breadcrumb>
                  </Link>
                </BreadcrumbWrap>
                <Headline scale='large'>Submit {section.name} Ticket</Headline>
                {!this.state.ticketId &&
                  <HelpdeskContent
                    {...this.props}
                    submitHelpdeskTicket={this.submitJitbitHelpdeskTicket}
                    section={section}
                    submitting={this.state.submitting}
                    onError={this.setError}
                  />
                }
                {this.state.message && (
                  <FormMessage
                    message={this.state.message}
                    error={this.state.error}
                    ticketId={this.state.ticketId}
                    buildLinkToTicket={this.buildLinkToTicket}
                  />
                )}
              </InnerContainer>
            </MaxWidthContainer>
          </PartnersContainer>
        </PermissionedComponent>
      </PermissionedComponent>
    );
  }

  private readonly setError: (message: string) => void = message => {
    this.setState({ error: true, message, submitting: false });
  }

  private readonly buildLinkToTicket: () => string = () => {
    if (!!this.state.hash) {
      return `${AUTO_LOGIN_TICKET_PATH}${this.props.me.email}&email=${this.props.me.email}` +
        `&userHash=${this.state.hash}&ReturnUrl=${JITBIT_TICKET_PATH}${this.state.ticketId}`;
    }

    return `${JITBIT_TICKET_PATH}${this.state.ticketId}`;
  }

  private readonly submitJitbitHelpdeskTicket: (helpdeskSections: IHelpdeskSections) => void = helpdeskSections => {
    try {
      if (pagerDutyAlertWindow(helpdeskSections.category)) {
        this.setState({
          submitting: true,
          error: false,
          message: null,
          ticketId: null
        });

        const section: ISection | null = Config[this.props.match.params.section];
        if (!section) {
          throw new Error(`Missing Helpdesk Section for ${this.props.match.params.section}`);
        }

        const helpdeskSectionTitle = section.categories[helpdeskSections.category].titles[helpdeskSections.title];

        submitJitbitHelpdeskTicket(helpdeskSections, helpdeskSectionTitle, this.props.submitTicket).then(
          newState => this.setState(currentState => ({ ...currentState, ...newState })),
          err => {
            throw new Error(err);
          }
        );
      }
    } catch (e) {
      console.error(e);
      ErrorHandler.notify('Failed to submit ticket', 'Helpdesk', 'error');
      ErrorLogger.captureException(e);
    }
  }
}

const Component: React.FC<RouteComponentProps<{ section: string }>> = props => (
  <Consumer>
    {me => (
      <Mutation<SubmitJitbitHelpdeskTicket, SubmitJitbitHelpdeskTicketInput> mutation={SubmitJitbitHelpdeskTicketQuery} >
        {submitTicket => <Submit {...props} me={me} submitTicket={submitTicket} />}
      </Mutation>
    )}
  </Consumer>
);

export default withRouter(Component);
