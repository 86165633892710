import {
  DOCUMENTS_PERMISSIONS,
  EDIT_DOCUMENTS,
  EDIT_REPORTS,
  REPORTS_PERMISSIONS,
  VIEW_CREDIT_CARD_DOCUMENTS,
  VIEW_CREDIT_CARD_REPORTS,
  VIEW_DOCUMENTS,
  VIEW_LOAN_DOCUMENTS,
  VIEW_LOAN_REPORTS,
  VIEW_POS_DOCUMENTS,
  VIEW_POS_REPORTS,
  VIEW_REPORTS,
  VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS,
  VIEW_SENSITIVE_CREDIT_CARD_REPORTS,
  VIEW_SENSITIVE_LOAN_DOCUMENTS,
  VIEW_SENSITIVE_LOAN_REPORTS,
  VIEW_SENSITIVE_POS_DOCUMENTS,
  VIEW_SENSITIVE_POS_REPORTS,
  VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS,
  VIEW_SENSITIVE_SERVICING_ORG_REPORTS,
  VIEW_SERVICING_ORG_DOCUMENTS,
  VIEW_SERVICING_ORG_REPORTS,
  STANDARD,
  POINT_OF_SALE,
  PERSONAL_LOAN,
  FolderTypeMap,
  EDIT_POS_REPORTS,
  EDIT_LOAN_REPORTS,
  EDIT_POS_DOCUMENTS,
  EDIT_LOAN_DOCUMENTS,
  SERVICING_ORG,
  EDIT_SERVICING_ORG_REPORTS,
  INVALID_PERMISSION,
} from '../config/permissions';

export const FILE_KEY = 'File';
export const FOLDER_KEY = 'Folder';

export const S3_REPORTS = '__reports__';
export const S3_POLICIES = '__policies__';
export type VALID_S3_TYPES = typeof S3_REPORTS | typeof S3_POLICIES;

export const ROOT_FOLDER: string = '__root__';

export const VALID_S3_PREFIXES: VALID_S3_TYPES[] = [S3_POLICIES, S3_REPORTS];
export const LOOKER_FILE_EXTENSION: string = '.looker';
export const isLookerDashboard: (name: string) => boolean = name => name.endsWith(LOOKER_FILE_EXTENSION);

export const S3_REPORTS_READABLE = 'reports';
export const S3_POLICIES_READABLE = 'policies';
export const S3_PARTNERS_INBRANCH_README_TYPE  = 'visualization';
export type HUMAN_READABLE_S3_TYPES = typeof S3_REPORTS_READABLE | typeof S3_POLICIES_READABLE |  typeof S3_PARTNERS_INBRANCH_README_TYPE;

export const S3TypeToReadableMap: Map<VALID_S3_TYPES, HUMAN_READABLE_S3_TYPES> = new Map<VALID_S3_TYPES, HUMAN_READABLE_S3_TYPES>([
  [S3_REPORTS, S3_REPORTS_READABLE],
  [S3_POLICIES, S3_POLICIES_READABLE],
]);
export const ReadableToS3TypeMap: Map<HUMAN_READABLE_S3_TYPES, VALID_S3_TYPES> = new Map<HUMAN_READABLE_S3_TYPES, VALID_S3_TYPES>([
  [S3_REPORTS_READABLE, S3_REPORTS],
  [S3_POLICIES_READABLE, S3_POLICIES],
]);

export const UPLOAD_URL: string = '/api/report/upload';

const LOAN_PRODUCT_KEY = 'loan';
const POS_PRODUCT_KEY = 'pos';
const CREDIT_CARD_PRODUCT_KEY = 'cc';
const SERVICING_ORG_KEY = 'servicing';

export const VALID_PRODUCTS = [
  LOAN_PRODUCT_KEY,
  POS_PRODUCT_KEY,
  CREDIT_CARD_PRODUCT_KEY,
  SERVICING_ORG_KEY
] as const;
type VALID_PRODUCTS_TYPE = typeof VALID_PRODUCTS[number];

export const PRODUCT_FOLDER_MAP: { [x in VALID_PRODUCTS_TYPE]: string } = {
  [LOAN_PRODUCT_KEY]: 'Personal Loan',
  [POS_PRODUCT_KEY]: 'Point of Sale',
  [CREDIT_CARD_PRODUCT_KEY]: 'Credit Card',
  [SERVICING_ORG_KEY]: 'Servicing Org'
} as const;

type ReportsProductPermissionMap = { [x in VALID_PRODUCTS_TYPE]: REPORTS_PERMISSIONS };
export const REPORTS_PRODUCT_PERMISSION_MAP: ReportsProductPermissionMap = {
  [LOAN_PRODUCT_KEY]: VIEW_LOAN_REPORTS,
  [POS_PRODUCT_KEY]: VIEW_POS_REPORTS,
  [CREDIT_CARD_PRODUCT_KEY]: VIEW_CREDIT_CARD_REPORTS,
  [SERVICING_ORG_KEY]: VIEW_SERVICING_ORG_REPORTS
};
type DocumentsProductPermissionMap = { [x in VALID_PRODUCTS_TYPE]: DOCUMENTS_PERMISSIONS };
export const DOCUMENTS_PRODUCT_PERMISSION_MAP: DocumentsProductPermissionMap = {
  [LOAN_PRODUCT_KEY]: VIEW_LOAN_DOCUMENTS,
  [POS_PRODUCT_KEY]: VIEW_POS_DOCUMENTS,
  [CREDIT_CARD_PRODUCT_KEY]: VIEW_CREDIT_CARD_DOCUMENTS,
  [SERVICING_ORG_KEY]: VIEW_SERVICING_ORG_DOCUMENTS
};
export type ProductPermissionsMap = ReportsProductPermissionMap | DocumentsProductPermissionMap;
export const S3_TO_PRODUCT_PERMISSION_MAP: { [key in VALID_S3_TYPES]: ProductPermissionsMap } = {
  [S3_REPORTS]: REPORTS_PRODUCT_PERMISSION_MAP,
  [S3_POLICIES]: DOCUMENTS_PRODUCT_PERMISSION_MAP
};

type ViewFilePermissions = typeof VIEW_REPORTS | typeof VIEW_DOCUMENTS;

export const S3_TO_VIEW_FILE_PERMISSIONS: { [key in VALID_S3_TYPES]: ViewFilePermissions } = {
  [S3_REPORTS]: VIEW_REPORTS,
  [S3_POLICIES]: VIEW_DOCUMENTS
};
export const S3_TO_EDIT_FILE_PERMISSIONS: { [key in VALID_S3_TYPES]: FolderTypeMap } = {
  [S3_REPORTS]: {
    [STANDARD]: EDIT_REPORTS,
    [POINT_OF_SALE]: EDIT_POS_REPORTS,
    [PERSONAL_LOAN]: EDIT_LOAN_REPORTS,
    [SERVICING_ORG]: EDIT_SERVICING_ORG_REPORTS
  },
  [S3_POLICIES]: {
    [STANDARD]: EDIT_DOCUMENTS,
    [POINT_OF_SALE]: EDIT_POS_DOCUMENTS,
    [PERSONAL_LOAN]: EDIT_LOAN_DOCUMENTS,
    [SERVICING_ORG]: INVALID_PERMISSION
  }
};

export const REPORTS_SENSITIVE_PRODUCT_PERMISSION_MAP: ReportsProductPermissionMap = {
  [LOAN_PRODUCT_KEY]: VIEW_SENSITIVE_LOAN_REPORTS,
  [POS_PRODUCT_KEY]: VIEW_SENSITIVE_POS_REPORTS,
  [CREDIT_CARD_PRODUCT_KEY]: VIEW_SENSITIVE_CREDIT_CARD_REPORTS,
  [SERVICING_ORG_KEY]: VIEW_SENSITIVE_SERVICING_ORG_REPORTS
};
export const DOCUMENTS_SENSITIVE_PRODUCT_PERMISSION_MAP: DocumentsProductPermissionMap = {
  [LOAN_PRODUCT_KEY]: VIEW_SENSITIVE_LOAN_DOCUMENTS,
  [POS_PRODUCT_KEY]: VIEW_SENSITIVE_POS_DOCUMENTS,
  [CREDIT_CARD_PRODUCT_KEY]: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS,
  [SERVICING_ORG_KEY]: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS
};
export const S3_TO_SENSITIVE_PRODUCT_PERMISSION_MAP: { [key in VALID_S3_TYPES]: ProductPermissionsMap } = {
  [S3_REPORTS]: REPORTS_SENSITIVE_PRODUCT_PERMISSION_MAP,
  [S3_POLICIES]: DOCUMENTS_SENSITIVE_PRODUCT_PERMISSION_MAP
};
