import * as React from 'react';
import { Helmet } from 'react-helmet';
import {
  BannerLink,
  ConditionalRenderWrapper,
} from '@amount/frontend-components';

import {
  ModuleRouteMap,
  MODULES_LIST,
  VIEW_PERMISSION_TO_MODULE_MAP
} from '../../../shared/config/modules';
import { SmartLink } from '../SmartLink';
import { PermissionedComponent } from '../PermissionedComponent';
import { Card, CardContain } from '../Card';
import { MaxWidthContainer } from '../MaxWidthContainer';
import { PartnersContainer } from '../PartnersContainer';
import { InnerContainer } from '../InnerContainer';
import {
  AlertIcon,
  AlertLabel,
  MarginlessH1Headline,
  StyledAlert,
} from '../CommonComponents';
import { PartneredComponent } from '../PartneredComponent';
import { hidePartnerSwitcher } from '../../services/hidePartnerSwitcher';

const NoModules: React.FC = () => (
  <StyledAlert alertStyle='inactive'>
    <ConditionalRenderWrapper hiddenOnMobile={true} breakpoint='small'>
      <AlertIcon icon='circle-alert-large' />
    </ConditionalRenderWrapper>
    <AlertLabel>
      You do not have any permissions.
      Please contact <BannerLink bannerStyle='inactive'>
        <a href='mailto:partnerportalsupport@amount.com'>partnerportalsupport@amount.com</a>
      </BannerLink>.
    </AlertLabel>
  </StyledAlert>
);

const Home: React.FC = () => {
  React.useEffect(() => {
    hidePartnerSwitcher.setFalse();
  },
  []);

  return (
    <PartnersContainer>
      <MaxWidthContainer>
        <InnerContainer>
          <Helmet>
            <title>Partner Portal</title>
          </Helmet>
          <div>
            <MarginlessH1Headline scale='large'>Partner Portal</MarginlessH1Headline>
            <p>View and manage information and documents related to your Amount partnership.</p>
          </div>
          <PermissionedComponent
            allowedPermissions={Object.keys(VIEW_PERMISSION_TO_MODULE_MAP)}
            NoAccessComponent={NoModules}
          >
            <CardContain>
              {MODULES_LIST.map(p =>
                ModuleRouteMap[p].enabled ? (
                  <React.Fragment key={p}>
                    <PartneredComponent allowedPartners={ModuleRouteMap[p].partners} excludedPartners={ModuleRouteMap[p].excludedPartners}>
                      <PermissionedComponent allowedPermissions={ModuleRouteMap[p].permissions}>
                        <SmartLink
                          forceLink={ModuleRouteMap[p].forceLink}
                          newTab={ModuleRouteMap[p].newTab}
                          href={ModuleRouteMap[p].route}
                          data-event={p}
                        >
                          <Card {...ModuleRouteMap[p]} />
                        </SmartLink>
                      </PermissionedComponent>
                    </PartneredComponent>
                  </React.Fragment>
                ) : null
              )}
            </CardContain>
          </PermissionedComponent>
        </InnerContainer>
      </MaxWidthContainer>
    </PartnersContainer>
  );
};

export default Home;
