import * as React from 'react';
import { Mutation, Query } from 'react-apollo';

import { EDIT_USERS, VIEW_USERS } from '../../../shared/config/permissions';
import { InnerContainer } from '../InnerContainer';
import { PermissionedComponent } from '../PermissionedComponent';
import { Spinner } from '../Spinner';
import HomeRedirectComponent from '../HomeRedirectComponent';

import editUserRolesQuery, { EditUserRoles, EditUserRolesInput } from './queries/editUserRoles.graphql';
import getUserQuery, { GetUser as getUser, GetUserInput } from './queries/getUser.graphql';
import { ILiftedProps, UserRoleEdit } from './Presentational/UserRoleEdit';
import { UserManagementContext } from './UserManagement.context';

const LiftedUserRoleEdit: React.FC<ILiftedProps> = props => {
  const { state: userMgmt } = React.useContext(UserManagementContext);

  return (
    <PermissionedComponent allowedPermissions={[VIEW_USERS]} NoAccessComponent={HomeRedirectComponent}>
      <PermissionedComponent allowedPermissions={[EDIT_USERS]} NoAccessComponent={HomeRedirectComponent}>
        <Query<getUser, GetUserInput>
          query={getUserQuery}
          variables={{ id: props.match.params.id, organization: userMgmt.currentOrg }}
        >
          {({ data, loading, refetch }) => (
            <Mutation<EditUserRoles, EditUserRolesInput>
              mutation={editUserRolesQuery}
            >
              {editUser => (
                <InnerContainer>
                  {loading ? (
                    <Spinner />
                  ) : (
                      <UserRoleEdit
                        {...props}
                        data={data}
                        editUser={editUser}
                        refetchUser={refetch}
                        organization={userMgmt.currentOrg}
                      />
                    )}
                </InnerContainer>
              )}
            </Mutation>
          )}
        </Query>
      </PermissionedComponent>
    </PermissionedComponent>
  );
};

export default LiftedUserRoleEdit;
