import * as React from 'react';
import { Mutation, MutationFn, Query, QueryResult } from 'react-apollo';
import * as classnames from 'classnames';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { INSERT_REASON_BLACKLIST_TEXT } from '../../../../../shared/transitions/sar';
import InfoQuery, { SARInfo, SARInfoInput } from '../../info.graphql';
import { SetLastViewed, SetLastViewedInput } from '../../setLastViewed.graphql';
import { ErrorLogger } from '../../../../services/error';
import { ModuleRoutes } from '../../../../../shared/routes';

import ViewMatches from './ViewMatches';
import WriteSARQuery, { WriteSAR, WriteSARInput } from './writeSAR.graphql';
import Actions from './Actions';
import { EditCategories } from './editCategories';
import {
  inputs__Group__Text,
  inputs__TextInput__Base,
  narrative__Container,
  narrative__Entry__Container,
  narrative__Entry__Form__Error,
  narrative__Entry__Form__Group,
  narrative__Input,
} from './style.css';
import { FormGroup } from './common';

interface INarrativePropsBase extends Pick<QueryResult<SARInfo, {}>, 'data' | 'loading'> {
  editable?: boolean;
}

type RouteProps = RouteComponentProps<{ id: string }>;
interface INarrativeProps extends INarrativePropsBase, RouteProps {
  submit: MutationFn<WriteSAR, WriteSARInput>;
}

interface INarrativeState {
  dirty?: boolean;
  error?: string;
  editingCategories: boolean;
}

const cannotEditTitleStatus: Set<string> = new Set([ 'partnerReview' ]);

class Narrative extends React.Component<INarrativeProps, INarrativeState> {
  public state: INarrativeState = {
    dirty: false,
    error: undefined,
    editingCategories: false,
  };

  private _form: HTMLFormElement | null | undefined;
  private _filingName: HTMLInputElement | null | undefined;
  private _filingType: HTMLDivElement | null | undefined;
  private _narrative: HTMLTextAreaElement | null | undefined;

  public setDirtyFlag: () => void = () => {
    this.setState({ dirty: true,  error: undefined });
  }

  public resetState: () => void = () => {
    this.setState({ dirty: false, error: undefined });
  }

  public toggleEditCategories: () => void = () => {
    this.setState(({ editingCategories }) => ({ editingCategories: !editingCategories }));
  }

  get filingType (): string | null {
    if (!this._filingType) { return null; }

    const checked: HTMLInputElement | null = this._filingType.querySelector('input:checked');

    return checked ? checked.value : null;
  }

  get filingName (): string | null {
    return this._filingName ? this._filingName.value : null;
  }

  get narrative (): string | null {
    return (this._narrative || { value: null }).value;
  }

  // tslint:disable-next-line max-func-body-length
  public render (): JSX.Element {
    if (this.props.loading) {
      return <div className='spinner' />;
    }
    if (!this.props.data || !this.props.data.sar) {
      return <div>Error loading data. Please try again.</div>;
    }

    return (
      <div className={narrative__Container}>
        <Helmet>
          <title>{this.props.data.sar.name} · PSAEs · Partner Portal</title>
        </Helmet>
        {this.props.children}
        {(this.props.editable && this.state.editingCategories) && (
          <EditCategories
            categories={this.props.data.sar.metadata.additionalCategories || {}}
            sarId={this.props.data.sar.id}
            closeCategories={this.toggleEditCategories}
          />
        )}
        <ViewMatches sar={this.props.data.sar} />
        <form ref={r => this._form = r} className={narrative__Entry__Container}>
          <FormGroup>
            <h3><label htmlFor='name'>Filing Name</label></h3>
            <input
              key={`name_${this.props.data.sar.id}`}
              className={classnames(inputs__TextInput__Base, inputs__Group__Text)}
              disabled={!this.props.editable || cannotEditTitleStatus.has(this.props.data.sar.status)}
              onChange={this.setDirtyFlag}
              ref={r => this._filingName = r}
              name='name'
              id='name'
              type='text'
              placeholder='Filing Name'
              required={true}
              defaultValue={this.props.data.sar.name || ''}
              data-event='filingNameInput'
            />
          </FormGroup>
          {/* tslint:disable-next-line: jsx-no-lambda */}
          <FormGroup passRef={r => this._filingType = r}>
            <h3><label htmlFor='type'>Type of Filing</label></h3>
            <input
              key={`initial_report_${this.props.data.sar.id}`}
              disabled={!this.props.editable}
              onChange={this.setDirtyFlag}
              role='radio'
              aria-checked={this.props.data.sar.filingType === 'initial_report'}
              name='type'
              type='radio'
              value='initial_report'
              id='initial_report'
              defaultChecked={this.props.data.sar.filingType === 'initial_report'}
              data-event='initialReportInput'
            />
            <label htmlFor='initial_report'>Initial Report</label><br />
            <input
              key={`continuing_report_${this.props.data.sar.id}`}
              disabled={!this.props.editable}
              onChange={this.setDirtyFlag}
              name='type'
              type='radio'
              value='continuing_report'
              id='continuing_report'
              role='radio'
              aria-checked={this.props.data.sar.filingType === 'continuing_report'}
              defaultChecked={this.props.data.sar.filingType === 'continuing_report'}
              data-event='continuingReportInput'
            />
            <label htmlFor='continuing_report'>Continuing Activity Report</label><br />
            <input
              key={`correct_report_${this.props.data.sar.id}`}
              disabled={!this.props.editable}
              onChange={this.setDirtyFlag}
              name='type'
              type='radio'
              value='correct_report'
              id='correct_report'
              role='radio'
              aria-checked={this.props.data.sar.filingType === 'correct_report'}
              defaultChecked={this.props.data.sar.filingType === 'correct_report'}
              data-event='correctReportInput'
            />
            <label htmlFor='correct_report'>Correct/Amend Report</label><br />
            <input
              key={`joint_report_${this.props.data.sar.id}`}
              disabled={!this.props.editable}
              onChange={this.setDirtyFlag}
              name='type'
              type='radio'
              value='joint_report'
              id='joint_report'
              role='radio'
              aria-checked={this.props.data.sar.filingType === 'joint_report'}
              defaultChecked={this.props.data.sar.filingType === 'joint_report'}
              data-event='jointReportInput'
            />
            <label htmlFor='joint_report'>Joint Report</label><br />
          </FormGroup>

          <FormGroup className={narrative__Entry__Form__Group}>
            <h3><label htmlFor='narrative'>Narrative</label></h3>
            <textarea
              key={`narrative_${this.props.data.sar.id}`}
              className={classnames(inputs__TextInput__Base, narrative__Input)}
              disabled={!this.props.editable}
              onChange={this.setDirtyFlag}
              ref={r => this._narrative = r}
              name='narrative'
              id='narrative'
              required={true}
              defaultValue={this.props.data.sar.narrative || ''}
              data-event='narrativeInput'
            />
          </FormGroup>
          {this.state.error && (
            <span className={narrative__Entry__Form__Error}>
              {this.state.error}
            </span>
          )}
          <Actions
            {...this.props}
            editable={!!this.props.editable}
            dirty={!!this.state.dirty}
            submit={this.submit}
            status={this.props.data.sar.status || ''}
            sarId={this.props.data.sar.id}
            toggleCategoryEditor={this.toggleEditCategories}
          />
        </form>
      </div>
    );
  }

  private readonly submit: React.FormEventHandler<HTMLButtonElement> = async e => {
    e.preventDefault();

    try {
      const filingName: string = this.filingName || '';
      const filingType: string = this.filingType || '';
      const narrative: string  = this.narrative  || '';

      if (!this._form) {
        alert('Error! Please refresh and try again.');

        return;
      }

      if (!this._form.checkValidity() || !filingName || !filingType || !narrative) {
        const missingThings: string[] = [];
        if (!filingName) { missingThings.push('Filing Name'); }
        if (!filingType) { missingThings.push('Filing Type'); }
        if (!narrative)  { missingThings.push('Narrative'); }

        if (missingThings.length) {
          this.setState({ dirty: false, error: `Please fill out all fields.\nMissing: ${missingThings.join(', ')}` });
        }

        return;
      }

      if (narrative.includes(INSERT_REASON_BLACKLIST_TEXT)) {
        this.setState({ dirty: false, error: `Narrative includes "${INSERT_REASON_BLACKLIST_TEXT}", cannot proceed.` });

        return;
      }

      this.resetState();

      const response = await this.props.submit({
        variables: { narrative, filingName, filingType, sarId: this.props.match.params.id, clientMutationId: '0' },
        optimisticResponse: {
          writeSAR: {
            sar: {
              __typename: 'SAR',
              id: this.props.match.params.id,
              // tslint:disable-next-line: no-non-null-assertion
              createdAt: this.props.data!.sar!.createdAt,
              updatedAt: (new Date()).toISOString(),
              narrative,
              filingType,
              name: filingName,
              // tslint:disable-next-line: no-non-null-assertion
              data: this.props.data!.sar!.data
            }
          }
        }
      });
      if (!response) { throw new Error('Missing data'); }
      const { errors } = response;
      if (!!errors) { throw new Error('Error'); }

      this.props.history.push(`${ModuleRoutes.psaes}/${this.props.match.params.id}`);
    } catch (e) {
      this.setDirtyFlag();
      this.setState({ error: 'There was an error submitting your narrative. Please try again' });
    }
  }
}

interface ILiftedNarrativeProps extends RouteComponentProps<{ id: string }> {
  editable: boolean;
  lastViewed: MutationFn<SetLastViewed, SetLastViewedInput>;
}

class LiftedNarrative extends React.Component<ILiftedNarrativeProps> {
  public componentDidMount () {
    try {
      // There is a potential for parallelism issues with this function, since
      // InfoQuery fires on render, and this request fires on mounting.
      // Keep in mind for future refactor.
      void this.props.lastViewed();
    } catch (err) {
      console.error(err);
      ErrorLogger.captureException(err);
    }
  }

  public render () {
    return (
      <Query<SARInfo, SARInfoInput>
        query={InfoQuery}
        variables={{ id: this.props.match.params.id }}
      >
        {({ data, loading }) => (
          <Mutation<WriteSAR, WriteSARInput>
            mutation={WriteSARQuery}
          >
            {writeSarMutation => (
              <Narrative
                {...this.props}
                data={data}
                loading={loading}
                submit={writeSarMutation}
              />
            )}
          </Mutation>
        )}
      </Query>
    );
  }
}

// tslint:disable-next-line: no-any
export default withRouter<any, any>(LiftedNarrative);
