/* tslint:disable */
import * as React from 'react';
import styled from 'styled-components';

const SyledPath = styled.path`
fill: ${({ theme }) => theme.name === 'amountDark' ? '#FFFFFF' : '#262729'}};
`;

const SvgIconGuaranteedRate = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 79 32"
    fill="none"
    aria-hidden="true"
    {...props}
  >
    <title>Guaranteed Rate Logo</title>
    <SyledPath
      d="M0 0H15.5509C21.9509 0 27.3778 2.93801 27.3778 9.33801C27.3778 12.931 25.5438 15.476 22.2503 16.9544V17.0854C24.7953 18.0398 26.1053 19.7988 26.6292 22.2503C27.4526 26.0117 26.7602 30.5216 27.9017 30.8397V31.3263H18.8819C18.0023 30.7088 18.3579 26.8538 17.7029 24.0468C17.1415 21.6327 16.0748 20.4164 13.4175 20.4164H9.16959V31.3263H0V0ZM9.15087 13.8854H14.0538C16.6737 13.8854 18.1333 12.7439 18.1333 10.5544C18.1333 8.4959 16.8234 7.18596 14.1848 7.18596H9.15087V13.8854Z"
    />
    <SyledPath
      d="M46.6161 30.8959C46.1296 30.5965 46.036 29.8292 46.036 28.407V19.5555C46.036 17.1041 45.0816 15.2702 43.2103 14.0912C41.8442 13.2491 40.0664 12.8936 37.3155 12.8936C31.1775 12.8936 28.8758 16.2807 28.7448 19.4433V19.6304H35.2945L35.3132 19.4807C35.4816 18.1895 36.2115 17.5158 37.4465 17.5158C38.6068 17.5158 39.1308 18.0023 39.1308 19.0316C39.1308 19.9111 38.4758 20.3976 34.546 20.8281C31.7202 21.1462 27.9775 22.0819 27.9775 26.4608C27.9775 29.8479 30.298 31.9438 34.0594 31.9438C36.3424 31.9438 38.1577 31.1766 39.3366 29.7357C39.4676 30.428 39.636 30.952 39.898 31.4011L39.9541 31.4947H46.691V30.9333L46.6161 30.8959ZM39.3366 23.6351V24.9076C39.3366 26.9661 37.8957 27.7146 36.5296 27.7146C35.6313 27.7146 34.6582 27.3216 34.6582 26.1988C34.6582 25.338 35.0325 24.7953 37.0161 24.3649C37.9518 24.159 38.7003 23.9158 39.3366 23.6351Z"
    />
    <SyledPath
      d="M78.5963 24.0842C78.6711 20.3602 77.5483 17.2351 75.4524 15.3076C73.6746 13.6795 71.4477 12.8748 68.8091 12.8748C63.2325 12.8748 59.1904 16.8982 59.1904 22.4561C59.1904 28.0702 63.2512 32 69.0524 32C71.3916 32 73.1507 31.5321 74.7974 30.4842C76.5939 29.3427 77.8477 27.7333 78.2407 26.1053L78.2969 25.8807H71.6162L71.56 25.9743C71.1109 26.7789 70.3249 27.1906 69.2583 27.1906C67.5366 27.1906 66.4325 26.1988 66.0208 24.2526H78.5963V24.0842ZM66.0021 20.4351C66.3951 18.5825 67.3682 17.6468 68.9401 17.6468C70.3624 17.6468 71.3729 18.676 71.6723 20.4351H66.0021Z"
    />
    <SyledPath
      d="M59.1337 17.7778V13.455H55.7653V7.65384L48.991 11.1345V13.455H46.708V17.7778H48.991V26.2363C48.991 30.9147 51.6484 31.869 55.653 31.869C56.701 31.869 57.7115 31.738 58.9279 31.4386L59.0589 31.4012V26.2363L58.8717 26.255C57.6179 26.3486 56.6261 26.3673 56.1396 25.9182C55.8963 25.6936 55.7653 25.3193 55.7653 24.7766V17.7778H59.1337Z"
    />
    <path d="M37.109 0.0187073V6.73683H33.8154L44.0517 11.9953L54.2879 6.73683H51.0131V0.0187073H37.109Z" fill="#CF3438" />
  </svg>
);

export default SvgIconGuaranteedRate;
