import * as React from 'react';
import camelCase from 'lodash-es/camelCase';
import {
  FormError,
  TextAreaInput,
} from '@amount/frontend-components';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { IText, Label } from './common';

const TextArea = styled(TextAreaInput)`
  min-width: 100%;
  max-width: 100%;
  min-height: 10em;
  height: 16em;
  margin-top: .75em;
`;

export const Description: React.FC<IText> = ({ name, isRequired, labelName, placeholder}) => {
  const { errors, register } = useFormContext();

  const inputId = `input-${name}`;
  const errorId = `input-${name}-error`;

  return (
    <>
      <Label inputId={inputId} labelName={labelName} required={isRequired || false} />
      <TextArea
        name={name}
        data-event={camelCase(`${name} textarea`)}
        id={inputId}
        ref={register({ required: isRequired })}
        placeholder={placeholder}
        required={isRequired}
        aria-describedby={errorId}
      />
      {errors[name] && <FormError id={errorId}>This field is required</FormError>}
    </>
  );
};
