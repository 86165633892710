import { GraphQLClient } from '@avant/crm-backend/services/api/authenticated';

import { FLOATING_ORGANIZATION_MAP, Organizations, SwitchablePartner } from './partners';

export type UserPermissions = { [key in SwitchablePartner]?: string[] };

export interface ITokenPayload {
  loginToken: string;
  loginTokenExpiry: string;
}

export interface IUser {
  id: string;
  email: string;
  accountId: string;
  createdAt: Date;
  updatedAt: Date;
  loginToken?: ITokenPayload['loginToken'] | null;
  loginTokenExpiry?: ITokenPayload['loginTokenExpiry'] | null;
}

export interface ISessionUser extends IUser {
  firstName: string;
  lastName: string;
  organization: Organizations;
  currentPartner: SwitchablePartner | null;
  permissions: string[];
  roles: string[];
  username: string;
  allPermissions: UserPermissions;
  loginToken: ITokenPayload['loginToken'] | null;
  loginTokenExpiry: ITokenPayload['loginTokenExpiry'] | null;
  switchablePartners: ReadonlyArray<SwitchablePartner>;
  switchablePartnerSet: Readonly<Set<SwitchablePartner>>;

  // this is used in crm.
  graphAPI: GraphQLClient;
}

// If a user org isn't a switchable partner, we'll use the floating org map to find a valid switchable partner to use.
export const getGroundedOrganization: (organization: Organizations) => SwitchablePartner = organization =>
  organization in FLOATING_ORGANIZATION_MAP ? FLOATING_ORGANIZATION_MAP[organization] : organization;
