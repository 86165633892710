import * as React from 'react';
import { camelCase } from 'lodash-es';
import { Link } from 'react-router-dom';

import { Card, CardContain } from '../../Card';
import { SelectionOnRequestTypes } from '../queries/getRequestCategories.graphql';

export const RequestLink: React.FC<{ request: SelectionOnRequestTypes }> = ({ request }) => (
  <CardContain oneColumn={true} margin='0'>
    <Link to={`${request.serviceDeskId}/${request.id}`} data-event={camelCase(`RequestType ${request.name}`)} key={request.id}>
      <Card
        icon='minus'
        label={request.description}
        name={request.name}
        data-event={camelCase(`Requesttype ${request.name}`)}
      />
    </Link>
  </CardContain>
);

interface IProps {
  types: SelectionOnRequestTypes[];
}

const Requests: React.FC<IProps> = ({ types }) => (
  <CardContain oneColumn={true} margin='0'>
    {types && types.sort((a, b) => a.name.localeCompare(b.name)).map((request: SelectionOnRequestTypes) => (
      <RequestLink request={request} key={request.id} />
    ))}
  </CardContain>
);

export default Requests;
