import * as React from 'react';
import ErrorHandler from '@avant/crm-frontend-utils/error';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { Mutation, MutationFn, Query } from 'react-apollo';

import { ErrorLogger } from '../../../services/error';
import { ModuleRoutes } from '../../../../shared/routes';
import HelpdeskBody from '../Common/HelpdeskBody';
import SubmitHelpdeskTicketQuery, { SubmitHelpdeskTicket, SubmitHelpdeskTicketInput } from '../queries/submitHelpdeskTicket.graphql';
import GetRequestFieldsQuery, { GetRequestFields, GetRequestFieldsInput } from '../queries/getRequestFields.graphql';
import { Spinner } from '../../Spinner';
import { HELPDESK_ROUTES } from '../helpdesk-routes';
import { MarginlessH1Headline } from '../../CommonComponents';

import SubmitForm from './SubmitForm';
import submitRequestHandler from './submitRequest';
import { IRequestIds } from './interfaces';

interface ISubmitProps {
  submitTicket: MutationFn<SubmitHelpdeskTicket, SubmitHelpdeskTicketInput>;
  data: GetRequestFields | undefined;
}

export const Submit = ({ data, submitTicket }: ISubmitProps) => {
  const history = useHistory();
  const { serviceDeskId, requestTypeId } = useParams<IRequestIds>();

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string | null>(null);

  const submitRequest: (formData: object) => void = async formData => {
    try {
      setSubmitting(true);
      setMessage(null);

      const request = await submitRequestHandler(submitTicket, formData, { serviceDeskId, requestTypeId });
      if (!request.issueKey) { throw Error('Request creation failed.'); }

      ErrorHandler.notify('Ticket submitted successfully', 'Helpdesk', 'success');
      history.push(`${ModuleRoutes.helpdesk}${HELPDESK_ROUTES.viewRequest}/${request.issueKey}`);
    } catch (err) {
      console.log(err);
      ErrorHandler.notify('Failed to submit ticket', 'Helpdesk', 'error');
      ErrorLogger.captureException(err);

      let submitErrorMessage: string = `${err.message} Please email partnerportalsupport@amount.com with the ticket details.`;

      if (err.message.startsWith('Network')) {
        submitErrorMessage = 'Please ensure you are connected to the network and retry submission. ' +
          'If you experience this issue multiple times, please email partnerportalsupport@amount.com.';
      }

      setMessage(submitErrorMessage);
    }
    setSubmitting(false);
  };

  return (
    <HelpdeskBody backPath={`${ModuleRoutes.helpdesk}${HELPDESK_ROUTES.createRequestCategories}`}>
      <Helmet>
        <title>{`${serviceDeskId} Ticket · Helpdesk · Partner Portal`}</title>
      </Helmet>
      {data && (
        <>
          <MarginlessH1Headline scale='large'>Submit {data.requestFields.title} Ticket</MarginlessH1Headline>
          <SubmitForm
            fields={data && data.requestFields.requestTypeFields || []}
            submitRequest={submitRequest}
            submitting={submitting}
            serviceDeskId={serviceDeskId}
            error={message}
          />
        </>
      ) || <div>There has been an issue getting Helpdesk data. Please contact support.</div>}
    </HelpdeskBody>
  );
};

const Component: React.FC = () => {
  const params = useParams<IRequestIds>();

  return (
    <Mutation<SubmitHelpdeskTicket, SubmitHelpdeskTicketInput> mutation={SubmitHelpdeskTicketQuery} >
      {submitTicket => (
        <Query<GetRequestFields, GetRequestFieldsInput> query={GetRequestFieldsQuery} variables={params}>
          {({ data, loading }) => loading ? <Spinner withMargin={true} /> : <Submit submitTicket={submitTicket} data={data} />}
        </Query>
      )}
    </Mutation>
  );
};

export default Component;
