import * as React from 'react';
import { FormLabel } from '@amount/frontend-components';
import styled from 'styled-components';

import UploadButton from '../../UploadButton';
import { FileIcon } from '../../Reports/Presentational/common';

import { IAttachment, Section } from './';

interface IAttachmentSection {
  sectionName: string;
  label: string;
  value: IAttachment | null;
  attachmentName: string;
  onRemove: React.FormEventHandler<HTMLButtonElement>;
  onChange: React.FormEventHandler<HTMLInputElement>;
}

const UploadLabel = styled(FormLabel)`
  padding-bottom: 1em;
`;

const UploadList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75em 0em 0.5em 0em;
`;

const RemoveFileButton = styled.button`
  text-decoration: underline;
  margin-left: 1em;
`;

const UploadInput = styled.input`
  display: none;
`;

export class AttachmentSection extends React.Component<IAttachmentSection> {
  private upload: HTMLInputElement | undefined;

  public render (): JSX.Element {
    return (
      <Section key={this.props.sectionName}>
        <UploadLabel>{this.props.label}</UploadLabel>
        {!!this.props.value ? (
          <UploadList>
            <FileIcon icon='file' />
            <span>{this.props.attachmentName}</span>
            <RemoveFileButton
              onClick={this.props.onRemove}
              data-event='removeFile'
            >
              Remove
            </RemoveFileButton>
          </UploadList>
        ) : (
          <>
            <UploadInput
              type='file'
              onChange={this.props.onChange}
              // tslint:disable-next-line: no-any
              ref={(input: any) => this.upload = input}
            />
            <UploadButton
              uploadingFile={false}
              uploadFile={this.triggerUpload}
            />
          </>
        )}
      </Section>
    );
  }

  private readonly triggerUpload: () => void = () => {
    if (this.upload) { this.upload.click(); }
  }
}
