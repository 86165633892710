import * as React from 'react';
import { ActionButton, FormError, Headline, SVGIcon } from '@amount/frontend-components';
import { format, startOfYear, isAfter } from 'date-fns'
import { MutationFn } from 'react-apollo';
import ErrorHandler from '@avant/crm-frontend-utils/error';

import { SelectionOnInBranchIndividual } from '../../queries/getInBranchData.graphql';
import { DownloadInBranchCSV, DownloadInBranchCSVInput } from '../../queries/getCSV.graphql';
import { stringToFileDownload } from '../../../../services/downloadFile';

import {
  ISummaryData,
  Spacer,
  FilterLabel,
  GroupedFilter,
  ColumnContainer,
  isDateWithin,
  DATE_INPUT_FORMAT,
  CHART_WIDTH,
  CHART_HEIGHT,
  PaddingContainer,
  Row,
  ButtonLabel,
  DATE_DOWNLOAD_FORMAT
} from './ChartCommon';

import { getSummaryViewData, useIssuanceChartData } from './InBranchDataMappers';
import IssuanceChart, { IChartDatum } from './IssuanceChart';
import BranchFunnelChart from './BranchFunnelChart';
import DebouncedDatePicker from './DebouncedDatePicker';

interface IProps {
  data: SelectionOnInBranchIndividual[];
  currentDate: Date;
  getCSVFn: MutationFn<DownloadInBranchCSV, DownloadInBranchCSVInput>
}

const formatDate = (d: Date): string => format(d, DATE_INPUT_FORMAT)

const IndividualDashboard: React.FC<IProps> = ({ data, currentDate, getCSVFn }) => {

  const [appsFrom, setAppsFrom] = React.useState<string>(formatDate(startOfYear(currentDate)));
  const [issuedAppsFrom, setIssuedAppsFrom] = React.useState<string>(formatDate(startOfYear(currentDate)));

  const [appsTo, setAppsTo] = React.useState<string>(formatDate(currentDate));
  const [issuedAppsTo, setIssuedAppsTo] = React.useState<string>(formatDate(currentDate))

  const [dateError, setDateError] = React.useState<boolean>(false);
  const [issuedDateError, setIssuedDateError] = React.useState<boolean>(false);

  const [downloadDisabled, setDownloadDisabled] = React.useState<boolean>(false)

  React.useEffect(() => {
    setDateError(isAfter(appsFrom, appsTo));
    setIssuedDateError(isAfter(issuedAppsFrom, issuedAppsTo));

  }, [appsFrom, appsTo, issuedAppsFrom, issuedAppsTo])

  const filteredData = React.useMemo(
    () => {
      if (!data) {
        return [];
      }

      return data
      .filter(d => isDateWithin(d.createdTime, appsFrom, appsTo))
    },
    [appsFrom, appsTo, data]
  );

  const issuedFilteredData = React.useMemo(
    () => {
      if (!data) {
        return [];
      }

      return data
        .filter(d => isDateWithin(d.issuedTime, issuedAppsFrom, issuedAppsTo))
    },
    [data, issuedAppsFrom, issuedAppsTo]
  )

  const summaryData: ISummaryData = React.useMemo(
    () => getSummaryViewData(filteredData), [filteredData]
  );

  const issuanceChartData: IChartDatum[] = useIssuanceChartData(issuedFilteredData, issuedAppsFrom, issuedAppsTo);

  const handleDownloadCSV = async () => {
    setDownloadDisabled(true)

    try {
      const fields = filteredData.map(d => ({
        branchId: d.branchId,
        agentId: d.agentId,
        customerAppId: d.id.toString(),
        createdDate: format(d.createdTime, DATE_DOWNLOAD_FORMAT),
        status: d.status,
        stage: d.stage,
        appStartType: d.appStartType,
        phoneNumber: d.phoneNumber,
        email: d.email
      }))

      const res = await getCSVFn({
        variables: {
          fields
        }
      })
      if (!res || !res.data || !res.data.downloadInBranchCSV) { throw new Error('invalid response'); }
      const data: string | null = res.data.downloadInBranchCSV.data;
      if (!data) { throw new Error('response data is undefined'); }

      const csvTitle = `TDInBranchData-IndividualReporting-${format(new Date, DATE_DOWNLOAD_FORMAT)}.csv`
      stringToFileDownload(data, csvTitle);

    } catch (e) {
      console.error('CSV Download unsuccessful', e)
      ErrorHandler.notify('Unable to download CSV', 'Download CSV', 'error')
    }
    setDownloadDisabled(false)
  }

  return (
    <>
      <Row>
        <Headline scale='medium'>Sales Funnel</Headline>
        <ActionButton
          onClick={handleDownloadCSV}
          disabled={downloadDisabled}
        >
          <SVGIcon icon='download' />
          <ButtonLabel>CSV</ButtonLabel>
        </ActionButton>
      </Row>
      <Spacer padding='1em' />
      <PaddingContainer>
        <ColumnContainer>
          <GroupedFilter>
            <div id='reset'>
              <FilterLabel>App Created - From</FilterLabel>
              <DebouncedDatePicker
                defaultValue={appsFrom}
                name='appsFromIndi'
                onDebouncedChange={setAppsFrom}
              />
            </div>
            <div>
              <FilterLabel>App Created - To</FilterLabel>
              <DebouncedDatePicker
                defaultValue={appsTo}
                name='appsToIndi'
                onDebouncedChange={setAppsTo}
              />
            </div>
          </GroupedFilter>
          {dateError && (
            <FormError>Invalid Date Range</FormError>
          )}
        </ColumnContainer>
      </PaddingContainer>
      <BranchFunnelChart {...summaryData} width={CHART_WIDTH} height={CHART_HEIGHT} />
      <Spacer padding='3em' />
      <Headline scale='medium'>Loan Issuance Over Time</Headline>
      <Spacer padding='1em' />
      <PaddingContainer>
        <ColumnContainer>
          <GroupedFilter>
            <div id='reset'>
              <FilterLabel>Issued - From</FilterLabel>
              <DebouncedDatePicker
                defaultValue={issuedAppsFrom}
                name='issuedAppsFromIndi'
                onDebouncedChange={setIssuedAppsFrom}
              />
            </div>
            <div>
              <FilterLabel>Issued - To</FilterLabel>
              <DebouncedDatePicker
                defaultValue={issuedAppsTo}
                name='issuedAppsToIndi'
                onDebouncedChange={setIssuedAppsTo}
              />
            </div>
          </GroupedFilter>
          {issuedDateError && (
            <FormError>Invalid Date Range</FormError>
          )}
        </ColumnContainer>
      </PaddingContainer>
      <IssuanceChart data={issuanceChartData} width={CHART_WIDTH} height={CHART_HEIGHT} />
      <Spacer padding='4em' />
    </>
  );

};

export default IndividualDashboard;
IndividualDashboard.displayName = 'IndividualDashboard';
