import * as React from 'react';
import { Link } from 'react-router-dom';
import camelCase from 'lodash-es/camelCase';
import {
  ConditionalRenderWrapper,
  Headline,
  Label,
  media,
} from '@amount/frontend-components';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { HUMAN_READABLE_S3_TYPES } from '../../../../shared/reports/constants';
import {
  BackArrow,
  Breadcrumb as MobileBreadcrumb,
  BreadcrumbWrap as BreadcrumbWrapBase,
} from '../../Breadcrumb';
import {
  Menu,
  MenuItemContain,
  MenuItemLabel,
} from '../../CommonComponents';

import {
  FileRestrictedIcon as FileIcon,
} from './common';

const BreadcrumbWrap = styled(BreadcrumbWrapBase)`
  display: flex;
  white-space: nowrap;
  width: auto;
  max-width: calc(100vw - 2em);

  ${media.small`
    top: -2em;
  `}

  ${ConditionalRenderWrapper} {
    width: 100%;
  }
`;

const FileRestrictedIcon = styled(FileIcon)`
  width: 24px;
  height: 24px;
  margin-left: 0.25em;
  color: ${({ theme }) => theme.text.colorText};
`;

const BreadcrumbLink = styled.span`
  position: relative;

  &:hover {
    color: ${({ theme }) => theme.link.colorLinkHover};
  }

  &:not(:first-child)::before {
    content: '/';
    padding: 0 0.5em 0 0;
    color: ${({ theme: { brand } }) => brand.colorAlert};
    margin-left: 0.5em;
  }

  &:last-child {
    width: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const CurrentBreadcrumb = styled(Headline.withComponent('h1'))`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;

  &::before {
    content: '/';
    padding: 0 1rem 0 0;
    color: ${({ theme: { brand } }) => brand.colorAlert};
  }
`;

const ContextMenu = styled(Menu)`
  position: absolute;
  top: 30px;
`;

const BreadcrumbLabel = styled(Label)`
  cursor: pointer;

  &:hover {
    color: ${({ theme: { link }}) => link.colorLinkHover};
  }
`;

const ROOT_TO_TEXT_MAP: { [key: string]: string } = {
  reports: 'Reports',
  policies: 'Documents'
};

const Breadcrumb: React.FC<{ to: string }> = props => (
  <BreadcrumbLink>
    <Link
      to={props.to}
      children={props.children}
      data-event={props.children ? camelCase(`${props.children as string} breadcrumb`) : null}
    />
  </BreadcrumbLink>
);

interface ICollapsedBreadcrumbProps {
  showMenu: boolean;
  root: HUMAN_READABLE_S3_TYPES;
  breadcrumbList: string[];
  onClick (): void;
}

const BreadcrumbMenu: React.FC<Pick<ICollapsedBreadcrumbProps, 'breadcrumbList' | 'root'>> = ({ root, breadcrumbList }) => (
  <ContextMenu
    data-breadcrumbmenu={true}
  >
    {breadcrumbList.map((breadcrumb, i) => (
      <Link
        to={`/${root}/${breadcrumbList.slice(0, i + 1).join('%2f')}`}
        data-event={`${breadcrumb}BreadcrumbMenu`}
        key={breadcrumb}
      >
        <MenuItemContain>
          <MenuItemLabel>{breadcrumb}</MenuItemLabel>
        </MenuItemContain>
      </Link>
    ))}
  </ContextMenu>
);

const CollapsedBreadcrumb: React.FC<ICollapsedBreadcrumbProps> = ({ root, onClick, showMenu, breadcrumbList}) => (
  <BreadcrumbLink>
    <BreadcrumbLabel
      as='button'
      data-breadcrumbbutton={true}
      onClick={onClick}
      data-event='showBreadcrumbMenu'
    >
      ...
    </BreadcrumbLabel>
    {showMenu && <BreadcrumbMenu breadcrumbList={breadcrumbList} root={root} />}
  </BreadcrumbLink>
);

interface IBreadcrumbProps {
  pathNoRoot: string;
  root: HUMAN_READABLE_S3_TYPES;
  restricted?: boolean;
}

interface IBreadcrumbState {
  showMenu: boolean;
}

class Breadcrumbs extends React.Component<IBreadcrumbProps, IBreadcrumbState> {
  public state: IBreadcrumbState = {
    showMenu: false
  };

  public componentDidMount (): void {
    window.addEventListener('click', this.clickListener);
  }

  public componentWillUnmount (): void {
    window.removeEventListener('click', this.clickListener);
  }

  public render (): JSX.Element {
    const { root, pathNoRoot } = this.props;
    const rootFolder: string = ROOT_TO_TEXT_MAP[root];
    const pathList: string[] = pathNoRoot.split('/').filter(x => !!x);
    const folderDepth: number = pathList.length;
    const previousPath: string = pathList.slice(0, folderDepth - 1).join('%2f');
    const currentFolder: string = pathList[folderDepth - 1] ? `${pathList[folderDepth - 1]}` : rootFolder;
    const previousFolder: string | undefined = folderDepth > 1 ? pathList[folderDepth - 2] : undefined;
    const collapsedBreadcrumbs: string[] | undefined = folderDepth > 2 ? pathList.slice(0, pathList.length - 2) : undefined;
    const currentFolderTitle: string = rootFolder === currentFolder ? '' : `${currentFolder} · `;

    return (
      <>
        <Helmet>
          <title>{currentFolderTitle}{rootFolder} · Partner Portal</title>
        </Helmet>
        <BreadcrumbWrap>
          <ConditionalRenderWrapper breakpoint='small' hiddenOnMobile={true}>
            {!!folderDepth && <Breadcrumb to={`/${root}`}>{rootFolder}</Breadcrumb>}
            {!!collapsedBreadcrumbs &&
              <CollapsedBreadcrumb
                onClick={this.toggleBreadcrumbDropdown}
                showMenu={this.state.showMenu}
                breadcrumbList={collapsedBreadcrumbs}
                root={root}
              />
            }
            {!!previousFolder && <Breadcrumb to={`/${root}/${previousPath}`}>{previousFolder}</Breadcrumb>}
          </ConditionalRenderWrapper>
          <ConditionalRenderWrapper breakpoint='small' hiddenOnMobile={false}>
            {!!folderDepth && (
              <Link to={`/${root}/${!!previousFolder ? previousPath : ''}`} data-event='reportsBack'>
                <MobileBreadcrumb>
                  <BackArrow icon='return-arrow' />
                  <span>{!!previousFolder ? previousFolder : rootFolder}</span>
                </MobileBreadcrumb>
              </Link>
            )}
          </ConditionalRenderWrapper>
        </BreadcrumbWrap>
        <CurrentBreadcrumb scale='large'>
          {currentFolder}
          {!!this.props.restricted && (
            <FileRestrictedIcon icon='lock' />
          )}
        </CurrentBreadcrumb>
      </>
    );
  }

  private readonly toggleBreadcrumbDropdown: () => void = () => {
    this.setState(({ showMenu }) => ({ showMenu: !showMenu }));
  }

  private readonly clickListener: (e: MouseEvent) => void = e => {
    if (!this.state.showMenu) { return; }

    const Button: HTMLElement | null = document.querySelector(`[data-breadcrumbbutton='true']`);
    if (Button && Button.contains(e.target as Element)) { return; }

    e.preventDefault();
    this.toggleBreadcrumbDropdown();
  }
}

export default Breadcrumbs;
