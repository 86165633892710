import { SwitchablePartner } from '../config/partners';

export const PRIVATE_SARS_CHANNEL: string = 'private-partners-sars';
export const PRIVATE_FILES_CHANNEL: string = 'private-partners-files';
export const FILE_UPLOAD_EVENT: string = 'FILE_UPLOADED';

export interface ISarPubsubInfo {
  id: string;
  previousStatus: string;
  status: string;
  updatedAt: string;
  updatedBy: string;
  tenant: SwitchablePartner;
  currentPartner?: SwitchablePartner | null;
}

export interface IFilePubsubInfo {
  fileName: string;
  createdBy: string;
  createdAt: string;
  tenant: SwitchablePartner;
  currentPartner?: SwitchablePartner | null;
}
