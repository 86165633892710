import * as React from 'react';
import { ActionButton, SVGIcon, VALID_SVG_ICONS } from '@amount/frontend-components';
import styled from 'styled-components';

import { ButtonLabel } from '../../CommonComponents/ButtonLabel';

const MIN_INPUT_WIDTH: string = '164px';

export const AddButton = styled(ActionButton)`
  border: none;
  padding-left: 0;
  width: ${MIN_INPUT_WIDTH};
  margin: 0.25em 0 1em 0;
  justify-content: flex-start;

  svg {
    width: 2.2rem;
  }
`;

const ChildWrapper = styled.div`
  width: ${MIN_INPUT_WIDTH};
`;

interface IProps {
  icon: VALID_SVG_ICONS;
  buttonText: string;
}

interface IState {
  active: boolean;
}

export default class DissapearingButton extends React.PureComponent<IProps, IState> {
  public state: IState = {
    active: false
  };
  private readonly ref: React.RefObject<HTMLInputElement>;

  constructor (props: IProps) {
    super(props);
    this.ref = React.createRef();
  }

  public componentDidMount () {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  public componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  public render () {
    if (!this.state.active) {
      return (
        <AddButton onClick={this.toggleActive}>
          <SVGIcon icon={this.props.icon} />
          <ButtonLabel>{this.props.buttonText}</ButtonLabel>
        </AddButton>
      );
    }

    return (
      <ChildWrapper ref={this.ref}>
        {this.props.children}
      </ChildWrapper>
    );
  }

  private readonly toggleActive = (): void => this.setState({ active: !this.state.active });

  private readonly handleClick = (e: MouseEvent): void => {
    if (this.ref.current && e.target && !this.ref.current.contains(e.target as Node)) {
      this.setState({ active: false });
    }
  }
}
