/* tslint:disable */
import * as React from "react";

const SvgHsbcLogo: React.FC<
  React.SVGAttributes<SVGElement>
> = props => (
  <>
    <svg
      viewBox="0 0 315.9 85"
      height={30}
      xmlSpace="preserve"
      aria-hidden='true'
      {...props}
    >
      <path fill="#fff" d="M42.6 0h85v85h-85z" />
      <title>{`HSBC`}</title>
      <path
        fill='#db0011'
        d="M170.1 42.6L127.6 0v85.1zM85.1 42.6L127.6 0h-85z"
      />
      <path fill='#db0011' d="M0 42.6l42.6 42.5V0zM85.1 42.6L42.6 85.1h85z" />
      <path fill='var(--color-1, #000)' d="M207.4 45.1H192v15.2h-7.7V24.7h7.7v14.6h15.4V24.7h7.7v35.6h-7.7V45.1zM233.7 61c-7.7 0-14-3.1-14.1-11.6h7.7c.1 3.8 2.3 6.1 6.5 6.1 3.1 0 6.7-1.6 6.7-5.1 0-2.8-2.4-3.6-6.4-4.8l-2.6-.7c-5.6-1.6-11.2-3.8-11.2-10.2 0-7.9 7.4-10.6 14.1-10.6 6.9 0 12.9 2.4 13 10.3h-7.7c-.3-3.2-2.2-5.1-5.8-5.1-2.9 0-5.7 1.5-5.7 4.7 0 2.6 2.4 3.4 7.4 5l3 .9c6.1 1.9 10 4 10 10-.1 8-7.9 11.1-14.9 11.1zM252.9 24.8h12.4c2.3-.1 4.7 0 7 .4 4.3 1 7.6 3.8 7.6 8.6 0 4.6-2.9 6.9-7.1 8 4.8.9 8.4 3.3 8.4 8.6 0 8.1-8 9.9-14.2 9.9h-14l-.1-35.5zm12.4 14.8c3.4 0 6.9-.7 6.9-4.8 0-3.7-3.2-4.7-6.4-4.7h-5.4v9.5h4.9zM266 55c3.6 0 7.1-.8 7.1-5.2s-3-5.2-6.7-5.2h-6.1V55h5.7zM301.2 61c-11.5 0-16.6-7.3-16.6-18.2s5.7-18.8 17-18.8c7.1 0 14 3.2 14.2 11.2h-8c-.4-3.6-2.8-5.4-6.2-5.4-7 0-9.1 7.5-9.1 13.2 0 5.7 2.1 12.3 8.8 12.3 3.5 0 6.1-1.9 6.6-5.5h8c-.8 8.2-7.3 11.2-14.7 11.2z" />
    </svg>
  </>
);
export default SvgHsbcLogo;
