import { RouteComponentProps } from 'react-router';
import { ApolloError, NetworkStatus } from 'apollo-client';
import { ExecutionResult } from 'react-apollo';
import { MutationFunctionOptions } from '@apollo/react-common';
import {
  ActionButton,
  media,
  SVGIcon
} from '@amount/frontend-components';
import styled, { css } from 'styled-components';

import { HUMAN_READABLE_S3_TYPES, VALID_S3_TYPES } from '../../../../shared/reports/constants';
import { GetFileList, SelectionOnFiles, SelectionOnFolders } from '../queries/fileList.graphql';
import { MoveFileInput } from '../queries/moveFile.graphql';
import { DeleteFileInput } from '../queries/deleteFile.graphql';
import { DeleteFolderInput } from '../queries/deleteFolder.graphql';
import { READABLE_PARTNER_NAME_MAP } from '../../../../shared/config/partners';
import { SelectionOnMe } from '../../Context/me.graphql';
import { IModalProps } from '../../Modal';
import { getParentDirectories } from '../../../../shared/reports/util';
import { CreateFolder, CreateFolderInput } from '../queries/newFolder.graphql';

export interface IApolloVariablesType<T> {
  variables: T;
}

export interface IBaseProps extends RouteComponentProps<{ path: string | undefined }> {
  uploadPath: string;
  pathType: string;
}

export interface IS3Info {
  // tslint:disable-next-line: no-reserved-keywords
  type: VALID_S3_TYPES;
  pusherChannel: string;
  root: HUMAN_READABLE_S3_TYPES;
}

export type IProps = IS3Info & IBaseProps;

export interface IFileBaseProps extends SelectionOnFiles, Pick<IProps, 'root'> {
  fileName: string;
  canEdit: boolean;
  location?: string;
  showDrawer?: React.MouseEventHandler;
}

export interface IFileQuery {
  data?: GetFileList;
  error?: ApolloError;
  loading: boolean;
  networkStatus: NetworkStatus;
  refetch (): void;
}

export interface IFolderBaseProps extends SelectionOnFolders, Pick<IProps, 'root'>  {
  prefixNoParents?: string;
  canEdit: boolean;
  showDrawer?: React.MouseEventHandler;
}

export type NewFolderFn = (
  options?: MutationFunctionOptions<CreateFolder, CreateFolderInput> | undefined
) => Promise<ExecutionResult<CreateFolder>>;

export interface ILiftedProps extends IProps {
  pusherChannel: string;
  me: SelectionOnMe;
  fileQuery: IFileQuery;
  newFolder: NewFolderFn;
}

const urlMap: { [key in IProps['root']]: string } = {
  reports: 'report',
  policies: 'policy',
  visualization: 'visualization'
};
export const getFileUrl: (path: string, root: string, partner: string) => string =
  (path, root, partner) => `/api/${urlMap[root]}/${READABLE_PARTNER_NAME_MAP[partner]}/${path}`;

export const joinDirectories: (paths: string[]) => string = path => path.join('/').replace(/\/\//g, '/');
export const restoreSlashes: (path?: string) => string = path => (path || '').replace(/\%2f/g, '/');
export const ensureTrailingSlash: (path: string) => string = path => path.endsWith('/') ? path : `${path}/`;
const disallowedChars = /[^A-Za-z0-9-_.'() ]/;
export const isValidFileName: (name: string) => boolean = name => !disallowedChars.test(name);

type MoveFilePropsToVariablesType = (
  props: {
    // tslint:disable-next-line: no-reserved-keywords
    type: string;
    Key: string;
  },
  newName: string
) => IApolloVariablesType<MoveFileInput>;
export const MoveFilePropsToVariables: MoveFilePropsToVariablesType = ({ type, Key }, newName) => ({
  variables: {
    path: Key,
    destination: joinDirectories([getParentDirectories(Key), newName]),
    clientMutationId: '0',
    type
  }
});

// tslint:disable-next-line: no-reserved-keywords
type DeleteFilePropsToVariablesType = (props: { type: string; Key: string }) => IApolloVariablesType<DeleteFileInput>;
export const DeleteFilePropsToVariables: DeleteFilePropsToVariablesType = ({ type, Key }) => ({
  variables: {
    key: Key,
    clientMutationId: '0',
    type
  }
});

type DeleteFolderPropsToVariablesType = (
  props: {
    // tslint:disable-next-line: no-reserved-keywords
    type: string;
    Prefix: string;
  }
) => IApolloVariablesType<DeleteFolderInput>;
export const DeleteFolderPropsToVariables: DeleteFolderPropsToVariablesType = ({ type, Prefix }) => ({
  variables: {
    prefix: Prefix,
    clientMutationId: '0',
    type
  }
});

type MoveFolderPropsToVariablesType = (
  props: {
    // tslint:disable-next-line: no-reserved-keywords
    type: string;
    Prefix: string;
    restricted: boolean;
  },
  newName: string
) => IApolloVariablesType<MoveFileInput>;
export const MoveFolderPropsToVariables: MoveFolderPropsToVariablesType = ({ type, Prefix, restricted }, newName) => ({
  variables: {
    path: ensureTrailingSlash(Prefix),
    restricted,
    destination: ensureTrailingSlash(joinDirectories([getParentDirectories(Prefix), newName])),
    clientMutationId: '0',
    type
  }
});

export const drawerMobilePadding: IModalProps['padding'] = { mobile: '4.5em 0 1em', small: '4.5em 0 1em', medium: '4.5em 0 1em' };

const darkItemStyles = css`
  &:hover {
    color: ${({ theme: { link } }) => link.colorLinkHover};
  }
`;

const lightItemStyles = css`
  &:hover {
    background: ${({ theme }) => theme.colorSlate5};
  }
`;

export const FileListContain = styled.div`
  margin: 1em 0 3em;
  border-top: 1px solid ${({ theme }) => theme.colorSlate};

  ${media.small`
    margin: 2em 0 0;
    border-top: none;
  `}
`;

export const ListEntry = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 4em;
  padding: 0 1em;
  margin-right: 0.5em;
  border-bottom: 1px solid ${({ theme }) => theme.colorSlate};
  color: ${({ theme: { text } }) => text.colorText};
  cursor: pointer;

  ${({ theme }) => theme.name === 'amountDark' ? css`
    ${darkItemStyles}
  ` : css`
    ${lightItemStyles}
  `};
`;

export const EmptyRow = styled(ListEntry)`
  cursor: default;

  &:hover {
    background: none;
    color: ${({ theme: { text } }) => text.colorText};
  }
`;

export const FileIcon = styled(SVGIcon)`
  display: flex;
  width: 23px;
  height: 23px;
  margin-right: 1em;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const FileActionIcon = styled(SVGIcon)`
  display: flex;
  width: 16px;
  height: 16px;
  margin-left: 0.5em;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const FileRestrictedIcon = styled(SVGIcon)`
  width: 18px;
  height: 18px;
  margin-left: 0.5em;
  display: inline-flex;
  top: 2px;
  position: relative;
  color: ${({ theme }) => theme.colorSlate70};

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const FileTextContain = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 65px);

  ${media.small`
    width: calc(100% - 55px);
  `}
`;

export const FileText = styled.span`
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* fixes sailec line height */
  margin-top: 0.25em;
`;

export const FileActionContain = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MobileActionButton = styled(ActionButton)`
  padding: 0.25em 0.5em;
  border-radius: 1.5em;
  width: auto;

  background: ${({ theme }) => theme.colorSlate5};
  border-color: ${({ theme }) => theme.colorSlate5};

  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.colorSlate10};
    background: ${({ theme }) => theme.colorSlate10};

    svg {
      fill: ${({ theme }) => theme.colorSlate};
    }
  }

  svg {
    display: flex;
    transition: fill 100ms ease-out;
    margin: 0;
  }

  ${FileActionIcon} {
    margin-left: 0;
  }

  ${({ theme }) => theme.name === 'amountDark' && css`
    background-color: ${theme.colorBackground};

    &:focus {
      background-color: ${theme.colorBackground};
    }
  `};
`;
