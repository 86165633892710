const LS_AFFIRMATIVE_KEY: string = 'true';
const LS_NEGATIVE_KEY: string = 'false';

// tslint:disable-next-line: no-suspicious-comment
// TODO: this can be moved into shared code with ip-dash.

export const generateLocalStorageKey: (key: string) => string = key => `partners:${key.replace(' ', ':')}`;
export interface ILocalStorageInfo {
  /**
   * If the value is true
   */
  isTrue (): boolean;
  /**
   * Whether the key has previously been set
   */
  isSet (): boolean;
  /**
   * Set the value to true
   */
  setTrue (): void;
  /**
   * Set the value to false
   */
  setFalse (): void;
  /**
   * Clear the value
   */
  clear (): void;
}

export const getLocalStorageInfo: (key: string) => ILocalStorageInfo = key => ({
  isTrue: () => localStorage.getItem(key) === LS_AFFIRMATIVE_KEY,
  isSet: () => localStorage.getItem(key) !== null,
  setTrue: () => localStorage.setItem(key, LS_AFFIRMATIVE_KEY),
  setFalse: () => localStorage.setItem(key, LS_NEGATIVE_KEY),
  clear: () => localStorage.removeItem(key),
});
