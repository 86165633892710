
import * as React from 'react';
import { Mutation } from 'react-apollo';

import { VALID_S3_TYPES } from '../../../shared/reports/constants';
import { removeParentDirectories } from '../../../shared/reports/util';
import { Consumer } from '../Context';

import FileObject from './Presentational/File';
import FileBase from './Presentational/FileBase';
import DeleteFileMutate, { DeleteFile, DeleteFileInput } from './queries/deleteFile.graphql';
import MoveFileMutate, { MoveFile, MoveFileInput } from './queries/moveFile.graphql';
import {
  DeleteFilePropsToVariables,
  IProps,
} from './Presentational/common';
import { SelectionOnFiles } from './queries/fileList.graphql';

interface ILiftedFileProps extends SelectionOnFiles, Pick<IProps, 'root'> {
  canEdit: boolean;
  // tslint:disable-next-line: no-reserved-keywords
  type: VALID_S3_TYPES;
  refetch (): void;
}

type FileClass = SelectionOnFiles & ILiftedFileProps;

const LiftedFile: React.FC<FileClass> = props => (
  <Mutation<DeleteFile, DeleteFileInput>
    mutation={DeleteFileMutate}
    {...DeleteFilePropsToVariables(props)}
  >
    {deleteFile => (
      <Mutation<MoveFile, MoveFileInput>
        mutation={MoveFileMutate}
      >
        {renameFile =>
          <Consumer>
            {me =>
              <FileObject
                {...props}
                deleteFile={deleteFile}
                renameFile={renameFile}
                currentPartner={me.currentPartner}
              />
            }
          </Consumer>
        }
      </Mutation>
    )}
  </Mutation>
);

export const ConditionalLiftedFile: React.FC<ILiftedFileProps> = props => props.canEdit ? (
    <LiftedFile {...props} />
  ) : (
    <FileBase {...props} fileName={removeParentDirectories(props.Key) || ''} />
  );
