import * as React from 'react';
import { Mutation } from 'react-apollo';

import { ReadableToS3TypeMap } from '../../../shared/reports/constants';

import { FolderDrawer, ILiftedFolderDrawer } from './Presentational/FolderDrawer';
import DeleteFolderQuery, { DeleteFolder, DeleteFolderInput } from './queries/deleteFolder.graphql';
import MoveFolderQuery, { MoveFile, MoveFileInput } from './queries/moveFile.graphql';
import { DeleteFolderPropsToVariables } from './Presentational/common';

const LiftedFolderDrawer: React.FC<ILiftedFolderDrawer> = props => (
  <Mutation<DeleteFolder, DeleteFolderInput>
    mutation={DeleteFolderQuery}
    // tslint:disable-next-line: no-non-null-assertion
    {...DeleteFolderPropsToVariables({ Prefix: props.Prefix, type: ReadableToS3TypeMap.get(props.root)! })}
  >
    {deleteFolder => (
      <Mutation<MoveFile, MoveFileInput>
        mutation={MoveFolderQuery}
      >
        {moveFolder => (
          <FolderDrawer
            {...props}
            deleteFolder={deleteFolder}
            renameFolder={moveFolder}
          />
        )}
      </Mutation>
    )}
  </Mutation>
);

export default LiftedFolderDrawer;
