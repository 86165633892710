import * as React from 'react';
import { ActionButton, SVGIcon } from '@amount/frontend-components';
import styled from 'styled-components';

const ButtonLabel = styled.span`
  margin-bottom: -0.25em;
`;

const DropDownContentWrap = styled.li`
  position: absolute;
  top: 11.25em;
  right: .5em;
  width: 13em;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 0 5px 0 ${({ theme }) => theme.colorSlate10};
  list-style-type: none;
  button {
    color: ${({ theme }) => theme.name === 'amountDark' ? theme.actionButton.colorTextActive : theme.actionButton.colorText };
    font-size: 14px;
    width: 100%;
    padding: 1em;
    text-align: right;
  }
  button:hover {
    background-color: ${({ theme }) => theme.colorSlate5 };
  }
`;

const SortButton = styled(ActionButton)`
  margin: 1em 0 1em auto;
  color: ${({ theme }) => theme.name === 'amountDark' && theme.actionButton.colorTextActive };
  svg {
    fill: ${({ theme }) => theme.name === 'amountDark' && theme.actionButton.colorTextActive };
    padding: 0;
    margin: 0 0 0 .5em;
    width: .75em;
    height: auto;
  }
`;

export interface ISortOption { label: string; direction: 'ASC' | 'DESC'; field: 'created_at' | 'updated_at'; }
export const SortOptions = {
  // tslint:disable: no-object-literal-type-assertion
  createdDesc: {
    label: 'Date Created (Newest)',
    direction: 'DESC',
    field: 'created_at',
  } as ISortOption,
  createdAsc: {
    label: 'Date Created (Oldest)',
    direction: 'ASC',
    field: 'created_at',
  } as ISortOption,
  updatedDesc: {
    label: 'Last Updated (Newest)',
    direction: 'DESC',
    field: 'updated_at',
  } as ISortOption,
  updatedAsc: {
    label: 'Last Updated (Oldest)',
    direction: 'ASC',
    field: 'updated_at',
  } as ISortOption,
  // tslint:enable: no-object-literal-type-assertion
};

interface ISortCompProps {
  linkRef: React.RefObject<HTMLDivElement>;
  open: boolean;
  toggleOpen (): void;
  handleSortChange (s: ISortOption): void;
}

// tslint:disable: jsx-no-lambda
export const SortComp: React.FC<ISortCompProps> = ({linkRef, toggleOpen, open, handleSortChange}) =>
  (
    <div ref={linkRef}>
      <SortButton onClick={toggleOpen} active={open}>
        <ButtonLabel>Sort By</ButtonLabel>
        <SVGIcon icon={'arrow-up'} />
      </SortButton>
      {open && (
        <DropDownContentWrap>
          <button onClick={() => handleSortChange(SortOptions.createdDesc)}>{SortOptions.createdDesc.label}</button>
          <button onClick={() => handleSortChange(SortOptions.createdAsc)}>{SortOptions.createdAsc.label}</button>
          <button onClick={() => handleSortChange(SortOptions.updatedDesc)}>{SortOptions.updatedDesc.label}</button>
          <button onClick={() => handleSortChange(SortOptions.updatedAsc)}>{SortOptions.updatedAsc.label}</button>
        </DropDownContentWrap>
      )}
    </div>
  );
// tslint:enable: jsx-no-lambda
