/* eslint-disable max-lines */
import { get } from 'lodash';

import {
  AML_PSAE,
  AMOUNT_PERMISSION_KEY,
  AMOUNT_REJECTED_STEP_PSAE,
  AVANT_HELPDESK,
  AVANT_PERMISSION_KEY,
  BARCLAYS_PERMISSION_KEY,
  BBVA_PERMISSION_KEY,
  BPO_PERMISSION_KEY,
  CAPITAL_ONE_PERMISSION_KEY,
  DEMO_BANK_PERMISSION_KEY,
  JENIUS_PERMISSION_KEY,
  DRAFT_STEP_PSAE,
  EDIT_DOCUMENTS,
  EDIT_REPORTS,
  EDIT_USERS,
  ELOAN_PERMISSION_KEY,
  FINAL_REVIEW_SEND_BACK_PSAE,
  FINAL_REVIEW_STEP_PSAE,
  FRAUD_PSAE,
  GUARANTEED_RATE_PERMISSION_KEY,
  HSBC_PERMISSION_KEY,
  ICON_PERMISSION_KEY,
  INVALID_PERMISSION,
  JOMASHOP_PERMISSION_KEY,
  OPEN_STEP_PSAE,
  PARTNER_APPROVED_GENERATE_JOB,
  PARTNER_APPROVED_STEP_PSAE,
  PARTNER_REJECTED_STEP_PSAE,
  PEER_REVIEW_STEP_PSAE,
  PNC_PERMISSION_KEY,
  POLLEN_STREET_PERMISSION_KEY,
  RADIUS_PERMISSION_KEY,
  REGIONS_PERMISSION_KEY,
  SUBMIT_ACCESS_REQUEST_HELPDESK,
  SUBMIT_BUG_REPORT_HELPDESK,
  SUBMIT_CHANGE_REQUEST_HELPDESK,
  SUBMIT_DATA_SET_SPLIT_HELPDESK,
  SUBMIT_DIRECT_MAIL_HELPDESK,
  SUBMIT_ESCALATION_HELPDESK,
  SUBMIT_GENERAL_INQUIRY_HELPDESK,
  SUBMIT_SERVICE_INCIDENT_HELPDESK,
  TD_PERMISSION_KEY,
  TELEPERFORMANCE_PERMISSION_KEY,
  VIEW_ADMIN_HELPDESK,
  VIEW_CIP,
  VIEW_COMPLAINTS,
  VIEW_CREDIT_CARD_DOCUMENTS,
  VIEW_CREDIT_CARD_REPORTS,
  VIEW_CSP,
  VIEW_DISPUTES,
  VIEW_DOCUMENTS,
  VIEW_HELPDESK,
  VIEW_KNOWLEDGE_BASE,
  VIEW_IN_BRANCH_INDIVIDUAL,
  VIEW_IN_BRANCH_STORE,
  VIEW_LOAN_DOCUMENTS,
  VIEW_LOAN_REPORTS,
  VIEW_OPS_TICKETS,
  VIEW_OPS_TICKETS_RO,
  VIEW_PAYMENT_GATEWAY,
  VIEW_POS_DOCUMENTS,
  VIEW_POS_REPORTS,
  VIEW_PSAE,
  VIEW_REPORTS,
  VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS,
  VIEW_SENSITIVE_CREDIT_CARD_REPORTS,
  VIEW_SENSITIVE_LOAN_DOCUMENTS,
  VIEW_SENSITIVE_LOAN_REPORTS,
  VIEW_SENSITIVE_POS_DOCUMENTS,
  VIEW_SENSITIVE_POS_REPORTS,
  VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS,
  VIEW_SENSITIVE_SERVICING_ORG_REPORTS,
  VIEW_SERVICING_ORG_DOCUMENTS,
  VIEW_SERVICING_ORG_REPORTS,
  EDIT_SERVICING_ORG_REPORTS,
  VIEW_USERS,
  WEBBANK_PERMISSION_KEY,
  EDIT_POS_REPORTS,
  EDIT_LOAN_REPORTS,
  EDIT_LOAN_DOCUMENTS,
  EDIT_POS_DOCUMENTS,
  VIEW_MERCHANTS,
  VIEW_CONFIGURATIONS,
  VIEW_BPO_SUPPORT_HELPDESK,
  EDIT_BPO_SUPPORT_HELPDESK,
  UPDATE_BPO_SUPPORT_HELPDESK,
} from '../../shared/config/permissions';
import {
  FloatingOrganizations,
  READABLE_PARTNER_NAME_MAP,
} from '../../shared/config/partners';
import { ISessionUser } from '../../shared/config/user';
import { INVALID_ORGANIZATION } from '../../shared/config/constants';

import { IOrganizationConfig, IWardenConfig, SARPermission, SARTeamPermissions, Warden } from './warden';

type ValidTeamFilter = 'aml' | 'fraud';

const TEAM_FILTER_MAP: { [x in ValidTeamFilter]: SARTeamPermissions } = {
  aml: AML_PSAE,
  fraud: FRAUD_PSAE
};

type CheckReportPermission = (
  allowRoot: boolean
) => (payload: { user: ISessionUser; obj: string }) => boolean;
const checkReportPermissionBase: CheckReportPermission = allowRoot => ({
  obj: filePath,
  user: { currentPartner }
}) => {
  if (!currentPartner) { return false; }
  // if in root, show all files
  if (!filePath.includes('/')) { return allowRoot; }

  const splitKeys: string[] = filePath
    .split('/')
    .filter(x => !!x);

  if (!splitKeys.length) { return true; }
  const rootDirectory: string = splitKeys[0].toLowerCase();

  return rootDirectory === READABLE_PARTNER_NAME_MAP[currentPartner].toLowerCase();
};

const checkReportPermission = checkReportPermissionBase(true);
const checkReportUploadPermission = checkReportPermissionBase(false);

const filterSarOnTeamFilterMetadata: NonNullable<SARPermission['when']> = ({ obj, user }) => {
  const teamFilter: ValidTeamFilter | null = get(obj.metadata.userDefinedMetadata, 'teamFilter', null)?.toLowerCase() || null;
  if (!teamFilter) { return false; }

  const permissionToCheckFor: SARTeamPermissions | typeof INVALID_PERMISSION = TEAM_FILTER_MAP[teamFilter] || INVALID_PERMISSION;

  return user.permissions.includes(permissionToCheckFor);
};

const invalidOrgWardenConfig: { [INVALID_ORGANIZATION]: IOrganizationConfig } = {
  [INVALID_ORGANIZATION]: {
    availablePermissions: []
  }
};

const floatingOrgWardenConfig: { [organization in FloatingOrganizations]: IOrganizationConfig } = {
  [BPO_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_KNOWLEDGE_BASE },
      { name: VIEW_DISPUTES },
      { name: VIEW_HELPDESK },
      { name: VIEW_ADMIN_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: AVANT_HELPDESK },
      { name: SUBMIT_DATA_SET_SPLIT_HELPDESK },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: OPEN_STEP_PSAE },
      { name: DRAFT_STEP_PSAE },
      { name: AMOUNT_REJECTED_STEP_PSAE },
      { name: FINAL_REVIEW_STEP_PSAE },
      { name: PEER_REVIEW_STEP_PSAE },
      { name: VIEW_PSAE },
      { name: FINAL_REVIEW_SEND_BACK_PSAE },
      { name: VIEW_COMPLAINTS },
      { name: VIEW_CSP },
      { name: VIEW_CIP },
      { name: VIEW_USERS },
      { name: VIEW_PAYMENT_GATEWAY },
      { name: EDIT_USERS },
      { name: VIEW_OPS_TICKETS },
      { name: VIEW_OPS_TICKETS_RO },
    ]
  },
  [WEBBANK_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_PSAE },
      { name: PARTNER_APPROVED_GENERATE_JOB },
      {
        name: PARTNER_APPROVED_STEP_PSAE,
        when: ({ obj }) => WEBBANK_PERMISSION_KEY === obj.partner
      },
      {
        name: PARTNER_REJECTED_STEP_PSAE,
        when: ({ obj }) => WEBBANK_PERMISSION_KEY === obj.partner
      },
      { name: VIEW_HELPDESK },
      { name: VIEW_ADMIN_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: VIEW_USERS },
      { name: VIEW_PAYMENT_GATEWAY },
      { name: EDIT_USERS }
    ]
  },
  [TELEPERFORMANCE_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_DISPUTES },
      { name: VIEW_CIP },
      { name: VIEW_USERS },
      { name: EDIT_USERS },
      { name: VIEW_COMPLAINTS },
      { name: VIEW_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
    ]
  },
  [RADIUS_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_DISPUTES },
      { name: VIEW_CIP },
      { name: VIEW_USERS },
      { name: EDIT_USERS },
      { name: VIEW_COMPLAINTS },
      { name: VIEW_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: VIEW_CSP },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: VIEW_REPORTS },
      { name: VIEW_DOCUMENTS },
      { name: EDIT_REPORTS },
      { name: EDIT_DOCUMENTS },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ]
  },
};

export const wardenConfig: IWardenConfig = {
  [AMOUNT_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_KNOWLEDGE_BASE },
      { name: VIEW_HELPDESK },
      { name: VIEW_ADMIN_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: AVANT_HELPDESK },
      { name: SUBMIT_DATA_SET_SPLIT_HELPDESK },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: OPEN_STEP_PSAE },
      { name: DRAFT_STEP_PSAE },
      { name: AMOUNT_REJECTED_STEP_PSAE },
      { name: FINAL_REVIEW_STEP_PSAE },
      { name: PEER_REVIEW_STEP_PSAE },
      { name: VIEW_PSAE },
      { name: FINAL_REVIEW_SEND_BACK_PSAE },
      { name: VIEW_COMPLAINTS },
      { name: VIEW_CSP },
      { name: VIEW_CIP },
      { name: VIEW_DISPUTES },
      { name: VIEW_IN_BRANCH_INDIVIDUAL },
      { name: VIEW_IN_BRANCH_STORE },
      { name: VIEW_USERS },
      { name: VIEW_PAYMENT_GATEWAY },
      { name: EDIT_USERS },
      { name: VIEW_OPS_TICKETS },
      { name: VIEW_OPS_TICKETS_RO },
      { name: VIEW_MERCHANTS },
      { name: VIEW_CONFIGURATIONS },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ]
  },
  [AVANT_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_KNOWLEDGE_BASE },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_PSAE },
      { name: OPEN_STEP_PSAE, when: ({ obj }) => WEBBANK_PERMISSION_KEY === obj.partner },
      { name: DRAFT_STEP_PSAE, when: ({ obj }) => WEBBANK_PERMISSION_KEY === obj.partner },
      { name: AMOUNT_REJECTED_STEP_PSAE, when: ({ obj }) => WEBBANK_PERMISSION_KEY === obj.partner },
      { name: FINAL_REVIEW_STEP_PSAE, when: ({ obj }) => WEBBANK_PERMISSION_KEY === obj.partner },
      { name: PEER_REVIEW_STEP_PSAE, when: ({ obj }) => WEBBANK_PERMISSION_KEY === obj.partner },
      { name: FINAL_REVIEW_SEND_BACK_PSAE },
      { name: VIEW_DISPUTES },
      { name: VIEW_HELPDESK },
      { name: VIEW_OPS_TICKETS },
      { name: VIEW_OPS_TICKETS_RO },
      { name: VIEW_ADMIN_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: AVANT_HELPDESK },
      { name: SUBMIT_DATA_SET_SPLIT_HELPDESK },
      { name: VIEW_CSP },
      { name: VIEW_CIP },
      { name: VIEW_COMPLAINTS },
      { name: VIEW_USERS },
      { name: VIEW_PAYMENT_GATEWAY },
      { name: EDIT_USERS },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ]
  },
  [CAPITAL_ONE_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_DISPUTES },
      { name: VIEW_CIP },
      { name: VIEW_USERS },
      { name: EDIT_USERS },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_HELPDESK },
      { name: VIEW_COMPLAINTS },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ]
  },
  [DEMO_BANK_PERMISSION_KEY]: process.env.ENABLE_DEMO_BANK ? {
    availablePermissions: [
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      {
        name: PARTNER_REJECTED_STEP_PSAE,
        when: ({ user, obj }) => user.currentPartner === obj.partner
      },
      {
        name: PARTNER_APPROVED_STEP_PSAE,
        when: ({ user, obj }) => user.currentPartner === obj.partner
      },
      { name: VIEW_PSAE },
      { name: PARTNER_APPROVED_GENERATE_JOB },
      { name: VIEW_CSP },
      { name: VIEW_COMPLAINTS },
      { name: VIEW_HELPDESK },
      { name: VIEW_ADMIN_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: VIEW_USERS },
      { name: VIEW_PAYMENT_GATEWAY },
      { name: EDIT_USERS },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ],
  } : {
    availablePermissions: []
  },
  [ELOAN_PERMISSION_KEY]: {
    availablePermissions: [
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
      {
        name: PARTNER_REJECTED_STEP_PSAE,
        when: ({ user, obj }) => user.currentPartner === obj.partner
      },
      {
        name: PARTNER_APPROVED_STEP_PSAE,
        when: ({ user, obj }) => user.currentPartner === obj.partner
      },
      { name: VIEW_PSAE },
      { name: PARTNER_APPROVED_GENERATE_JOB },
      { name: VIEW_CSP },
      { name: VIEW_COMPLAINTS },
      { name: VIEW_HELPDESK },
      { name: VIEW_ADMIN_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: VIEW_USERS },
      { name: VIEW_PAYMENT_GATEWAY },
      { name: EDIT_USERS }
    ],
  },
  [GUARANTEED_RATE_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_DISPUTES },
      { name: VIEW_CIP },
      { name: VIEW_USERS },
      { name: EDIT_USERS },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_HELPDESK },
      { name: VIEW_COMPLAINTS },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ]
  },
  [HSBC_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_HELPDESK },
      { name: VIEW_ADMIN_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      {
        name: PARTNER_REJECTED_STEP_PSAE,
        when: ({ user, obj }) => user.currentPartner === obj.partner
      },
      {
        name: PARTNER_APPROVED_STEP_PSAE,
        when: ({ user, obj }) => user.currentPartner === obj.partner
      },
      { name: PARTNER_APPROVED_GENERATE_JOB },
      { name: VIEW_PSAE },
      { name: VIEW_CSP },
      { name: VIEW_COMPLAINTS },
      { name: VIEW_USERS },
      { name: VIEW_PAYMENT_GATEWAY },
      { name: EDIT_USERS },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ],
  },
  [JOMASHOP_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_DISPUTES },
      { name: VIEW_CIP },
      { name: VIEW_USERS },
      { name: EDIT_USERS },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ]
  },
  [PNC_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_HELPDESK },
      { name: VIEW_ADMIN_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      {
        name: PARTNER_REJECTED_STEP_PSAE,
        when: ({ user, obj }) => user.currentPartner === obj.partner
      },
      {
        name: PARTNER_APPROVED_STEP_PSAE,
        when: ({ user, obj }) => user.currentPartner === obj.partner
      },
      { name: PARTNER_APPROVED_GENERATE_JOB },
      { name: VIEW_PSAE },
      { name: VIEW_CSP },
      { name: VIEW_COMPLAINTS },
      { name: VIEW_USERS },
      { name: VIEW_PAYMENT_GATEWAY },
      { name: EDIT_USERS },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ],
  },
  [POLLEN_STREET_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_HELPDESK },
      { name: VIEW_ADMIN_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_CSP },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ]
  },
  [REGIONS_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_HELPDESK },
      { name: VIEW_ADMIN_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_CSP },
      { name: VIEW_COMPLAINTS },
      { name: VIEW_USERS },
      { name: VIEW_PAYMENT_GATEWAY },
      { name: EDIT_USERS },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ]
  },
  [TD_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_DISPUTES },
      { name: VIEW_IN_BRANCH_INDIVIDUAL },
      { name: VIEW_IN_BRANCH_STORE },
      { name: VIEW_HELPDESK },
      { name: VIEW_ADMIN_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: AML_PSAE },
      { name: FRAUD_PSAE },
      {
        name: PARTNER_REJECTED_STEP_PSAE,
        when: ({ user, obj }) =>
          user.currentPartner === obj.partner && filterSarOnTeamFilterMetadata({ user, obj })
      },
      {
        name: PARTNER_APPROVED_STEP_PSAE,
        when: ({ user, obj }) =>
          user.currentPartner === obj.partner && filterSarOnTeamFilterMetadata({ user, obj })
      },
      { name: PARTNER_APPROVED_GENERATE_JOB },
      { name: VIEW_PSAE },
      { name: VIEW_CSP },
      { name: VIEW_CIP },
      { name: VIEW_COMPLAINTS },
      { name: VIEW_USERS },
      { name: VIEW_PAYMENT_GATEWAY },
      { name: EDIT_USERS },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ]
  },
  [ICON_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_DISPUTES },
      { name: VIEW_CIP },
      { name: VIEW_USERS },
      { name: VIEW_PAYMENT_GATEWAY },
      { name: EDIT_USERS },
      { name: VIEW_MERCHANTS },
      { name: VIEW_CONFIGURATIONS },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ]
  },
  [BBVA_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_USERS },
      { name: EDIT_USERS },
      { name: VIEW_PAYMENT_GATEWAY },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_PSAE },
      { name: VIEW_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: VIEW_COMPLAINTS },
      { name: VIEW_CSP },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ]
  },
  [BARCLAYS_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_DISPUTES },
      { name: VIEW_CIP },
      { name: VIEW_USERS },
      { name: EDIT_USERS },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ],
  },
  [JENIUS_PERMISSION_KEY]: {
    availablePermissions: [
      { name: VIEW_CIP },
      { name: VIEW_USERS },
      { name: EDIT_USERS },
      { name: VIEW_HELPDESK },
      { name: VIEW_ADMIN_HELPDESK },
      { name: SUBMIT_CHANGE_REQUEST_HELPDESK },
      { name: SUBMIT_BUG_REPORT_HELPDESK },
      { name: SUBMIT_ACCESS_REQUEST_HELPDESK },
      { name: SUBMIT_GENERAL_INQUIRY_HELPDESK },
      { name: SUBMIT_SERVICE_INCIDENT_HELPDESK },
      { name: SUBMIT_ESCALATION_HELPDESK },
      { name: SUBMIT_DIRECT_MAIL_HELPDESK },
      { name: VIEW_DOCUMENTS, when: checkReportPermission },
      { name: EDIT_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_DOCUMENTS, when: checkReportUploadPermission },
      { name: EDIT_POS_DOCUMENTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS, when: checkReportPermission },
      { name: VIEW_REPORTS, when: checkReportPermission },
      { name: EDIT_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_POS_REPORTS, when: checkReportUploadPermission },
      { name: EDIT_LOAN_REPORTS, when: checkReportUploadPermission },
      { name: VIEW_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: EDIT_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_CREDIT_CARD_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_LOAN_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_POS_REPORTS, when: checkReportPermission },
      { name: VIEW_SENSITIVE_SERVICING_ORG_REPORTS, when: checkReportPermission },
      { name: VIEW_BPO_SUPPORT_HELPDESK },
      { name: EDIT_BPO_SUPPORT_HELPDESK },
      { name: UPDATE_BPO_SUPPORT_HELPDESK },
    ],
  },
  ...floatingOrgWardenConfig,
  ...invalidOrgWardenConfig
};

const AvantWarden = new Warden(wardenConfig);

export default AvantWarden;
