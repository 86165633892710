import * as React from 'react';
import { ConditionalRenderWrapper } from '@amount/frontend-components';
import styled from 'styled-components';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1em;
  margin: 0;
  align-items: baseline;
`;

const ButtonsList = styled.ul`
  li {
    display: inline-block;
  }

  span {
    padding: .25em .5em;
    margin: 0 .125em;
  }

  button {
    color: ${({ theme }) => theme.colorSlate70};
    padding: .25em .5em;
    margin: 0 .125em;

    &:hover {
      color: ${({ theme }) => theme.actionButton.colorTextHover};
    }

    &:disabled {
      color: ${({ theme }) => theme.colorSlate10};
    }
  }
`;

const StyledPagination = styled.div`
  color: ${({ theme }) => theme.colorSlate70};
`;

interface IProps {
  current: number;
  limit: number;
  total: number;
  count: number;
  getPage (i: number): void;
}

const Pagination: React.FC<IProps> = ({ count, current, getPage, limit, total }) => {
  const first = (): void => getPage(0);
  const prev = (): void => getPage(current - limit);
  const next = (): void => getPage(current + limit);
  const last = (): void => getPage(total - total % limit);

  const arePagesBefore: boolean = current < 1;
  const arePagesLeft: boolean = current + limit > total;

  return (
    <PaginationContainer>
      <ConditionalRenderWrapper hiddenOnMobile={true} breakpoint='small'>
        <ButtonsList>
          <li>
            <button onClick={first} disabled={arePagesBefore}>
              <span aria-hidden='true'>&lt;&lt;</span>First
            </button>
          </li>
          <li>
            <button onClick={prev} disabled={arePagesBefore}>
              <span aria-hidden='true'>&lt;</span>Prev
            </button>
          </li>
          <li>
            <button onClick={next} disabled={arePagesLeft}>
              Next<span aria-hidden='true'>&gt;</span>
            </button>
          </li>
          <li>
            <button onClick={last} disabled={arePagesLeft}>
              Last<span aria-hidden='true'>&gt;&gt;</span>
            </button>
          </li>
        </ButtonsList>
      </ConditionalRenderWrapper>
      <div>
        <ConditionalRenderWrapper hiddenOnMobile={false} breakpoint='small'>
          <StyledPagination>Showing <strong> {current + count} of {total} open</strong> requests</StyledPagination>
        </ConditionalRenderWrapper>
        <ConditionalRenderWrapper hiddenOnMobile={true} breakpoint='small'>
          <p>Displaying requests <strong>{current + 1} - {current + count} </strong> of <strong>{total} </strong> total </p>
        </ConditionalRenderWrapper>
      </div>
    </PaginationContainer>
  );
};

export default Pagination;
