import * as React from 'react';
import { VALID_COLOR } from '@amount/frontend-components';
import { useHistory, useParams } from 'react-router';
import { ApolloQueryResult } from 'apollo-client';
import { format } from 'date-fns';
import { useQuery } from '@apollo/react-hooks';
import ErrorHandler from '@avant/crm-frontend-utils/error';

import { ModuleRoutes } from '../../../../shared/routes';
import { StyledAlert } from '../../CommonComponents/Alert';
import HelpdeskBody from '../Common/HelpdeskBody';
import GetRequestDetailQuery, {
  GetRequestDetail,
  GetRequestDetailInput,
  SelectionOnRequestComments,
  SelectionOnRequestDetail,
  SelectionOnRequestFieldValues,
  SelectionOnValues1,
  SelectionOnValues2,
} from '../queries/getRequestDetail.graphql';
import { Spinner } from '../../Spinner';
import { ErrorLogger } from '../../../services/error';
import {
  HELPDESK_DATE_FORMAT,
  HELPDESK_TIME_FORMAT,
  JIRA_PARTNER_FIELD_ID,
  JIRA_PRIORITY_FIELD_ID,
  JIRA_SUMMARY_FIELD_ID
} from '../../../../shared/config/helpdesk';
import { MeContext } from '../../Context';
import UserStatusPill from '../Common/UserStatusPill';
import { SelectionOnMe } from '../../Context/me.graphql';
import { canAddComment, canViewTicket, hasAdminPermission, ispartnerHelpdeskAdmin } from '../__helpers__/permissioning';

import RequestComments from './RequestCommentList';
import RequestFields from './RequestFieldList';
import RequestField from './RequestField';
import RequestParticipants from './RequestParticipants';
import RequestCommentInput from './RequestCommentInput';
import RequestStatus from './RequestStatus';
import RequestSummary from './RequestSummary';
import RequestPriority from './RequestPriority';
import ChangeRequest from './ChangeRequest';
import {
  RequestContain,
  RequestBody,
  StyledBannerSection,
  StyledFormLabel,
  StyledDivider,
  RequestMeta,
  CommentWrapper,
  FieldFormLabel,
  FieldHeader
} from './common';

const SUPPORT_EMAIL: string = 'partnerportalsupport@amount.com';
const NONE_STRING: string = 'None';
const MATCH_ATTACHMENT_ID_REGEXP = /attachment\/(\d{0,9})/;

interface IProps {
  request: SelectionOnRequestDetail;
  comments: SelectionOnRequestComments;
  requestKey: string;
  requestId: string;
  context: SelectionOnMe;
  refetch (): Promise<ApolloQueryResult<GetRequestDetail>>;
}

/*
 * matches a jiraRest url and returns the id in group 1
 * from a section where id always follows /attachment as in : '/attachment/{id}'
 */
const stripAttachmentID: (url: string) => string | null = url => {
  const match: RegExpMatchArray | null = url.match(MATCH_ATTACHMENT_ID_REGEXP);

  return !!match ? match[1] : match;
};

const HelpdeskError: React.FC<{content: string}> = content => (
  <HelpdeskBody>
    {content} Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
  </HelpdeskBody>
);

/*
 * search all requestComments and return a list of all public attachment ids.
 */
const scrapeCommentAttachments: (comments: SelectionOnRequestComments) => string[] = comments =>
  comments.values
    .reduce((acc: Array<Array<SelectionOnValues1 | null>>, comment: SelectionOnValues2) => {
      if (!!comment.attachments) { acc.push(comment.attachments.values); }

      return acc;
    },      [])
    .flat()
    .map(attachment => !!attachment && !!attachment._links && stripAttachmentID(attachment._links.jiraRest) || '')
    .filter(a => a !== '');

// tslint:disable-next-line: max-func-body-length
const Requests: React.FC<IProps> = ({ request, comments, refetch, requestKey, requestId, context }) => {
  if (!request) {
    return <h2>An error occured. The request is unable to be viewed.</h2>;
  }

  const { requestFieldValues, id, createdDate, reporter, currentStatus, participants, serviceDeskId } = request;
  let summary: SelectionOnRequestFieldValues | undefined;
  let priority: string | undefined;
  const fieldsArray: SelectionOnRequestFieldValues[] = [];
  const requestStatus: string = request.currentStatus.status;
  const requestType: string = request.requestType.name;
  const attachmentIdList: string[] = scrapeCommentAttachments(comments);
  const isHelpdeskAdmin: boolean = hasAdminPermission(context) || ispartnerHelpdeskAdmin(context);

  requestFieldValues.forEach((r: SelectionOnRequestFieldValues) => {
    if (r.fieldId === JIRA_SUMMARY_FIELD_ID) {
      summary = r;
    } else if (r.fieldId === JIRA_PRIORITY_FIELD_ID) {
      priority = !!r.value && r.value.textData || '';
      fieldsArray.push(r);
    } else if (r.fieldId !== JIRA_PARTNER_FIELD_ID) {
      fieldsArray.push(r);
    }
  });

  const [statePriority, updateState] = React.useState(priority);

  if (!summary) {
    // A helpdesk request is linked to a Jira Support issue, which requires a 'summary' field
    ErrorLogger.captureMessage(`Request ${requestKey} does not have a summary`);

    return <HelpdeskError content='The request is malformed.' /> ;
  }

  if (!canViewTicket(requestType, context)) {
    return <HelpdeskError content='You do not have the Operational Support permission.' />;
  }

  return (
    <HelpdeskBody backPath={ModuleRoutes.helpdesk}>
      <RequestContain>
        <RequestBody>
          <RequestSummary
            scale='large'
            requestKey={requestKey}
            requestId={requestId}
            isHelpdeskAdmin={isHelpdeskAdmin}
            summaryText={summary.value.textData || ''}
          />
          {canAddComment(requestType, context) && (
            <CommentWrapper>
              <StyledBannerSection as='h2'>Latest Activity</StyledBannerSection>
              <StyledFormLabel as='h2' className='no-print'>
                <label htmlFor="comment">Add a comment</label>
                <RequestCommentInput requestKey={requestKey} refetch={refetch} serviceDeskId={request.serviceDeskId} />
              </StyledFormLabel>
              <StyledDivider />
              <RequestComments commentList={comments && comments.values} />
            </CommentWrapper>
          )}
          <StyledBannerSection as='h2'>Task Details</StyledBannerSection>
          <RequestFields fields={fieldsArray} issueId={request.id} attachmentIds={attachmentIdList} />
        </RequestBody>
        <RequestMeta>
          <UserStatusPill status={currentStatus.status} as='h2' />
          <RequestStatus
            issueId={request.id}
            requestType={requestType}
            requestStatus={requestStatus}
            requestKey={requestKey}
            context={context}
          />
          <RequestField label='Created'>
            <div>{format(new Date(createdDate.iso8601), HELPDESK_DATE_FORMAT)}</div>
            <div> at {format(new Date(createdDate.iso8601), HELPDESK_TIME_FORMAT)}</div>
          </RequestField>
          <RequestField label='Reporter'>
            {reporter && reporter.displayName || NONE_STRING}
          </RequestField>

          <RequestField>
            <FieldHeader>
              <FieldFormLabel htmlFor='comment-priority'>Priority</FieldFormLabel>
            </FieldHeader>
            {!priority ? NONE_STRING :
              <RequestPriority
                requestType={requestType}
                priority={statePriority}
                update={updateState}
                issueId={id}
                requestKey={requestKey}
              />}
          </RequestField>

          <RequestParticipants participants={participants.values} requestKey={requestKey} serviceDeskId={serviceDeskId} />
          <ChangeRequest requestKey={requestKey} fields={request.changeRequestFieldValues} refetch={refetch} />
        </RequestMeta>
      </RequestContain>
    </HelpdeskBody>
  );
};

const LiftedRequests: React.FC = () => {
  const history = useHistory();
  const { requestId } = useParams<{ requestId: string }>();
  const context = React.useContext(MeContext);

  const variables: GetRequestDetailInput = { key: requestId, partner: context.currentPartner || '' };
  const { data, loading, refetch, error } = useQuery<GetRequestDetail, GetRequestDetailInput>(GetRequestDetailQuery, { variables });

  if (error && !data) {
    ErrorHandler.notify('Unable to view this Request', 'Helpdesk', 'error');
    history.push(`${ModuleRoutes.helpdesk}`);
  }

  if (!!loading) {
    return <Spinner withMargin={true} />;
  }
  if (!data) {
    const alertStyle: VALID_COLOR = 'alert';

    return <StyledAlert alertStyle={alertStyle}>An error occured. The request is unable to be viewed.</StyledAlert>;
  }

  return (
    <Requests
      request={data.requestDetail}
      comments={data.requestComments}
      requestKey={requestId}
      requestId={data.requestDetail.id}
      refetch={refetch}
      context={context}
    />
  );
};

export default LiftedRequests;
