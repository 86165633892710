import { VALID_SVG_ICONS } from '@amount/frontend-components';

import {
  AmountHelpdeskRedirect,
  CIPRoute,
  ComplaintsRoute,
  CrmRoute,
  DisputesRoute,
  ModuleRoutes,
  PaymentGatewayRoute,
  PSAEsRoute,
  KnowledgeBaseRedirect,
  CspAmountRedirect,
  MerchantsRoute,
  ConfigurationsRoute,
} from '../routes';

import { FEATURE_FLAGS } from './featureFlag';
import {
  ALL_PERMISSIONS,
  AMOUNT_PERMISSION_KEY,
  AVANT_HELPDESK,
  AVANT_PERMISSION_KEY,
  BBVA_PERMISSION_KEY,
  EDIT_DOCUMENTS,
  EDIT_REPORTS,
  EDIT_USERS,
  HSBC_PERMISSION_KEY,
  PNC_PERMISSION_KEY,
  VIEW_CIP,
  VIEW_COMPLAINTS,
  VIEW_CSP,
  VIEW_DISPUTES,
  VIEW_DOCUMENTS,
  VIEW_HELPDESK,
  VIEW_PAYMENT_GATEWAY,
  VIEW_PSAE,
  VIEW_REPORTS,
  VIEW_USERS,
  VIEW_KNOWLEDGE_BASE,
  VIEW_MERCHANTS,
  VIEW_CONFIGURATIONS,
} from './permissions';
import { SwitchablePartner } from './partners';

/*
 * Changing this list? Be aware that the order here determines
 * the order the modules appear in on the homepage.
 */
export const MODULES_LIST = [
  'reports',
  'documents',
  'crm',
  'crmAmount',
  'helpdesk',
  'helpdeskAmount',
  'jitbitHelpdesk',
  'complaints',
  'psaes',
  'psaesRedirect',
  'userManagement',
  'disputes',
  'cip',
  'paymentGateway',
  'knowledgeBase',
  'merchants',
  'configurations',
] as const;

export type VALID_MODULES = typeof MODULES_LIST[number];

const READABLE_MODULE_NAME_MAP: { [key in VALID_MODULES]: string } = {
  documents: 'Documents',
  cip: 'Customers',
  crm: 'CSP',
  crmAmount: 'CSP',
  complaints: 'Complaints',
  disputes: 'Disputes',
  helpdesk: 'Helpdesk',
  helpdeskAmount: 'Helpdesk',
  jitbitHelpdesk: 'Avant IT',
  merchants: 'Merchant Portal',
  reports: 'Reports',
  paymentGateway: 'Payment Gateway',
  psaes: 'PSAEs',
  psaesRedirect: 'PSAEs',
  userManagement: 'User Admin',
  knowledgeBase: 'Knowledge Base',
  configurations: 'Configurations',
};

type MODULE_VIEW_PERMISSIONS =
  | typeof VIEW_DOCUMENTS
  | typeof VIEW_REPORTS
  | typeof VIEW_CIP
  | typeof VIEW_CSP
  | typeof VIEW_DISPUTES
  | typeof VIEW_PAYMENT_GATEWAY
  | typeof VIEW_HELPDESK
  | typeof AVANT_HELPDESK
  | typeof VIEW_PSAE
  | typeof VIEW_COMPLAINTS
  | typeof VIEW_USERS
  | typeof VIEW_KNOWLEDGE_BASE
  | typeof VIEW_MERCHANTS
  | typeof VIEW_CONFIGURATIONS;

export const VIEW_PERMISSION_TO_MODULE_MAP: { [key in MODULE_VIEW_PERMISSIONS]: string } = {
  [VIEW_CIP]: READABLE_MODULE_NAME_MAP.cip,
  [VIEW_CSP]: READABLE_MODULE_NAME_MAP.crm,
  [VIEW_COMPLAINTS]: READABLE_MODULE_NAME_MAP.complaints,
  [VIEW_DISPUTES]: READABLE_MODULE_NAME_MAP.disputes,
  [VIEW_HELPDESK]: READABLE_MODULE_NAME_MAP.helpdesk,
  [VIEW_REPORTS]: READABLE_MODULE_NAME_MAP.reports,
  [VIEW_DOCUMENTS]: READABLE_MODULE_NAME_MAP.documents,
  [VIEW_PAYMENT_GATEWAY]: READABLE_MODULE_NAME_MAP.paymentGateway,
  [VIEW_PSAE]: READABLE_MODULE_NAME_MAP.psaes,
  [VIEW_USERS]: READABLE_MODULE_NAME_MAP.userManagement,
  [AVANT_HELPDESK]: READABLE_MODULE_NAME_MAP.jitbitHelpdesk,
  [VIEW_KNOWLEDGE_BASE]: READABLE_MODULE_NAME_MAP.knowledgeBase,
  [VIEW_MERCHANTS]: READABLE_MODULE_NAME_MAP.merchants,
  [VIEW_CONFIGURATIONS]: READABLE_MODULE_NAME_MAP.configurations,
};

export const EDIT_PERMISSION_TO_MODULE_MAP: { [key in ALL_PERMISSIONS]?: string } = {
  [EDIT_DOCUMENTS]: READABLE_MODULE_NAME_MAP.documents,
  [EDIT_REPORTS]: READABLE_MODULE_NAME_MAP.reports,
  [EDIT_USERS]: READABLE_MODULE_NAME_MAP.userManagement
};

interface IModule {
  route: string;
  name: string;
  label: string;
  permissions: MODULE_VIEW_PERMISSIONS[];
  partners?: SwitchablePartner[];
  excludedPartners?: SwitchablePartner[];
  icon: VALID_SVG_ICONS;
  enabled: boolean;
  forceLink?: boolean;
  newTab?: boolean;
}

export const ModuleRouteMap: { [ x in VALID_MODULES ]: IModule } = {
  complaints: {
    route: ComplaintsRoute,
    name: READABLE_MODULE_NAME_MAP.complaints,
    label: 'View, manage and download customer complaints and issues',
    icon: 'complaints',
    permissions: [VIEW_COMPLAINTS],
    forceLink: true,
    newTab: true,
    enabled: true,
  },
  cip: {
    route: CIPRoute,
    name: READABLE_MODULE_NAME_MAP.cip,
    label: 'View customers, loans, and applications',
    icon: 'crm',
    permissions: [VIEW_CIP],
    forceLink: true,
    enabled: FEATURE_FLAGS.customersEnabled,
  },
  crm: {
    route: CrmRoute,
    name: READABLE_MODULE_NAME_MAP.crm,
    label: 'View customer communications, loan documents, and data',
    icon: 'crm',
    permissions: [VIEW_CSP],
    excludedPartners: [AMOUNT_PERMISSION_KEY, AVANT_PERMISSION_KEY],
    forceLink: true,
    enabled: true
  },
  crmAmount: {
    route: CspAmountRedirect,
    name: READABLE_MODULE_NAME_MAP.crm,
    label: 'View customer communications, loan documents, and data',
    icon: 'crm',
    permissions: [VIEW_CSP],
    partners: [AMOUNT_PERMISSION_KEY, AVANT_PERMISSION_KEY],
    forceLink: true,
    enabled: true
  },
  disputes: {
    route: DisputesRoute,
    name: READABLE_MODULE_NAME_MAP.disputes,
    label: 'View, resolve, and manage customer disputes',
    icon: 'disputes',
    forceLink: true,
    permissions: [VIEW_DISPUTES],
    enabled: FEATURE_FLAGS.disputesEnabled,
  },
  documents: {
    route: ModuleRoutes.policies,
    name: READABLE_MODULE_NAME_MAP.documents,
    label: 'View documents, policies, and agreements related to your partnership',
    icon: 'documents',
    permissions: [VIEW_DOCUMENTS],
    enabled: true,
  },
  helpdesk: {
    route: ModuleRoutes.helpdesk,
    name: READABLE_MODULE_NAME_MAP.helpdesk,
    label: 'Request services and report issues',
    icon: 'helpdesk',
    permissions: [VIEW_HELPDESK],
    excludedPartners: [AMOUNT_PERMISSION_KEY],
    enabled: true,
  },
  helpdeskAmount: {
    route: AmountHelpdeskRedirect,
    name: READABLE_MODULE_NAME_MAP.helpdesk,
    label: 'Request services and report issues',
    icon: 'helpdesk',
    permissions: [VIEW_HELPDESK],
    partners: [AMOUNT_PERMISSION_KEY],
    forceLink: true,
    enabled: true,
  },
  jitbitHelpdesk: {
    route: ModuleRoutes.jitbitHelpdesk,
    name: READABLE_MODULE_NAME_MAP.jitbitHelpdesk,
    label: 'Request services and report issues for Avant',
    icon: 'helpdesk',
    permissions: [AVANT_HELPDESK],
    partners: [AVANT_PERMISSION_KEY],
    enabled: true,
  },
  reports: {
    route: ModuleRoutes.reports,
    name: READABLE_MODULE_NAME_MAP.reports,
    label: 'View reports related to your partnership',
    icon: 'reports',
    permissions: [VIEW_REPORTS],
    enabled: true,
  },
  merchants: {
    route: MerchantsRoute,
    name: READABLE_MODULE_NAME_MAP.merchants,
    label: 'View order information',
    icon: 'shopping-bag',
    permissions: [VIEW_MERCHANTS],
    forceLink: true,
    enabled: true,
  },
  configurations: {
    route: ConfigurationsRoute,
    name: READABLE_MODULE_NAME_MAP.configurations,
    label: 'Manage policy configurations and deployments',
    icon: 'workflow',
    permissions: [VIEW_CONFIGURATIONS],
    forceLink: true,
    enabled: FEATURE_FLAGS.configurationsEnabled,
  },
  paymentGateway: {
    route: PaymentGatewayRoute,
    name: READABLE_MODULE_NAME_MAP.paymentGateway,
    label: 'Review and facilitate payment processing',
    icon: 'payment-gateway',
    forceLink: true,
    permissions: [VIEW_PAYMENT_GATEWAY],
    enabled: FEATURE_FLAGS.paymentGatewayEnabled,
  },
  psaes: {
    route: ModuleRoutes.psaes,
    name: READABLE_MODULE_NAME_MAP.psaes,
    label: 'Manage Potentially Suspicious Activity Escalations',
    icon: 'psaes',
    permissions: [VIEW_PSAE],
    /*
     * This should be removed when we transition
     * to a fully standalone psae application
     */
    excludedPartners: [HSBC_PERMISSION_KEY, PNC_PERMISSION_KEY, BBVA_PERMISSION_KEY],
    enabled: true,
  },
  psaesRedirect: {
    route: PSAEsRoute,
    name: READABLE_MODULE_NAME_MAP.psaesRedirect,
    label: 'Manage Potentially Suspicious Activity Escalations',
    icon: 'psaes',
    permissions: [VIEW_PSAE],
    partners: [HSBC_PERMISSION_KEY, PNC_PERMISSION_KEY, BBVA_PERMISSION_KEY],
    forceLink: true,
    newTab: true,
    enabled: true,
  },
  userManagement: {
    route: ModuleRoutes.userManagement,
    name: READABLE_MODULE_NAME_MAP.userManagement,
    label: 'Manage employee roles and permissions',
    icon: 'onboarding',
    permissions: [VIEW_USERS],
    enabled: true,
  },
  knowledgeBase: {
    route: KnowledgeBaseRedirect,
    name: READABLE_MODULE_NAME_MAP.knowledgeBase,
    label: 'View customer service knowledge base documentation.',
    icon: 'knowledge-base',
    permissions: [VIEW_KNOWLEDGE_BASE],
    partners: [AVANT_PERMISSION_KEY],
    enabled: true,
    forceLink: true,
    newTab: true,
  }
};
