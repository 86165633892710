export const ACTIVE_STATUS = 'active';
export const INACTIVE_STATUS = 'inactive';
export const PENDING_STATUS = 'pending_user_action';
export const PROCESSING_STATUS = 'processing';
export const RECOVERY_STATUS = 'password_reset';
export const LOCKED_STATUS = 'locked_out';

export type VALID_STATUS =
  typeof ACTIVE_STATUS |
  typeof INACTIVE_STATUS |
  typeof PENDING_STATUS |
  typeof PROCESSING_STATUS |
  typeof RECOVERY_STATUS |
  typeof LOCKED_STATUS;

export const STATUS_LIST_READABLE_MAP: { [key in VALID_STATUS]: string } = {
  [ACTIVE_STATUS]: 'Active',
  [INACTIVE_STATUS]: 'Inactive',
  [PENDING_STATUS]: 'Pending',
  [PROCESSING_STATUS]: 'Processing',
  [RECOVERY_STATUS]: 'Password Reset',
  [LOCKED_STATUS]: 'Locked Out'
};

export const VALID_FILTER_STATUSES: VALID_STATUS[] = [
  ACTIVE_STATUS,
  INACTIVE_STATUS,
  PENDING_STATUS,
  RECOVERY_STATUS,
  LOCKED_STATUS
];

export enum VALID_POINTER_KEYS {
  LOGIN = 'login',
  EMAIL = 'email',
  GENERAL = 'general',
  ALREADY_EXISTS = 'alreadyExists'
}

export const ALREADY_EXISTS_TEXT: string = 'Email or Login already exists';

// this is only used on the frontend and isn't a valid okta status
export const DISABLED_STATUS = 'Removed';
export type MODIFIED_VALID_STATUS = VALID_STATUS | typeof DISABLED_STATUS;
