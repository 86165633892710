import * as React from 'react';
import {
  Button,
  FieldSet,
  FormError,
  FormGroup,
  FormLabel,
  Headline,
  Input,
  media,
  Pill,
  RadioCard,
} from '@amount/frontend-components';
import styled from 'styled-components';
import Events from '@avant/crm-frontend-utils/events';
import { useHistory } from 'react-router';
import camelCase from 'lodash-es/camelCase';

import { ModuleRoutes } from '../../../../shared/routes';
import { PARTNER_CHANGED_EVENT } from '../../../../shared/config/constants';
import { Organizations, PARTNER_SSO_PROVIDER_MAP, SWITCHABLE_PARTNER_LIST, SwitchablePartner } from '../../../../shared/config/partners';
import { GetRoles as getRoles, SelectionOnRoles } from '../queries/getRoles.graphql';
import { SelectionOnRoles as SelectionOnUserRoles } from '../queries/getUser.graphql';
import { CardLabel, CardText } from '../../Card';
import Modal, { IModalProps } from '../../Modal';
import { ButtonActionRow, CancelButton } from '../../Modal/common';
import { LogoCardContain, SwitcherLogo } from '../../Header/Presentational/PartnerSwitcher';
import { getGroundedOrganization } from '../../../../shared/config/user';

import {
  ACTIVE_STATUS,
  INACTIVE_STATUS,
  LOCKED_STATUS,
  PENDING_STATUS,
  PROCESSING_STATUS,
  RECOVERY_STATUS,
  STATUS_LIST_READABLE_MAP,
  VALID_POINTER_KEYS,
  VALID_STATUS
} from './constants';

export enum USER_MANAGEMENT_ROUTES {
  editInfo = '/edit-info',
  editPermission = '/edit-role',
  createUser = '/create-user'
}

enum EMAIL_ERROR_CODES {
  ERRU005 = 'ERRU005',
  ERRU007 = 'ERRU007',
  ERRU009 = 'ERRU009'
}

enum LOGIN_ERROR_CODES {
  ERRU006 = 'ERRU006',
  ERRU008 = 'ERRU008',
  ERRU010 = 'ERRU010'
}

export interface IUserManagementState {
  selectedUser: string | undefined;
  roles: string[];
  status: string[];
  search: string | undefined;
}
export interface IUserManagement extends IUserManagementState {
  handleInputChange: React.FormEventHandler<HTMLInputElement>;
  handleStatusChange: React.FormEventHandler<HTMLInputElement>;
  handleSearchChange: React.FormEventHandler<HTMLInputElement>;
  handleClearFilter: React.FormEventHandler<HTMLButtonElement>;
  handleSearchClear (): void;
  selectUser (id: string): void;
}

export const PendingStatusPill: JSX.Element = <Pill margin='0' pillColor='warning'>{STATUS_LIST_READABLE_MAP[PENDING_STATUS]}</Pill>;

const StatusPillMap: { [status in VALID_STATUS]: JSX.Element } = {
  [ACTIVE_STATUS]: <Pill margin='0' pillColor='primary'>{STATUS_LIST_READABLE_MAP[ACTIVE_STATUS]}</Pill>,
  [INACTIVE_STATUS]: <Pill margin='0' pillColor='inactive'>{STATUS_LIST_READABLE_MAP[INACTIVE_STATUS]}</Pill>,
  [PENDING_STATUS]: PendingStatusPill,
  [PROCESSING_STATUS]: <Pill margin='0' pillColor='warning'>{STATUS_LIST_READABLE_MAP[PROCESSING_STATUS]}</Pill>,
  [RECOVERY_STATUS]: <Pill margin='0' pillColor='warning'>{STATUS_LIST_READABLE_MAP[RECOVERY_STATUS]}</Pill>,
  [LOCKED_STATUS]: <Pill margin='0' pillColor='alert'>{STATUS_LIST_READABLE_MAP[LOCKED_STATUS]}</Pill>,
};

export const userStatusPill: (status: string) => JSX.Element = status => StatusPillMap[status] || PendingStatusPill;

export const UserActions = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardContain = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  grid-gap: 10px;
  width: 100%;
  margin-top: 1em;

  ${media.small`
    /* grid-template-columns: repeat(2, 50%); */
    grid-gap: 16px;
    /* width: calc( 100% - 16px ); */
  `}

  /* ${media.medium`
    grid-template-columns: repeat(3, calc( 100%/3 ));
    width: calc( 100% - 40px );
  `} */
`;

export const FieldContain = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  grid-gap: 26px;
  width: 100%;
  margin-top: 1.5em;

  ${media.small`
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 35px 20px;
  `}
`;

export const FormFieldsSection = styled.div`
  margin: 0 0 2em;
`;

const RadioCardWrap = styled.span`
  position: relative;
`;

export const getUsername: (organization: string) => string = org => `${PARTNER_SSO_PROVIDER_MAP[org] || ''} Username`;

export const isBlank = (input: string | undefined) => !input || /^\s*$/.test(input);

export const getFormInputField = (code: string) => {
  if (code in EMAIL_ERROR_CODES) { return 'email'; }
  if (code in LOGIN_ERROR_CODES) { return 'login'; }
  if (code === '400') { return 'alreadyExists'; }

  return 'general';
};

export type UserRoles = { [key in SwitchablePartner]?: SelectionOnUserRoles[] };
export const EMPTY_CELL: string = '--';
export const oktaRolesByOrg: (roles: SelectionOnUserRoles[], organization: string) => UserRoles = (groups, organization) => {
  if (!groups) { return { [organization]: [EMPTY_CELL] }; }

  const roles: UserRoles = groups.reduce<UserRoles>(
    (acc: UserRoles, role: SelectionOnUserRoles) => {
      if (!acc[role.partner]) { acc[role.partner] = []; }
      acc[role.partner].push(role);

      return acc;
    },
    {}
  );

  // if you don't have any roles for your org, we should display empty in the table.
  if (!roles[organization]) { roles[organization] = [EMPTY_CELL]; }

  return roles;
};

export const redirectOnPartnerSwitch = () => {
  const history = useHistory();
  const redirectToRoot: () => void = () => {
    history.push(ModuleRoutes.userManagement);
  };

  React.useEffect(() => {
    Events.subscribe(PARTNER_CHANGED_EVENT, redirectToRoot);

    return () => {
      Events.unsubscribe(PARTNER_CHANGED_EVENT, redirectToRoot);
    };
  });

  return null;
};

interface IUserInfoFieldsProps {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  username: string | undefined;
  organization: string;
  handleInputChange: React.FormEventHandler<HTMLInputElement>;
  errorFields: Map<string, string>;
}

export const UserInfoFields: React.FC<IUserInfoFieldsProps> = ({
  firstName,
  lastName,
  email,
  username,
  // organization,
  handleInputChange,
  errorFields
}) => (
    <FormFieldsSection>
      <FieldSet>
        <FieldContain>
          <FormGroup>
            <FormLabel>First Name</FormLabel>
            <Input
              data-lpignore={true}
              autoComplete='off'
              type='text'
              name='firstName'
              value={firstName}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Last Name</FormLabel>
            <Input
              data-lpignore={true}
              autoComplete='off'
              type='text'
              name='lastName'
              value={lastName}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Email</FormLabel>
            <Input
              data-lpignore={true}
              autoComplete='off'
              type='email'
              name='email'
              value={email}
              onChange={handleInputChange}
              alert={errorFields.has(VALID_POINTER_KEYS.EMAIL)}
            />
            {(errorFields.has(VALID_POINTER_KEYS.EMAIL)) &&
              <FormError>{errorFields.get(VALID_POINTER_KEYS.EMAIL)}</FormError>
            }
          </FormGroup>
          <FormGroup>
            <FormLabel>Username</FormLabel>
            <Input
              data-lpignore={true}
              autoComplete='off'
              type='email'
              name='username'
              value={username}
              onChange={handleInputChange}
              alert={errorFields.has(VALID_POINTER_KEYS.LOGIN)}
            />
            {(errorFields.has(VALID_POINTER_KEYS.LOGIN)) &&
              <FormError> {errorFields.get(VALID_POINTER_KEYS.LOGIN)} </FormError>
            }
          </FormGroup>
        </FieldContain>
      </FieldSet>
    </FormFieldsSection>
  );

export interface IUserRolesFieldsProps {
  partner: string;
  data: getRoles;
  selectedRoleId: string | undefined;
  selectRole: React.FormEventHandler<HTMLInputElement>;
}

export const UserRoleFields: React.FC<IUserRolesFieldsProps> = ({ data, selectedRoleId, selectRole, partner }) => {
  // The filter here is to not show roles we have for other organizations. This only matters for Amount.
  const roles: SelectionOnRoles[] = data.roles.filter(role => role.partner === partner);

  return (
    <CardContain>
      {!roles.length && (
        <span>There are no available roles.</span>
      )}
      {roles.map(({ name, description, id: roleId }) => (
        <RadioCardWrap key={roleId}>
          <RadioCard
            data-role={roleId}
            data-organization={partner}
            data-event={`changeRole${roleId}`}
            id={roleId}
            checked={selectedRoleId === roleId}
            onChange={selectRole}
          >
            <div>
              <CardLabel>{name}</CardLabel>
              <CardText>{description}</CardText>
            </div>
          </RadioCard>
        </RadioCardWrap>
      ))}
    </CardContain>
  );
};

interface IPartnerCards {
  partners: SwitchablePartner[];
  selectPartner: React.MouseEventHandler<HTMLInputElement>;
}

interface IUserPartnersModalProps extends IModalProps, IPartnerCards {
  submit: React.MouseEventHandler<HTMLButtonElement>;
  currentPartner: string | null;
}

export const UserPartnersModal: React.FC<IUserPartnersModalProps> = props => (
  <Modal
    data-modal={true}
    close={props.close}
    show={props.show}
  >
    <Headline scale='medium'>Select all additional partners this user will need access to.</Headline>
    <FieldSet>
      <LogoCardContain>
        {SWITCHABLE_PARTNER_LIST.filter(p => p !== getGroundedOrganization(props.currentPartner as Organizations)).map(p =>
          <RadioCardWrap key={p}>
            <RadioCard
              id={p}
              data-partner={p}
              data-event={camelCase(`partnerRoleAdd ${p}`)}
              checked={props.partners.reduce<boolean>((acc, partner) => (partner === p ? true : acc), false)}
              readOnly={true}
              onClick={props.selectPartner}
            >
              <SwitcherLogo partner={p} />
            </RadioCard>
          </RadioCardWrap>
        )}
      </LogoCardContain>
    </FieldSet>
    <ButtonActionRow>
      <Button
        inline={true}
        onClick={props.submit}
        data-event='submitAddPartners'
      >
        Next
      </Button>
      <CancelButton
        onClick={props.close}
      >
        Cancel
      </CancelButton>
    </ButtonActionRow>
  </Modal>
);
