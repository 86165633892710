import * as React from 'react';
import {
  Checkbox,
  DropdownButton,
  Label,
  SVGIcon,
} from '@amount/frontend-components';
import styled from 'styled-components';

import { Spinner } from '../../Spinner';
import { GetRoles as getRoles, SelectionOnRoles } from '../queries/getRoles.graphql';
import { ButtonLabel } from '../../CommonComponents/ButtonLabel';
import { READABLE_PARTNER_NAME_MAP, SwitchablePartner } from '../../../../shared/config/partners';

import { STATUS_LIST_READABLE_MAP, VALID_FILTER_STATUSES } from './constants';
import { IUserManagement } from './common';
import SearchButton from './../../CommonComponents/SearchButton';

type FilterRoles = { [key in SwitchablePartner]?: SelectionOnRoles[] };
const RolesByOrg: (roles: SelectionOnRoles[]) => FilterRoles = roles => roles.reduce<FilterRoles>(
  (acc: FilterRoles, role: SelectionOnRoles) => {
    if (!acc[role.partner]) { acc[role.partner] = []; }
    acc[role.partner].push(role);

    return acc;
  },
  {}
);

const FilterRow = styled.div`
  display: flex;
`;

const FilterButton = styled(DropdownButton)`
  margin-right: 0.5em;
`;

const FilterLabel = styled.p`
  white-space: nowrap;
  color: ${({ theme }) => theme.colorSlate70};
`;

const FilterCategory = styled(Label)`
  display: block;
  margin: 1.5em 0 1em;
`;

const ClearButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5em;
`;

const ButtonIcon = styled(SVGIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export type ILiftedProps = Omit<IUserManagement, 'selectUser' | 'selectedUser'>;
interface IUserFilters extends ILiftedProps {
  data?: getRoles;
  loading: boolean;
}

interface IClearFilterProps extends Pick<ILiftedProps, 'handleClearFilter'> {
  filter: string;
}
const ClearFilter: React.FC<IClearFilterProps> = ({ handleClearFilter, filter }) => (
  <ClearButton onClick={handleClearFilter} data-filter={filter}>
    <ButtonIcon icon='close' />
    <ButtonLabel>Clear filter</ButtonLabel>
  </ClearButton>
);

type RoleFilterProps = Omit<IUserFilters, 'search' | 'status' | 'handleStatusChange' | 'handleSearchChange' | 'handleSearchClear'>;
const RoleFilter: React.FC<RoleFilterProps> = ({ data, loading, roles, handleInputChange, handleClearFilter }) => {
  const groupedRoles: FilterRoles | undefined = data && data.roles && RolesByOrg(data.roles);

  return (
    <FilterButton
      minWidth='30em'
      maxHeight='55vh'
      active={!!roles.length}
      buttonContent={
        <ButtonLabel>
          {!!roles.length ?
            `${roles.length} Role${roles.length > 1 ? 's' : ''}` :
            'Role'
          }
        </ButtonLabel>
      }
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <FilterLabel>Filter by roles</FilterLabel>
          {(!!data && groupedRoles) ? (
            <>
              {Object.keys(groupedRoles).sort().map((org, _, n) => groupedRoles[org] && (
                <React.Fragment key={org}>
                  {n.length > 1 && <FilterCategory>{READABLE_PARTNER_NAME_MAP[org]} Roles</FilterCategory>}
                  {(groupedRoles[org as SwitchablePartner] || []).map(role => (
                    <Checkbox
                      checked={roles.some(r => role.id === r)}
                      key={`${org}${role.id}`}
                      name={role.id}
                      data-filter='roles'
                      onChange={handleInputChange}
                    >
                      {role.name}
                    </Checkbox>
                  ))}
                </React.Fragment>
              ))}
              <ClearFilter handleClearFilter={handleClearFilter} filter='roles' />
            </>
          ) : (
            <span>Failed to retrieve roles</span>
          )}
        </>
      )}
    </FilterButton>
  );
};

type IStatusFilterProps = Pick<ILiftedProps, 'handleClearFilter' | 'handleInputChange' | 'status'>;
const StatusFilter: React.FC<IStatusFilterProps> = ({ status, handleClearFilter, handleInputChange }) => (
  <FilterButton
    active={!!status.length}
    buttonContent={
      <ButtonLabel>
        {!!status.length ?
          `${status.length} Status${status.length > 1 ? 'es' : ''}` :
          'Status'
        }
      </ButtonLabel>
    }
  >
    <FilterLabel>Filter by status</FilterLabel>
    {VALID_FILTER_STATUSES.map(label => (
      <Checkbox
        checked={status.some(s => label === s)}
        key={label}
        name={label}
        data-filter='status'
        onChange={handleInputChange}
      >
        {STATUS_LIST_READABLE_MAP[label]}
      </Checkbox>
    ))}
    <ClearFilter handleClearFilter={handleClearFilter} filter='status' />
  </FilterButton>
);

export const UserFilters: React.FC<IUserFilters> = ({
  status,
  search,
  handleInputChange,
  handleStatusChange,
  handleSearchChange,
  handleSearchClear,
  handleClearFilter,
  ...rest
}) => (
  <FilterRow>
    <RoleFilter {...rest} handleInputChange={handleInputChange} handleClearFilter={handleClearFilter} />
    <StatusFilter status={status} handleInputChange={handleStatusChange} handleClearFilter={handleClearFilter} />
    <SearchButton initialValue={search} inputChangeHandler={handleSearchChange} closeHandler={handleSearchClear} />
  </FilterRow>
);
