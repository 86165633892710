import {
  ANALYST_REVIEW_STEP,
  COMPLETED_STEP,
  CREATED_STEP,
  DRAFTING_STEP,
  FRAUD_REVIEW_STEP,
  PARTNER_REJECTED_STEP,
  PARTNER_REVIEW_STEP,
  REJECTED_STEP,
  SUBMITTED_STEP,
} from '../transitions/sar/steps';

export const AMOUNT_PERMISSION_KEY = 'amount';
export const AVANT_PERMISSION_KEY = 'avant';
export const BPO_PERMISSION_KEY = 'bpo';
export const DEMO_BANK_PERMISSION_KEY = 'demo';
export const ELOAN_PERMISSION_KEY = 'eloan';
export const HSBC_PERMISSION_KEY = 'hsbc';
export const PNC_PERMISSION_KEY = 'pnc';
export const POLLEN_STREET_PERMISSION_KEY = 'psc';
export const RADIUS_PERMISSION_KEY = 'radius';
export const REGIONS_PERMISSION_KEY = 'regions';
export const TD_PERMISSION_KEY = 'td';
export const WEBBANK_PERMISSION_KEY = 'webbank';
export const ICON_PERMISSION_KEY = 'icon';
export const BBVA_PERMISSION_KEY = 'bbva';
export const CAPITAL_ONE_PERMISSION_KEY = 'capital_one';
export const BARCLAYS_PERMISSION_KEY = 'barclays';
export const JOMASHOP_PERMISSION_KEY = 'jomashop';
export const TELEPERFORMANCE_PERMISSION_KEY = 'teleperformance';
export const GUARANTEED_RATE_PERMISSION_KEY = 'guaranteed_rate';
export const JENIUS_PERMISSION_KEY = 'jenius_bank';


export const VIEW_CSP = 'csp:view';

export const VIEW_CIP = 'customers:view';

export const VIEW_COMPLAINTS = 'complaints:view';

export const VIEW_DISPUTES = 'disputes:view';

export const VIEW_PAYMENT_GATEWAY = 'payment_gateway:view';

export const VIEW_MERCHANTS = 'merchants:view';

// TODO: Use correct permission once PE01-4606 is merged/deployed
export const VIEW_CONFIGURATIONS = 'helpdesk:view';
// export const VIEW_CONFIGURATIONS = 'configurations:view'

export const VIEW_HELPDESK = 'helpdesk:view';
export const VIEW_ADMIN_HELPDESK = 'helpdesk:view_tickets';
export const VIEW_OPS_TICKETS = 'helpdesk:view_ops_tickets';
export const VIEW_OPS_TICKETS_RO = 'helpdesk:view_ops_tickets_ro';
export const AVANT_HELPDESK = 'helpdesk:avant';
export const SUBMIT_CHANGE_REQUEST_HELPDESK = 'helpdesk:change_request';
export const SUBMIT_BUG_REPORT_HELPDESK = 'helpdesk:bug_report';
export const SUBMIT_ACCESS_REQUEST_HELPDESK = 'helpdesk:access_request';
export const SUBMIT_GENERAL_INQUIRY_HELPDESK = 'helpdesk:general_inquiry';
export const SUBMIT_SERVICE_INCIDENT_HELPDESK = 'helpdesk:service_incident';
export const SUBMIT_DATA_SET_SPLIT_HELPDESK = 'helpdesk:datasetsplit';
export const SUBMIT_DIRECT_MAIL_HELPDESK = 'helpdesk:directmail';
export const SUBMIT_ESCALATION_HELPDESK = 'helpdesk:escalations';
export const VIEW_BPO_SUPPORT_HELPDESK = 'helpdesk:view_bpo_tickets';
export const EDIT_BPO_SUPPORT_HELPDESK = 'helpdesk:edit_bpo_tickets';
export const UPDATE_BPO_SUPPORT_HELPDESK = 'helpdesk:update_bpo_tickets';

export const VIEW_REPORTS = 'reports:view';
export const VIEW_SERVICING_ORG_REPORTS = 'reports:servicing_org:view';
export const EDIT_SERVICING_ORG_REPORTS = 'reports:servicing_org:edit';
export const VIEW_SENSITIVE_SERVICING_ORG_REPORTS = 'reports:servicing_org:sensitive:view';
export const VIEW_LOAN_REPORTS = 'reports:loan:view';
export const VIEW_SENSITIVE_LOAN_REPORTS = 'reports:loan:sensitive:view';
export const VIEW_CREDIT_CARD_REPORTS = 'reports:credit_card:view';
export const VIEW_SENSITIVE_CREDIT_CARD_REPORTS = 'reports:credit_card:sensitive:view';
export const VIEW_POS_REPORTS = 'reports:point_of_sale:view';
export const VIEW_SENSITIVE_POS_REPORTS = 'reports:point_of_sale:view:sensitive';
export const EDIT_REPORTS = 'reports:edit';
export const EDIT_LOAN_REPORTS = 'reports:loan:edit';
export const EDIT_POS_REPORTS = 'reports:point_of_sale:edit';

export const VIEW_DOCUMENTS = 'documents:view';
export const VIEW_SERVICING_ORG_DOCUMENTS = 'documents:servicing_org:view';
export const VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS = 'documents:servicing_org:sensitive:view';
export const VIEW_LOAN_DOCUMENTS = 'documents:loan:view';
export const VIEW_SENSITIVE_LOAN_DOCUMENTS = 'documents:loan:sensitive:view';
export const VIEW_CREDIT_CARD_DOCUMENTS = 'documents:credit_card:view';
export const VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS = 'documents:credit_card:sensitive:view';
export const VIEW_POS_DOCUMENTS = 'documents:point_of_sale:view';
export const VIEW_SENSITIVE_POS_DOCUMENTS = 'documents:point_of_sale:view:sensitive';
export const EDIT_DOCUMENTS = 'documents:edit';
export const EDIT_LOAN_DOCUMENTS = 'documents:loan:edit';
export const EDIT_POS_DOCUMENTS = 'documents:point_of_sale:edit';

export const VIEW_PSAE = 'psae:view';
export const FINAL_REVIEW_SEND_BACK_PSAE = 'psae:final_review_send_back';
export const PARTNER_APPROVED_GENERATE_JOB =
  'psae:partner_approved_generate_job';
export const AML_PSAE = 'psae:aml';
export const FRAUD_PSAE = 'psae:fraud';

export const OPEN_STEP_PSAE = 'psae:open';
export const DRAFT_STEP_PSAE = 'psae:draft';
export const PEER_REVIEW_STEP_PSAE = 'psae:peer_review';
export const FINAL_REVIEW_STEP_PSAE = 'psae:final_review';
export const AMOUNT_REJECTED_STEP_PSAE = 'psae:amount_rejected';
export const PARTNER_REJECTED_STEP_PSAE = 'psae:partner_rejected';
export const PARTNER_APPROVED_STEP_PSAE = 'psae:partner_approved';
export const SUBMIT_STEP_PSAE = 'psae:peer_review';
export const COMPLETE_STEP_PSAE = 'psae:partner_approved';

export const VIEW_KNOWLEDGE_BASE = 'knowledgebase:view';

export const VIEW_USERS = 'users:view';
export const EDIT_USERS = 'users:edit';

export const VIEW_IN_BRANCH_INDIVIDUAL = 'reports:loan:ind_reporting:view';
export const VIEW_IN_BRANCH_STORE = 'reports:loan:store_reporting:view';

// a permission that does not exist in okta. Can be used to temporarily satisfy a type as in FolderTypeMaps
// export const TEMP_PERMISSION = 'temp:permission';

export type SAR_STEPS =
  | typeof OPEN_STEP_PSAE
  | typeof DRAFT_STEP_PSAE
  | typeof PEER_REVIEW_STEP_PSAE
  | typeof FINAL_REVIEW_STEP_PSAE
  | typeof AMOUNT_REJECTED_STEP_PSAE
  | typeof PARTNER_REJECTED_STEP_PSAE
  | typeof PARTNER_APPROVED_STEP_PSAE;

export type HELPDESK_PERMISSIONS =
  | typeof VIEW_HELPDESK
  | typeof VIEW_ADMIN_HELPDESK
  | typeof AVANT_HELPDESK
  | typeof SUBMIT_CHANGE_REQUEST_HELPDESK
  | typeof SUBMIT_BUG_REPORT_HELPDESK
  | typeof SUBMIT_ACCESS_REQUEST_HELPDESK
  | typeof SUBMIT_GENERAL_INQUIRY_HELPDESK
  | typeof SUBMIT_DATA_SET_SPLIT_HELPDESK
  | typeof SUBMIT_DIRECT_MAIL_HELPDESK
  | typeof SUBMIT_ESCALATION_HELPDESK
  | typeof SUBMIT_SERVICE_INCIDENT_HELPDESK
  | typeof VIEW_OPS_TICKETS
  | typeof VIEW_OPS_TICKETS_RO
  | typeof VIEW_BPO_SUPPORT_HELPDESK
  | typeof EDIT_BPO_SUPPORT_HELPDESK
  | typeof UPDATE_BPO_SUPPORT_HELPDESK;

export type INBRANCH_PERMISSIONS =
  | typeof VIEW_IN_BRANCH_INDIVIDUAL
  | typeof VIEW_IN_BRANCH_STORE;

export type REPORTS_PERMISSIONS =
  | typeof VIEW_REPORTS
  | typeof EDIT_REPORTS
  | INBRANCH_PERMISSIONS
  | typeof VIEW_LOAN_REPORTS
  | typeof VIEW_POS_REPORTS
  | typeof VIEW_CREDIT_CARD_REPORTS
  | typeof VIEW_SERVICING_ORG_REPORTS
  | typeof EDIT_SERVICING_ORG_REPORTS
  | typeof VIEW_SENSITIVE_CREDIT_CARD_REPORTS
  | typeof VIEW_SENSITIVE_LOAN_REPORTS
  | typeof VIEW_SENSITIVE_POS_REPORTS
  | typeof VIEW_SENSITIVE_SERVICING_ORG_REPORTS
  | typeof EDIT_REPORTS
  | typeof EDIT_LOAN_REPORTS
  | typeof EDIT_POS_REPORTS
  | typeof INVALID_PERMISSION;

export type DOCUMENTS_PERMISSIONS =
  | typeof VIEW_DOCUMENTS
  | typeof VIEW_POS_DOCUMENTS
  | typeof VIEW_LOAN_DOCUMENTS
  | typeof VIEW_CREDIT_CARD_DOCUMENTS
  | typeof VIEW_SERVICING_ORG_DOCUMENTS
  | typeof VIEW_SENSITIVE_CREDIT_CARD_DOCUMENTS
  | typeof VIEW_SENSITIVE_LOAN_DOCUMENTS
  | typeof VIEW_SENSITIVE_POS_DOCUMENTS
  | typeof VIEW_SENSITIVE_SERVICING_ORG_DOCUMENTS
  | typeof EDIT_DOCUMENTS
  | typeof EDIT_LOAN_DOCUMENTS
  | typeof EDIT_POS_DOCUMENTS;

export type SAR_PERMISSIONS =
  | typeof VIEW_PSAE
  | typeof FINAL_REVIEW_SEND_BACK_PSAE
  | typeof PARTNER_APPROVED_GENERATE_JOB
  | typeof AML_PSAE
  | typeof FRAUD_PSAE
  | typeof SUBMIT_STEP_PSAE
  | typeof COMPLETE_STEP_PSAE
  | SAR_STEPS;

export type USER_MANAGEMENT_PERMISSIONS =
  | typeof VIEW_USERS
  | typeof EDIT_USERS;

export type ALL_PERMISSIONS =
  | typeof VIEW_CSP
  | typeof VIEW_COMPLAINTS
  | typeof VIEW_CIP
  | typeof VIEW_DISPUTES
  | typeof VIEW_PAYMENT_GATEWAY
  | typeof VIEW_KNOWLEDGE_BASE
  | HELPDESK_PERMISSIONS
  | REPORTS_PERMISSIONS
  | DOCUMENTS_PERMISSIONS
  | SAR_PERMISSIONS
  | USER_MANAGEMENT_PERMISSIONS;

export const SAR_TAB_PERMISSIONS: { [tab: string]: SAR_STEPS } = {
  [CREATED_STEP]: OPEN_STEP_PSAE,
  [DRAFTING_STEP]: DRAFT_STEP_PSAE,
  [FRAUD_REVIEW_STEP]: PEER_REVIEW_STEP_PSAE,
  [ANALYST_REVIEW_STEP]: FINAL_REVIEW_STEP_PSAE,
  [PARTNER_REVIEW_STEP]: PARTNER_APPROVED_STEP_PSAE,
  [SUBMITTED_STEP]: SUBMIT_STEP_PSAE,
  [COMPLETED_STEP]: COMPLETE_STEP_PSAE,
  [REJECTED_STEP]: AMOUNT_REJECTED_STEP_PSAE,
  [PARTNER_REJECTED_STEP]: PARTNER_REJECTED_STEP_PSAE,
};

type ViewFilePermissions = typeof VIEW_REPORTS | typeof VIEW_DOCUMENTS;
type EditFilePermissions =
  | typeof EDIT_REPORTS
  | typeof EDIT_DOCUMENTS
  | typeof EDIT_POS_REPORTS
  | typeof EDIT_LOAN_REPORTS
  | typeof EDIT_POS_DOCUMENTS
  | typeof EDIT_LOAN_DOCUMENTS
  | typeof EDIT_SERVICING_ORG_REPORTS
  | typeof INVALID_PERMISSION;

export const S3_TO_VIEW_FILE_PERMISSIONS: { [key: string]: ViewFilePermissions } = {
  __reports__: VIEW_REPORTS,
  __policies__: VIEW_DOCUMENTS,
};

export const STANDARD = 'Standard';
export const POINT_OF_SALE = 'Point of Sale';
export const PERSONAL_LOAN = 'Personal Loan';
export const SERVICING_ORG = 'Servicing Org';

export const INVALID_PERMISSION = 'invalid_permission';

export type FolderType = typeof STANDARD | typeof POINT_OF_SALE | typeof PERSONAL_LOAN | typeof SERVICING_ORG;
export type FolderTypeMap = { [key in FolderType ]: EditFilePermissions };

export const S3_TO_EDIT_FILE_PERMISSIONS: { [key: string]: FolderTypeMap } = {
  __reports__: {
    [STANDARD]: EDIT_REPORTS,
    [POINT_OF_SALE]: EDIT_POS_REPORTS,
    [PERSONAL_LOAN]: EDIT_LOAN_REPORTS,
    [SERVICING_ORG]: EDIT_SERVICING_ORG_REPORTS
  },
  __policies__: {
    [STANDARD]: EDIT_DOCUMENTS,
    [POINT_OF_SALE]: EDIT_POS_DOCUMENTS,
    [PERSONAL_LOAN]: EDIT_LOAN_DOCUMENTS,
    [SERVICING_ORG]: INVALID_PERMISSION
  },
};
