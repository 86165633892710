import * as React from 'react';
import { Mutation } from 'react-apollo';

import { ReadableToS3TypeMap } from '../../../shared/reports/constants';

import DeleteFileQuery, { DeleteFile, DeleteFileInput } from './queries/deleteFile.graphql';
import MoveFileQuery, { MoveFile, MoveFileInput } from './queries/moveFile.graphql';
import { FileDrawer, ILiftedFileDrawer } from './Presentational/FileDrawer';
import { DeleteFilePropsToVariables } from './Presentational/common';

const LiftedFileDrawer: React.FC<ILiftedFileDrawer> = props => (
  <Mutation<DeleteFile, DeleteFileInput>
    mutation={DeleteFileQuery}
    // tslint:disable-next-line: no-non-null-assertion
    {...DeleteFilePropsToVariables({ Key: props.Key, type: ReadableToS3TypeMap.get(props.root)! })}
  >
    {deleteFile => (
      <Mutation<MoveFile, MoveFileInput>
        mutation={MoveFileQuery}
      >
        {moveFile => (
          <FileDrawer
            {...props}
            deleteFile={deleteFile}
            renameFile={moveFile}
          />
        )}
      </Mutation>
    )}
  </Mutation>
);

export default LiftedFileDrawer;
