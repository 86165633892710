import * as React from 'react';
import {
  ConditionalRenderWrapper,
  media,
  SelectInput,
  SelectOption,
} from '@amount/frontend-components';
import styled from 'styled-components';

import { PARTNER_FACING_CATEGORIES } from '../../../../shared/config/helpdesk';
import { SelectionOnIssues } from '../queries/getOwnedRequests.graphql';
import { FILTER_NAMES, IActionBar } from '../constants';

import FilterButton from './FilterButton';
import { LiftedRequestTypeFilter } from './RequestTypeFilter';
import SearchButton from './../../CommonComponents/SearchButton';

const FilterRow = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 1.5em;
  padding: 1em 1em 0.75em;

  ${media.small`
    margin: 0;
    padding: 0;
  `}

  & > *:not(:last-child) {
    margin-right: .5em;
  }

  & > *:last-child {
    margin-left: auto;
    width: 13em;
  }
`;

const FocusIndicator = styled.div`
  padding: 2px;

  &:focus-within {
    border: 1px solid ${({ theme }) => theme.alert.primary.colorBackground};
    padding: 1px;
  }
`;

const POSSIBLE_PRIORITIES: string[] = ['Highest', 'High', 'Medium', 'Low', 'Lowest', 'None'];
const POSSIBLE_STATUSES: string[] = Object.values(PARTNER_FACING_CATEGORIES).map(({ category }) => category);
const POSSIBLE_PRODUCTS: string[] = ['Credit Card', 'In-Branch', 'Installment', 'Point of Sale', 'Refinance', 'Secured'];

interface IProps extends IActionBar {
  requestList: SelectionOnIssues[];
}

const Actions: React.FC<IProps> = props => {
  const { priority, status, product } = FILTER_NAMES;
  const changeHandlers = { onClear: props.handleClear, onChange: props.handleChange };

  return (
    <FilterRow>
      <SearchButton inputChangeHandler={props.handleSearchChange} />
      <ConditionalRenderWrapper hiddenOnMobile={true} breakpoint='small'>
        <FilterButton {...changeHandlers} value={props[status]} options={POSSIBLE_STATUSES} name={status} />
        <LiftedRequestTypeFilter {...props} />
        <FilterButton {...changeHandlers} value={props[product]} options={POSSIBLE_PRODUCTS} name={product} />
        <FilterButton {...changeHandlers} value={props[priority]} options={POSSIBLE_PRIORITIES} name={priority} />
      </ConditionalRenderWrapper>
      <FocusIndicator>
        <SelectInput defaultValue='updated' onChange={props.handleSelectChange} aria-label='Sort by'>
          <SelectOption value='updated'>Sort by Last Updated </SelectOption>
          <SelectOption value='created'>Sort by Created Date</SelectOption>
          <SelectOption value='status'>Sort by Status</SelectOption>
          <SelectOption value='project'>Sort by Category</SelectOption>
          <SelectOption value='priority'>Sort by Priority</SelectOption>
          <SelectOption value='reporter'>Sort by Reporter</SelectOption>
        </SelectInput>
      </FocusIndicator>
    </FilterRow>
  );
};

export default Actions;
