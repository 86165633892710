import {
  SERVICE_ACCOUNT_DISPLAY_NAME,
} from '../../../../shared/config/helpdesk';

const AUTHOR_ERROR_STRING: string = '';

/*
 * Parse out the author string we add on comment creation. Syntax:
 * https://community.atlassian.com/t5/Jira-questions/How-can-we-allow-user-emails-to-be-shown-in-the-new-view-for/qaq-p/1592055
 */
const parseOutAuthor = (actualAuthor: string, unparsedBody: string): { author: string; body: string } => {
  // tslint:disable-next-line: tsr-detect-possible-timing-attacks
  if (actualAuthor !== SERVICE_ACCOUNT_DISPLAY_NAME) {
    return { author: actualAuthor, body: unparsedBody };
  }

  // tslint:disable-next-line: max-line-length tsr-detect-non-literal-regexp
  const re: RegExp = new RegExp('<p><font color="#010203">Author: <a href="https://amount.atlassian.net/secure/ViewProfile.jspa\\?(.+)>(.+)<\/a><\/font><\/p>((.|\\s)+)');
  // tslint:disable-next-line: max-line-length tsr-detect-non-literal-regexp
  const emailRegex: RegExp = new RegExp('<p><b>Author: <a href="mailto:(.+)>(.+)<\/a><\/b><\/p>((.|\\s)+)');
  const [ /* ignore */, /* ignore */, parsedAuthor, authorlessBody] = unparsedBody.match(re) || [];
  const [ /* ignore */, /* ignore */, parsedEmail, emaillessBody] = unparsedBody.match(emailRegex) || [];

  if (parsedAuthor) {
    return { author: parsedAuthor, body: authorlessBody };
  } else if (parsedEmail) {
    return { author: parsedEmail, body: emaillessBody };
  } else {
    return { author: AUTHOR_ERROR_STRING, body: unparsedBody };
  }
};

export default parseOutAuthor;
