import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { ApolloLink } from 'apollo-link';

const link: ApolloLink = new BatchHttpLink({
  uri: '/graphql',
  // uri: 'http://localhost:4051/graphql',
  credentials: 'same-origin'
});

const cache: InMemoryCache = new InMemoryCache({
  addTypename: true,
  cacheRedirects: {
    Query: {
      requestDetail: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'IssueType', id: args.id }),
      user: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'OktaUser', id: args.id }),
      sar: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'SAR', id: args.id })
    },
  },
});

export const client: ApolloClient<{}> = new ApolloClient({
  link,
  cache,
});
