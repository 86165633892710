import * as React from 'react';
import { Mutation, MutationFn } from 'react-apollo';
import Error from '@avant/crm-frontend-utils/error';
import * as flat from 'flat';
import camelCase from 'lodash-es/camelCase';
import isEqual from 'lodash-es/isEqual';

import {
  ADDITIONAL_SAR_CATEGORIES,
  IAdditionalSARCategoryInformation,
  IAdditionalSARCategoryOption
} from '../../../../../shared/transitions/sar';
import { Button } from '../../Buttons';

import {
  editCategories__Modal,
  editCategories__Modal__Background,
  editCategories__Modal__Content__Actions__Container,
  editCategories__Modal__Content__Categories__Container,
  editCategories__Modal__Content__Categories__FieldSet,
  editCategories__Modal__Content__Categories__Form,
  editCategories__Modal__Content__Categories__Form__Field,
  editCategories__Modal__Content__Categories__Form__Field__Text_Field,
  editCategories__Modal__Content__Container,
} from './style.css';
import AddCategories, { CategorizeSAR, CategorizeSARInput } from './addCategories.graphql';

const joinKeys: (...keys: string[]) => string = (...keys) => keys.join('.');

interface IProps {
  sarId: string;
  categories: object;
  closeCategories (): void;
}
interface ILiftedProps extends IProps {
  addCategory: MutationFn<CategorizeSAR, CategorizeSARInput>;
}
interface IPassedValues extends IProps {
  setValue: React.FormEventHandler<HTMLInputElement>;
  loading: boolean;
  flattenedMap: object;
}

interface IState {
  loading: boolean;
  flattenedMap: object;
}

type FieldType = React.FC<IAdditionalSARCategoryOption & { id: string; name: string } & IPassedValues>;
const Field: FieldType = ({ id, code, description, needsComment, setValue, flattenedMap, loading, name }) => {
  const key: string = joinKeys(id, code);
  const checkboxKey: string = joinKeys(key, 'value');
  const inputKey: string = joinKeys(key, 'comment');

  return (
    <div className={editCategories__Modal__Content__Categories__Form__Field}>
      <input
        id={checkboxKey}
        name={checkboxKey}
        type='checkbox'
        role='checkbox'
        aria-checked={!!flattenedMap[checkboxKey]}
        checked={!!flattenedMap[checkboxKey]}
        onChange={setValue}
        disabled={loading}
        data-event={camelCase(`${name} ${code} checkbox`)}
      />
      <label htmlFor={checkboxKey}>
        <h5>
          {code}. {description}
          {
            needsComment && (
              <input
                name={inputKey}
                className={editCategories__Modal__Content__Categories__Form__Field__Text_Field}
                type='text'
                required={true}
                onChange={setValue}
                value={flattenedMap[inputKey] || ''}
                disabled={loading}
                data-event={camelCase(`${name} ${code} Input`)}
              />
            )
          }
        </h5>
      </label>
    </div>
  );
};

const Category: React.FC<IAdditionalSARCategoryInformation & IPassedValues> = ({ id, options, name, ...passProps }) => (
  <div key={id} className={editCategories__Modal__Content__Categories__FieldSet}>
    <h3>
      {name}
    </h3>
    <div>
      {
        options.map(opt => (
          <Field key={id + opt.code} id={id} name={name} {...opt} {...passProps} />
        ))
      }
    </div>
  </div>
);

interface IModalBackdropProps {
  closeCategories (): void;
}

const KEY_DOWN_EVENT: string = 'keydown';
const ESCAPE: string = 'Escape';

const checkEscape: (e: KeyboardEvent) => boolean = ({ key }) => key === ESCAPE;
// tslint:disable-next-line: no-any
const checkNotInTextBox: (e: KeyboardEvent) => boolean = ({ target: { tagName } }: any) => tagName !== 'INPUT';
const generateEscapeCallback: (fn: Function) => EventListener = fn => e =>
  checkEscape(e as KeyboardEvent) &&
  checkNotInTextBox(e as KeyboardEvent) &&
  fn();

class ModalBackdrop extends React.PureComponent<IModalBackdropProps> {
  private readonly eventListener = generateEscapeCallback(this.props.closeCategories);

  public componentDidMount (): void {
    // tslint:disable-next-line: no-any
    document.addEventListener(KEY_DOWN_EVENT, this.eventListener);
  }
  public componentWillUnmount (): void {
    // tslint:disable-next-line: no-any
    document.removeEventListener(KEY_DOWN_EVENT, this.eventListener as any);
  }

  public render (): JSX.Element {
    return (
      <div className={editCategories__Modal__Background} role='button' onClick={this.props.closeCategories} />
    );
  }
}

// tslint:disable-next-line: no-reserved-keywords
const getValue: (input: { type: string; checked: boolean; value: string }) => string | boolean = ({ type, checked, value }) => (
  type === 'checkbox' ? checked : value
);

class EditCategoriesBase extends React.Component<ILiftedProps, IState> {
  public state: IState = {
    loading: false,
    flattenedMap: flat<object, object>(this.props.categories || {})
  };

  public setValue: React.FormEventHandler<HTMLInputElement> = ({ currentTarget: { type, name, value, checked } }) => {
    this.setFlatMap({ ...this.state.flattenedMap, [name]: getValue({ type, checked, value }) });
  }

  public setFlatMap: ( categories: object ) => void = categories => {
    this.setState({ flattenedMap: flat<object, object>(categories || {}) });
  }

  public submit: React.FormEventHandler<HTMLDivElement | HTMLButtonElement> = async e => {
    e.preventDefault();
    try {
      const metadataFile: object = flat.unflatten(this.state.flattenedMap);
      this.setState({ loading: true });

      await this.props.addCategory({
        variables: { sarId: this.props.sarId, categories: metadataFile, clientMutationId: '0' },
        optimisticResponse: {
          categorizeSAR: {
            __typename: 'CategorizeSARPayload',
            sar: {
              __typename: 'SAR',
              id: this.props.sarId,
              metadata: {
                __typename: 'SARMetadata',
                additionalCategories: this.props.categories
              }
            }
          }
        }
      });

      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
      Error.notify('Error adding information', 'SAR Categories', 'error');
    }
  }

  public render (): JSX.Element {
    const isDirty = !isEqual(flat(this.props.categories), this.state.flattenedMap);

    return (
      <div className={editCategories__Modal}>
        <ModalBackdrop closeCategories={this.props.closeCategories} />
        <div className={editCategories__Modal__Content__Container}>
          <div className={editCategories__Modal__Content__Categories__Container}>
            <form className={editCategories__Modal__Content__Categories__Form}>
              {
                ADDITIONAL_SAR_CATEGORIES.map(cat => (
                  <Category
                    key={cat.id}
                    loading={this.state.loading}
                    flattenedMap={this.state.flattenedMap}
                    {...cat}
                    {...this.props}
                    setValue={this.setValue}
                  />
                ))
              }
            </form>
          </div>
          <div className={editCategories__Modal__Content__Actions__Container}>
            {isDirty && (
              <Button
                event='success'
                disabled={this.state.loading}
                onClick={this.submit}
                data-event='saveCategoriesButton'
              >
                Save
              </Button>
            )}
            <Button
              event='error'
              disabled={this.state.loading}
              onClick={this.props.closeCategories}
              data-event={`${isDirty ? 'cancel' : 'close'}CategoriesButton`}
            >
              {isDirty ? 'Cancel' : 'Close'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export const EditCategories: React.FC<IProps> = props => (
  <Mutation<CategorizeSAR, CategorizeSARInput>
    mutation={AddCategories}
  >
    {addCategory => (
      <EditCategoriesBase {...props} addCategory={addCategory} />
    )}
  </Mutation>
);
