import * as React from 'react';
import { media, Pill } from '@amount/frontend-components';
import styled, { css } from 'styled-components';

import { FieldHeader, Field, FieldLabel, FieldData } from './common';

const fullPagePillCss = css`
  padding: 0 2em;
  margin: 0 1em;
  order: 1;
`;

const PaddedPill = styled(Pill)`
  order: -1;
  margin: 1em 0;
  width: min-content;

  ${media.small`
    ${fullPagePillCss}
  `}

  @media print {
    ${fullPagePillCss}
  }
`;

const FieldMeta = styled.div`
  color: ${props => props.theme.colorSlate};
`;

interface IProps {
  label?: string;
  time?: string;
  latest?: boolean;
}

const RequestField: React.FC<IProps> = ({ label, time, children, latest }) => (
  <Field>
    {(label || time || latest) && <FieldHeader>
      {label && <FieldLabel>{label}</FieldLabel>}
      {time && <FieldMeta>{time}</FieldMeta>}
      {latest && <PaddedPill pillColor='warning' margin='0 1em'>Latest</PaddedPill>}
    </FieldHeader>}
    <FieldData>
      {children}
    </FieldData>
  </Field>
);

export default RequestField;
