import { countBy, groupBy } from 'lodash-es';
import * as React from 'react';
import {
  format,
  parse,
  eachDay,
  isAfter
} from 'date-fns';

import { SelectionOnInBranchIndividual } from '../../queries/getInBranchData.graphql';
import { SelectionOnInBranchStore } from '../../queries/getInBranchStoreData.graphql';

import { IChartDatum } from './IssuanceChart';
import { ISummaryData } from './ChartCommon';
import { IBarChartData } from './BranchBarChart';

const STORE_ISSUANCE_DATE_FORMAT = 'YYYY-MM-DD';

type StoreIndividual = SelectionOnInBranchIndividual | SelectionOnInBranchStore;

interface IReducedData
  extends Omit<ISummaryData, 'issued' | 'avgLoanAmount' | 'linksSent'> {
  loanAmounts: number[];
}

const BLANK_SUMMARY_DATA: ISummaryData = {
  linksSent: 0,
  appsStarted: 0,
  loansIssued: 0,
  appsCompleted: 0,
  issued: 0,
  avgLoanAmount: 0,
  appsSubmitted: 0,
  appsApproved: 0,
  appsEnteredVer: 0,
  contractsSubmitted: 0
};

export const getSummaryViewData = (data: StoreIndividual[]) => {
  if (!data) {
    return BLANK_SUMMARY_DATA;
  }

  // eslint-disable-next-line max-len
  const { appsStarted, loansIssued, appsCompleted, loanAmounts, appsSubmitted, appsApproved, appsEnteredVer, contractsSubmitted } = data.reduce<
  IReducedData
  >(
    (acc, curr) => ({
      appsStarted: !!curr.personalViewedTime
        ? acc.appsStarted + 1
        : acc.appsStarted,
      appsCompleted: !!curr.completedApplication
        ? acc.appsCompleted + 1
        : acc.appsCompleted,
      loansIssued: !!curr.issued ? acc.loansIssued + 1 : acc.loansIssued,
      loanAmounts:
        !!curr.issued && curr.amount !== 0
          ? [...acc.loanAmounts, curr.amount]
          : acc.loanAmounts,
      appsSubmitted: !!curr.personalSubmittedTime ? acc.appsSubmitted + 1 : acc.appsSubmitted,
      appsApproved: !!curr.loanId ? acc.appsApproved + 1 : acc.appsApproved,
      appsEnteredVer: !!curr.appsEnteredVer ? acc.appsEnteredVer + 1 : acc.appsEnteredVer,
      contractsSubmitted: !!curr.contractsSubmitted ? acc.contractsSubmitted + 1 : acc.contractsSubmitted
    }),
    { ...BLANK_SUMMARY_DATA, loanAmounts: [] }
  );

  const issued: number = loanAmounts.reduce((a: number, b: number) => a + b, 0);

  const avgLoanAmount: number = loanAmounts.length !== 0 ? Math.round(issued / loanAmounts.length) : 0;

  return {
    linksSent: data.length,
    appsStarted,
    appsCompleted,
    loansIssued,
    issued,
    avgLoanAmount,
    appsSubmitted,
    appsApproved,
    appsEnteredVer,
    contractsSubmitted
  };
};

const getTotal: <T>(
  accessor: (datum: T) => number
) => (data: T[]) => number = accessor => data =>
  data.reduce<number>((acc, curr) => acc + accessor(curr), 0);

const getTotalAmount = getTotal(
  (datum: SelectionOnInBranchIndividual) => datum.amount
);
const getTotalIssued = getTotal((datum: SelectionOnInBranchIndividual) =>
  datum.issued ? 1 : 0
);

const issuecreateChartData = (
  dateIterator: string[],
  groupedData: Record<string, SelectionOnInBranchIndividual[]>
): IChartDatum[] =>
  dateIterator.map<IChartDatum>((date: string) => {
    return ({
      date: parse(date),
      amount: !!groupedData[date] ? getTotalAmount(groupedData[date]) : 0,
      issued: !!groupedData[date] ? getTotalIssued(groupedData[date]) : 0
    });
  })

export const useIssuanceChartData = (
  filteredData: StoreIndividual[],
  from: string,
  to: string
): IChartDatum[] => {

  const intervalIterator = React.useMemo(
    () => {
      const fromDate = parse(from)
      const toDate = parse(to)

      if (isAfter(fromDate, toDate)) {
        return []
      }

      return eachDay(fromDate, toDate).map(day => format(day, STORE_ISSUANCE_DATE_FORMAT));
    },
    [from, to]
  )

  return React.useMemo(
    () => {
      const groupedData = groupBy(filteredData, _data => format(parse(_data.createdTime), STORE_ISSUANCE_DATE_FORMAT));

      return issuecreateChartData(intervalIterator, groupedData);
    },
    [filteredData, from, to]
  )
}

export const getBarChartData: (data: StoreIndividual[]) => IBarChartData[] = data => {
  const issueFilter = (d: StoreIndividual) => !!d.issued;
  const countByBranchId = countBy(data.filter(issueFilter), 'branchId');

  return Object.keys(countByBranchId).map(branchId => ({ branchId, count: countByBranchId[branchId] }));
};
