import {
  AMOUNT_PERMISSION_KEY,
  AVANT_PERMISSION_KEY,
  EDIT_BPO_SUPPORT_HELPDESK,
  RADIUS_PERMISSION_KEY,
  UPDATE_BPO_SUPPORT_HELPDESK,
  VIEW_ADMIN_HELPDESK,
  VIEW_BPO_SUPPORT_HELPDESK,
  VIEW_OPS_TICKETS,
  VIEW_OPS_TICKETS_RO,
} from '../../../../shared/config/permissions';
import {
  OPERATIONAL_SUPPORT,
} from '../../../../shared/config/helpdesk';
import { SelectionOnMe } from '../../Context/me.graphql';

export const hasAdminPermission: (context: SelectionOnMe) => boolean = context => context.permissions.includes(VIEW_ADMIN_HELPDESK);
export const hasViewOpPermission: (context: SelectionOnMe) => boolean = context => context.permissions.includes(VIEW_OPS_TICKETS);
export const hasViewOpRoPermission: (context: SelectionOnMe) => boolean = context => context.permissions.includes(VIEW_OPS_TICKETS_RO);
export const hasViewBPOPermission: (context: SelectionOnMe) => boolean = context => context.permissions.includes(VIEW_BPO_SUPPORT_HELPDESK);
export const hasUpdateBPOPermission: (context: SelectionOnMe) => boolean = context => context.permissions.includes(UPDATE_BPO_SUPPORT_HELPDESK);
export const hasEditBPOPermission: (context: SelectionOnMe) => boolean = context => context.permissions.includes(EDIT_BPO_SUPPORT_HELPDESK);

export const isAmountUser: (context: SelectionOnMe) => boolean = context =>
  context.currentPartner === AMOUNT_PERMISSION_KEY;
export const isAvantUser: (context: SelectionOnMe) => boolean = context =>
  context.currentPartner === AVANT_PERMISSION_KEY;
export const isRadiusUser: (context: SelectionOnMe) => boolean = context =>
  context.organization === RADIUS_PERMISSION_KEY;

export const canViewOperationalSupportFilter: (
  context: SelectionOnMe
) => boolean = context =>
  (isAvantUser(context) && hasViewOpPermission(context)) ||
  hasViewOpRoPermission(context);

// There can be a future scnerio when there are more than one Helpdesk partners hence adding this method to handle multiple helpDesk partners
export const ispartnerHelpdesk: (context: SelectionOnMe) => boolean = isRadiusUser;

export const ispartnerHelpdeskAdmin: (
  context: SelectionOnMe
) => boolean = context =>
  ispartnerHelpdesk(context) && hasViewBPOPermission(context) && hasUpdateBPOPermission(context) && hasEditBPOPermission(context);


export const ispartnerHelpdeskSubAdmin: (
  context: SelectionOnMe
) => boolean = context =>
  ispartnerHelpdesk(context) && hasViewBPOPermission(context)  && hasEditBPOPermission(context);

export const canViewTicket: (
  requestType: string,
  context: SelectionOnMe
) => boolean = (requestType, context) => {
  if (requestType === OPERATIONAL_SUPPORT) {
    return hasViewOpPermission(context) || hasViewOpRoPermission(context);
  }

  return true;
};

export const canUpdateStatus: (
  requestType: string,
  context: SelectionOnMe
) => boolean = (requestType, context) => {
  if (requestType === OPERATIONAL_SUPPORT) {
    return isAvantUser(context) && hasViewOpPermission(context);
  } else if (ispartnerHelpdesk(context)) {
    return ispartnerHelpdeskAdmin(context) ;
  }

  return hasAdminPermission(context)  ;
};

export const canChangePriority: (
  requestType: string,
  context: SelectionOnMe
) => boolean = (requestType, context) => {
  if (requestType === OPERATIONAL_SUPPORT) {
    return isAvantUser(context) && hasViewOpPermission(context);
  } else if (ispartnerHelpdesk(context)) {
    return ispartnerHelpdeskAdmin(context) || ispartnerHelpdeskSubAdmin(context);
  }

  return hasAdminPermission(context);
};

export const canAddComment: (
  requestType: string,
  context: SelectionOnMe
) => boolean = (requestType, context) => {
  if (requestType === OPERATIONAL_SUPPORT) {
    return isAvantUser(context) && hasViewOpRoPermission(context);
  }

  return true;
};
