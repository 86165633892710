import * as React from 'react';
import { Link } from 'react-router-dom';

import { BackArrow, Breadcrumb, BreadcrumbWrap } from '../../Breadcrumb';
import { InnerContainer } from '../../InnerContainer';
import { PartnersContainer } from '../../PartnersContainer';
import { MaxWidthContainer } from '../../MaxWidthContainer';

interface IProps {
  backPath?: string;
}

const Body: React.FC<IProps> = props => (
  <PartnersContainer>
    <MaxWidthContainer>
      <InnerContainer>
        {!!props.backPath && (
          <BreadcrumbWrap className='no-print'>
            <Link to={props.backPath} data-event='helpdeskBackArrow'>
              <Breadcrumb>
                <BackArrow icon='return-arrow' />
                <span>Back</span>
              </Breadcrumb>
            </Link>
          </BreadcrumbWrap>
        )}
        {props.children}
      </InnerContainer>
    </MaxWidthContainer>
  </PartnersContainer>
);

export default Body;
