import * as React from 'react';
import Error from '@avant/crm-frontend-utils/error';
import { toast, ToastContainer } from 'react-toastify';
import {
  BreakPointsMap,
  Label,
  NotificationBody,
  NotificationClose,
  NotificationContainer,
  NotificationMessage,
 } from '@amount/frontend-components';
import styled from 'styled-components';
export type NotificationLevel = 'error' | 'warning' | 'info' | 'success';

const ToastNotificationContainer = styled.div`
  .Toastify__toast-container {
    transform: translate3d(0, 0, 1000em);
    position: fixed;
    margin-top: 5em;
    top: 1em;
    right: 1em;
    z-index: 10000;
  }

  .Toastify__toast-body {
    width: 100%;
  }

  @keyframes Toastify__slideInRight {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    from {
      opacity: 0;
      transform: translate3d(3000px, 0, 0); }
    60% {
      opacity: 1;
      transform: translate3d(2000px, 0, 0); }
    75% {
      transform: translate3d(1000px, 0, 0); }
    90% {
      transform: translate3d(0px, 0, 0); }
    to {
      transform: none; }
  }

  @keyframes Toastify__slideOutRight {
    20% {
      opacity: 1;
      transform: translate3d(0px, 0, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(2000px, 0, 0);
    }
  }

  .Toastify__bounce-enter--top-right {
    animation-name: Toastify__slideInRight;
  }

  .Toastify__bounce-exit--top-right {
    animation-name: Toastify__slideOutRight;
  }

  @keyframes Toastify__slideInDown {
    from {
      transform: translate3d(0, -110%, 0);
      visibility: visible; }
    to {
      transform: translate3d(0, 0, 0); } }

  @keyframes Toastify__slideOutDown {
    from {
      transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      transform: translate3d(0, 500px, 0); }
  }

  @keyframes Toastify__slideOutUp {
    from {
      transform: translate3d(0, 0, 0); }
    to {
      visibility: hidden;
      transform: translate3d(0, -500px, 0); }
  }

  @media only screen and (max-width: ${BreakPointsMap.small}em) {
    .Toastify__toast-container {
      width: 100vw;
      padding: 0;
      left: 0;
      right: 0;
      top: 0;
      margin: 0;
    }

    .Toastify__bounce-enter--top-right {
      animation-name: Toastify__slideInDown;
    }

    .Toastify__bounce-exit--top-right {
      animation-name: Toastify__slideOutUp;
    }

  }

  /* progress bar style needed to make the autoClose functionality work */
  @keyframes Toastify__trackProgress {
    0% {
      transform: scaleX(1); }
    100% {
      transform: scaleX(0); }
  }

  .Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 9999;
    opacity: 0.7;
    background-color: rgba(255, 255, 255, 0.7);
    transform-origin: left;
  }

  .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards;
  }

  .Toastify__progress-bar--controlled {
    transition: transform .2s;
  }

  .Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    transform-origin: right;
  }

  .Toastify__progress-bar--default {
    background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  }
`;

interface IToastProps {
  title: string;
  message: string;
  // tslint:disable-next-line: no-reserved-keywords
  type: NotificationLevel;
}

const Toast: React.FC<IToastProps> = ({ title, message, type }) => {
  const closeButtonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(
    () => {
      if (closeButtonRef.current) {
        closeButtonRef.current.focus();
      }
    },
    []
  );

  return (
    <NotificationContainer>
      <NotificationBody level={type}>
        <Label>
          {title}
        </Label>
        <NotificationMessage>
          {message}
        </NotificationMessage>
      </NotificationBody>
      <NotificationClose ref={closeButtonRef} />
    </NotificationContainer>
  );
};

const ToastNotification: React.FC = () => {
  const [toastIds, setToastIds] = React.useState<React.ReactText[]>([]);
  const addToastId = (newId: React.ReactText): void => {
    setToastIds([...toastIds, newId]);
  };

  const dismissLastToast = (ids: React.ReactText[]) => ids.every((id, index) => {
    const dismissAndUpdateState = () => {
      toast.dismiss(id);
      setToastIds(ids.slice(index));

      return false;
    };

    // return false to break out of the every() and only dismiss the first active notification
    return toast.isActive(id) ? dismissAndUpdateState() : true;
  });

  interface IAddNotificationProps {
    detail: IToastProps;
  }

  const addNotification = ({ detail }: IAddNotificationProps): void => {
    const id = toast(<Toast {...detail} />);
    addToastId(id);
  };

  React.useEffect(
    () => {
      Error.subscribeToNotifications(addNotification);

      return () => {
        Error.unsubscribeFromNotifications(addNotification);
      };
    }
  );

  const onKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (e.key === 'Escape') {
      dismissLastToast(toastIds);
    }
  };

  return (
    <ToastNotificationContainer onKeyDown={onKeyDown}>
      <ToastContainer
        closeButton={false}
        autoClose={false}
        hideProgressBar={true}
      />
    </ToastNotificationContainer>
  );
};

export default ToastNotification;
