import { Headline, Card, DatapointGrid, FormLabel } from '@amount/frontend-components';
import styled from 'styled-components';

import { isWithinRange, isAfter } from 'date-fns';

export const Chart = styled.div`
  padding-top: 1em;
`;

export const ChartTitle = styled(Headline)`
  padding-bottom: .75em;
`;


export const Spacer = styled.div<{padding: string}>`
  padding-bottom: ${({ padding }) => padding };
`;

export const ChartCard = styled(Card)`
  padding: 2.875em 2.875em 2.250em 2.875em;
`;

export const FunnelCard = styled(Card)`
  padding: 2.250em;
`;

export const FunnelLabel = styled(DatapointGrid)`
  margin: 0;
  padding: 0 0 2em 0;
  div {
    font-size: 18px;
  }
`;

export const FilterLabel = styled(FormLabel)`
  color: ${({ theme }) => theme.text.colorCaption };
`;

export const isDateWithin = (selectedDateString: string | null, fromDateString: string, toDateString: string): boolean => {
  if (
    !selectedDateString ||
    isAfter(fromDateString, toDateString)
  ) { return false }

  return isWithinRange(selectedDateString, fromDateString, toDateString)
}

export const GroupedFilter = styled.div`
  display: flex;

  #reset {
    padding: 0;
    input {
      border-right: none !important;
    }
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaddingContainer = styled.div`
  width: 100%;
  padding-bottom: 1.5em;
  display: flex;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonLabel = styled.span`
  margin-right: 0.5em;
  margin-bottom: -0.25em;
`;

export const DATE_DOWNLOAD_FORMAT = 'MM/DD/YYYY';
export const DATE_INPUT_FORMAT = 'YYYY-MM-DD'
export const SELECT_ALL = '-1'
export const CHART_WIDTH = 765
export const CHART_HEIGHT = 335

export interface IChartMargin {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export interface ISummaryData {
  linksSent: number;
  appsStarted: number;
  appsCompleted: number;
  loansIssued: number;
  issued: number;
  avgLoanAmount: number;
  appsSubmitted: number;
  appsApproved: number;
  appsEnteredVer: number;
  contractsSubmitted: number;
}
