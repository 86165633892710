import * as React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import { SelectionOnValues2 } from '../queries/getRequestDetail.graphql';
import {
  HELPDESK_DATE_FORMAT,
  HELPDESK_TIME_FORMAT,
} from '../../../../shared/config/helpdesk';

import RequestField from './RequestField';
import parseOutAuthor from './parseOutAuthor';

const linkMatcher = (_match: string, issue: string, id: string) =>
  `="/api/helpdesk/download?id=${id}&name=${issue}-${id}&issue=${issue}"`;

// replace Jira's content hosting links with our own Helpdesk resource proxy
const replaceJiraLinks = (body: string): string => {
  // tslint:disable-next-line: max-line-length
  const absoluteRegex: RegExp = /="https:\/\/amount\.atlassian\.net\/servicedesk\/customer\/request\/(\d+)\/attachment\/(\d+)"/g;
  const relativeRegex: RegExp = /="\/servicedesk\/customer\/request\/(\d+)\/attachment\/(\d+)"/g;

  return body.replace(absoluteRegex, linkMatcher).replace(relativeRegex, linkMatcher);
};

const CommentContainer = styled.div`
  padding: 1em 0;
`;

const DangerDiv = styled.div`
  all: initial;
  color: ${({ theme: { text }}) => text.colorText};
  font-family: ${({ theme: { typography }}) => typography.bodyTypeface};
  font-weight: 400;
  -webkit-font-smoothing: antialiased;

  & > ul {
    list-style: initial;
  }
  & > ul, li {
    list-style-position: inside;
  }
`;

interface ILiftedProps {
  commentList: SelectionOnValues2[] | undefined;
}

interface IProps {
  comment: SelectionOnValues2;
  latest: boolean;
}

const Comment: React.FC<IProps> = ({ comment, latest }) => {
  const dateObject: Date = new Date(comment.created.iso8601);
  const date: string = format(dateObject, HELPDESK_DATE_FORMAT);
  const time: string = format(dateObject, HELPDESK_TIME_FORMAT);
  const createdTimeString: string = `${date} at ${time}`;

  const { author, body } = parseOutAuthor(comment.author.displayName, comment.renderedBody);
  const bodyWithReplacedLinks: string = replaceJiraLinks(body);

  return (
    <RequestField label={author} time={createdTimeString} latest={latest}>
      {/* tslint:disable-next-line: react-no-dangerous-html */}
      <DangerDiv dangerouslySetInnerHTML={{ __html: bodyWithReplacedLinks }} />
    </RequestField>
  );
};

const RequestComments: React.FC<ILiftedProps> = ({ commentList }) => (
  <CommentContainer>
    {!!commentList && commentList.map((c, i) => (
      <Comment comment={c} key={c.id} latest={i === commentList.length - 1} />
    )).reverse() || 'Comments Unavailable'}
  </CommentContainer>
);

export default RequestComments;
