import * as React from 'react';

import { Consumer } from '../Context';

import { Header } from './Presentational/Header';
import LiftedPartnerSwitcher from './LiftedPartnerSwitcher';

interface IComponent {
  forceLink?: boolean;
}

interface IComponentState {
  showTakeover: boolean;
}

class Component extends React.Component<IComponent, IComponentState> {
  public state: IComponentState = {
    showTakeover: false
  };

  public render (): JSX.Element {
    return (
      <Consumer>
        {me => (
          <>
            <Header
              {...this.props}
              me={me}
              togglePartnerSwitcher={this.togglePartnerSwitcher}
            />
            <LiftedPartnerSwitcher
              {...this.props}
              me={me}
              show={this.state.showTakeover}
              close={this.closePartnerSwitcher}
            />
          </>
        )}
      </Consumer>
    );
  }

  private readonly closePartnerSwitcher: () => void = () => {
    this.setState(({ showTakeover: false }));
  }

  private readonly togglePartnerSwitcher: () => void = () => {
    this.setState(({ showTakeover }) => ({ showTakeover: !showTakeover }));
  }
}

export default Component;
