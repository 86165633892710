import * as React from 'react';
import camelCase from 'lodash-es/camelCase';
import { Link } from 'react-router-dom';
import { VALID_SVG_ICONS } from '@amount/frontend-components';

import { Card } from '../../Card';
import { Config } from '../Config';
import { PermissionedComponent } from '../../PermissionedComponent';
import { ModuleRoutes } from '../../../../shared/routes';

import { ISection } from './';

interface IHelpdeskCard {
  title: string;
  subtitle: string;
  section: string;
  iconType: VALID_SVG_ICONS;
}

const HelpdeskCard: React.FC<IHelpdeskCard> =
  ({ section: sectionName, title, iconType, subtitle }) => {
    const section: ISection | undefined = Config[sectionName];

    if (!section) {
      return null;
    }

    return (
      <PermissionedComponent allowedPermissions={section.permissions}>
        <Link to={`${ModuleRoutes.jitbitHelpdesk}/${sectionName}`} data-event={camelCase(title)}>
          <Card
            icon={iconType}
            label={subtitle}
            name={title}
            data-event={camelCase(title)}
          />
        </Link>
      </PermissionedComponent>
    );
  };

export default HelpdeskCard;
