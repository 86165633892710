import * as React from 'react';
import { MutationFn } from 'react-apollo';
import { Helmet } from 'react-helmet';
import ErrorHandler from '@avant/crm-frontend-utils/error';
import {
  ActionButton,
  ButtonWithSpinner,
  FormError,
  Headline,
  HorizontalDivider,
  SVGIcon,
} from '@amount/frontend-components';
import styled from 'styled-components';
import { Link, RouteComponentProps } from 'react-router-dom';

import { InnerContainer } from '../../InnerContainer';
import { ModuleRoutes } from '../../../../shared/routes';
import { BackArrow, Breadcrumb, BreadcrumbWrap } from '../../Breadcrumb';
import {
  Organizations,
  SWITCH_ENABLED_PARTNER_SET,
  SWITCHABLE_PARTNER_SET,
  SwitchablePartner,
} from '../../../../shared/config/partners';
import { CreateUser, CreateUserInput } from '../queries/createUser.graphql';
import { ButtonLabel } from '../../CommonComponents/ButtonLabel';
import { LiftedUserRoleFields } from '../UserRoleEditFields';
import { ErrorLogger } from '../../../services/error';

import {
  getFormInputField,
  isBlank,
  UserInfoFields,
  UserPartnersModal
} from './common';
import { ALREADY_EXISTS_TEXT, VALID_POINTER_KEYS } from './constants';

const AddPartnerButton = styled(ActionButton)`
  margin-bottom: 2.5em;
`;

const FormSection = styled.div`
  margin-top: 2em;
`;

interface IUserCreateProps extends RouteComponentProps {
  createUser: MutationFn<CreateUser, CreateUserInput>;
  organization: Organizations;
}

type selectedRoles = { [key in SwitchablePartner]?: string };

interface IErrorField {
  errorField: Map<string, string>;
}

interface IUserCreateState extends IErrorField {
  roles: selectedRoles;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  submitting: boolean;
  showPartnersModal: boolean;
  partners: SwitchablePartner[];
  tempPartners: SwitchablePartner[];
}

export class UserCreate extends React.Component<IUserCreateProps, IUserCreateState> {
  public state: IUserCreateState = {
    submitting: false,
    showPartnersModal: false,
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    roles: {},
    partners: [this.props.organization as SwitchablePartner],
    tempPartners: [this.props.organization as SwitchablePartner],
    errorField: new Map<string, string>()
  };

  public render (): JSX.Element {
    return (
      <InnerContainer>
        <Helmet>
          <title>Add New User · User Admin</title>
        </Helmet>
        <BreadcrumbWrap>
          <Link to={ModuleRoutes.userManagement} data-event='newUserBackArrow'>
            <Breadcrumb>
              <BackArrow icon='return-arrow' />
              <span>Back</span>
            </Breadcrumb>
          </Link>
        </BreadcrumbWrap>
        <UserPartnersModal
          partners={this.state.tempPartners}
          show={this.state.showPartnersModal}
          close={this.hidePartnersModal}
          selectPartner={this.selectPartner}
          submit={this.savePartnerChanges}
          currentPartner={this.props.organization}
        />
        <Headline scale='large'>Add New User</Headline>
        <FormSection>
          <Headline scale='small'>User Information</Headline>
          <UserInfoFields
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            organization={this.props.organization}
            username={this.state.username}
            handleInputChange={this.handleInputChange}
            errorFields={this.state.errorField}
          />
        </FormSection>
        {this.state.errorField.has(VALID_POINTER_KEYS.ALREADY_EXISTS) &&
          <FormError>{this.state.errorField.get(VALID_POINTER_KEYS.ALREADY_EXISTS)}</FormError>
        }
        <FormSection>
          {this.state.partners.map((org, index) => (
            <React.Fragment key={org}>
              <LiftedUserRoleFields
                partner={org}
                currentPartner={this.props.organization}
                selectedRole={this.state.roles[org]}
                selectRole={this.selectRole}
                removePartner={this.removePartner}
              />
              {index !== this.state.partners.length - 1 && <HorizontalDivider />}
            </React.Fragment>
          ))}
        </FormSection>
        {SWITCH_ENABLED_PARTNER_SET.has(this.props.organization) && (
          <AddPartnerButton onClick={this.showPartnersModal}>
            <SVGIcon icon='plus' />
            <ButtonLabel>Add Partner Permissions</ButtonLabel>
          </AddPartnerButton>
        )}
        {this.state.errorField.has(VALID_POINTER_KEYS.GENERAL) &&
          <FormError>{this.state.errorField.get(VALID_POINTER_KEYS.GENERAL)}</FormError>
        }
        <ButtonWithSpinner
          key='submitButton'
          data-event='submitEditUserInfo'
          inline={true}
          onClick={this.SubmitCreateUser}
          disabled={!this.canSubmit()}
          loading={this.state.submitting}
        >
          Save
        </ButtonWithSpinner>
      </InnerContainer>
    );
  }

  private readonly showPartnersModal: () => void = () => {
    this.setState({ showPartnersModal: true, tempPartners: this.state.partners });
  }

  private readonly hidePartnersModal: () => void = () => {
    this.setState({ showPartnersModal: false });
  }

  private readonly savePartnerChanges: React.FormEventHandler<HTMLButtonElement> = () => {
    this.setState({ partners: this.state.tempPartners.sort(), showPartnersModal: false });
  }

  private readonly removePartner: React.FormEventHandler<HTMLButtonElement> = ({ currentTarget: { dataset: { partner } } }) => {
    if (!partner) { return; }

    this.setState(({ partners, roles }) => {
      // tslint:disable-next-line: no-dynamic-delete
      delete roles[partner];

      return {
        partners: partners.filter(p => p !== partner),
        roles
      };
    });
  }

  private readonly selectPartner: React.FormEventHandler<HTMLInputElement> = ({ currentTarget: { dataset: { partner } } }) => {
    if (!partner) { return; }

    this.setState(({ tempPartners, roles }) => {
      if (tempPartners.includes(partner as SwitchablePartner)) {
        // tslint:disable-next-line: no-dynamic-delete
        delete roles[partner];

        return {
          tempPartners: tempPartners.filter(p => p !== partner),
          roles
        };
      }

      // ensure valid partner
      if (!SWITCHABLE_PARTNER_SET.has(partner as SwitchablePartner)) {
        return {
          tempPartners,
          roles
        };
      }

      return {
        tempPartners: [
          ...tempPartners,
          partner as SwitchablePartner
        ],
        roles
      };
    });
  }

  private readonly canSubmit: () => boolean = () => (
    !!this.state.email.length &&
    Object.values(this.state.roles).length === this.state.partners.length &&
    !isBlank(this.state.firstName) &&
    !isBlank(this.state.lastName) &&
    !this.state.submitting
  )

  private readonly selectRole: React.FormEventHandler<HTMLInputElement> = ({ currentTarget: { dataset: { role, organization } } }) => {
    if (!role) { return; }
    if (!organization) { return; }

    this.setState(({ roles }) => ({ roles: {
      ...roles,
      [organization as SwitchablePartner]: role
    }}));
  }

  private readonly handleInputChange: React.FormEventHandler<HTMLInputElement> = event => {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const value: string = target.value;
    const name: keyof IUserCreateState = target.name as keyof IUserCreateState;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  private readonly SubmitCreateUser: React.MouseEventHandler = async () => {
    if (!this.canSubmit()) { return; }

    this.state.errorField.clear();
    this.setState({ submitting: true });

    try {
      const roles: string[] = Object.values(this.state.roles)
        .filter((x: string | undefined): x is string => !!x);

      const response = await this.props.createUser({
        variables: {
          organization: this.props.organization,
          email: this.state.email,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          username: this.state.username,
          roles,
          clientMutationId: '0',
        }
      });
      if (!response) { throw new Error('Failed to create user'); }
      const { data, errors } = response;

      if (errors && errors.length) { throw new Error('Failed to create user'); }
      if (!data || !data.createUser) { throw new Error('Failed to create user'); }

      if (!!data.createUser.error) {
        data.createUser.error.forEach(err => {
          if (err) {
            const formInputField: string = getFormInputField(err.errorCode || 'None');
            if (err.errorCode === '400') {
              this.state.errorField.set(formInputField, ALREADY_EXISTS_TEXT);
            } else {
              this.state.errorField.set(formInputField, err.errorMessage);
            }
          }
        });
      }

      this.setState({
        submitting: false
      });

      if (!data.createUser.success) { throw new Error('Failed to create user'); }
      this.props.history.push(`${ModuleRoutes.userManagement}`);
      ErrorHandler.notify(`Successfully created user ${this.state.firstName} ${this.state.lastName}`, 'User Create', 'success');
    } catch (e) {
      console.error(e);
      ErrorLogger.captureException(e);

      ErrorHandler.notify(`${e}`, 'User Create', 'error');

      this.setState({ submitting: false });
    }
  }
}
