import * as React from 'react';
import {
  ConditionalRenderWrapper,
  media,
  VALID_COLOR,
} from '@amount/frontend-components';
import styled, { css } from 'styled-components';
import Events from '@avant/crm-frontend-utils/events';
import { Link } from 'react-router-dom';

import { PARTNER_CHANGED_EVENT } from '../../../../shared/config/constants';
import {
  GetUsers as getUsers,
  SelectionOnEdges as SelectionOnOktaUserEdge,
  SelectionOnNode as SelectionOnOktaUser,
} from '../queries/getUsers.graphql';
import { ModuleRoutes } from '../../../../shared/routes';

import {
  ACTIVE_STATUS,
  INACTIVE_STATUS,
  LOCKED_STATUS,
  PENDING_STATUS,
  PROCESSING_STATUS,
  RECOVERY_STATUS,
  STATUS_LIST_READABLE_MAP,
  VALID_STATUS
} from './constants';

const NO_USERS_FOUND: JSX.Element = (
  <span>The search resulted in no users.</span>
);

const darkItemStyles = css`
  &:hover {
    color: ${({ theme: { link } }) => link.colorLinkHover};
  }
`;

const lightItemStyles = css`
  &:hover {
    background: ${({ theme }) => theme.colorSlate5};
  }
`;

export const UserList = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  ${({ theme }) => css`
    ${media.small`
      width: 15em;
      min-width: 15em;
      max-width: 15em;
      height: calc( 100vh - 380px);
      border: 1px solid ${theme.colorSlate20};
    `}
  `}

`;

interface IUserData {
  active?: boolean;
}

const UserData = styled.button<IUserData>`
  display: block;
  position: relative;
  width: 100%;
  padding: 1em;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;

  ${({ theme: { name } }) => name === 'amountDark' ? darkItemStyles : lightItemStyles}
  ${({ active, theme }) => active && css`
    ${media.small`
      background: ${theme.radioCard.colorBackgroundChecked};
      &:hover {
        background: ${theme.radioCard.colorBackgroundChecked};
      }
      
      /* forced to use color literals since there isn't a stable option in radio/radioCard */
      &:not(:first-child) {
        border-top: 1px solid #7befb2
      }
      &:not(:last-child) {
        border-bottom: 1px solid #7befb2
      }
    `}
  `}
`;

const UserCell = styled.div`
  font-size: 1.4rem;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const userStatusIndicatorColorMap: { [x in VALID_STATUS]: VALID_COLOR } = {
  [ACTIVE_STATUS]: 'primary',
  [INACTIVE_STATUS]: 'inactive',
  [PROCESSING_STATUS]: 'alert',
  [PENDING_STATUS]: 'warning',
  [RECOVERY_STATUS]: 'warning',
  [LOCKED_STATUS]: 'alert'
};

const UserStatusIndicator = styled.div<{ status: VALID_COLOR }>`
  display: inline-flex;
  background-color: ${({ theme: { pill }, status }) => pill[status].colorBackground};
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  margin-left: 0.5em;
`;

const MobileUserLink = styled(Link)`
  width: 100%;
`;

export interface ILiftedProps  {
  organization: string | null;
  roles: string[];
  status: string[];
  search: string | undefined;
  selectedUser: string | undefined;
  selectUser (id: string): void;
}

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  status: string;
  selectedUser: string | undefined;
  selectUser?: React.MouseEventHandler<HTMLButtonElement>;
}
const User: React.FC<IUser> = ({ id, firstName, lastName, status, selectUser, selectedUser }) => (
  <UserData
    data-id={id}
    onClick={selectUser}
    active={selectedUser === id}
  >
    <UserCell>{firstName} {lastName}</UserCell>
    <UserCell>
      {STATUS_LIST_READABLE_MAP[status]}
      <UserStatusIndicator status={userStatusIndicatorColorMap[status]} />
    </UserCell>
  </UserData>
);

const formatUsers: (data: getUsers | undefined) => SelectionOnOktaUser[] | undefined | null = data => (
  data &&
  data.usersConnection.edges &&
  data.usersConnection.edges.reduce<SelectionOnOktaUser[]>(
    (acc: SelectionOnOktaUser[], user: SelectionOnOktaUserEdge | null) => !!user ? [...acc, user.node] : acc,
    []
  )
);

interface IUserTableProps extends ILiftedProps {
  data?: getUsers;
  refetch (): void;
}
export class UserTable extends React.Component<IUserTableProps> {
  public static getDerivedStateFromProps (nextProps: IUserTableProps): null {
    if (!nextProps.data || nextProps.selectedUser) { return null; }
    const users: SelectionOnOktaUser[] | undefined | null = formatUsers(nextProps.data);

    if (users && users[0]) {
      nextProps.selectUser(users[0].id);
    }

    return null;
  }

  public state = {};

  public componentDidMount (): void {
    Events.subscribe(PARTNER_CHANGED_EVENT, this.refetchUsers);
  }

  public componentWillUnmount (): void {
    Events.unsubscribe(PARTNER_CHANGED_EVENT, this.refetchUsers);
  }

  public render (): JSX.Element {
    const users: SelectionOnOktaUser[] | undefined | null = formatUsers(this.props.data);

    if (!users) {
      return (
        <span>Unable to retrieve user list, please try again in a few minutes.</span>
      );
    }

    if (!users.length) {
      return (
        <UserList>
          {NO_USERS_FOUND}
        </UserList>
      );
    }

    return (
      <>
        <ConditionalRenderWrapper hiddenOnMobile={false} breakpoint='small'>
          <UserList>
            {users.map(user => (
              <MobileUserLink key={user.id} to={`${ModuleRoutes.userManagement}/${user.id}`} data-event='mobileUserTableUserClick'>
                <User {...user} selectedUser={this.props.selectedUser} />
              </MobileUserLink>
            ))}
          </UserList>
        </ConditionalRenderWrapper>
        <ConditionalRenderWrapper hiddenOnMobile={true} breakpoint='small'>
          <UserList>
            {users.map(user => (
              <User key={user.id} {...user} selectedUser={this.props.selectedUser} selectUser={this.selectUser} />
            ))}
          </UserList>
        </ConditionalRenderWrapper>
      </>
    );
  }
  private readonly refetchUsers: () => void = () => {
    this.props.refetch();
  };

  private readonly selectUser: React.MouseEventHandler<HTMLButtonElement> = ({ currentTarget: { dataset: { id } } }) => {
    if (!id) { return; }

    this.props.selectUser(id);
  };
}
