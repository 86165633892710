import * as React from 'react';
import {
  ButtonWithSpinner,
  Checkbox,
  FormLabel,
  Headline,
  Input,
} from '@amount/frontend-components';
import styled from 'styled-components';
import ErrorHandler from '@avant/crm-frontend-utils/error';
import { ExecutionResult, MutationFn } from 'react-apollo';

import Modal, { IModalProps } from '../../Modal';
import { ButtonActionRow, CancelButton, InputRow, ModalText } from '../../Modal/common';
import { ErrorLogger } from '../../../services/error';
import { CreateFolder, CreateFolderInput } from '../queries/newFolder.graphql';
import { DeleteFile, DeleteFileInput } from '../queries/deleteFile.graphql';
import { MoveFile, MoveFileInput } from '../queries/moveFile.graphql';
import { DeleteFolder, DeleteFolderInput } from '../queries/deleteFolder.graphql';
import { VALID_S3_TYPES } from '../../../../server/database/s3';
import { FILE_KEY, FOLDER_KEY } from '../../../../shared/reports/constants';

import { IApolloVariablesType, isValidFileName, MoveFilePropsToVariables, MoveFolderPropsToVariables, NewFolderFn } from './common';

const NameInput = styled(Input)`
  margin-bottom: 2em;
`;

type VALID_KINDS = typeof FILE_KEY | typeof FOLDER_KEY;

const NameReadOnly = styled.strong`
  word-break: break-word;
`;

interface IModalBase extends IModalProps {
  name?: string;
  kind: VALID_KINDS;
}

interface IModalState {
  submitting: boolean;
  name?: string;
  restricted?: boolean;
}

type NewFolderPropsToVariablesType = (
  // tslint:disable-next-line: no-reserved-keywords
  props: { type: VALID_S3_TYPES },
  key: string,
  restricted: boolean
) => IApolloVariablesType<CreateFolderInput>;
export const NewFolderPropsToVariables: NewFolderPropsToVariablesType = ({ type }, key, restricted) => ({
  variables: {
    key,
    clientMutationId: '0',
    type,
    restricted
  }
});

interface ICreateProps extends IModalBase {
  prefix: string;
  // tslint:disable-next-line: no-reserved-keywords
  type: VALID_S3_TYPES;
  restricted?: boolean;
  mutation: NewFolderFn;
  refetch (): void;
}

export class CreateModal extends React.Component<ICreateProps, IModalState> {
  public state: IModalState = {
    submitting: false,
    restricted: false
  };

  public constructor (props: ICreateProps) {
    super(props);

    this.state = {
      submitting: false,
      restricted: props.restricted
    };
  }

  public render (): JSX.Element {
    return (
      <Modal
        data-modal={true}
        close={this.props.close}
        show={this.props.show}
        maxWidth='30em'
      >
        <Headline scale='medium'>Create New {this.props.kind}</Headline>
        <InputRow>
          <FormLabel>{this.props.kind} Name</FormLabel>
          <NameInput
            onChange={this.handleInputChange}
            defaultValue={this.props.name}
          />
        </InputRow>
        <InputRow>
          <Checkbox
            defaultChecked={this.props.restricted}
            disabled={this.props.restricted}
            onChange={this.setValue}
          >
            Mark this folder as restricted
          </Checkbox>
        </InputRow>
        <ButtonActionRow>
          <ButtonWithSpinner
            inline={true}
            buttonStyle='secondary'
            onClick={this.handleNewFolderClick}
            disabled={this.state.submitting || !this.state.name}
            loading={this.state.submitting}
            data-event='confirmDeactivateUser'
          >
            Create
          </ButtonWithSpinner>
          <CancelButton
            hidden={this.state.submitting}
            onClick={this.props.close}
            data-event='cancelInactivate'
          >
            Cancel
          </CancelButton>
        </ButtonActionRow>
      </Modal>
    );
  }

  private readonly handleInputChange: React.FormEventHandler<HTMLInputElement> = ({ currentTarget: { value }}) => {
    this.setState({ name: value.trim() });
  }

  private readonly setValue: React.FormEventHandler<HTMLInputElement> = ({ currentTarget: { checked } }) => {
    this.setState({ restricted: checked });
  }

  private readonly handleNewFolderClick: React.MouseEventHandler<HTMLButtonElement> =  async e => {
    try {
      const newName: string = (this.state.name || '').trim();
      if (!newName.length) {
        ErrorHandler.notify(`${this.props.kind} name required.`, `New ${this.props.kind}`, 'error');

        return;
      }
      if (!isValidFileName(newName)) {
        ErrorHandler.notify(`${this.props.kind} name contains invalid characters`, `New ${this.props.kind}`, 'error');

        return;
      }
      this.setState({ submitting: true });

      const result: ExecutionResult<CreateFolder> | void = await this.props.mutation(
        NewFolderPropsToVariables(this.props, `${this.props.prefix}/${newName}`, !!this.state.restricted)
      );

      this.setState({ submitting: false });
      if (
        result &&
        result.data &&
        result.data.createFolder &&
        result.data.createFolder.success
      ) {
        ErrorHandler.notify(`Successfully created new ${this.props.kind} ${newName}`, `New ${this.props.kind}`, 'success');
        this.props.refetch();
        this.props.close(e);

        return;
      }

      ErrorHandler.notify(`Failed to create ${this.props.kind.toLowerCase()}`, `New ${this.props.kind}`, 'error');
    } catch (err) {
      this.setState({ submitting: false });
      console.error(err);
      ErrorLogger.captureException(err);
      ErrorHandler.notify(`Failed to create ${this.props.kind.toLowerCase()}`, `New ${this.props.kind}`, 'error');
    }
  }
}

interface IDeleteProps extends IModalBase {
  mutation: MutationFn<DeleteFile, DeleteFileInput> | MutationFn<DeleteFolder, DeleteFolderInput>;
  refetch (): void;
}

export class DeleteModal extends React.Component<IDeleteProps, IModalState> {
  public state: IModalState = {
    submitting: false,
  };

  public render (): JSX.Element {
    return (
      <Modal
        data-modal={true}
        close={this.props.close}
        show={this.props.show}
        maxWidth='30em'
      >
        <Headline scale='medium'>Delete {this.props.kind}</Headline>
        <InputRow>
          <ModalText>
            Are you sure you want to delete <NameReadOnly>{this.props.name}</NameReadOnly>?
          </ModalText>
        </InputRow>
        <ButtonActionRow>
          <ButtonWithSpinner
            inline={true}
            buttonStyle='secondary'
            onClick={this.handleDeleteItem}
            disabled={this.state.submitting}
            loading={this.state.submitting}
            data-event='confirmDeactivateUser'
          >
            Delete
          </ButtonWithSpinner>
          <CancelButton
            hidden={this.state.submitting}
            onClick={this.props.close}
            data-event='cancelInactivate'
          >
            Keep {this.props.kind}
          </CancelButton>
        </ButtonActionRow>
      </Modal>
    );
  }

  private readonly handleDeleteItem: () => void = async () => {
    try {
      this.setState({ submitting: true });
      const result: ExecutionResult<DeleteFolder | DeleteFile> | void = await this.props.mutation();

      this.setState({ submitting: false });

      if ((
          result &&
          result.data
        ) && ((
            (result.data as DeleteFile).deleteFile &&
            // tslint:disable-next-line: no-non-null-assertion
            (result.data as DeleteFile).deleteFile!.success
          ) || (
            (result.data as DeleteFolder).deleteFolder &&
            // tslint:disable-next-line: no-non-null-assertion
            (result.data as DeleteFolder).deleteFolder!.success
          ))
      ) {
        this.props.refetch();
        ErrorHandler.notify(`Successfully deleted ${this.props.kind} ${this.props.name}`, `Delete ${this.props.kind}`, 'success');

        return;
      }

      ErrorHandler.notify(`Failed to delete ${this.props.kind}`, `Delete ${this.props.kind}`, 'error');
    } catch (err) {
      console.error(err);
      ErrorHandler.notify(`Failed to delete ${this.props.kind}`, `Delete ${this.props.kind}`, 'error');
      this.setState({ submitting: false });
    }
  }
}

interface IEditProps extends IModalBase {
  prefix: string;
  // tslint:disable-next-line: no-reserved-keywords
  type: VALID_S3_TYPES;
  restricted?: boolean;
  parentRestricted?: boolean;
  mutation: MutationFn<MoveFile, MoveFileInput>;
  refetch (): void;
}

export class RenameModal extends React.Component<IEditProps, IModalState> {
  public state: IModalState = {
    submitting: false,
    restricted: false,
  };
  constructor (props: IEditProps) {
    super(props);

    this.state = {
      submitting: false,
      name: props.name,
      restricted: props.restricted
    };
  }

  public render (): JSX.Element {
    return (
      <Modal
        data-modal={true}
        close={this.props.close}
        show={this.props.show}
        maxWidth='30em'
      >
        <Headline scale='medium'>Edit {this.props.kind}</Headline>
        <InputRow>
          <FormLabel>{this.props.kind} Name</FormLabel>
          <NameInput
            onChange={this.handleInputChange}
            defaultValue={this.props.name}
          />
        </InputRow>
        {this.props.kind === FOLDER_KEY && (
          <InputRow>
            <Checkbox
              disabled={this.props.parentRestricted}
              defaultChecked={this.props.restricted}
              onChange={this.setValue}
            >
              Mark this folder as restricted
            </Checkbox>
          </InputRow>
        )}
        <ButtonActionRow>
          <ButtonWithSpinner
            inline={true}
            buttonStyle='secondary'
            onClick={this.handleRenameItem}
            disabled={this.state.submitting || !this.state.name}
            loading={this.state.submitting}
            data-event='confirmDeactivateUser'
          >
            Rename
          </ButtonWithSpinner>
          <CancelButton
            hidden={this.state.submitting}
            onClick={this.props.close}
            data-event='cancelInactivate'
          >
            Cancel
          </CancelButton>
        </ButtonActionRow>
      </Modal>
    );
  }
  private readonly handleInputChange: React.FormEventHandler<HTMLInputElement> = ({ currentTarget: { value }}) => {
    this.setState({ name: value.trim() });
  }

  private readonly setValue: React.FormEventHandler<HTMLInputElement> = ({ currentTarget: { checked } }) => {
    this.setState({ restricted: checked });
  }

  private readonly handleRenameItem: () => void = async () => {
    try {
      const newName: string = (this.state.name || '').trim();
      if (!newName.length) {
        ErrorHandler.notify(`${this.props.kind} name required.`, `Edit ${this.props.kind}`, 'error');

        return;
      }
      if (!isValidFileName(newName)) {
        ErrorHandler.notify(`${this.props.kind} name contains invalid characters`, `Edit ${this.props.kind}`, 'error');

        return;
      }
      this.setState({ submitting: true });

      const result: ExecutionResult<MoveFile> | void = await this.props.mutation(
        this.props.kind === FILE_KEY ?
          MoveFilePropsToVariables(
            {
              Key: this.props.prefix,
              type: this.props.type,
            },
            newName
          ) :
          MoveFolderPropsToVariables(
            {
              type: this.props.type,
              Prefix: this.props.prefix,
              restricted: this.state.restricted || false
            },
            newName
          )
      );

      this.setState({ submitting: false });

      if (
        result &&
        result.data &&
        result.data.moveFile &&
        result.data.moveFile.success
      ) {
        this.props.refetch();
        ErrorHandler.notify(
          `Successfully edited ${this.props.kind} ${newName}`,
          `Rename ${this.props.kind}`,
          'success'
        );

        return;
      }

      ErrorHandler.notify(`Failed to rename ${this.props.kind}`, `Rename ${this.props.kind}`, 'error');
    } catch (err) {
      this.setState({ submitting: false });
      console.error(err);
      ErrorLogger.captureException(err);
      ErrorHandler.notify(`Failed to rename ${this.props.kind}`, `Rename ${this.props.kind}`, 'error');
    }
  }
}
