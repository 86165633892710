import * as React from 'react';
import {
  Checkbox,
  DropdownButton,
  SVGIcon,
} from '@amount/frontend-components';
import styled from 'styled-components';
import * as pluralize from 'pluralize';

import { ButtonLabel } from '../../CommonComponents/ButtonLabel';

const FilterLabel = styled.legend`
  white-space: nowrap;
  color: ${({ theme }) => theme.colorSlate70};
  margin-bottom: 1em;
  font-size: 1.6rem;
  line-height: 2.2rem;
`;

const ClearButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5em;
`;

const ButtonIcon = styled(SVGIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledDropdownButton = styled(DropdownButton)`
  margin-right: 0.5em;
`;

interface IProps {
  options: string[];
  name: string;
  value: Set<string>;
  onChange: React.FormEventHandler<HTMLInputElement>;
  onClear: React.FormEventHandler<HTMLButtonElement>;
}

const StatefulFilterButton: React.FC<IProps> = ({ value, name, onChange, options, onClear }) => (
  <StyledDropdownButton
    active={!!value.size}
    buttonContent={<ButtonLabel>{!!value.size ? value.size : ''} {pluralize(name, value.size || 1)}</ButtonLabel>}
  >
    <fieldset>
      <FilterLabel>Filter by {name}</FilterLabel>
      {options.map((o: string) => (
        <Checkbox key={o} checked={value.has(o)} value={o} name={name} onChange={onChange}>{o}</Checkbox>
      ))}
      <ClearButton name={name} onClick={onClear}>
        <ButtonIcon icon='close' />
        <ButtonLabel>Clear Filter</ButtonLabel>
      </ClearButton>
    </fieldset>
  </StyledDropdownButton>
);

export default StatefulFilterButton;
