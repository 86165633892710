import * as React from 'react';
import { Menu as SideMenu } from '@amount/frontend-components';
import camelCase from 'lodash-es/camelCase';
import Events from '@avant/crm-frontend-utils/events';

import { ModuleRouteMap, MODULES_LIST, VIEW_PERMISSION_TO_MODULE_MAP } from '../../../../shared/config/modules';
import { PermissionedComponent } from '../../PermissionedComponent';
import { AppRoutes } from '../../../../shared/routes';
import { SmartLink } from '../../SmartLink';
import { PARTNER_CHANGED_EVENT } from '../../../../shared/config/constants';
import { getDarkModeEnabled, THEME_CHANGED_EVENT } from '../../../services/theme';
import { PartneredComponent } from '../../PartneredComponent';
import { hidePartnerSwitcher } from '../../../services/hidePartnerSwitcher';

interface IModulesProps {
  showMenu: boolean;
  forceLink?: boolean;
  close (e?: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>): void;
}

const Modules: React.FC<IModulesProps> = ({ forceLink, close }) => (
  <SideMenu.Group>
    {MODULES_LIST.map(p =>
      ModuleRouteMap[p].enabled && (
        <React.Fragment key={p}>
          <PartneredComponent allowedPartners={ModuleRouteMap[p].partners} excludedPartners={ModuleRouteMap[p].excludedPartners}>
            <PermissionedComponent allowedPermissions={ModuleRouteMap[p].permissions}>
              <SideMenu.Item>
                <SmartLink
                  forceLink={!!forceLink || ModuleRouteMap[p].forceLink}
                  newTab={ModuleRouteMap[p].newTab}
                  href={ModuleRouteMap[p].route}
                  onClick={forceLink ? undefined : close}
                  data-event={camelCase(`Menu ${p}`)}
                >
                  <span>{ModuleRouteMap[p].name}</span>
                </SmartLink>
              </SideMenu.Item>
            </PermissionedComponent>
          </PartneredComponent>
        </React.Fragment>
      ))}
  </SideMenu.Group>
);

interface IMenuProps {
  switchablePartners: string[];
  showMenu: boolean;
  forceLink?: boolean;
  darkModeDisabled?: boolean;
  toggleMenu (e?: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>): void;
  togglePartnerSwitcher (): void;
}

interface IMenuState {
  showTakeover: boolean;
}

export class Menu extends React.Component<IMenuProps, IMenuState> {
  public state: IMenuState = {
    showTakeover: false
  };

  public componentDidMount (): void {
    window.addEventListener('click', this.clickListener);
    Events.subscribe(PARTNER_CHANGED_EVENT, this.changeListener);
  }

  public componentWillUnmount (): void {
    window.removeEventListener('click', this.clickListener);
    Events.unsubscribe(PARTNER_CHANGED_EVENT, this.changeListener);
  }

  public render (): JSX.Element {
    return (
      <SideMenu
        onClick={this.props.toggleMenu}
        showMenu={this.props.showMenu}
        data-menu={true}
        menuHeadingText='Modules'
      >
        <PermissionedComponent allowedPermissions={Object.keys(VIEW_PERMISSION_TO_MODULE_MAP)}>
          <Modules showMenu={this.props.showMenu} close={this.props.toggleMenu} forceLink={this.props.forceLink} />
        </PermissionedComponent>
        <SideMenu.Group>
          {(this.props.switchablePartners.length > 1 && !hidePartnerSwitcher.isTrue()) && (
            <SideMenu.Item>
              <button
                tabIndex={this.props.showMenu ? 0 : -1}
                onClick={this.props.togglePartnerSwitcher}
                data-event='MenuPartnerSwitcher'
              >
                <span>Switch Partner Instance</span>
              </button>
            </SideMenu.Item>
          )}
          <SideMenu.Item>
            <SmartLink
              forceLink={this.props.forceLink}
              href={AppRoutes.myAccount}
              onClick={this.props.toggleMenu}
              data-event='MenuMyAccount'
            >
              <span>My Account</span>
            </SmartLink>
          </SideMenu.Item>
          {!this.props.darkModeDisabled && (
            <SideMenu.Item>
              <SideMenu.Toggle
                defaultChecked={getDarkModeEnabled()}
                onClick={this.toggleDarkMode}
              >
                Dark Mode
              </SideMenu.Toggle>
            </SideMenu.Item>
          )}
        </SideMenu.Group>
        <SideMenu.Group>
          <SideMenu.Item>
            <SmartLink
              forceLink={true}
              href={AppRoutes.logout}
              data-event='MenuLogout'
            >
              <span>Logout</span>
            </SmartLink>
          </SideMenu.Item>
        </SideMenu.Group>
      </SideMenu>
    );
  }

  private readonly changeListener: EventListener = () => {
    this.props.toggleMenu();
  }

  private readonly clickListener: EventListener = e => {
    if (!this.props.showMenu) { return; }

    const Wrap: HTMLElement | null = document.querySelector(`[data-menu='true']`);
    const Modal: HTMLElement | null = document.querySelector(`[data-modal='true']`);
    const Button: HTMLElement | null = document.querySelector(`[data-menubutton='true']`);

    if (Wrap && Wrap.contains(e.target as Element)) { return; }
    if (Button && Button.contains(e.target as Element)) { return; }
    if (Modal && Modal.contains(e.target as Element)) { return; }

    e.preventDefault();
    this.props.toggleMenu();
  }

  private readonly toggleDarkMode: React.MouseEventHandler<HTMLInputElement> = e => {
    const detail: boolean = !!e.currentTarget.checked;
    Events.publish(THEME_CHANGED_EVENT, { detail });
  }
}
