import * as React from 'react';
import { MutationFn } from 'react-apollo';
import ErrorHandler from '@avant/crm-frontend-utils/error';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';
import {
  ConditionalRenderWrapper,
  Headline,
  HorizontalDivider,
  Label,
  media,
  TableContain,
  TBody,
  VTableCell as TableCell,
  VTableHeaderCell,
  VTableRow as TableRow,
} from '@amount/frontend-components';
import styled from 'styled-components';
import { Link, RouteComponentProps } from 'react-router-dom';

import { EDIT_PERMISSION_TO_MODULE_MAP, VALID_MODULES, VIEW_PERMISSION_TO_MODULE_MAP } from '../../../../shared/config/modules';
import { ModuleRoutes } from '../../../../shared/routes';
import { client as apolloClient } from '../../../client';
import { Organizations, READABLE_PARTNER_NAME_MAP } from '../../../../shared/config/partners';
import getUserQuery, {
  GetUser,
  GetUserInput,
  SelectionOnPermissions,
  SelectionOnPermissions as SelectionOnOktaUserPermission,
} from '../queries/getUser.graphql';
import getUsersQuery, {
  GetUsers as getUsers,
  GetUsersInput,
  SelectionOnEdges,
} from '../queries/getUsers.graphql';
import { EditUserActive as editUserActive, EditUserActiveInput } from '../queries/editUserActive.graphql';
import {
  ResendUserActivation as resendUserActivation,
  ResendUserActivationInput
} from '../queries/resendUserActivation.graphql';
import { ErrorLogger } from '../../../services/error';
import { BackArrow, Breadcrumb, BreadcrumbWrap } from '../../Breadcrumb';

import {
  // getUsername,
  EMPTY_CELL,
  HeaderRow,
  oktaRolesByOrg,
  UserRoles,
  userStatusPill,
} from './common';
import {
  ACTIVE_STATUS,
  DISABLED_STATUS,
  INACTIVE_STATUS,
  LOCKED_STATUS,
  MODIFIED_VALID_STATUS,
  PENDING_STATUS,
  PROCESSING_STATUS,
  RECOVERY_STATUS,
} from './constants';
import {
  ActivationResentModal,
  ConfirmActivationModal,
  statusToChangeMap,
  UserDetailActions,
  UserDetailActionsDrawer,
} from './UserDetailModals';

const DetailTable = styled(TableContain)`
  table-layout: fixed;

  &:first-child {
    margin-top: 1.75em;
  }

  &:last-child {
    margin-top: 1.5em;
  }

  ${media.small`
    &:last-child {
      margin-top: 1.5em;
    }
  `}
`;

const DetailBreadcrumbWrap = styled(BreadcrumbWrap)`
  display: flex;
  justify-content: space-between;
  width: calc( 100% - 2em );
  align-items: center;
  top: -3.625em;
`;

const HeaderCol = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  ${media.small`
    /* shrink to fit button */
    max-width: calc( 100% - 150px );
  `}
`;

const HeadlineWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserHeadline = styled(Headline)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HeadlinePill = styled.div`
  margin: 0 0 0.5em 0.75em;
  height: 1.375em;
`;

const SubheadText = styled.p`
  font-size: 14px;
`;

const TableWrap = styled.div`
  ${media.small`
    margin-top: 3em;
  `}
`;

const VTableRow = styled(TableRow)`
  display: block;
  position: relative;

  ${media.small`
    display: table-row;
  `}
`;

const VTableCell = styled(TableCell)`
  &:before {
    padding-bottom: 0;
  }

  &:last-child {
    padding-bottom: 1em;
  }

  ${media.small`
    /* responsive text overflow */
    max-width: 0;

    &:last-child {
      padding-bottom: 2em;
    }
  `}
`;

const VTableInfoCell = styled(VTableCell)`
  padding-top: 0;

  &:first-child {
    padding-top: 0;
  }
`;

const TableItem = styled.p`
  font-size: 1.4rem;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PermissionsCell = styled.div`
  ${media.small`
    position: relative;
  `}
`;

const FetchError: React.FC = () => (
  <>
    <Helmet>
      <title>User Admin · Partner Portal</title>
    </Helmet>
    <HeaderRow>
      <HeaderCol>
        <HeadlineWrap>
          <UserHeadline scale='large'>There was a problem</UserHeadline>
        </HeadlineWrap>
        <SubheadText>We encountered a problem retrieving user data</SubheadText>
      </HeaderCol>
    </HeaderRow>
  </>
);

const NotFound: React.FC = () => (
  <>
    <Helmet>
      <title>User Admin · Partner Portal</title>
    </Helmet>
    <HeaderRow>
      <HeaderCol>
        <HeadlineWrap>
          <UserHeadline scale='large'>User not found</UserHeadline>
        </HeadlineWrap>
        <SubheadText>We were not able to find this user</SubheadText>
      </HeaderCol>
    </HeaderRow>
  </>
);

type UserPermissions = { [key in VALID_MODULES]?: string; };
const permissionsByModule: (permissions: SelectionOnOktaUserPermission[]) => UserPermissions = permissions => {
  if (!permissions) { return {}; }

  return permissions.reduce<UserPermissions>(
    (acc: UserPermissions, permission: SelectionOnOktaUserPermission) => {
      const readableModule: string | undefined = VIEW_PERMISSION_TO_MODULE_MAP[permission.name];
      const editableModule: string | undefined = EDIT_PERMISSION_TO_MODULE_MAP[permission.name];

      if (readableModule) { acc[readableModule] = `${readableModule}${acc[readableModule] || ''}`; }
      if (editableModule) { acc[editableModule] = `${acc[editableModule] || ''} (edit access)`; }

      return acc;
    },
    {}
  );
};

const statusToActiveMap: { [x in MODIFIED_VALID_STATUS]: boolean } = {
  [ACTIVE_STATUS]: true,
  [INACTIVE_STATUS]: false,
  [PENDING_STATUS]: false,
  [DISABLED_STATUS]: false,
  [PROCESSING_STATUS]: false,
  [RECOVERY_STATUS]: false,
  [LOCKED_STATUS]: false,
};

interface IUserTableRowProps {
  organization: string;
  name: string;
  permissions: SelectionOnPermissions[] | null;
}

const UserTableRow: React.FC<IUserTableRowProps> = ({ name, organization, permissions }) => (
  <VTableRow>
    <VTableCell collapsedDescription='Organization'>
      <TableItem>{READABLE_PARTNER_NAME_MAP[organization]}</TableItem>
    </VTableCell>
    <VTableCell collapsedDescription='Roles'>
      <TableItem>{name || EMPTY_CELL}</TableItem>
    </VTableCell>
    <VTableCell collapsedDescription='Permissions'>
      <PermissionsCell>
        {permissions ?
          Object.values(permissionsByModule(permissions)).sort().map(permission => (
            <TableItem key={permission}>{permission}</TableItem>
          )) :
          EMPTY_CELL
        }
      </PermissionsCell>
    </VTableCell>
  </VTableRow>
);

// this can be removed in apollo client 3.0
// https://github.com/apollographql/apollo-feature-requests/issues/1
const readUsers: (org: string) => getUsers | null = (organization: string) => {
  try {
    return apolloClient.readQuery<getUsers, GetUsersInput>({
      query: getUsersQuery,
      variables: { organization }
    });
  } catch (e) {
    return null;
  }
};

export interface ILiftedProps extends RouteComponentProps<{ id?: string }> {
  id?: string;
}
interface IUserDetail extends ILiftedProps {
  data?: GetUser;
  editUser: MutationFn<editUserActive, EditUserActiveInput>;
  resendActivation: MutationFn<resendUserActivation, ResendUserActivationInput>;
  currentOrg: string | Organizations;
  refetch (): void;
}
interface IUserDetailState {
  showDrawer: boolean;
  submitting: boolean;
  showResendModal: boolean;
  showStatusModal: boolean;
}
export class UserDetail extends React.Component<IUserDetail, IUserDetailState> {
  public state: IUserDetailState = {
    submitting: false,
    showDrawer: false,
    showResendModal: false,
    showStatusModal: false
  };

  // tslint:disable-next-line: max-func-body-length
  public render (): JSX.Element {
    const { data } = this.props;
    const userId: string | undefined = this.props.id || this.props.match.params.id;
    if (!userId) { return <NotFound />; }
    if (!data || !data.user) { return <FetchError />; }

    const isStandalone: boolean = !!this.props.match.params.id;
    // const usernameLabel: string = (data && data.user) ? getUsername(data.user.organization) : 'Username';
    const usernameLabel: string = 'Username';
    const userRoles: UserRoles = oktaRolesByOrg(data.user.roles || [], data.user.organization);

    return (
      <>
        <Helmet>
          <title>{data.user.firstName} {data.user.lastName} · User Admin</title>
        </Helmet>
        {isStandalone && (
          <DetailBreadcrumbWrap>
            <Link to={ModuleRoutes.userManagement} data-event='userDetailBackArrow'>
              <Breadcrumb>
                <BackArrow icon='return-arrow' />
                <span>Back</span>
              </Breadcrumb>
            </Link>
            <ConditionalRenderWrapper
              breakpoint='small'
              hiddenOnMobile={false}
            >
              <UserDetailActions
                submitting={this.state.submitting}
                status={data.user.status}
                id={userId}
                handleDrawer={this.showSettingsDrawer}
                handleStatus={this.showStatusModal}
                handleResend={this.resendUserActivation}
              />
            </ConditionalRenderWrapper>
          </DetailBreadcrumbWrap>
        )}
        <ActivationResentModal
          email={data.user.email}
          show={this.state.showResendModal}
          close={this.dismissModal}
        />
        <ConfirmActivationModal
          confirm={this.changeUserStatus}
          firstName={data.user.firstName}
          lastName={data.user.lastName}
          status={data.user.status}
          submitting={this.state.submitting}
          show={this.state.showStatusModal}
          close={this.dismissModal}
        />
        <UserDetailActionsDrawer
          submitting={this.state.submitting}
          status={data.user.status}
          id={userId}
          handleStatus={this.showStatusModal}
          handleResend={this.resendUserActivation}
          show={this.state.showDrawer}
          close={this.dismissModal}
        />
        <HeaderRow>
          <HeaderCol>
            <HeadlineWrap>
              {isStandalone ? (
                <UserHeadline scale='large'>{data.user.firstName} {data.user.lastName}</UserHeadline>
              ) : (
                <Link to={`${ModuleRoutes.userManagement}/${this.props.id}`} data-event='userDetailBackArrow'>
                  <UserHeadline scale='large'>{data.user.firstName} {data.user.lastName}</UserHeadline>
                </Link>
              )}
              <HeadlinePill>{userStatusPill(data.user.status)}</HeadlinePill>
            </HeadlineWrap>
            <SubheadText>Last updated {format(data.user.updated, 'MMMM D, YYYY')}</SubheadText>
          </HeaderCol>
          <span>
            <ConditionalRenderWrapper
              breakpoint='small'
              hiddenOnMobile={true}
            >
              <UserDetailActions
                submitting={this.state.submitting}
                status={data.user.status}
                id={userId}
                handleDrawer={this.showSettingsDrawer}
                handleStatus={this.showStatusModal}
                handleResend={this.resendUserActivation}
              />
            </ConditionalRenderWrapper>
          </span>
        </HeaderRow>
        <TableWrap>
          <DetailTable>
            <TBody>
              <VTableRow>
                <VTableHeaderCell>
                  <Label>Email</Label>
                </VTableHeaderCell>
                <VTableHeaderCell>
                  <Label>{usernameLabel}</Label>
                </VTableHeaderCell>
                <VTableHeaderCell>
                  <Label>Created On</Label>
                </VTableHeaderCell>
              </VTableRow>
              <VTableRow>
                <VTableInfoCell collapsedDescription='Email'>
                  <TableItem>{data.user.email}</TableItem>
                </VTableInfoCell>
                <VTableInfoCell collapsedDescription={usernameLabel}>
                  <TableItem>{data.user.login}</TableItem>
                </VTableInfoCell>
                <VTableInfoCell collapsedDescription='Created On'>
                  <TableItem>{format(data.user.created, 'M/D/YY')}</TableItem>
                </VTableInfoCell>
              </VTableRow>
            </TBody>
          </DetailTable>
          <DetailTable>
            <TBody>
              <VTableRow>
                <VTableHeaderCell>
                  <Label>Organization</Label>
                </VTableHeaderCell>
                <VTableHeaderCell>
                  <Label>Role</Label>
                </VTableHeaderCell>
                <VTableHeaderCell>
                  <Label>Permissions</Label>
                </VTableHeaderCell>
              </VTableRow>
              {Object.entries(userRoles).sort().map(([ org, roles ]) => (
                <React.Fragment key={org}>
                  {(roles || []).map(role => (
                    <UserTableRow
                      {...role}
                      organization={org}
                      key={`${org}-${role.id}`}
                    />
                  ))}
                </React.Fragment>
              ))}
            </TBody>
          </DetailTable>
        </TableWrap>
        {(data.user.roles && data.user.roles.length > 1) && (
          <HorizontalDivider />
        )}
      </>
    );
  }

  private readonly resendUserActivation: React.MouseEventHandler = async () => {
    if (this.state.submitting) { return; }

    try {
      this.setState({ submitting: true });

      const response = await this.props.resendActivation();

      if (!response) { throw new Error('Failed to resend user activation email'); }
      const { data, errors } = response;
      if (errors && errors.length) { throw new Error('Failed to resend user activation email'); }
      if (!data || !data.resendUserActivation) { throw new Error('Failed to resend user activation email'); }
      if (!!data.resendUserActivation.error) { throw new Error(data.resendUserActivation.error); }
      if (!data.resendUserActivation.success) { throw new Error('Failed to resend user activation email'); }

      this.setState({ submitting: false, showResendModal: true });
    } catch (e) {
      console.error(e);
      ErrorLogger.captureException(e);
      ErrorHandler.notify(`${e}`, 'User Activation', 'error');

      this.setState({ submitting: false });
    }
  }

  private readonly showStatusModal: React.MouseEventHandler = () => {
    this.setState({ showStatusModal: true });
  }

  private readonly showSettingsDrawer: React.MouseEventHandler = () => {
    this.setState({ showDrawer: true });
  }

  private readonly dismissModal: React.MouseEventHandler = () => {
    this.setState({
      showResendModal: false,
      showStatusModal: false,
      showDrawer: false,
    });
  }

  private readonly changeUserStatus: React.MouseEventHandler = () => {
    if (!this.props.data || !this.props.data.user) { return; }

    const status: MODIFIED_VALID_STATUS = statusToChangeMap[this.props.data.user.status];
    this._changeUserStatus(statusToActiveMap[status]);
  }

  private readonly _changeUserStatus: (active: boolean) => void = async active => {
    const userId: string | undefined = this.props.id || this.props.match.params.id;
    if (!this.props.data || !this.props.data.user || this.state.submitting || !userId) { return; }

    try {
      this.setState({ submitting: true });

      const response = await this.props.editUser({
        variables: {
          organization: this.props.currentOrg,
          id: userId,
          active,
          clientMutationId: '0',
        }
      });
      if (!response) { throw new Error('Failed to change user status'); }
      const { data, errors } = response;
      if (errors && errors.length) { throw new Error('Failed to change user status'); }
      if (!data || !data.editUserActive) { throw new Error('Failed to change user status'); }
      if (!!data.editUserActive.error) { throw new Error(data.editUserActive.error); }
      if (!data.editUserActive.success) { throw new Error('Failed to change user status'); }

      this.setState({ submitting: false, showStatusModal: false });

      const userData: GetUser | null = apolloClient.readQuery<GetUser, GetUserInput>({
        query: getUserQuery,
        variables: { id: userId, organization: this.props.currentOrg }
      });

      if (userData && userData.user) {
        apolloClient.writeQuery<GetUser, GetUserInput>({
          data: {
            ...userData,
            user: {
              ...userData.user,
              // if we're activating the user, we're not going to know what status they will have.
              // so we're using 'processing' here as a generic result.
              status: active ? PROCESSING_STATUS : INACTIVE_STATUS
            }
          },
          query: getUserQuery,
          variables: { id: userId, organization: this.props.currentOrg }
        });
      }

      const tableData: getUsers | null = readUsers(this.props.currentOrg);

      if (tableData && tableData.usersConnection.edges) {
        const usersList: Array<SelectionOnEdges | null> = tableData.usersConnection.edges
          .filter((x: SelectionOnEdges | null): x is SelectionOnEdges => !!x)
          .map(edge => {
            if (edge.node.id === userId) {
              edge.node.status = active ? PROCESSING_STATUS : INACTIVE_STATUS;
            }

            return edge;
          });

        apolloClient.writeQuery<getUsers, GetUsersInput>({
          data: {
            ...tableData,
            usersConnection: {
              ...tableData.usersConnection,
              edges: [
                ...usersList,
              ]
            }
          },
          query: getUserQuery,
          variables: { organization: this.props.currentOrg }
        });
      }
      ErrorHandler.notify(
        `Successfully changed the status of ${this.props.data.user.firstName} ${this.props.data.user.lastName}`,
        'User Status',
        'success');
    } catch (e) {
      console.error(e);
      ErrorLogger.captureException(e);
      ErrorHandler.notify(`${e}`, 'User Status', 'error');

      this.setState({ submitting: false, showStatusModal: false });
    }
  }
}
