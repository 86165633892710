export enum ModuleRoutes {
  reports = '/reports',
  policies = '/policies',
  psaes = '/psaes',
  helpdesk = '/helpdesk',
  jitbitHelpdesk = '/helpdesk-avant',
  userManagement = '/user-management',
}

export const PSAEsRoute: string = `${window.location.origin}/redirect/psaes`;
export const AmountHelpdeskRedirect = `${process.env.AMOUNT_HELPDESK_REDIRECT}`;
export const CspAmountRedirect = `${process.env.AMOUNT_CSP_REDIRECT}`;
export const ComplaintsRoute: string = `${window.location.origin}/redirect/complaints`;
export const CrmRoute: string = `${window.location.origin}/adminPortal`;
export const CIPRoute: string = `${window.location.origin}/redirect/cip`;
export const DisputesRoute: string = `${window.location.origin}/redirect/disputes`;
export const PaymentGatewayRoute: string = `${window.location.origin}/redirect/paymentGateway`;
export const MerchantsRoute = `${window.location.origin}/redirect/merchants`;
export const ConfigurationsRoute = `${window.location.origin}/redirect/configurations`;
export const KnowledgeBaseRedirect = `${process.env.KNOWLEDGE_BASE_URL}`;

export enum AppRoutes {
  lookerDash = '/looker-dash',
  myAccount = '/my-account',
  logout = '/logout'
}
