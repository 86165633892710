import * as React from 'react';
import { Query, QueryResult } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import ErrorHandler from '@avant/crm-frontend-utils/error';

import GetPageQuery, { GetPage, GetPageInput, SelectionOnNode as ISarInterface } from '../getPage.graphql';
import { ErrorLogger } from '../../../services/error';
import { ModuleRoutes } from '../../../../shared/routes';

type RouteProps = RouteComponentProps<{ queue: string }>;

interface INextInQueueProps extends RouteProps {
  data: Pick<QueryResult<GetPage, {}>, 'data' | 'loading' | 'refetch'>;
}

class NextInQueue extends React.Component<INextInQueueProps> {
  public componentWillMount (): void {
    this.tryToMove(this.props);
  }

  public componentWillUpdate (nextProps: INextInQueueProps): void {
    this.tryToMove(nextProps);
  }

  // tslint:disable-next-line prefer-function-over-method
  public render (): JSX.Element {
    return (<div className='spinner' />);
  }

  private readonly tryToMove: (props: INextInQueueProps) => void = async props => {
    if (!props.match.params.queue) {
      props.history.replace(ModuleRoutes.psaes);
    }
    if (props.data.loading) { return; }

    let nextItem: ISarInterface | undefined;

    try {
      const response: { data: GetPage } = await props.data.refetch();
      if (!response.data.sarsConnection.edges || !response.data.sarsConnection.edges[0]) {
        throw new Error('failed to get more PSAEs.');
      }

      nextItem = response.data.sarsConnection.edges[0].node;
    } catch (e) {
      console.error(e);
      ErrorLogger.captureException(e);
      ErrorHandler.notify('Failed to get more PSAEs', 'PSAEs', 'error');
    }

    const url: string = nextItem ? nextItem.id : '';
    props.history.replace(`${ModuleRoutes.psaes}/${url}`);
  }
}

const LiftedNextInQueue: React.FC<RouteProps> = props => (
  <Query<GetPage, GetPageInput>
    query={GetPageQuery}
    variables={{
      status: props.match.params.queue,
      first: 1
    }}
  >
    {({ data, loading, refetch }) => (
      <NextInQueue
        {...props}
        data={{
          data,
          loading,
          refetch
        }}
      />
    )}
  </Query>
);

const RoutedComp = withRouter(LiftedNextInQueue);

export default RoutedComp;
