import * as React from 'react';
import { QueryResult } from 'react-apollo';

import { GetPage, SelectionOnNode } from '../getPage.graphql';
import { SelectionOnMe } from '../../Context/me.graphql';
import { MeContext } from '../../Context';

import * as styles from './style.css';
import { SARListRow } from './Row';
import { IVisibleProps, withVisible } from './VisibleHOC';

export interface ISARListPropsBase extends React.ClassAttributes<HTMLDivElement> {
  // tslint:disable-next-line: no-reserved-keywords
  type: string;
  status: string;
}

type DataProps = Pick<QueryResult<GetPage, {}>, 'data' | 'loading' | 'refetch'>;
export type ISARListProps = ISARListPropsBase & DataProps & IVisibleProps;
export interface ISARListPropsLifted extends ISARListProps {
  partnerFilter?: string | null;
  setPartnerFilter: React.FormEventHandler<HTMLSelectElement>;
  me: SelectionOnMe;
}

interface ISARListState {
  partnerFilter: string | null;
}

const MAX_COLUMNS: number = 5;

const Listener: React.ComponentClass<IVisibleProps> = withVisible<IVisibleProps>(({ refFn, loadMore, loading }) => (
  <td
    onClick={loadMore}
    style={{ textAlign: 'center', cursor: 'pointer' }}
    colSpan={MAX_COLUMNS}
    ref={refFn}
    data-event='loadMore'
  >
    {loading ? <div className='spinner spinner-small' /> : 'Load More'}
  </td>
));

const willMount: (props: ISARListProps) => void = ({ refetch, loading, data }) => {
  if (loading || !data) { return; }

  if (data.sarsConnection.edges && data.sarsConnection.edges.length) {
    return;
  } else if (!data.sarsConnection.pageInfo.hasNextPage) {
    return;
  }

  void refetch();
};

const optionalPartnerFilter: (filterValue?: string | null) => (sar: SelectionOnNode) => boolean = filterValue => {
  if (!filterValue) { return () => true; }

  return ({ partner }) => partner === filterValue;
};

export const SARListNoLifecycle: React.FC<ISARListPropsLifted> = ({
  data,
  loading,
  loadMoreEntries,
  me,
  partnerFilter,
  setPartnerFilter,
  type
}) => {
  if (!data || (!data.sarsConnection && !loading)) {
    return <div />;
  }
  if (loading || !data.sarsConnection.edges) {
    return <div className='spinner' />;
  }
  if (!data.sarsConnection.edges.length) {
    return <div className={styles.noResults}>No Results</div>;
  }

  // tslint:disable-next-line: no-non-null-assertion
  const sars: SelectionOnNode[] = data.sarsConnection.edges.map(e => e!.node);
  const partners: Set<string> = sars.reduce((acc, sar) => acc.add(sar.partner), new Set<string>());
  const isSwitchable: boolean = me.switchablePartners.length > 1;
  const isPartnerReview: boolean = type === 'partnerApproval';

  return (
    <div className={styles.tableWrapper}>
      {
        partners.size > 1 && (
          <div className={styles.partnerFilter}>
            <select value={partnerFilter || ''} onChange={setPartnerFilter} data-event='partnerFilterSelect'>
              {/* tslint:disable-next-line: react-a11y-role-has-required-aria-props */}
              <option value=''>Show All Partners</option>
              {/* tslint:disable-next-line: react-a11y-role-has-required-aria-props */}
              {[...partners].map(p => <option value={p} key={p}>{p}</option>)}

            </select>
          </div>
        )
      }
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Name</th>
            {isSwitchable && <th>Partner</th>}
            <th>Type</th>
            <th>Status</th>
            <th>Date Created</th>
            {isPartnerReview && <th>Date Escalated To Partner</th>}
            <th>Last Update</th>
            <th>Last Viewed By</th>
            <th>Last Viewed At</th>
          </tr>
        </thead>
        <tbody>
          {
            sars.filter(optionalPartnerFilter(partnerFilter))
              .map((sar, rowIndex) =>
                <SARListRow
                  key={sar.id}
                  isPartnerReview={isPartnerReview}
                  rowIndex={rowIndex}
                  showPartner={isSwitchable}
                  {...sar}
                />
              )
          }
        </tbody>
        {
          data.sarsConnection.pageInfo.hasNextPage && (
            <tfoot>
              <tr>
                {/* TS#13288 */}
                <Listener loadMoreEntries={loadMoreEntries} />
              </tr>
            </tfoot>
          )
        }
      </table>
    </div>
  );
};

export class SARList extends React.Component<ISARListProps, ISARListState> {
  public context!: React.ContextType<typeof MeContext>;

  public state: ISARListState = {
    partnerFilter: null
  };

  public componentWillMount (): void {
    willMount(this.props);
  }

  public componentWillUpdate (): void {
    willMount(this.props);
  }

  public render (): JSX.Element {
    return (
      <SARListNoLifecycle
        me={this.context}
        partnerFilter={this.state.partnerFilter}
        setPartnerFilter={this.setPartnerFilter}
        {...this.props}
      />
    );
  }

  private readonly setPartnerFilter: (e: React.FormEvent<HTMLSelectElement>) => void = e => {
    this.setState({ partnerFilter: e.currentTarget.value });
  }
}

SARList.contextType = MeContext;
