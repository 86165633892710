import {
  SVGIcon
} from '@amount/frontend-components';
import styled, { css } from 'styled-components';

const darkItemStyles = css`
  &:hover {
    color: ${({ theme: { link }}) => link.colorLinkHover};
  }
`;

const lightItemStyles = css`
  &:hover {
    background: ${({ theme }) => theme.colorSlate5};
  }
`;

export const Menu = styled.div`
  min-width: 8.5em;
  text-align: left;
  background-color: ${({ theme }) => theme.colorWhite};
  background-clip: padding-box;
  box-shadow: 0 16px 48px 0 rgba(0,0,0,0.1);
  outline: none;
  z-index: 10;
`;

export const MenuItemContain = styled.div`
  display: flex;
  align-items: center;
  padding: 1.25em 1em 1em;
  cursor: pointer;
  color: ${({ theme: { text } }) => text.colorText};
  background: ${({ theme: { card } }) => card.colorBackground};

  ${({ theme }) => theme.name === 'amountDark' ? darkItemStyles : lightItemStyles}
`;

export const MenuItemLabel = styled.span`
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.5;
  text-align: inherit;
  max-width: 14em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const MenuIcon = styled(SVGIcon)`
  svg {
    width: 12px;
    height: 12px;
    margin-right: 1em;
  }
`;
