import ErrorHandler from '@avant/crm-frontend-utils/error';
import * as React from 'react';
import { DrawerClose, DrawerItem, SVGIcon } from '@amount/frontend-components';

import Modal, { IModalProps } from '../../Modal';
import { VALID_S3_TYPES } from '../../../../shared/reports/constants';
import { ErrorLogger } from '../../../services/error';

import { drawerMobilePadding } from './common';

export interface ILiftedActionDrawer extends IModalProps {
  // tslint:disable-next-line: no-reserved-keywords
  type: VALID_S3_TYPES;
  prefix: string;
  uploadingFile: boolean;
  refetch (): void;
  fileUpload (): void;
}

interface IActionDrawer extends ILiftedActionDrawer {
  showCreateModal: React.MouseEventHandler;
}

interface INewFolderState {
  submitting: boolean;
  folderName?: string;
}

export class ActionDrawer extends React.Component<IActionDrawer, INewFolderState> {
  public state: INewFolderState = {
    submitting: false,
  };

  public render (): JSX.Element {
    return (
      <>
        <Modal
          show={this.props.show}
          close={this.props.close}
          drawer={true}
          padding={drawerMobilePadding}
        >
          <DrawerClose onClick={this.props.close}>
            <SVGIcon icon='close' />
          </DrawerClose>
          <DrawerItem onClick={this.handleNewFileClick}>Add File</DrawerItem>
          <DrawerItem onClick={this.props.showCreateModal}>Create Folder</DrawerItem>
        </Modal>
      </>
    );
  }

  private readonly handleNewFileClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    if (this.props.uploadingFile) {
      return;
    }
    try {
      e.preventDefault();
      if (!this.props.fileUpload) {
        throw new Error('Missing mutation!');
      }
      this.props.fileUpload();
      this.props.close(e);
    } catch (err) {
      console.error(err);
      ErrorLogger.captureException(err);
      ErrorHandler.notify('Failed to upload file', 'New File', 'error');
    }
  }
}
