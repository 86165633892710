import { MutationFn } from 'react-apollo';

import { SelectionOnSubmitHelpdeskTicket, SubmitHelpdeskTicket, SubmitHelpdeskTicketInput  } from '../queries/submitHelpdeskTicket.graphql';

import { IRequestIds } from './interfaces';

const submitRequest: (
  submitTicket: MutationFn<SubmitHelpdeskTicket, SubmitHelpdeskTicketInput>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any,
  requestDetails: IRequestIds
) => Promise<SelectionOnSubmitHelpdeskTicket> = async (submitTicket, payload, requestDetails) => {
  // if (sectionNote.trim().length < 1) {
  //   return Promise.resolve({ submitting: false, error: true, message: 'Please add a ticket note.' });
  // }
  // Check if the proper fields have data
  // Check if the ticket has been edited

  const requestFieldValues: Array<{ key: string; value: string }> = [];

  Object.keys(payload).map(key => {
    if (payload[key] || payload[key] === 0) {
      const value = JSON.stringify(payload[key]);
      requestFieldValues.push({ key, value });
    }
  });

  const variables: SubmitHelpdeskTicketInput = { ...requestDetails, requestFieldValues, clientMutationId: '0' };
  const response = await submitTicket({ variables });

  if (!response) {
    throw Error('Ticket submission failed. Missing response.');
  }

  const { data } = response;

  if (!data || !data.submitHelpdeskTicket) {
    throw Error('Ticket submission failed. Missing data.');
  }

  return data.submitHelpdeskTicket;
};

export default submitRequest;
