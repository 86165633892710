import { GraphQLFieldResolver, GraphQLResolveInfo } from 'graphql';

import {
  EDIT_REPORTS,
  PARTNER_APPROVED_GENERATE_JOB,
  SAR_TAB_PERMISSIONS,
  VIEW_ADMIN_HELPDESK,
  VIEW_BPO_SUPPORT_HELPDESK,
  EDIT_BPO_SUPPORT_HELPDESK,
  UPDATE_BPO_SUPPORT_HELPDESK,
  VIEW_CSP,
  VIEW_HELPDESK,
  VIEW_PSAE,
  VIEW_USERS,
} from '../../shared/config/permissions';
import { IRequest } from '../database/dataloader';
import User from '../models/user';
import { ISessionUser } from '../../shared/config/user';
import AvantWarden from '../services/ability';
import { SARPermissionCheckObject } from '../services/warden';
import { ISARMetadata } from '../models/suspiciousActivityReport';

type TrueOrNever = true | never;

// can add some context later
export const PermissionError: Error = new Error('Permission Denied');
export const throwError: (error?: Error) => never = (error = PermissionError) => { throw error; };

// if false: throws; else: identity fn
export const throwIfFalse: (value: boolean) => TrueOrNever = value => value || throwError();

// tslint:disable-next-line: no-any
type MutationFn<TArgs> = (object: TArgs, ctx: IRequest, info: GraphQLResolveInfo) => Promise<any> | any;
// tslint:disable-next-line: no-any
export type Resolver = GraphQLFieldResolver<any, IRequest>;
type Signature = (...permissionFns: Resolver[]) => (fn: Resolver) => Resolver | never;
type MutationSignature = <TArgs extends {}>(...permissionFns: Resolver[]) => (fn: MutationFn<TArgs>) => MutationFn<TArgs>;

export const applyPermissions: Signature = (...fns) => wrap => (src, args, ctx, info) => (
  fns.every(fn => fn(src, args, ctx, info)) ? wrap(src, args, ctx, info) : throwError()
);

/**
 * Try all permission functions:
 *  if any are true:
 *    run the wrapped function
 *  else:
 *    throw {@link PermissionError}
 *
 * @returns GraphQL Resolver or Never
 * @throws {PermissionError} Permission Error is thrown {@link PermissionError}
 */
export const orPermissions: Signature = (...fns) => wrap => (src, args, ctx, info) =>
  fns.some(fn => {
    try {
      return fn(src, args, ctx, info);
    } catch (e) {
      return false;
    }
  })
    ? wrap(src, args, ctx, info)
    : throwError();

export const applyPermissionsMutation: MutationSignature = (...fns) => wrap => (args, ctx, info) => (
  // tslint:disable-next-line: no-any
  applyPermissions(...fns)((_, args$, ctx$, info$) => wrap(args$ as any, ctx$, info$))(null, args, ctx, info)
);

export const orPermissionsMutation: MutationSignature = (...fns) => wrap => (args, ctx, info) => (
  // tslint:disable-next-line: no-any
  orPermissions(...fns)((_, args$, ctx$, info$) => wrap(args$ as any, ctx$, info$))(null, args, ctx, info)
);

export const checkForViewUsers: (user: ISessionUser) => boolean = user => AvantWarden.check({ user, permission: VIEW_USERS, obj: null });

export const requireViewUsers: (user: ISessionUser) => TrueOrNever = user => throwIfFalse(
  checkForViewUsers(user)
);

export const requireViewUsersGQL: Resolver = (_source, _args, { user }) => requireViewUsers(user);

export const checkForViewHelpdesk: (user: ISessionUser) => boolean = user => AvantWarden.check({
  user,
  permission: VIEW_HELPDESK,
  obj: null
});

export const requireViewHelpdesk: (user: ISessionUser) => TrueOrNever = user => throwIfFalse(checkForViewHelpdesk(user));

export const requireViewHelpdeskGQL: Resolver = (_source, _args, { user }) => requireViewHelpdesk(user);

export const checkForViewSars: (user: ISessionUser) => boolean = user => AvantWarden.check({ user, permission: VIEW_PSAE, obj: null });

export const requireViewSARs: (user: ISessionUser) => TrueOrNever = user => throwIfFalse(
  checkForViewSars(user)
);

export const requireViewSARsGQL: Resolver = (_source, _args, { user }) => requireViewSARs(user);

export const canViewSAR: (user: ISessionUser) => (sar: SARPermissionCheckObject) => boolean = user => sar => (
  checkForViewSars(user) && (
    AvantWarden.check({
      user,
      obj: sar,
      permission: SAR_TAB_PERMISSIONS[sar.status]
    })
  )
);

// tslint:disable-next-line only-arrow-functions
export function filterSAR<T extends { status: string; partner: string; metadata: ISARMetadata }> (user: User): (sar: T) => T | never {
  return obj => {
    if (canViewSAR(user)(obj)) {
      return obj;
    } else {
      throw PermissionError;
    }
  };
}

// tslint:disable-next-line only-arrow-functions
export function filterSARs<T extends { status: string; partner: string; metadata: ISARMetadata }> (user: User): (sars: T[]) => T[] {
  const partialApplication: (sars: T) => boolean = canViewSAR(user);

  return sars => sars.filter(partialApplication);
}

export const requireReportAdminGQL: Resolver = (_source, { key }, { user }) => AvantWarden.check({
  user,
  permission: EDIT_REPORTS,
  obj: key
});

export const requireGardenCredentials: (user: User) => TrueOrNever = user => throwIfFalse(
  AvantWarden.check({
    user,
    permission: PARTNER_APPROVED_GENERATE_JOB,
    obj: null
  })
);

export const requirePortalCredentials: (user: User) => TrueOrNever = user => throwIfFalse(
  AvantWarden.check({
    user,
    permission: VIEW_CSP,
    obj: null
  })
);

// Garden Tasks

export const checkGardenPermissions: (user: User) => TrueOrNever = user => throwIfFalse(
  requireGardenCredentials(user)
);

export const checkGardenPermissionsGQL: Resolver = (_src, _args, { user }) => (
  checkGardenPermissions(user)
);

export interface IGardenOutput {
  id: string;
  value?: string;
}

export interface IGardenStatusResponse {
  id: number;
  state: string;
  author: string;
  inputs: {
    scalars: {
      include_narrative: boolean;
      sar_file_name: string;
      list_of_sar_ids: string;
    };
  };
  outputs: {
    scalars: {
      error_message: string;
      sar_url_link: string;
    };
  };
  started_at: string;
  ended_at: string;
  duration: number;
  task_slug: string;
}

export const checkGardenStatusPermissions: (result: IGardenStatusResponse, user: User) => IGardenStatusResponse | false =
  (result, user) => (
    checkGardenPermissions(user) && result
  );

export const checkIfHelpdeskAdmin: (user: ISessionUser) => boolean = user =>
  AvantWarden.check({ user, permission: VIEW_ADMIN_HELPDESK, obj: null });

// There can be a future scnerio when there are more than one Helpdesk partners hence adding this method to handle multiple helpDesk partners
export const partnerHelpdesk: (user: ISessionUser) => boolean = user =>
  user.organization == 'radius';

export const partnerHelpdeskAdmin: (user: ISessionUser) => boolean = user =>
  user.permissions.includes(EDIT_BPO_SUPPORT_HELPDESK) && user.permissions.includes(VIEW_BPO_SUPPORT_HELPDESK) && user.permissions.includes(UPDATE_BPO_SUPPORT_HELPDESK) && partnerHelpdesk(user);

export const partnerHelpdeskSubAdmin: (user: ISessionUser) => boolean = user =>
  user.permissions.includes(EDIT_BPO_SUPPORT_HELPDESK) && user.permissions.includes(VIEW_BPO_SUPPORT_HELPDESK) && partnerHelpdesk(user);
