import * as React from 'react';
import { Card, Input, Pill, Spinner } from '@amount/frontend-components';
import styled from 'styled-components';

const MINIMUM_INPUT_LENGTH = 3;
const TagInputWrapper = styled.div`
  position: relative;
  display: flex;
  transition: border-color ease-in-out 0.15s;
  border-bottom: 2px solid ${({ theme: { input } }) => input.colorBorder};

  &:hover,
  &:focus {
    border: none;
    border-bottom: 2px solid ${({ theme: { input } }) => input.colorFocus};
  }
`;

const InvisibleTagInput = styled(Input)`
  border: none;
  width: min-content;
  display: inline-block;

  &:focus, &:hover {
    border: none;
  }
`;

const TagModal = styled(Card)`
  position: absolute;
  top: calc(100% + .5em);
  z-index: 5;
  width: 100%;
  padding: .25em;
  white-space: normal;
`;

const ModalButton = styled.button`
  margin: .5em;
  width: calc(100% - .5em * 2);
`;

const TagPill = styled(Pill)`
  margin-top: .5em;
  margin-bottom: .5em;
  white-space: nowrap;
`;

const PillButton = styled.button`
  margin-left: .75em;
`;

export type TagMap = Map<string, string>;

export interface ITagInputProps {
  id: string;
  loading?: boolean;
  options?: TagMap;
  tagAddable?: boolean;
  value: string[];
  hideTags?: boolean;
  placeholder?: string;
  serviceDeskId: string;
  onChange (name: string, tags: string[]): void;
  onSearch? (query: string): void;
}

interface IState {
  input: string;
  possibleTags: TagMap;
}

export default class TagInput extends React.PureComponent<ITagInputProps, IState> {
  public state: IState = { input: '', possibleTags: new Map() };
  private readonly inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  public render () {
    const { value = [], options = new Map(), loading = false, tagAddable = false, hideTags = false, placeholder } = this.props;
    const doesTagExist = options.has(this.state.input) || value.includes(this.state.input);
    const uniqueOptions: string[][] = Array.from(options).filter((tag: string[]) => !value.includes(tag[0]));
    const tagArray = value.map(key => ({ key, value: this.state.possibleTags.get(key) || 'Tag Not Found' }));

    return (
      <TagInputWrapper onClick={this.focusInput}>
        <div>
          {!hideTags && tagArray.map(tag => (
            <TagPill key={tag.key} pillColor='inactive'>
              <span>{tag.value}</span>
              <PillButton value={tag.key} onClick={this.onRemoveTag}>X</PillButton>
            </TagPill>
          ))}
          <InvisibleTagInput
            name='input'
            value={this.state.input}
            onChange={this.handleChange}
            ref={this.inputRef}
            autoComplete='off'
            placeholder={placeholder}
          />
        </div>
        {this.state.input && this.state.input.length >= MINIMUM_INPUT_LENGTH && (
          <TagModal>
            {!doesTagExist && tagAddable && (
              <ModalButton type='button' onClick={this.onAddTag} disabled={!this.state.input} value={this.state.input}>
                Add new tag: '{this.state.input}'
              </ModalButton>
            )}
            {loading && <Spinner small={true} />}
            {uniqueOptions.map((s: string[]) =>
             <ModalButton type='button' onClick={this.onAddTag} key={s[0]} value={s[0]}>{s[1]}</ModalButton>)}
          </TagModal>
        )}
      </TagInputWrapper>
    );
  }

  private readonly handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.currentTarget.value;

    this.setState({ input: query });

    if (this.props.onSearch && query.length >= MINIMUM_INPUT_LENGTH) {
      this.props.onSearch(query);
    }
  }

  private readonly onAddTag = (e: React.MouseEvent<HTMLButtonElement>): void => {
    this.props.onChange(this.props.id, [...this.props.value, e.currentTarget.value]);
    this.setState({ input: '' });

    if (!this.state.possibleTags.has(e.currentTarget.value)) {
      const options: TagMap = this.props.options || new Map();
      const tagValue: string = options.get(e.currentTarget.value) || e.currentTarget.value;
      const mergedMap: TagMap = new Map([...this.state.possibleTags, [e.currentTarget.value, tagValue]]);
      this.setState({ possibleTags: mergedMap });
    }
  }

  private readonly onRemoveTag: React.MouseEventHandler<HTMLButtonElement> = ({ currentTarget: { value }}) => {
    const filteredTags = this.props.value.filter(s => s !== value);
    this.props.onChange(this.props.id, filteredTags);
  }

  private readonly focusInput = () => this.inputRef.current && this.inputRef.current.focus();
}
