import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ErrorHandler from '@avant/crm-frontend-utils/error';
import Events from '@avant/crm-frontend-utils/events';
import { media } from '@amount/frontend-components';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { PARTNER_CHANGED_EVENT } from '../../../shared/config/constants';
import { ModuleRoutes } from '../../../shared/routes';
import { Spinner } from '../Spinner';
import { ErrorLogger } from '../../services/error';

import { removeLookerType } from './Presentational/FileBase';

const StyledIFrame = styled.iframe`
  height: calc( 100vh - 4em);

  ${media.small`
    height: calc( 100vh - 5em);
  `}
`;

interface IState {
  lookerUrl?: string;
  loading: boolean;
}

class EmbeddedLookerIframe extends React.PureComponent<RouteComponentProps<{ file: string }>, IState> {
  public state: IState = { loading: true };

  public componentDidMount () {
    void this.getLookerDashUrl();
    Events.subscribe(PARTNER_CHANGED_EVENT, this.redirectToReports);
  }

  public componentWillUnmount (): void {
    Events.unsubscribe(PARTNER_CHANGED_EVENT, this.redirectToReports);
  }

  public render (): JSX.Element | null {
    if (this.state.loading) {
      return <Spinner />;
    }
    const dashName: string = removeLookerType(this.props.match.params.file);
    const title: string = dashName.length ? `${dashName} Dashboard` : 'Dashboard';

    return (
      <>
        <Helmet>
          <title>{title} · Partner Portal</title>
        </Helmet>
        <StyledIFrame
          src={this.state.lookerUrl}
          // tslint:disable-next-line:react-iframe-missing-sandbox
          sandbox='allow-downloads allow-scripts allow-same-origin allow-forms allow-popups'
          width='100%'
          height='100%'
          frameBorder='none'
        />
      </>
    );
  }

  private readonly getLookerDashUrl: () => Promise<void> = async () => {
    this.setState({ loading: true });
    const pathname = this.props.match.params.file;
    const location = this.props.location.search;
    const filePath = decodeURIComponent(pathname);

    fetch(`/api/report-looker/${filePath}${location}`, { credentials: 'same-origin', headers: { Accept: 'application/json' } })
      .then(response => response.json())
      .then(json => {
        this.setState({ lookerUrl: json.lookerUrl, loading: false });
      })
      .catch(err => {
        ErrorLogger.captureException(err);
        this.setState({ loading: false });
        ErrorHandler.notify('Failed to retrieve looker url', 'Looker Fetch', 'error');
      });
  };

  private readonly redirectToReports: () => void = () => {
    this.props.history.push(ModuleRoutes.reports);
  };
}

export default EmbeddedLookerIframe;
