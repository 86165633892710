import * as React from 'react';
import { Query } from 'react-apollo';
import { Headline } from '@amount/frontend-components';
import styled from 'styled-components';
import { camelCase } from 'lodash-es';

import { Card } from '../../Card';
import { Spinner } from '../../Spinner';
import GetRequestCategoriesQuery, {
  GetRequesttypes, GetRequesttypesInput, SelectionOnRequestTypes, SelectionOnValues
} from '../queries/getRequestCategories.graphql';
import { PermissionedComponent } from '../../PermissionedComponent';
import {
  SUBMIT_ACCESS_REQUEST_HELPDESK,
  SUBMIT_BUG_REPORT_HELPDESK,
  SUBMIT_CHANGE_REQUEST_HELPDESK,
  SUBMIT_DATA_SET_SPLIT_HELPDESK,
  SUBMIT_DIRECT_MAIL_HELPDESK,
  SUBMIT_ESCALATION_HELPDESK,
  SUBMIT_GENERAL_INQUIRY_HELPDESK,
  VIEW_HELPDESK,
} from '../../../../shared/config/permissions';

import RequestTypeList, { RequestLink } from './RequestTypeList';

export const MarginHeadline = styled(Headline)`
  margin: 1.5em 0em 0.5em 0em;
`;

const FullWidthButton = styled.button`
  width: 100%;
`;

const RequestListContain = styled.div`
  margin: 1em 2em;
`;

// tslint:disable: object-literal-key-quotes
// These ids come from https://linearft.atlassian.net/rest/servicedeskapi/servicedesk/22/requesttypegroup
// for a particular servicedesk, e.g. ^ ASP(id 22)
//
export const permissionsConfig = {
  82: SUBMIT_CHANGE_REQUEST_HELPDESK,
  84: SUBMIT_BUG_REPORT_HELPDESK,
  83: SUBMIT_ACCESS_REQUEST_HELPDESK,
  81: SUBMIT_GENERAL_INQUIRY_HELPDESK,
  85: SUBMIT_DATA_SET_SPLIT_HELPDESK,
  86: SUBMIT_DIRECT_MAIL_HELPDESK,
  65: VIEW_HELPDESK,
  75: VIEW_HELPDESK,
  76: VIEW_HELPDESK,
  80: SUBMIT_ESCALATION_HELPDESK,
};
// tslint:enable: object-literal-key-quotes

interface IRequestGroup {
  category: SelectionOnValues;
  expand: boolean;
  types: SelectionOnRequestTypes[];
  setDesk: React.FormEventHandler<HTMLButtonElement>;
}

const RequestGroup: React.FC<IRequestGroup> = ({ types, category, setDesk, expand }) => {
  if (types.length === 1) {
    return (
      <li>
        <RequestLink request={types[0]} />
        <RequestListContain />
      </li>
    );
  }

  return (
    <React.Fragment key={category.id}>
      <FullWidthButton onClick={setDesk} value={category.id}>
        <Card icon='minus' name={category.name} data-event={`${camelCase(category.name)}Categories`} />
      </FullWidthButton>
      <RequestListContain>
        {expand && <RequestTypeList types={types} />}
      </RequestListContain>
    </React.Fragment>
  );
};

interface ILiftedProps {
  serviceDeskId: string;
  serviceDeskName?: string;
}

interface IProps extends ILiftedProps {
  categories: SelectionOnValues[];
  requestTypes: SelectionOnRequestTypes[];
}

interface IState {
  serviceDesk: Map<string, boolean>;
}

class HelpdeskCategories extends React.PureComponent<IProps, IState> {
  public state: IState = { serviceDesk: new Map<string, boolean>() };

  public render () {
    const { categories, requestTypes, serviceDeskName } = this.props;
    const { serviceDesk } = this.state;

    console.log(' --- categories:');
    console.log(categories);

    return (
      <>
        {serviceDeskName && <MarginHeadline scale='medium'>{serviceDeskName}</MarginHeadline>}
        {categories.sort((a, b) => a.name.localeCompare(b.name)).map((category: SelectionOnValues) => (
          <PermissionedComponent allowedPermissions={[permissionsConfig[category.id]]} key={category.id}>
            <RequestGroup
              category={category}
              types={requestTypes.filter(r => r.groupIds.includes(category.id))}
              setDesk={this.setServiceDesk}
              expand={!!serviceDesk.get(category.id)}
            />
          </PermissionedComponent>
        ))}
      </>
    );
  }

  private readonly setServiceDesk: React.FormEventHandler<HTMLButtonElement> = ({ currentTarget: { value } }) => {
    const copiedState: Map<string, boolean> = new Map([...this.state.serviceDesk]);
    const show: boolean = copiedState.get(value) || false;
    this.setState({ serviceDesk: copiedState.set(value, !show) });
  };
}

const LiftedList: React.FC<ILiftedProps> = props => (
  <Query<GetRequesttypes, GetRequesttypesInput> query={GetRequestCategoriesQuery} variables={{ id: props.serviceDeskId }}>
    {({ data, loading }) => loading ? <Spinner withMargin={true} /> : (
      <HelpdeskCategories
        {...props}
        categories={data && data.requestGroupList && data.requestGroupList.values || []}
        requestTypes={data && data.requestGroupList && data.requestTypes || []}
      />
    )}
  </Query>
);

export default LiftedList;
