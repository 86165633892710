import * as React from 'react';
import { Query } from 'react-apollo';
import isBoolean from 'lodash-es/isBoolean';

import { table, tableWrapper } from '../List/style.css';
import { GARDEN_END_STATES } from '../../../../shared/config/constants';

import GetJobStatus, {
  GetGardenStatus as getGardenStatus,
  GetGardenStatusInput,
  SelectionOnOutputs as SelectionOnGardenOutput
} from './getJobStatus.graphql';

const POLL_INTERVAL: number = 2000;

interface IProps {
  instanceIds: Set<string>;
  removeInstance (id: string): void;
}

interface ILiftedInstanceProps {
  id: string;
  removeInstance (id: string): void;
}

interface IInstanceProps extends ILiftedInstanceProps {
  jobStatus: {
    data: getGardenStatus | undefined;
    loading: boolean;
    refetch (): void;
  };
}

const formatBoolean: (bool?: boolean | null) => string = bool => isBoolean(bool) ? bool.toString() : '';

interface IDownloaderProps {
  outputs: SelectionOnGardenOutput[];
  clickFn: React.MouseEventHandler<HTMLAnchorElement>;
}
class Downloader extends React.PureComponent<IDownloaderProps> {
  public render () {
    const batchDownload = this.generateDownloadAnchor('sar_url_link', 'Download Batch');
    const xmlDownload = this.generateDownloadAnchor('sar_url_link_xml', 'Download Xml');

    return (
      <>
        {batchDownload}
        {xmlDownload}
      </>
    );
  }

  private readonly generateDownloadAnchor: (outputId: string, downloadText: string) => React.ReactNode = (outputId, downloadText) => {
    const output: SelectionOnGardenOutput | undefined = this.props.outputs.find(({ id }) => id === outputId);

    if (!output || !output.value) { return null; }
    const href: string = output.value;
    if (!href.includes('http')) { return <span>{href}</span>; }

    return (
      <a href={href} onClick={this.props.clickFn} download={true}>
        {downloadText}
      </a>
    );
  }
}

const LoadingDetail: React.FC = () => (
  <>
    <td />
    <td />
    <td />
  </>
);
const FailedDetail: React.FC = () => (
  <>
    <td>Failed</td>
    <td>False</td>
    <td />
  </>
);

class Instance extends React.PureComponent<IInstanceProps> {
  public componentDidMount (): void {
    this.pollForResult();
  }

  public render (): JSX.Element {
    return (
      <tr>
        <td>{this.props.id}</td>
        {this.props.jobStatus.loading ? (
          <LoadingDetail />
        ) : (
          <>
            {this.props.jobStatus.data && this.props.jobStatus.data.gardenStatus ? (
              <>
                <td>{this.props.jobStatus.data.gardenStatus.state}</td>
                <td>{this.props.jobStatus.data && formatBoolean(this.props.jobStatus.data.gardenStatus.success)}</td>
                <td>
                  {this.props.jobStatus.data &&
                    <Downloader outputs={this.props.jobStatus.data.gardenStatus.outputs} clickFn={this.triggerDownload} />
                  }
                </td>
              </>
            ) : (
              <FailedDetail />
            )}
          </>
        )}
      </tr>
    );
  }

  private readonly pollForResult = () => {
    setTimeout(this.refetch, POLL_INTERVAL);
  }

  private readonly refetch = () => {
    if (
      this.props.jobStatus.data &&
      this.props.jobStatus.data.gardenStatus &&
      !GARDEN_END_STATES.has(this.props.jobStatus.data.gardenStatus.state)
    ) {
      this.props.jobStatus.refetch();
      this.pollForResult();
    }
  }

  private readonly triggerDownload: React.MouseEventHandler<HTMLAnchorElement> = () => {
    this.props.removeInstance(this.props.id);
  }
}

const LiftedInstance: React.FC<ILiftedInstanceProps> = ({id, ...rest}) => (
  <Query<getGardenStatus, GetGardenStatusInput>
    query={GetJobStatus}
    variables={{ instanceId: id }}
  >
    {({ data, loading, refetch }) => <Instance {...rest} id={id} jobStatus={{ data, loading, refetch}} />}
  </Query>
);

const NUM_COLUMNS: number = 4;

const Instances: React.FC<IProps> = props => (
  <div className={tableWrapper}>
    <table className={table}>
      <thead>
        <tr>
          <th>Job ID</th>
          <th>Status</th>
          <th>Success</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {[...props.instanceIds].map(id =>
          <LiftedInstance
            key={id}
            id={id}
            // tslint:disable-next-line: no-unbound-method */}
            removeInstance={props.removeInstance}
          />
        )}
        <tr>
          <td colSpan={NUM_COLUMNS} style={{textAlign: 'center'}}>
            IF YOU NAVIGATE AWAY FROM THIS PAGE, YOU WILL NOT BE ABLE TO DOWNLOAD THE FILE
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default Instances;
