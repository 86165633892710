import * as React from 'react';
import {
  ActionButton,
  Spinner,
  SVGIcon
} from '@amount/frontend-components';

import { ButtonLabel } from './CommonComponents/ButtonLabel';

interface IUploadFileProps {
  uploadingFile: boolean;
  uploadFile (): void;
}

class UploadFile extends React.PureComponent<IUploadFileProps> {
  public render (): JSX.Element {
    return (
      <>
        {this.props.uploadingFile && <Spinner small={true} />}
        <ActionButton
          onClick={this.handleClick}
          data-event='addFiles'
        >
          <SVGIcon icon='upload' />
          <ButtonLabel>Add File</ButtonLabel>
        </ActionButton>
      </>
    );
  }

  private readonly handleClick: React.FormEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    if (this.props.uploadingFile) { return; }
    this.props.uploadFile();
  }
}

export default UploadFile;
