import { ClusterOptions as PusherOptions } from 'pusher';

import {
  AMOUNT_PERMISSION_KEY,
  AVANT_PERMISSION_KEY,
  BBVA_PERMISSION_KEY,
  ELOAN_PERMISSION_KEY,
  HSBC_PERMISSION_KEY,
  PNC_PERMISSION_KEY,
  POLLEN_STREET_PERMISSION_KEY,
  REGIONS_PERMISSION_KEY,
  TD_PERMISSION_KEY,
} from './permissions';
import { SwitchablePartner } from './partners';

export { PusherOptions as IPusherConfig };

export const PusherConfigOptions: Map<SwitchablePartner, Partial<PusherOptions>> = new Map([
  [
    AMOUNT_PERMISSION_KEY,
    {
      appId: process.env.PUSHER_APP_ID_AMOUNT,
      key: process.env.PUSHER_APP_KEY_AMOUNT,
      secret: process.env.PUSHER_APP_SECRET_AMOUNT,
      encrypted: true,
      cluster: process.env.PUSHER_ENV,
    }
  ],
  [
    AVANT_PERMISSION_KEY,
    {
      appId: process.env.PUSHER_APP_ID,
      key: process.env.PUSHER_APP_KEY,
      secret: process.env.PUSHER_APP_SECRET,
      encrypted: true,
      cluster: process.env.PUSHER_ENV,
    }
  ],
  [
    BBVA_PERMISSION_KEY,
    {
      appId: process.env.PUSHER_APP_ID_BBVA,
      key: process.env.PUSHER_APP_KEY_BBVA,
      secret: process.env.PUSHER_APP_SECRET_BBVA,
      encrypted: true,
      cluster: process.env.PUSHER_ENV,
    },
  ],
  [
    ELOAN_PERMISSION_KEY,
    {
      appId: process.env.PUSHER_APP_ID_ELOAN,
      key: process.env.PUSHER_APP_KEY_ELOAN,
      secret: process.env.PUSHER_APP_SECRET_ELOAN,
      encrypted: true,
      cluster: process.env.PUSHER_ENV,
    },
  ],
  [
    HSBC_PERMISSION_KEY,
    {
      appId: process.env.PUSHER_APP_ID_HSBC,
      key: process.env.PUSHER_APP_KEY_HSBC,
      secret: process.env.PUSHER_APP_SECRET_HSBC,
      encrypted: true,
      cluster: process.env.PUSHER_ENV,
    },
  ],
  [
    PNC_PERMISSION_KEY,
    {
      appId: process.env.PUSHER_APP_ID_PNC,
      key: process.env.PUSHER_APP_KEY_PNC,
      secret: process.env.PUSHER_APP_SECRET_PNC,
      encrypted: true,
      cluster: process.env.PUSHER_ENV,
    }
  ],
  [
    POLLEN_STREET_PERMISSION_KEY,
    {
      appId: process.env.PUSHER_APP_ID_UK,
      key: process.env.PUSHER_APP_KEY_UK,
      secret: process.env.PUSHER_APP_SECRET_UK,
      encrypted: true,
      cluster: process.env.PUSHER_ENV_UK,
    },
  ],
  [
    REGIONS_PERMISSION_KEY,
    {
      appId: process.env.PUSHER_APP_ID_REGIONS,
      key: process.env.PUSHER_APP_KEY_REGIONS,
      secret: process.env.PUSHER_APP_SECRET_REGIONS,
      encrypted: true,
      cluster: process.env.PUSHER_ENV_REGIONS,
    },
  ],
  [
    TD_PERMISSION_KEY,
    {
      appId: process.env.PUSHER_APP_ID_TD,
      key: process.env.PUSHER_APP_KEY_TD,
      secret: process.env.PUSHER_APP_SECRET_TD,
      encrypted: true,
      cluster: process.env.PUSHER_ENV,
    },
  ],
]);
