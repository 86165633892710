import * as React from 'react';
import { MutationFn } from 'react-apollo';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import styled from 'styled-components';

import { FILE_KEY, isLookerDashboard, VALID_S3_TYPES } from '../../../../shared/reports/constants';
import { removeParentDirectories } from '../../../../shared/reports/util';
import {
  Menu,
  MenuIcon,
  MenuItemContain,
  MenuItemLabel,
 } from '../../CommonComponents';
import { DeleteFile, DeleteFileInput } from '../queries/deleteFile.graphql';
import { MoveFile, MoveFileInput } from '../queries/moveFile.graphql';
import { SelectionOnFiles } from '../queries/fileList.graphql';
import LiftedFileDrawer from '../LiftedFileDrawer';

import { DeleteModal, RenameModal } from './Modals';
import FileBase from './FileBase';
import {
  getFileUrl,
  IProps,
} from './common';

const StyledContextMenu = styled(ContextMenu)`
  z-index: 1;
`;

interface IFileComponentProps extends SelectionOnFiles, Pick<IProps, 'root'> {
  canEdit: boolean;
  // tslint:disable-next-line: no-reserved-keywords
  type: VALID_S3_TYPES;
  currentPartner: string | null;
  deleteFile: MutationFn<DeleteFile, DeleteFileInput>;
  renameFile: MutationFn<MoveFile, MoveFileInput>;
  refetch (): void;
}

interface IFileComponentState {
  showDeleteModal: boolean;
  showRenameModal: boolean;
  showDrawer: boolean;
  submitting: boolean;
  fileName: string | undefined;
}

class FileObject extends React.PureComponent<IFileComponentProps, IFileComponentState> {
  public state: IFileComponentState = {
    showDeleteModal: false,
    showRenameModal: false,
    showDrawer: false,
    submitting: false,
    fileName: removeParentDirectories(this.props.Key),
  };

  public render (): JSX.Element | null {
    const { Key }: SelectionOnFiles = this.props;
    if (!Key || !this.props.currentPartner) { return null; }
    const fileName: string | undefined = removeParentDirectories(Key);
    if (!fileName) { return null; }
    const fileUrl: string = getFileUrl(Key, this.props.root, this.props.currentPartner);

    return (
      <>
        <LiftedFileDrawer
          Key={Key}
          fileName={fileName}
          fileUrl={fileUrl}
          root={this.props.root}
          close={this.hideModals}
          show={this.state.showDrawer}
          showDelete={this.showDeleteModal}
          showEdit={this.showRenameModal}
        />
        <DeleteModal
          show={this.state.showDeleteModal}
          close={this.hideModals}
          name={fileName}
          kind={FILE_KEY}
          refetch={this.props.refetch}
          mutation={this.props.deleteFile}
        />
        <RenameModal
          show={this.state.showRenameModal}
          close={this.hideModals}
          prefix={this.props.Key}
          type={this.props.type}
          name={fileName}
          kind={FILE_KEY}
          refetch={this.props.refetch}
          mutation={this.props.renameFile}
        />
        <ContextMenuTrigger id={Key}>
          <FileBase {...this.props} fileName={fileName} showDrawer={this.showDrawer} />
        </ContextMenuTrigger>
        <StyledContextMenu id={Key}>
          <Menu>
            <MenuItem onClick={this.showRenameModal}>
              <MenuItemContain>
                <MenuIcon icon='edit' />
                <MenuItemLabel>Edit</MenuItemLabel>
              </MenuItemContain>
            </MenuItem>
            {!isLookerDashboard(Key) && (
              <MenuItem onClick={this.handleDownloadItem}>
                <MenuItemContain>
                  <MenuIcon icon='download' />
                  <MenuItemLabel>Download</MenuItemLabel>
                </MenuItemContain>
              </MenuItem>
            )}
            <MenuItem onClick={this.showDeleteModal}>
              <MenuItemContain>
                <MenuIcon icon='delete' />
                <MenuItemLabel>Delete</MenuItemLabel>
              </MenuItemContain>
            </MenuItem>
          </Menu>
        </StyledContextMenu>
      </>
    );
  }

  private readonly showDeleteModal: React.MouseEventHandler = () => {
    this.setState({ showDeleteModal: true });
  }

  private readonly showRenameModal: React.MouseEventHandler = () => {
    this.setState({ showRenameModal: true });
  }

  private readonly showDrawer: React.MouseEventHandler = e => {
    e.preventDefault();
    this.setState(({ showDrawer: true }));
  }

  private readonly hideModals: React.MouseEventHandler = () => {
    this.setState({ showDrawer: false, showDeleteModal: false, showRenameModal: false });
  }

  private readonly handleDownloadItem: React.MouseEventHandler = () => {
    if (!this.props.Key || !this.props.currentPartner) { return; }

    const downloadLink: HTMLAnchorElement = document.createElement('a');
    downloadLink.href = getFileUrl(this.props.Key, this.props.root, this.props.currentPartner);
    downloadLink.target = '_blank';
    if (!isLookerDashboard(this.props.Key)) { downloadLink.download = removeParentDirectories(this.props.Key) || ''; }
    downloadLink.click();
  }
}

export default FileObject;
