import * as React from 'react';
import camelCase from 'lodash-es/camelCase';
import { MutationFn } from 'react-apollo';
import Events from '@avant/crm-frontend-utils/events';
import Errors from '@avant/crm-frontend-utils/error';
import {
  ButtonWithSpinner,
  FieldSet,
  Headline,
  media,
  RadioCard
} from '@amount/frontend-components';
import styled from 'styled-components';

import { PARTNER_CHANGED_EVENT } from '../../../../shared/config/constants';
import { SelectionOnMe } from '../../Context/me.graphql';
import Modal from '../../Modal';
import { ButtonActionRow, CancelButton } from '../../Modal/common';
import { CurrentLogo } from '../../Logo';
import { SwitchablePartner } from '../../../../shared/config/partners';
import { SetPartner, SetPartnerInput } from '../queries/setPartner.graphql';
import { CardContain } from '../../Card';

const RadioCardWrap = styled.span`
  position: relative;
`;

export const LogoCardContain = styled(CardContain)`
  margin-top: 2em;
  padding-bottom: 2em;

  ${media.small`
    grid-gap: 20px;
    width: calc( 100% - 20px );
  `}

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

export const SwitcherLogo = styled(CurrentLogo)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5em 1em;

  ${media.small`
    padding: 1em;
  `}

  & > svg {
    height: 2.4rem;
  }
`;

interface IPartnerCards {
  partner: string | null;
  switchablePartners: SwitchablePartner[];
  selectPartner: React.MouseEventHandler<HTMLInputElement>;
}

const PartnerCards: React.FC<IPartnerCards> = ({ partner, switchablePartners, selectPartner }) => (
  <LogoCardContain>
    {switchablePartners.map(p =>
      <RadioCardWrap key={p}>
        <RadioCard
          id={p}
          data-partner={p}
          data-event={camelCase(`partnerSwitcher ${p}`)}
          checked={partner === p}
          readOnly={true}
          onClick={selectPartner}
        >
          <SwitcherLogo partner={p} />
        </RadioCard>
      </RadioCardWrap>
    )}
  </LogoCardContain>
);

export interface ILiftedPartnerSwitcherProps {
  me: SelectionOnMe;
  show: boolean;
  close (e: Event | React.SyntheticEvent): void;
}

interface IPartnerSwitcherProps extends ILiftedPartnerSwitcherProps {
  setPartner: MutationFn<SetPartner, SetPartnerInput>;
  me: SelectionOnMe;
}

interface IPartnerSwitcherState {
  submitting: boolean;
  partner: string | null;
}

const PartnerSwitcherModal = styled(Modal)`
  z-index: 99999;
`;

export class PartnerSwitcher extends React.Component<IPartnerSwitcherProps, IPartnerSwitcherState> {
  public state: IPartnerSwitcherState = {
    submitting: false,
    partner: this.props.me.currentPartner
  };

  public render (): JSX.Element | null {
    const { switchablePartners } = this.props.me;
    if (!switchablePartners.length) { return null; }

    return (
      <PartnerSwitcherModal
        data-modal={true}
        close={this.props.close}
        show={this.props.show}
      >
        <Headline scale='medium'>Switch Partner Instance</Headline>
        <FieldSet>
          <PartnerCards
            partner={this.state.partner}
            switchablePartners={switchablePartners as SwitchablePartner[]}
            selectPartner={this.selectPartner}
          />
        </FieldSet>
        <ButtonActionRow>
          <ButtonWithSpinner
            inline={true}
            onClick={this.setCurrentPartner}
            disabled={this.state.submitting}
            loading={this.state.submitting}
            data-event='saveChangePartner'
          >
            Switch
          </ButtonWithSpinner>
          <CancelButton
            hidden={this.state.submitting}
            onClick={this.props.close}
          >
            Cancel
          </CancelButton>
        </ButtonActionRow>
      </PartnerSwitcherModal>
    );
  }

  private readonly selectPartner: React.MouseEventHandler<HTMLInputElement> = ({ currentTarget: { dataset: { partner } } }) => {
    if (!partner) { return; }
    this.setState({ partner });
  }

  private readonly setCurrentPartner: React.MouseEventHandler<HTMLButtonElement> = async e => {
    e.stopPropagation();

    if (!this.state.partner) { return; }
    try {
      this.setState({ submitting: true });
      await this.props.setPartner({
        variables: {
          currentPartner: this.state.partner,
          clientMutationId: ''
        }
      });
      Events.publish(PARTNER_CHANGED_EVENT);
      this.setState({ submitting: false });
      this.props.close(e);
    } catch (err) {
      this.setState({ submitting: false });
      console.error(err);
      Errors.notify(
        'Failed to change partner',
        'Set Partner',
        'error'
      );
    }
  }
}
