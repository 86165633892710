import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import * as Sentry from '@sentry/browser';

import App from './app';
import HeapHelper from './services/HeapHelper';

HeapHelper.initializeHeap();

const {
  FRONTEND_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_RELEASE_VERSION
} = process.env;

if (!!FRONTEND_DSN) {
  Sentry.init({
    dsn: FRONTEND_DSN,
    release: SENTRY_RELEASE_VERSION,
    environment: SENTRY_ENVIRONMENT
  });
}

const rootElement: HTMLElement | null = document.getElementById('root');

if (!rootElement) { throw new Error('Root element not found.'); }

ReactDOM.render(
  (
      <AppContainer>
        <App />
      </AppContainer>
  ),
  rootElement
);

// tslint:disable
if (process.env.NODE_ENV !== 'production' && module && (module as any).hot) {
  (module as any).hot.accept('./app', () => {
    const NextApp = require('./app').default;
    ReactDOM.render(
        <AppContainer>
          <NextApp />
        </AppContainer>,
      rootElement
    );
  });
}
// tslint:enable
