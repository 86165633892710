import * as React from 'react';
import {
  Spinner as AvantSpinner,
} from '@amount/frontend-components';
import styled, { css } from 'styled-components';

interface IWrapProps {
  withMargin?: boolean;
}

const SpinnerWrap = styled.div<IWrapProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${({ withMargin }) => !!withMargin && css`
    margin: 2em;
  `}
`;

interface ISpinnerProps extends React.HTMLAttributes<HTMLElement>, IWrapProps {}

export const Spinner: React.FC<ISpinnerProps> = props => (
  <SpinnerWrap {...props}>
    <AvantSpinner />
  </SpinnerWrap>
);
