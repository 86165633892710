// tslint:disable strict-type-predicates
export const getBooleanFromEnv: (key: string) => boolean = key => {
  const value: string | number | boolean | undefined = process.env[key];

  if (typeof value === 'boolean') { return value; }
  if (typeof value === 'number')  { return value === 1; }
  if (typeof value === 'string')  { return value.toLowerCase() === 'true' || value === '1'; }

  return !!value;
};
// tslint:enable
