import * as React from 'react';
import { distanceInWordsToNow, parse } from 'date-fns';

import { ThirtySecondTimer } from '../../services/GlobalTimer';

export interface ITimeWidgetProps {
  timestamp: string | Date;
}

interface ITimeWidgetState {
  intervalId?: string;
  timeAgo?: string;
}

class TimeWidget extends React.Component<ITimeWidgetProps, ITimeWidgetState> {
  public state: ITimeWidgetState = {
    timeAgo: distanceInWordsToNow(parse(this.props.timestamp))
  };

  public componentDidMount (): void {
    const id: string = ThirtySecondTimer.addCallback(this._update);
    this.setState({ intervalId: id });
  }

  public componentWillUnmount (): void {
    if (this.state.intervalId) {
      ThirtySecondTimer.removeCallback(this.state.intervalId);
    }
  }

  public render (): JSX.Element {
    return <span>{this.state.timeAgo}</span>;
  }

  private readonly _update = () => {
    const newTimeAgo: string = distanceInWordsToNow(parse(this.props.timestamp));

    if (this.state.timeAgo !== newTimeAgo) {
      this.setState({ timeAgo: newTimeAgo });
    }
  }
}

export default TimeWidget;
