import {
  AMOUNT_PERMISSION_KEY,
  AVANT_PERMISSION_KEY,
  BARCLAYS_PERMISSION_KEY,
  BBVA_PERMISSION_KEY,
  BPO_PERMISSION_KEY,
  CAPITAL_ONE_PERMISSION_KEY,
  DEMO_BANK_PERMISSION_KEY,
  ELOAN_PERMISSION_KEY,
  GUARANTEED_RATE_PERMISSION_KEY,
  HSBC_PERMISSION_KEY,
  ICON_PERMISSION_KEY,
  JOMASHOP_PERMISSION_KEY,
  PNC_PERMISSION_KEY,
  POLLEN_STREET_PERMISSION_KEY,
  REGIONS_PERMISSION_KEY,
  TD_PERMISSION_KEY,
  TELEPERFORMANCE_PERMISSION_KEY,
  WEBBANK_PERMISSION_KEY,
  JENIUS_PERMISSION_KEY,
  RADIUS_PERMISSION_KEY,
} from './permissions';
import { INVALID_ORGANIZATION } from './constants';
import { ISessionUser } from './user';

/*
 * The list of all switchable partners.
 */
export const SWITCHABLE_PARTNER_LIST = [
  AMOUNT_PERMISSION_KEY,
  AVANT_PERMISSION_KEY,
  BARCLAYS_PERMISSION_KEY,
  CAPITAL_ONE_PERMISSION_KEY,
  DEMO_BANK_PERMISSION_KEY,
  ELOAN_PERMISSION_KEY,
  GUARANTEED_RATE_PERMISSION_KEY,
  HSBC_PERMISSION_KEY,
  JOMASHOP_PERMISSION_KEY,
  PNC_PERMISSION_KEY,
  POLLEN_STREET_PERMISSION_KEY,
  REGIONS_PERMISSION_KEY,
  ICON_PERMISSION_KEY,
  TD_PERMISSION_KEY,
  BBVA_PERMISSION_KEY,
  JENIUS_PERMISSION_KEY
] as const;

/*
 * a union type of valid switchable partners.
 */
export type SwitchablePartner = typeof SWITCHABLE_PARTNER_LIST[number];

/*
 * union type of not valid switchable partners.
 */
type NonSwitchablePartner =
  | typeof INVALID_ORGANIZATION;

/*
 *  Use FloatingOrganizations.
 */
export type FloatingOrganization =
  | typeof BPO_PERMISSION_KEY
  | typeof AVANT_PERMISSION_KEY
  | typeof WEBBANK_PERMISSION_KEY
  | typeof TELEPERFORMANCE_PERMISSION_KEY
  | typeof RADIUS_PERMISSION_KEY;

export type OrganizationSwitchView =
  | typeof BPO_PERMISSION_KEY
  | typeof AVANT_PERMISSION_KEY
  | typeof WEBBANK_PERMISSION_KEY;

/**
 * union type of the available organizations for the Warden and the application as a whole
 */
export type Organizations = SwitchablePartner | NonSwitchablePartner | FloatingOrganization;

export const SWITCHABLE_PARTNER_SET: Set<SwitchablePartner> = new Set(SWITCHABLE_PARTNER_LIST);

export const ORGANIZATION_LIST: Organizations[] = [
  ...SWITCHABLE_PARTNER_LIST,
  BPO_PERMISSION_KEY,
  WEBBANK_PERMISSION_KEY,
  TELEPERFORMANCE_PERMISSION_KEY,
  RADIUS_PERMISSION_KEY,
];

export const ORGANIZATION_SET: Set<Organizations> = new Set(ORGANIZATION_LIST);

/*
 * The list of partners that are allowed to switch to other partner instances.
 */
export const SWITCH_ENABLED_PARTNER_LIST: Organizations[] = [
  BPO_PERMISSION_KEY,
  AMOUNT_PERMISSION_KEY,
  RADIUS_PERMISSION_KEY,
];

export const SWITCH_ENABLED_PARTNER_SET: Set<Organizations> = new Set(SWITCH_ENABLED_PARTNER_LIST);

/*
 * Union type of organizations that are not in the switchableParter union type
 * this gives you all organizations that aren't a switchable partner.
 */
export type FloatingOrganizations = Exclude<Organizations, SwitchablePartner | NonSwitchablePartner>;

/*
 * The mapping of a floating organization (an organization that isn't a switchable partner)
 * onto a valid switchable partner.
 */
export const FLOATING_ORGANIZATION_MAP: { [x in FloatingOrganization]: SwitchablePartner } = {
  [BPO_PERMISSION_KEY]: AVANT_PERMISSION_KEY,
  [AVANT_PERMISSION_KEY]: AVANT_PERMISSION_KEY,
  [WEBBANK_PERMISSION_KEY]: AVANT_PERMISSION_KEY,
  [TELEPERFORMANCE_PERMISSION_KEY]: CAPITAL_ONE_PERMISSION_KEY,
  [RADIUS_PERMISSION_KEY]: PNC_PERMISSION_KEY,
};

/*
 * The mapping of a floating organization onto a valid switchable partner but only includes
 * org which should have the Switch Organization button.
 */
export const FLOATING_ORGANIZATION_SWITCH_VIEW_MAP: { [x in OrganizationSwitchView]: SwitchablePartner } = {
  [BPO_PERMISSION_KEY]: AVANT_PERMISSION_KEY,
  [AVANT_PERMISSION_KEY]: AVANT_PERMISSION_KEY,
  [WEBBANK_PERMISSION_KEY]: AVANT_PERMISSION_KEY
};

type getOrgType = (user: Pick<ISessionUser, 'currentPartner' | 'organization'>) => Organizations;
/**
 * Returns a floating organization instead of a user's switchable partner,
 * if the user's current partner is what their floating organization maps onto.
 *
 *
 * @param user The provided user
 * @returns the user's floating org or their switchablepartner.
 */
export const getOrg: getOrgType = ({ currentPartner, organization }) => (
  organization in FLOATING_ORGANIZATION_MAP && FLOATING_ORGANIZATION_MAP[organization] === currentPartner) ? (
    organization
  ) : (
    // tslint:disable-next-line: no-non-null-assertion
    currentPartner!
  );

/*
 * Maps partner permission keys onto readable names.
 *
 * This is also used by S3 to key folder names.
 */
export const READABLE_PARTNER_NAME_MAP: { [x in Organizations]: string } = {
  [AVANT_PERMISSION_KEY]: 'Avant',
  [AMOUNT_PERMISSION_KEY]: 'Amount',
  [DEMO_BANK_PERMISSION_KEY]: 'Demo',
  [ELOAN_PERMISSION_KEY]: 'Eloan',
  [HSBC_PERMISSION_KEY]: 'HSBC',
  [POLLEN_STREET_PERMISSION_KEY]: 'Pollen Street Capital',
  [PNC_PERMISSION_KEY]: 'PNC',
  [REGIONS_PERMISSION_KEY]: 'Regions',
  [TD_PERMISSION_KEY]: 'TD',
  [INVALID_ORGANIZATION]: 'Invalid Organization',
  [BPO_PERMISSION_KEY]: 'Avant BPO',
  [WEBBANK_PERMISSION_KEY]: 'WebBank',
  [ICON_PERMISSION_KEY]: 'Icon',
  [BBVA_PERMISSION_KEY]: 'BBVA',
  [CAPITAL_ONE_PERMISSION_KEY]: 'Capital One',
  [BARCLAYS_PERMISSION_KEY]: 'Barclays',
  [JOMASHOP_PERMISSION_KEY]: 'Jomashop',
  [TELEPERFORMANCE_PERMISSION_KEY]: 'Teleperformance',
  [GUARANTEED_RATE_PERMISSION_KEY]: 'Guaranteed Rate',
  [JENIUS_PERMISSION_KEY]: 'Jenius Bank',
  [RADIUS_PERMISSION_KEY]: 'Radius',
};

/*
 * Maps partner permission keys onto SSO provider names
 */
export const PARTNER_SSO_PROVIDER_MAP: { [key in SwitchablePartner]?: string } = {
  [TD_PERMISSION_KEY]: 'Novell',
  [AVANT_PERMISSION_KEY]: 'Centrify',
  [POLLEN_STREET_PERMISSION_KEY]: 'Centrify',
  [PNC_PERMISSION_KEY]: 'Ping'
};

/*
 * Maps tenants onto their single-tenant instance of complaints.
 */
export const COMPLAINTS_REDIRECT_MAP: { [x in SwitchablePartner]: string | undefined} = {
  [HSBC_PERMISSION_KEY]: process.env.COMPLAINTS_URL_REDIRECT_HSBC,
  [PNC_PERMISSION_KEY]: process.env.COMPLAINTS_URL_REDIRECT_PNC,
  [BBVA_PERMISSION_KEY]: process.env.COMPLAINTS_URL_REDIRECT_BBVA,
  // These below should be updated to their single-tenant instance of complaints.
  [AMOUNT_PERMISSION_KEY]: process.env.COMPLAINTS_URL_REDIRECT,
  [AVANT_PERMISSION_KEY]: process.env.COMPLAINTS_URL_REDIRECT,
  [TD_PERMISSION_KEY]: process.env.COMPLAINTS_URL_REDIRECT_TD,
  [ELOAN_PERMISSION_KEY]: process.env.COMPLAINTS_URL_REDIRECT,
  [REGIONS_PERMISSION_KEY]: process.env.COMPLAINTS_URL_REDIRECT,
  [DEMO_BANK_PERMISSION_KEY]: process.env.COMPLAINTS_URL_REDIRECT,
  [POLLEN_STREET_PERMISSION_KEY]: process.env.COMPLAINTS_URL_REDIRECT,
  [ICON_PERMISSION_KEY]: undefined,
  [CAPITAL_ONE_PERMISSION_KEY]: process.env.COMPLAINTS_URL_REDIRECT_CAPITALONE,
  [BARCLAYS_PERMISSION_KEY]: undefined,
  [JOMASHOP_PERMISSION_KEY]: undefined,
  [GUARANTEED_RATE_PERMISSION_KEY]: process.env.COMPLAINTS_URL_REDIRECT_GUARANTEEDRATE,
  [JENIUS_PERMISSION_KEY]: undefined,
};

/*
 * Maps tenants onto their single-tenant instance of disputes.
 */
export const DISPUTES_REDIRECT_MAP: { [x in SwitchablePartner]: string | undefined} = {
  [HSBC_PERMISSION_KEY]: undefined,
  [PNC_PERMISSION_KEY]: undefined,
  [BBVA_PERMISSION_KEY]: undefined,
  [AMOUNT_PERMISSION_KEY]: undefined,
  [AVANT_PERMISSION_KEY]: undefined,
  [TD_PERMISSION_KEY]: process.env.DISPUTES_URL_REDIRECT_TD,
  [ELOAN_PERMISSION_KEY]: undefined,
  [REGIONS_PERMISSION_KEY]: undefined,
  [DEMO_BANK_PERMISSION_KEY]: undefined,
  [POLLEN_STREET_PERMISSION_KEY]: undefined,
  [ICON_PERMISSION_KEY]: process.env.DISPUTES_URL_REDIRECT_ICON,
  [CAPITAL_ONE_PERMISSION_KEY]: process.env.DISPUTES_URL_REDIRECT_CAPITALONE,
  [BARCLAYS_PERMISSION_KEY]: process.env.DISPUTES_URL_REDIRECT_BARCLAYS,
  [JOMASHOP_PERMISSION_KEY]: process.env.DISPUTES_URL_REDIRECT_JOMASHOP,
  [GUARANTEED_RATE_PERMISSION_KEY]: undefined,
  [JENIUS_PERMISSION_KEY]: undefined,
};

/*
 * Maps tenants onto their single-tenant instance of cip.
 */
export const CIP_REDIRECT_MAP: { [x in SwitchablePartner]?: string | undefined} = {
  [AVANT_PERMISSION_KEY]: process.env.CIP_URL_REDIRECT,
  [TD_PERMISSION_KEY]: process.env.CIP_URL_REDIRECT_TD,
  [ICON_PERMISSION_KEY]: process.env.CIP_URL_REDIRECT_ICON,
  [AMOUNT_PERMISSION_KEY]: process.env.CIP_URL_REDIRECT_AMOUNT,
  [BARCLAYS_PERMISSION_KEY]: process.env.CIP_URL_REDIRECT_BARCLAYS,
  [CAPITAL_ONE_PERMISSION_KEY]: process.env.CIP_URL_REDIRECT_CAPITALONE,
  [JOMASHOP_PERMISSION_KEY]: process.env.CIP_URL_REDIRECT_JOMASHOP,
  [GUARANTEED_RATE_PERMISSION_KEY]: process.env.CIP_URL_REDIRECT_GUARANTEEDRATE,
  [JENIUS_PERMISSION_KEY]: process.env.CIP_URL_REDIRECT_JENIUSBANK,
};

/*
 * Maps tenants onto their single-tenant instance of psaes.
 */
export const PSAES_REDIRECT_MAP: { [x in SwitchablePartner]: string | undefined} = {
  [HSBC_PERMISSION_KEY]: process.env.PSAES_URL_REDIRECT_HSBC,
  [PNC_PERMISSION_KEY]: process.env.PSAES_URL_REDIRECT_PNC,
  // these should be updated to point to the single-tenant instance of psaes, if applicable.
  [AMOUNT_PERMISSION_KEY]: undefined,
  [AVANT_PERMISSION_KEY]: undefined,
  [TD_PERMISSION_KEY]: undefined,
  [ELOAN_PERMISSION_KEY]: undefined,
  [REGIONS_PERMISSION_KEY]: undefined,
  [DEMO_BANK_PERMISSION_KEY]: undefined,
  [POLLEN_STREET_PERMISSION_KEY]: undefined,
  [ICON_PERMISSION_KEY]: undefined,
  [BBVA_PERMISSION_KEY]: process.env.PSAES_URL_REDIRECT_BBVA,
  [CAPITAL_ONE_PERMISSION_KEY]: undefined,
  [BARCLAYS_PERMISSION_KEY]: undefined,
  [JOMASHOP_PERMISSION_KEY]: undefined,
  [GUARANTEED_RATE_PERMISSION_KEY]: undefined,
  [JENIUS_PERMISSION_KEY]: undefined,
};

export const MODULE_REDIRECTS = {
  cip: '/redirect/cip',
  complaints: '/redirect/complaints',
  disputes: '/redirect/disputes',
  psaes: '/redirect/psaes',
  paymentGateway: '/redirect/paymentGateway',
  merchants: '/redirect/merchants',
  configurations: '/redirect/configurations',
};
