import * as React from 'react';
import styled from 'styled-components';
import { Mutation, MutationFn } from 'react-apollo';
import { useParams } from 'react-router-dom';
import ErrorHandler from '@avant/crm-frontend-utils/error';

import UploadButton from '../../UploadButton';
import CreateHelpdeskAttachmentQuery, {
  CreateHelpdeskAttachment,
  CreateHelpdeskAttachmentInput,
  SelectionOnCreateAttachment,
} from '../queries/createRequestAttachment.graphql';
import { ErrorLogger } from '../../../services/error';
import { IRequestIds } from '../Submit/interfaces';

const ATTACHMENT_MAX_SIZE: number = 21000000;

const UploadInput = styled.input`
  display: none;
`;

interface ILiftedUploaderProps {
  formControlId?: string;
  setAttachments (attachments: SelectionOnCreateAttachment): void;
}

interface IUnroutedProps extends ILiftedUploaderProps {
  serviceDeskId: string;
}

interface IFileUploaderProps extends ILiftedUploaderProps, IUnroutedProps {
  createAttachment: MutationFn<CreateHelpdeskAttachment, CreateHelpdeskAttachmentInput>;
}

interface IState {
  isUploadingFile: boolean;
}

class Attachment extends React.Component<IFileUploaderProps, IState> {
  public state: IState = { isUploadingFile: false };
  private uploadElement: HTMLInputElement | undefined;

  public render () {
    return (
      <>
        <UploadInput
          type='file'
          onChange={this.uploadFile}
          ref={(input: HTMLInputElement) => this.uploadElement = input}
          id={this.props.formControlId}
        />
        <UploadButton
          uploadingFile={this.state.isUploadingFile}
          uploadFile={this.triggerUpload}
        />
      </>
    );
  }

  private readonly triggerUpload = () => !!this.uploadElement ? this.uploadElement.click() : null;

  private readonly handleFileLoad = async (name: string, content: string) => {
    const variables = { serviceDeskId: this.props.serviceDeskId, name, content };
    const response = await this.props.createAttachment({ variables });
    if (!response || !response.data || !response.data.createAttachment) {
      throw new Error('No data from file upload');
    }
    this.props.setAttachments(response.data.createAttachment);
  }

  private readonly uploadFile: React.FormEventHandler<HTMLInputElement> = async e => {
    const files: FileList | null = e.currentTarget.files;
    if (!files || !files[0]) {
      return;
    }
    const file: File = files[0];

    if (file.size > ATTACHMENT_MAX_SIZE) {
      ErrorHandler.notify('The attachment size exceeds the max 20 MB file size.', 'Helpdesk', 'error');

      return;
    }

    const reader: FileReader = new FileReader();

    reader.onload = async (): Promise<void> => {
      this.setState({ isUploadingFile: true });
      try {
        const content = btoa(reader.result as string);
        await this.handleFileLoad(file.name, content);
      } catch (err) {
        ErrorHandler.notify('The attachment was not able to be added. Please try again.', 'Helpdesk', 'error');
        ErrorLogger.captureException(err);
      }
      this.setState({ isUploadingFile: false });
    };

    reader.readAsBinaryString(file);
  }
}

const Component: React.FC<IUnroutedProps> = ({ setAttachments, serviceDeskId, formControlId }) => (
  <Mutation<CreateHelpdeskAttachment, CreateHelpdeskAttachmentInput> mutation={CreateHelpdeskAttachmentQuery} >
    {createAttachment => (
      <Attachment
        createAttachment={createAttachment}
        setAttachments={setAttachments}
        serviceDeskId={serviceDeskId}
        formControlId={formControlId}
      />
    )}
  </Mutation>
);

export default Component;

export const AttachmentUploaderWithRouter: React.FC<ILiftedUploaderProps> = ({ setAttachments, formControlId }) => {
  const { serviceDeskId } = useParams<IRequestIds>();

  return (
    <Component serviceDeskId={serviceDeskId} setAttachments={setAttachments} formControlId={formControlId} />
  );
};
