import * as React from 'react';
import { Mutation, Query } from 'react-apollo';
import { useParams, withRouter } from 'react-router';
import { media } from '@amount/frontend-components';
import styled, { css } from 'styled-components';

import { InnerContainer } from '../InnerContainer';
import { VIEW_USERS } from '../../../shared/config/permissions';
import { PermissionedComponent } from '../PermissionedComponent';
import { Spinner } from '../Spinner';
import HomeRedirectComponent from '../HomeRedirectComponent';

import getUserQuery, {
  GetUser,
  GetUserInput,
} from './queries/getUser.graphql';
import editUserActiveQuery, { EditUserActive as editUserActive, EditUserActiveInput } from './queries/editUserActive.graphql';
import resendUserActivationQuery, {
  ResendUserActivation as resendUserActivation,
  ResendUserActivationInput
} from './queries/resendUserActivation.graphql';
import { UserDetail } from './Presentational/UserDetail';
import { UserManagementContext } from './UserManagement.context';

const DetailContainer = styled(InnerContainer) <{ isStandalone: boolean }>`
  padding: 0;

  ${media.small`
    padding: 1em 0 0 2.875em;
  `}

  ${({ isStandalone }) => isStandalone && css`
    padding: 0 1em;

    ${media.small`
      padding: 0 1em;
    `}
  `}
`;

const RoutedUserDetail = withRouter(UserDetail);

const LiftedUserDetail: React.FC<{ id: string }> = props => {
  const { id: paramsId } = useParams<{ id: string }>();

  const { state: userMgmt } = React.useContext(UserManagementContext);

  return (
    <PermissionedComponent allowedPermissions={[VIEW_USERS]} NoAccessComponent={HomeRedirectComponent}>
      <Query<GetUser, GetUserInput>
        query={getUserQuery}
        variables={{ id: props.id || paramsId || '', organization: userMgmt.currentOrg }}
        skip={!props.id && !paramsId}
      >
        {({ data, loading, refetch }) => (
          <Mutation<editUserActive, EditUserActiveInput>
            mutation={editUserActiveQuery}
          >
            {editUser => (
              <Mutation<resendUserActivation, ResendUserActivationInput>
                mutation={resendUserActivationQuery}
                variables={{
                  organization: userMgmt.currentOrg,
                  id: props.id || paramsId || '',
                  clientMutationId: '0'
                }}
              >
                {resendActivation => (
                  <DetailContainer as='div' isStandalone={!!paramsId}>
                    {loading ? (
                      <Spinner />
                    ) : (
                        <RoutedUserDetail
                          id={props.id}
                          refetch={refetch}
                          data={data}
                          editUser={editUser}
                          resendActivation={resendActivation}
                          currentOrg={userMgmt.currentOrg}
                        />
                      )}
                  </DetailContainer>
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </Query>
    </PermissionedComponent>
  );
};

export default LiftedUserDetail;
