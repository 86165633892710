import * as Sentry from '@sentry/browser';

export interface IAdditionalUserInfo {
  appId: string | null;
  appUUID: string | null;

  productType: string | null;
  productId: string | null;
  productUUID: string | null;
}

export interface IUserInfo extends Partial<IAdditionalUserInfo> {
  id: string;
  uuid: string;
  email: string | undefined;
  [key: string]: string | number | boolean | void | null | undefined;
}

export { Severity } from '@sentry/browser';

interface IErrorLogger extends Pick<Sentry.Hub, 'setUser' | 'addBreadcrumb' | 'captureMessage' | 'setExtra'> {
  captureException: typeof Sentry.captureException;
}

export const ErrorLogger: IErrorLogger = {
  setUser: Sentry.setUser,
  addBreadcrumb: Sentry.addBreadcrumb,
  captureException: Sentry.captureException,
  captureMessage: Sentry.captureMessage,
  setExtra: Sentry.setExtra
};
