import * as React from 'react';
import styled from 'styled-components';

const Message = styled.span`
  display: block;
  color: ${({ theme: { brand } }) => brand.colorAlert};
  margin-bottom: 1em;
`;

const MessageText = styled.span`
  padding-right: 0.5em;
`;

interface IFormMessage {
  message: string;
}

const FormMessage: React.FC<IFormMessage> = ({ message }) => (
  <Message role='alert'>
    <MessageText>{message}</MessageText>
  </Message>
);

export default FormMessage;
