import { Route, Switch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import * as React from 'react';

import { PRIVATE_FILES_CHANNEL } from '../../../shared/pubsub';
import { S3_POLICIES, S3_REPORTS, S3_TO_VIEW_FILE_PERMISSIONS, UPLOAD_URL } from '../../../shared/reports/constants';
import { PermissionedComponent } from '../PermissionedComponent';
import HomeRedirectComponent from '../HomeRedirectComponent';
import { ModuleRoutes } from '../../../shared/routes';
import { MaxWidthContainer } from '../MaxWidthContainer';
import { PartnersContainer } from '../PartnersContainer';

import {
  IS3Info,
} from './Presentational/common';
import LiftedFileListWrapper from './LiftedFileListWrapper';

const ReportInfo: IS3Info = {
  type: S3_REPORTS,
  pusherChannel: PRIVATE_FILES_CHANNEL,
  root: 'reports'
};

const PoliciesInfo: IS3Info = {
  type: S3_POLICIES,
  pusherChannel: PRIVATE_FILES_CHANNEL,
  root: 'policies'
};

const ROUTE_TO_S3_MAP: { [key: string]: IS3Info } = {
  [ModuleRoutes.reports]: ReportInfo,
  [ModuleRoutes.policies]: PoliciesInfo,
};

export class WrappedS3 extends React.Component<RouteComponentProps<{ path: string }>> {
  public render (): JSX.Element {
    let pathType: string = ModuleRoutes.policies;
    if (this.props.match.path.includes(ModuleRoutes.reports)) { pathType = ModuleRoutes.reports; }

    const S3Info: IS3Info = ROUTE_TO_S3_MAP[pathType];

    if (!S3Info) {
      return <div>Error loading data for path: {this.props.match.path}</div>;
    }

    return (
      <PermissionedComponent allowedPermissions={[S3_TO_VIEW_FILE_PERMISSIONS[S3Info.type]]} NoAccessComponent={HomeRedirectComponent}>
        <LiftedFileListWrapper
          {...this.props}
          {...S3Info}
          uploadPath={UPLOAD_URL}
          pathType={pathType}
        />
      </PermissionedComponent>
    );
  }
}

export type IRoutesProps = RouteComponentProps<{ path: string }>;

const Routes: React.FC<IRoutesProps> = ({ match: { path } }) => (
  <PartnersContainer>
    <MaxWidthContainer>
      <Switch>
        <Route
          path={`${path}/:path`}
          component={WrappedS3}
        />
        <Route
          path={path}
          exact={true}
          component={WrappedS3}
        />
      </Switch>
    </MaxWidthContainer>
  </PartnersContainer>
);

export default Routes;
