import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { QueryHookOptions, useMutation, useQuery } from '@apollo/react-hooks';

import { FILE_UPLOAD_EVENT, IFilePubsubInfo } from '../../../shared/pubsub';
import { SubscribeCallback, withPusher } from '../HOC/withPusher';
import { MeContext } from '../Context';

import FileListQuery, { GetFileList, GetFileListInput } from './queries/fileList.graphql';
import CreateFolderQuery, { CreateFolder, CreateFolderInput } from './queries/newFolder.graphql';
import FileListWrapper from './Presentational/FileListWrapper';
import {
  IApolloVariablesType,
  ILiftedProps,
  IProps,
  restoreSlashes
} from './Presentational/common';

const MapPropsToVariables: (props: IProps) => IApolloVariablesType<GetFileListInput> = ({ match: { params: { path } }, type }) =>
({ variables: { type, path: restoreSlashes((path || '')) } });

const defaultProps: { refetch (): Promise<{}> } = {
  refetch: () => Promise.resolve({})
};

const PusherUpdateFn: SubscribeCallback<ILiftedProps, IFilePubsubInfo> =
  ({ fileQuery: { refetch } = defaultProps }) => () => {
    void refetch();
  };

const Wrapped: React.ComponentClass<ILiftedProps> =
  withPusher('', FILE_UPLOAD_EVENT, PusherUpdateFn)(FileListWrapper);

const LiftedFileListWrapper: React.FC<RouteComponentProps<{ path: string }> & IProps> = props => {
  const me = React.useContext(MeContext);
  const input: QueryHookOptions<GetFileList, GetFileListInput> = { fetchPolicy: 'cache-and-network', ...MapPropsToVariables(props) };
  const { data, error, loading, refetch, networkStatus } = useQuery<GetFileList, GetFileListInput>(FileListQuery, input);
  const [newFolder] = useMutation<CreateFolder, CreateFolderInput>(CreateFolderQuery);

  return (
    <Wrapped {...props} me={me} fileQuery={{ data, error, loading, refetch, networkStatus }} newFolder={newFolder} />
  );
};

export default LiftedFileListWrapper;
