import * as React from 'react';
import { Helmet } from 'react-helmet';

import List from '../List';
import { PermissionedComponent } from '../../PermissionedComponent';
import { SAR_TAB_PERMISSIONS } from '../../../../shared/config/permissions';
import {
  ITabInformation,
  TabInformation,
  UIToSARStatus,
} from '../../../../shared/transitions/sar';

const NoPermissions: React.FC = () => <div>You do not have permissions to view this page</div>;

interface IComponentState {
  titleText: string;
}

export default class Base extends React.Component<{}, IComponentState> {
  public state: IComponentState = {
    titleText: 'SARs'
  };
  protected readonly _type: string = '';

  public componentWillMount (): void {
    const info: ITabInformation | undefined = TabInformation.find(x => x.filterKey === this._type);
    this.setState({ titleText: info ? info.name : 'SARs' });
  }

  get _transitionType (): string {
    return UIToSARStatus[this._type] || '';

  }

  get allowedPermissions (): string[] {
    if (this._transitionType === 'completed') {
      return [UIToSARStatus.partnerRejected, UIToSARStatus.partnerApproval].map(status => SAR_TAB_PERMISSIONS[status])
    }
    return [SAR_TAB_PERMISSIONS[this._transitionType]]
  }

  get postfixElement (): JSX.Element | null {
    return null;
  }

  public render (): JSX.Element {
    return (
      <PermissionedComponent
        NoAccessComponent={NoPermissions}
        allowedPermissions={this.allowedPermissions}
      >
        <Helmet>
          <title>{this.state.titleText} · PSAEs · Partner Portal</title>
        </Helmet>
        <List
          {...this.props}
          status={this._transitionType}
          type={this._type}
        />
        {this.postfixElement}
      </PermissionedComponent>
    );
  }
}
