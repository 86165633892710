import * as React from 'react';
import { MutationFn } from 'react-apollo';
import camelCase from 'lodash-es/camelCase';
import { DrawerClose, DrawerItem, DrawerTitle, DrawerTitleSpan, SVGIcon } from '@amount/frontend-components';

import Modal from '../../Modal';
import { DeleteFile, DeleteFileInput } from '../queries/deleteFile.graphql';
import { MoveFile, MoveFileInput } from '../queries/moveFile.graphql';

import {
  drawerMobilePadding,
  FileIcon,
  IFileBaseProps,
} from './common';

export interface ILiftedFileDrawer extends Pick<IFileBaseProps, 'Key' | 'fileName' | 'root'> {
  fileUrl: string;
  show: boolean;
  close: React.MouseEventHandler;
  showEdit: React.MouseEventHandler;
  showDelete: React.MouseEventHandler;
}

interface IFileDrawer extends ILiftedFileDrawer {
  renameFile: MutationFn<MoveFile, MoveFileInput>;
  deleteFile: MutationFn<DeleteFile, DeleteFileInput>;
}

export const FileDrawer: React.FC<IFileDrawer> = props => (
  <Modal
    show={props.show}
    close={props.close}
    drawer={true}
    padding={drawerMobilePadding}
  >
    <DrawerClose onClick={props.close}>
      <SVGIcon icon='close' />
    </DrawerClose>
    <DrawerTitle>
      <FileIcon icon='file' />
      <DrawerTitleSpan>{props.fileName}</DrawerTitleSpan>
    </DrawerTitle>
    <a
      href={props.fileUrl}
      download={props.fileName}
      target='_blank'
      rel='noopener noreferrer'
      data-event={camelCase(`${props.fileName} file`)}
    >
      <DrawerItem>Download File</DrawerItem>
    </a>
    <DrawerItem onClick={props.showEdit}>Edit File</DrawerItem>
    <DrawerItem onClick={props.showDelete}>Delete File</DrawerItem>
  </Modal>
);
