import { PermissionError } from '../../server/HOF/admin';
import User from '../../server/models/user';

import {
  AVANT_PERMISSION_KEY,
  BPO_PERMISSION_KEY,
  WEBBANK_PERMISSION_KEY,
  TELEPERFORMANCE_PERMISSION_KEY,
  RADIUS_PERMISSION_KEY
} from './permissions';
import {
  FloatingOrganization,
  // getOrg,
  Organizations
} from './partners';

/*
 * The mapping of a floating organization (an organization that isn't a switchable partner)
 * onto a valid partner.
 */
export const FLOATING_ORGANIZATION_VIEW_MAP: {
  [x in FloatingOrganization]: Organizations[]
} = {
  [BPO_PERMISSION_KEY]: [AVANT_PERMISSION_KEY, BPO_PERMISSION_KEY],
  [AVANT_PERMISSION_KEY]: [AVANT_PERMISSION_KEY, BPO_PERMISSION_KEY],
  [WEBBANK_PERMISSION_KEY]: [WEBBANK_PERMISSION_KEY],
  [TELEPERFORMANCE_PERMISSION_KEY]: [TELEPERFORMANCE_PERMISSION_KEY],
  [RADIUS_PERMISSION_KEY]: [RADIUS_PERMISSION_KEY],
};

export const isFloating = (userOrg: Organizations): boolean =>
  userOrg in FLOATING_ORGANIZATION_VIEW_MAP;

export const isSwitchable = (organization: Organizations, switchablePartners: string[]) =>
  switchablePartners.includes(organization);

export const isPermissable = (
  org: Organizations,
  userOrg: Organizations
): boolean => FLOATING_ORGANIZATION_VIEW_MAP[userOrg].includes(org);

export const getOrgOrThrow = (user: User, organization: Organizations) => {
  if (
    isFloating(user.organization) &&
    (isPermissable(organization, user.organization) ||
      isSwitchable(organization, user.switchablePartners as string[]))
  ) {
    return organization;
  }
  if (!isFloating(user.organization) && isSwitchable(organization, user.switchablePartners as string[])) {
    return organization;
  }

  throw PermissionError;
};
