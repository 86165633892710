import * as React from 'react';
import camelCase from 'lodash-es/camelCase';
import omit from 'lodash-es/omit';
import classNames from 'classnames';

import { ISarRejectReason, IWorkflowTransitionInformation } from '../../../../shared/transitions/sar';

import * as styles from './style.css';

export interface IButtonProps extends
  Partial<IWorkflowTransitionInformation>,
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  event?: string;
  reasons?: ISarRejectReason[];
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  nextStep?: string;
}

type VALID_BUTTON_TYPES = 'Danger' | 'Warning' | 'Primary' | 'Success';

interface IButtonBaseProps extends IButtonProps {
  buttonType: VALID_BUTTON_TYPES;
}

// tslint:disable-next-line: no-reserved-keywords
const getButtonClass: (type: string, ...additionalClasses: string[]) => string = (type, ...addtl) =>
  classNames(styles.button, styles[`button${type}`], ...addtl);

const ButtonBase: React.FC<IButtonBaseProps> = props => (
  <button
    {
      ...omit(
        props,
        ['button', 'buttonType', 'event', 'sarId', 'status', 'text', 'transition', 'afterTransition', 'nextStep', 'reasons', 'permission']
      )
    }
    className={getButtonClass(props.buttonType)}
    data-event={props.name ? camelCase(`${props.name}Button`) : undefined}
  >
    {props.children}
  </button>
);

interface IButtonWithOptionsState {
  opened: boolean;
}

class ButtonWithOptions extends React.PureComponent<IButtonBaseProps & { reasons: ISarRejectReason[] } , IButtonWithOptionsState> {
  public state: IButtonWithOptionsState = { opened: false };

  public render (): JSX.Element {
    const { buttonType, event, children, reasons, nextStep, onClick, permission, ...passProps } = this.props;
    const dropdownClasses: string = classNames(styles.dropUpMenu, { [styles.dropUpMenu__Hidden]: !this.state.opened });

    return (
      <button
        {...passProps}
        onClick={this.openOrClose}
        className={getButtonClass(buttonType, styles.buttonDropUp)}
        data-event={passProps.name ? camelCase(`${passProps.name}Button`) : undefined}
      >
        {children}
        <div className={dropdownClasses}>
          {reasons.map(({ value, name, allowInput }) => (
            <div
              key={value}
              className={styles.dropUpItem}
              data-reason={value}
              data-allow-input={allowInput}
              role='button'
              onClick={onClick}
              data-event={camelCase(`${this.props.name} dropUpItem ${name}`)}
            >
              {name}
            </div>
          ))}
        </div>
      </button>
    );
  }

  private readonly openOrClose: React.FormEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    this.setState(({ opened }) => ({ opened: !opened }));
  }

}

const getButtonType: (verb?: string) => VALID_BUTTON_TYPES = verb => {
  switch (verb) {
    case 'rejected':
    case 'reject':
    case 'error':
      return 'Danger';

    case 'revert':
      return 'Warning';

    case 'primary':
      return 'Primary';

    default:
      return 'Success';
  }
};

export const Button: React.FC<IButtonProps> = props => !!props.reasons && !!props.reasons.length ? (
  // tslint:disable-next-line: no-any
  <ButtonWithOptions {...props as any} buttonType={getButtonType(props.event)} />
) : (
  <ButtonBase {...props} buttonType={getButtonType(props.event)} />
);
