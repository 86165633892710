import * as React from 'react';
import * as classNames from 'classnames';
import { Mutation, MutationFn, Query, QueryResult } from 'react-apollo';
import get from 'lodash-es/get';
import ErrorNoficiation from '@avant/crm-frontend-utils/error';

import TransitionQuery, { TransitionSARs, TransitionSARsInput } from '../Workflow/transitionSARs.graphql';
import GetPageQuery, { GetPage, GetPageInput, SelectionOnNode as ISarInterface } from '../getPage.graphql';
import { table, tableWrapper } from '../List/style.css';
import { button, buttonDanger, buttonPrimary, buttonSuccess } from '../Buttons/style.css';
import { PARTNER_REVIEW_STEP } from '../../../../shared/transitions/sar/steps';
import { PermissionedComponent } from '../../PermissionedComponent';
import { PARTNER_APPROVED_STEP_PSAE } from '../../../../shared/config/permissions';

import GenerateFileQuery, { GenerateFile as GenerateFileOutput, GenerateFileInput } from './generateFile.graphql';
import { fileGenerationOptions, fileGenerationOptionsWrapper } from './style.css';
import Instances from './Instances';

interface IProps extends Pick<QueryResult<GetPage, {}>, 'data' | 'loading'> {
  generateFile: MutationFn<GenerateFileOutput, GenerateFileInput>;
  submitSars: MutationFn<TransitionSARs, TransitionSARsInput>;
}

interface IState {
  selectedSars: Set<string>;
  dirty: boolean;
  instanceIds: Set<string>;
  includeNarrative: boolean;
}

type ToggleHandler = React.FormEventHandler<HTMLInputElement | HTMLTableRowElement>;

interface IListProps extends ISarInterface {
  checkboxHandler: ToggleHandler;
  selected: boolean;
  rowIndex: number;
}

const STOP_PROPAGATION_FN: React.MouseEventHandler<HTMLSpanElement> = e => { e.preventDefault(); e.stopPropagation(); };

// tslint:disable-next-line: react-a11y-event-has-role
const StopPropagation: React.FC = ({ children }) => <span onClick={STOP_PROPAGATION_FN}>{children}</span>;

const SARList: React.FC<IListProps> = ({ id, name, checkboxHandler, selected, rowIndex }) => (
  <tr data-name={id} onClick={checkboxHandler}>
    <td>
      <input
        type='checkbox'
        role='checkbox'
        name={id}
        aria-checked={selected}
        checked={selected}
        data-event={`sarInput-${rowIndex}`}
      />
    </td>
    <td>
      <StopPropagation>
        {name}
      </StopPropagation>
    </td>
    <td>
      <StopPropagation>
        {id}
      </StopPropagation>
    </td>
  </tr>
);

interface IFileGenerationOptions {
  isSelected: boolean;
  includeNarrative: boolean;
  toggleNarrative (): void;
  invertSars (): void;
  submitSars (): void;
  generateFile (): void;
}
type FGOType = React.FC<IFileGenerationOptions>;
const INVERT_BUTTON_ALT: string = 'Click to invert selection';
const TOGGLE_BUTTON_ALT: string = 'Click to toggle';

const FileGenerationOptions: FGOType = ({ toggleNarrative, includeNarrative, isSelected, generateFile, invertSars, submitSars }) => (
  <div className={fileGenerationOptionsWrapper}>
    <div className={fileGenerationOptions}>
      <button
        className={classNames(button, buttonPrimary)}
        onClick={invertSars}
        title={INVERT_BUTTON_ALT}
        data-event='invertSelectionButton'
      >
        Invert Selection
      </button>
      <button
        className={classNames(button, { [buttonDanger]: !includeNarrative, [buttonSuccess]: includeNarrative })}
        onClick={toggleNarrative}
        title={TOGGLE_BUTTON_ALT}
        data-event={`narrative${ includeNarrative ? 'Included' : 'Excluded' }Button`}
      >
        Narrative {includeNarrative ? 'Included' : 'Excluded'}
      </button>
    </div>
    <div className={fileGenerationOptions}>
      <button
        disabled={!isSelected}
        className={classNames(button, buttonSuccess)}
        onClick={submitSars}
        data-event='submitButton'
      >
        Submit
      </button>
      <button
        disabled={!isSelected}
        className={classNames(button, buttonPrimary)}
        onClick={generateFile}
        data-event='generateFlatFileButton'
      >
        Generate Flat File
      </button>
    </div>
  </div>
);

// tslint:disable-next-line: no-reserved-keywords
const invertFromArrayAndSet: <T, U>(all: T[], set: Set<U>, predicate: (x: T) => U) => Set<U> = (all, set, predicate) => (
  new Set(all.filter(entry => !set.has(predicate(entry))).map(predicate))
);

class FileGeneration extends React.PureComponent<IProps, IState> {
  public state: IState = {
    selectedSars: new Set(),
    dirty: false,
    instanceIds: new Set(),
    includeNarrative: true,
  };

  get webbankFiles (): ISarInterface[] {
    if (!this.props.data) { return []; }

    return (this.props.data.sarsConnection.edges || [])
      .filter(sar => sar && sar.node.status === PARTNER_REVIEW_STEP)
      // tslint:disable-next-line: no-non-null-assertion
      .map(sar => sar!.node);
  }

  public render (): JSX.Element {
    if (!this.props.data || this.props.loading) {
      return <div className='spinner' />;
    }

    const webbankFiles: ISarInterface[] = this.webbankFiles;

    if (!webbankFiles.length) {
      return <div>No SARs Found</div>;
    }

    return (
      <div>
        <div className={tableWrapper}>
          <table className={table}>
            <thead>
              <tr>
                <th />
                <th>Name</th>
                <th>ID</th>
              </tr>
            </thead>
            <tbody>
              {
                webbankFiles.map((f, rowIndex) => (
                  <SARList
                    {...f}
                    key={f.id}
                    selected={this.state.selectedSars.has(f.id)}
                    checkboxHandler={this.checkboxHandler}
                    rowIndex={rowIndex}
                  />
                ))
              }
            </tbody>
          </table>
        </div>
        <FileGenerationOptions
          isSelected={!!this.state.dirty && !!this.state.selectedSars.size}
          generateFile={this.generateFile}
          includeNarrative={this.state.includeNarrative}
          toggleNarrative={this.toggleNarrative}
          invertSars={this.invertSars}
          submitSars={this.submitSars}
        />
        {
          !!this.state.instanceIds.size && (
            <Instances
              instanceIds={this.state.instanceIds}
              removeInstance={this.removeInstance}
            />
          )
        }
      </div>
    );
  }

  private readonly invertSars: () => void = () => (
    // tslint:disable-next-line: no-void-expression
    this.setState(({ selectedSars }) => ({
      dirty: true,
      selectedSars: invertFromArrayAndSet(this.webbankFiles, selectedSars, s => s.id)
    }))
  )

  private readonly addSar: (id: string) => void = id => (
    // tslint:disable-next-line: no-void-expression
    this.setState(({ selectedSars }) => ({
      dirty: true,
      selectedSars: new Set(selectedSars).add(id),
    }))
  )

  private readonly addInstance: (id: string) => void = id => (
    // tslint:disable-next-line: no-void-expression
    this.setState(({ instanceIds }) => ({
      instanceIds: new Set(instanceIds).add(id),
    }))
  )

  private readonly removeSar: (id: string) => void = id => (
    // tslint:disable-next-line: no-void-expression
    this.setState(({ selectedSars }) => {
      const setClone: Set<string> = new Set(selectedSars);
      setClone.delete(id);

      return {
        dirty: true,
        selectedSars: setClone,
      };
    })
  )

  private readonly submitSars: () => void = () => {
    this.setState({ dirty: false });
    this.props.submitSars({
      variables: {
        sarIds: [...this.state.selectedSars],
        event: 'submit',
        clientMutationId: '0',
        reason: null
      }
    })
      .then(() => this.setState({ dirty: false }))
      .catch(() => this.setState({ dirty: true }));
  }

  private readonly removeInstance: (id: string) => void = id => (
    // tslint:disable-next-line: no-void-expression
    this.setState(({ instanceIds }) => {
      const setClone: Set<string> = new Set(instanceIds);
      setClone.delete(id);

      return {
        instanceIds: setClone,
      };
    })
  )

  private readonly checkboxHandler: ToggleHandler = ({ currentTarget: { dataset: { name } } }) => (
    name && (this.state.selectedSars.has(name) ? this.removeSar(name) : this.addSar(name))
  )

  private readonly generateFile: () => void = async () => {
    try {
      this.setState({ dirty: false });

      const response = await this.props.generateFile({
        variables: {
          inputs: { ids: [...this.state.selectedSars], includeNarrative: this.state.includeNarrative },
          clientMutationId: '0'
        }
      });
      if (!response) { throw new Error('Missing response'); }
      const { data } = response;

      if (!data) {
        throw new Error('Missing data');
      }

      const id: string | null = get(data.runGardenTask, 'job.id', null);
      if (id) {
        this.setState({ dirty: false, selectedSars: new Set() });
        this.addInstance(id);
      } else {
        throw new Error('Missing id');
      }
    } catch (e) {
      ErrorNoficiation.notify('Error starting job', 'File Generation', 'error');
      this.setState({ dirty: true });
    }
  }

  private readonly toggleNarrative: () => void = () => (
    // tslint:disable-next-line: no-void-expression
    this.setState(({ includeNarrative }) => ({ includeNarrative: !includeNarrative }))
  )
}

const LiftedFileGeneration: React.FC = () => (
  <Mutation<GenerateFileOutput, GenerateFileInput>
    mutation={GenerateFileQuery}
  >
    {generateFile => (
      <Mutation<TransitionSARs, TransitionSARsInput>
        mutation={TransitionQuery}
      >
        {submitSars => (
          <Query<GetPage, GetPageInput>
            query={GetPageQuery}
            variables={{ status: PARTNER_REVIEW_STEP }}
          >
            {({data, loading}) => (
              <FileGeneration
                submitSars={submitSars}
                generateFile={generateFile}
                data={data}
                loading={loading}
              />
            )}
          </Query>
        )}
      </Mutation>
    )}
  </Mutation>
);

const PermissionedFileGeneration: React.FC = () => (
  <PermissionedComponent allowedPermissions={[PARTNER_APPROVED_STEP_PSAE]}>
    <LiftedFileGeneration />
  </PermissionedComponent>
);

export default PermissionedFileGeneration;
