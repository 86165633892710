/* eslint-disable */
class Heap {
  public readonly initializeHeap: () => void = () => {
    const HEAP_ID = process.env.HEAP_ID;

    if (!HEAP_ID) { return; }
    try {
      // @ts-ignore
      window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
      // @ts-ignore
      window.heap.load(HEAP_ID);

      this.clearEventProperties();
    } catch (_e) {}
  }

  public readonly addUserProperties: (values: { [s: string]: string }) => void = values => {
    this.runHeapFn(() => this.heap.addUserProperties(values));
  };

  public readonly addEventProperties: (values: { [s: string]: string }) => void = values => {
    this.runHeapFn(() => this.heap.addEventProperties(values));
  };

  public readonly removeEventProperty: (key: string) => void = key => {
    this.runHeapFn(() => this.heap.removeEventProperty(key));
  };

  public readonly clearEventProperties: () => void = () => {
    this.runHeapFn(() => this.heap.clearEventProperties());
  };

  public readonly identify: (identity: string) => void = identity => {
    this.runHeapFn(() => this.heap.identify(identity));
  };

  public readonly resetIdentity: () => void = () => {
    this.runHeapFn(() => this.heap.resetIdentity());
  };

  private readonly runHeapFn: (fn: () => void) => void = fn => {
    if (!!this.heap) {
      fn();
    }
  };

  // tslint:disable-next-line: no-any
  private get heap (): any {
    // @ts-ignore
    return window.heap;
  }
}

const heapHelper = new Heap();
export default heapHelper;
