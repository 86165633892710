import * as React from 'react';
import { uniq } from 'lodash'
import styled from 'styled-components';
import {
  Input,
  theme
} from '@amount/frontend-components'

import { SELECT_ALL } from './ChartCommon';

const borderBox = `${theme.input.borderWidth} solid ${theme.input.colorBorder}`
const DEBOUNCE_TIMER = 1000;

const DropDownContentWrap = styled.div`
  position: absolute;
  top: 3.5em;
  left: 0;
  width: 150px;
  background-color: #fff;
  z-index: 1000;
  box-shadow: ${({ theme }) => theme.card.boxShadow};
`;

const DropDownItem = styled.button`
  width: 100%;

  :hover {
    background-color: ${({ theme }) => theme.colorBackground};
  }
`

const DropDownContainer = styled.div`
  padding: 10px;
`

const Container = styled.div`
  position: relative;
`

const DropDownInput: React.FC<{
  allData: string[],
  name: string,
  maxLength: number,
  onDebouncedChange(buffer: string): void,
  onSelect(branchId: string): React.MouseEventHandler<HTMLButtonElement>
}> = ({
  allData,
  name,
  maxLength,
  onDebouncedChange,
  onSelect
}) => {
  const [buffer, setBuffer] = React.useState<string>(SELECT_ALL)
  const [show, setShow] = React.useState<boolean>(false)
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timer>()

  const divEl = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    const id = setTimeout(() => {
      onDebouncedChange(buffer.length === maxLength ? buffer : SELECT_ALL)
    }, DEBOUNCE_TIMER)
    setTimeoutId(id)
  }, [buffer])

  const handleClickOutside = (e: MouseEvent) => {
    if (!show || !divEl) {
      return;
    }

    const inputCurrent = divEl.current
    const target = e.target as Element

    if (!inputCurrent || inputCurrent.contains(target) || target === inputCurrent) {
      return;
    }
    setShow(false)
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false)

    return () => document.removeEventListener('mousedown', handleClickOutside, false)
  })

  const handleClick = () => {
    setShow(state => !state)
  }

  const elements = React.useMemo(
    () => {
      if (!buffer || buffer.length < 2) {
        return []
      }
      return uniq(allData.filter(d => d.match(new RegExp(buffer, 'i'))).sort())
    },
    [buffer]
  )

  const handleSelect = (input: string): React.MouseEventHandler<HTMLButtonElement> => () => {
    setBuffer(input)
    onSelect(input)
  }

  return (
    <Container
      ref={divEl}
    >
      <Input
        data-lpignore={true}
        autoComplete='off'
        type='text'
        name={name}
        key={name}
        value={buffer === SELECT_ALL ? '' : buffer}
        onChange={({ target: { value }}) => setBuffer(value)}
        style={{
          border: borderBox
        }}
        onClick={handleClick}
        maxLength={maxLength}
      />
      {show && (buffer.length < maxLength) && (
        <DropDownContentWrap>
          {elements.map(e => (
            <DropDownItem
              onClick={handleSelect(e)}
            >
              <DropDownContainer>
                {e}
              </DropDownContainer>
            </DropDownItem>
          ))}
        </DropDownContentWrap>
      )}
    </Container>
  )
}

export default DropDownInput

