import * as React from 'react';
import { Link } from 'react-router-dom';
// tslint:disable: no-submodule-imports
// @ts-ignore
import * as CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { format } from 'date-fns';
import styled from 'styled-components';
import { media } from '@amount/frontend-components';
// tslint:enable

import TimeWidget from '../../TimeWidget';
import { SelectionOnNode } from '../getPage.graphql';
import { ModuleRoutes } from '../../../../shared/routes';
import { PSAE_DATE_FORMAT } from '../common/constants';

const StyledRow = styled(CSSTransitionGroup)`
  td {
    max-width: 6em;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
  ${media.large`
    td:first-child {
      max-width: none;
      word-break: normal;
      word-wrap: normal;
      overflow-wrap: normal;
    }
    td:not(:first-child)  {
      max-width: 6em;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  `}
`;

export interface IRowProps extends React.ClassAttributes<HTMLTableRowElement>, SelectionOnNode {
  overrides?: object;
  rowIndex: number;
  showPartner?: boolean;
  isPartnerReview: boolean;
}

export const SARListRow: React.FC<IRowProps> = ({ overrides= {}, rowIndex, showPartner, isPartnerReview, ...info }) => (
  <StyledRow
    transitionName='fade'
    transitionEnterTimeout={0}
    transitionLeaveTimeout={0}
    transitionAppearTimeout={0}
    transitionAppear={true}
    component='tr'
    {...overrides}
  >
    <td><Link to={`${ModuleRoutes.psaes}/${info.id}`} data-event={`psaeLink-${rowIndex}`}>{info.name}</Link></td>
    {showPartner && <td>{info.partner}</td>}
    <td>{info.type}</td>
    <td>{info.status}</td>
    <td>{format(info.createdAt, PSAE_DATE_FORMAT)}</td>
    {isPartnerReview && <td>{info.dateEscalatedToPartner && format(info.dateEscalatedToPartner, PSAE_DATE_FORMAT)}</td>}
    <td><TimeWidget timestamp={info.updatedAt} /></td>
    <td>{info.lastViewedBy}</td>
    <td>{info.lastViewedAt && <TimeWidget timestamp={info.lastViewedAt} />}</td>
  </StyledRow>
);
