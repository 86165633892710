import * as React from 'react';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';

import { withPusherPresence, WithPusherPresence } from '../HOC/withPusher';

import SetLastViewedQuery, { SetLastViewed, SetLastViewedInput } from './setLastViewed.graphql';
import Narrative from './Workflow/Narrative';
import {
  usersViewing__Item__Email,
  usersViewing__List,
} from './style.css';

type RouteProps = RouteComponentProps<{ id: string }>;

const formatInitialsFromEmail: (email: string) => string = email => (
  email.split('@')[0].split('.').map(x => x[0]).filter(x => !!x).join('').toUpperCase()
);

const UsersViewing: React.FC<WithPusherPresence<{}>> = ({ members }) => {
  if (!members.size) { return null; }

  return (
    <ul className={usersViewing__List}>
      {[...members.values()].map(({ id, email }) => (
        <li key={id}>
          {formatInitialsFromEmail(email)}
          <div className={usersViewing__Item__Email}>{email}</div>
        </li>
      ))}
    </ul>
  );
};

class SARInfo extends React.Component<WithPusherPresence<RouteProps>> {
  public render (): JSX.Element {
    return (
      <Mutation<SetLastViewed, SetLastViewedInput>
          mutation={SetLastViewedQuery}
          variables={{ id: this.props.match.params.id }}
      >
      {setLastViewedOutput => (
        <Narrative editable={false} lastViewed={setLastViewedOutput}>
          <UsersViewing
            members={this.props.members}
          />
        </Narrative>
      )}
      </Mutation>
    );
  }
}

export default withRouter(withPusherPresence<RouteProps>(({ match: { params: { id } } }) => id)(SARInfo));
