import {
  Button, FieldSet, Headline, RadioCard
} from '@amount/frontend-components';
import styled from 'styled-components';
import camelCase from 'lodash-es/camelCase';
import * as React from 'react';

import { Organizations } from '../../../../shared/config/partners';
import { SelectionOnMe } from '../../Context/me.graphql';
import Modal from '../../Modal';
import { ButtonActionRow, CancelButton } from '../../Modal/common';
import { FLOATING_ORGANIZATION_VIEW_MAP } from '../../../../shared/config/userAdmin';
import { LogoCardContain, SwitcherLogo } from '../../Header/Presentational/PartnerSwitcher';

const RadioCardWrap = styled.span`
  position: relative;
`;

interface IOrgCards {
  organization: string | null;
  switchableOrgs: Organizations[];
  selectOrg (org: string): void;
}

const OrgCards: React.FC<IOrgCards> = ({ organization, switchableOrgs, selectOrg }) => {

  const [currentOrg, setCurrentOrg] = React.useState(organization);

  const selectOrgHandler = (org: string) => () => {
    setCurrentOrg(org);
    selectOrg(org);
  };

  React.useEffect(
    () => setCurrentOrg(organization),
    [organization]
  );

  return (
    <LogoCardContain>
      {switchableOrgs.map(p =>
        <RadioCardWrap key={p}>
          <RadioCard
            id={p}
            data-org={p}
            data-event={camelCase(`orgSwitcher ${p}`)}
            checked={currentOrg === p}
            readOnly={true}
            onClick={selectOrgHandler(p)}
          >
            <SwitcherLogo partner={p} />
          </RadioCard>
        </RadioCardWrap>
      )}
    </LogoCardContain>
  );
};

OrgCards.displayName = 'Org Cards';

export interface ILiftedPartnerSwitcherProps {
  me: SelectionOnMe;
  show: boolean;
  close (): void;
}

interface IDialogProps {
  currentOrg: string | null;
  switchableOrgs: Organizations[];
  onClose (): void;
  onChange (org: string): void;
}

export const Dialog = ({ onClose, onChange, currentOrg, switchableOrgs }: IDialogProps) => {
  const [selectedOrg, setSelectedOrg] = React.useState(currentOrg || '');
  const selectOrg = React.useCallback(() => onChange(selectedOrg), [selectedOrg]);

  React.useEffect(
    () => setSelectedOrg(currentOrg || ''),
    [currentOrg]
  );

  return (
    <>
      <Headline scale='medium'>Select the organization you would like to manage.</Headline>
      <FieldSet>
        <OrgCards
          organization={currentOrg}
          switchableOrgs={switchableOrgs}
          selectOrg={setSelectedOrg}
        />
      </FieldSet>
      <ButtonActionRow>
        <Button
          inline={true}
          onClick={selectOrg}
          data-event='saveChangePartner'
        >
          Switch
        </Button>
        <CancelButton
          onClick={onClose}
        >
          Cancel
        </CancelButton>
      </ButtonActionRow>
    </>
  );
};

Dialog.displayName = 'Organization Switcher Dialog';

interface IOrganizationSwitcherProps extends ILiftedPartnerSwitcherProps {
  me: SelectionOnMe;
  currentOrg: string | null;
  onChange (org: string): void;
}

export const OrganizationSwitcher = ({ onChange, close, show, me, currentOrg }: IOrganizationSwitcherProps) => {
  const [_currentOrg, setCurrentOrg] = React.useState(currentOrg);

  React.useEffect(
    () => {
      setCurrentOrg(currentOrg);
    },
    [currentOrg]
  );

  return (
    <Modal
      data-modal={true}
      close={close}
      show={show}
    >
      <Dialog
        onClose={close}
        onChange={onChange}
        currentOrg={_currentOrg}
        switchableOrgs={FLOATING_ORGANIZATION_VIEW_MAP[me.organization]}
      />
    </Modal>
  );
};
